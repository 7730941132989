import { Box, Grid } from "@mui/material";

export interface PageContainerProps {
  children: React.ReactNode;
}

export const PageContainer = ({
  children,
}: PageContainerProps): JSX.Element => {
  return (
    <Box sx={{ padding: "0 32px 32px 32px" }}>
      <Grid container gap={4}>
        {children}
      </Grid>
    </Box>
  );
};
