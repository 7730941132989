import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SmartDirectoryForm } from "@stories/molecules/SmartDirectoryForm/SmartDirectoryForm";


export const SmartDirectModal = ({ handleClose , open} : any) => {

  const title = "Add Supplier";

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={"bgBlueOverlay"}
    >
      <DialogTitle sx={{ fontFamily:'Inter , sens-serif' , fontSize: "24px", padding: "10px 24px 24px",  }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ maxWidth:'800px', padding: "20px 24px 24px",  }}>
        <SmartDirectoryForm handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
