import { ExpandLess } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";

interface MenuListItemExtrasProps {
  open: boolean;
  count?: number;
  showDisclosure: boolean;
}

function MenuListItemCount({ count }: { count: number }) {
  return (
    <Box
      borderRadius="8px"
      color="#414651"
      fontFamily="Roboto"
      fontSize="14px"
      height="24px"
      lineHeight="24px"
      marginRight="8px"
      minWidth="24px"
      padding="0 8px"
      textAlign="center"
      sx={{
        background: "#FFF",
      }}
    >
      {count}
    </Box>
  );
}

export function MenuListItemExtras({
  count = 0,
  open,
  showDisclosure,
}: MenuListItemExtrasProps) {
  if (!showDisclosure && !count) {
    return null;
  }
  return (
    <Grid container>
      {count > 0 && <MenuListItemCount count={count} />}
      {showDisclosure && <DisclosureIndicator open={open} />}
    </Grid>
  );
}

function DisclosureIndicator({ open }: { open: boolean }) {
  return (
    <ExpandLess
      sx={{
        transform: `rotate(${open ? "0deg" : "180deg"})`,
        transition: "transform 0.25s",
      }}
    />
  );
}
