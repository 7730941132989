import useConfig from "@hooks/utils/useConfig";
import {
  Box,
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Transitions from "@stories/ui-component/extended/Transitions";
import { IconChevronDown, IconChevronRight } from "@tabler/icons";
import { useState } from "react";
import { NavItemType } from "types";

import NavItem from "../NavItem";

// mini-menu - wrapper
const PopperStyledMini = styled(Popper)(({ theme }) => ({
  "&:before": {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    content: '""',
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 120,
  },
  minWidth: 180,
  overflow: "visible",
  zIndex: 1202,
}));

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

interface NavCollapseProps {
  loading: boolean;
  menu: NavItemType;
  level: number;
  parentId: string;
  setSelectedItem: (item: string[]) => void;
  selectedItem: string[];
}

const NavCollapse = ({
  level,
  loading,
  menu,
  parentId,
  selectedItem,
  setSelectedItem,
}: NavCollapseProps) => {
  const theme = useTheme();

  const { borderRadius } = useConfig();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);
  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
  >(null);

  const handleClickMini = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined,
  ) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClosePopper = () => {
    setOpen(false);
    setSelected(null);
    setAnchorEl(null);
  };

  const openMini = Boolean(anchorEl);

  // menu collapse & item
  const menus =
    menu.children?.map((item) => {
      switch (item.type) {
        case "collapse":
          return (
            <NavCollapse
              loading={loading}
              key={item.id}
              menu={item}
              level={level + 1}
              parentId={parentId}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          );
        case "item":
          return (
            <NavItem
              loading={loading}
              key={item.id}
              item={item}
              level={level + 1}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          );
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    }) || [];

  const isSelected = selected === menu.id;

  const menuIcon = menu.icon;

  const collapseIcon = (
    <IconChevronRight
      stroke={1.5}
      size="16px"
      style={{ marginBottom: "auto", marginTop: "auto" }}
    />
  );

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark" ? "text.primary" : "secondary.main";

  return (
    <>
      <>
        <ListItemButton
          sx={{
            borderRadius: `${borderRadius}px`,
            mb: 0.5,
            pl: 1.25,
            zIndex: 1201,
            ...(level === 1 &&
              theme.palette.mode !== "dark" && {
                "&.Mui-selected": {
                  "&:hover": {
                    background: theme.palette.secondary.light,
                    color: iconSelectedColor,
                  },
                  background: theme.palette.secondary.light,
                  color: iconSelectedColor,
                },
                "&:hover": {
                  background: theme.palette.secondary.light,
                },
              }),
            ...(level !== 1 && {
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
              "&:hover": {
                bgcolor: "transparent",
              },
              py: level === 1 ? 0 : 1,
            }),
          }}
          selected={isSelected}
          {...{
            onMouseEnter: handleClickMini,
            onMouseLeave: handleClosePopper,
          }}
          onClick={handleClickMini}
        >
          {menuIcon && (
            <ListItemIcon
              sx={{
                color: isSelected ? iconSelectedColor : textColor,
                minWidth: level === 1 ? 36 : 18,
                ...(level === 1 && {
                  "&:hover": {
                    bgcolor:
                      theme.palette.mode === "dark"
                        ? theme.palette.secondary.main + 25
                        : "secondary.light",
                  },
                  alignItems: "center",
                  borderRadius: `${borderRadius}px`,
                  height: 46,
                  justifyContent: "center",
                  width: 46,
                  ...(isSelected && {
                    "&:hover": {
                      bgcolor:
                        theme.palette.mode === "dark"
                          ? theme.palette.secondary.main + 30
                          : "red",
                    },
                    bgcolor:
                      theme.palette.mode === "dark"
                        ? theme.palette.secondary.main + 25
                        : "secondary.light",
                  }),
                }),
              }}
            >
              {menuIcon}
            </ListItemIcon>
          )}
          {level !== 1 && (
            <ListItemText
              primary={
                <Typography
                  variant={isSelected ? "h5" : "body1"}
                  color="inherit"
                  sx={{ my: "auto" }}
                >
                  {menu.title}
                </Typography>
              }
              secondary={
                menu.caption && (
                  <Typography
                    variant="caption"
                    sx={{ ...theme.typography.subMenuCaption }}
                    display="block"
                    gutterBottom
                  >
                    {menu.caption}
                  </Typography>
                )
              }
            />
          )}

          {openMini || open ? (
            collapseIcon
          ) : (
            <IconChevronDown
              stroke={1.5}
              size="16px"
              style={{ marginBottom: "auto", marginTop: "auto" }}
            />
          )}

          <PopperStyledMini
            open={openMini}
            anchorEl={anchorEl}
            placement="right-start"
            style={{
              zIndex: 2001,
            }}
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [-12, 0],
                },
              },
            ]}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    backgroundImage: "none",
                    boxShadow: theme.shadows[8],
                    mt: 1.5,
                    overflow: "hidden",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClosePopper}>
                    <Box>{menus}</Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyledMini>
        </ListItemButton>
      </>
    </>
  );
};

export default NavCollapse;
