import { getApiData, postBatchApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import * as React from "react";
import { decodeContractorEmployeeListModel } from "social-pro-common/decoders/contractorEmployee";
import { encodeContractorEmployeeProject } from "social-pro-common/decoders/contractorEmployeeProject";
import { ContractorEmployeeListModel } from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ContractorEmployeeProjectLineItem } from "social-pro-common/interfaces/contractorEmployeeProject";

export const useProjectEmployee = (
  projectId?: string,
  contractorPackageId?: string,
  organisationId?: string,
) => {
  const [employeeCount, setEmployeeCount] = React.useState<number>(0);
  const [employees, setEmployees] = React.useState<EmployeeLineItem[]>([]);
  const [isEmployeeLoading, setIsEmployeeLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listProjectEmployees = React.useCallback(
    async (
      projectId: string,
      contractorPackageId: string,
      organisationId: string,
    ): Promise<void> => {
      try {
        setIsEmployeeLoading(true);
        const employeeResult = await getApiData(
          "listProjectEmployees",
          "projectEmployee",
          projectId,
          {
            contractorId: organisationId,
            contractorPackageId,
          },
        );
        const decodedEmployees = decodeContractorEmployeeListModel(
          employeeResult.data as ContractorEmployeeListModel,
        );
        setEmployeeCount(decodedEmployees.totalEmployees);
        setEmployees(decodedEmployees.employees);
      } catch (error) {
        catchSentryError(error);
        setError("Could not list employees");
      } finally {
        setIsEmployeeLoading(false);
      }
    },
    [
      getApiData,
      decodeContractorEmployeeListModel,
      setEmployeeCount,
      setEmployees,
      setIsEmployeeLoading,
      setError,
      catchSentryError,
    ],
  );

  const updateEmployeeAssignment = React.useCallback(
    async (
      assignmentsToUpsert: ContractorEmployeeProjectLineItem[],
    ): Promise<void> => {
      try {
        const encodedAssignments = assignmentsToUpsert.map((employee) =>
          encodeContractorEmployeeProject(employee),
        );
        await postBatchApiData(
          "upsertEmployeeProjectAssignment",
          "projectEmployee",
          encodedAssignments,
        );

        setEmployees((prev) => {
          return prev.map((employee) => {
            const employeeAssignment = assignmentsToUpsert.find(
              (e) => e.contractorEmployeeId === employee.id,
            );
            if (employeeAssignment) {
              return {
                ...employee,
                assigned: employeeAssignment.assigned,
              };
            }
            return employee;
          });
        });
      } catch (error) {
        catchSentryError(error);
        setError("Could not update employee");
      }
    },
    [
      encodeContractorEmployeeProject,
      postBatchApiData,
      employees,
      setEmployees,
      catchSentryError,
      setError,
    ],
  );

  React.useEffect(() => {
    if (projectId && contractorPackageId && organisationId) {
      listProjectEmployees(projectId, contractorPackageId, organisationId);
    }
  }, [projectId, contractorPackageId, organisationId, listProjectEmployees]);

  return {
    employeeCount,
    employees,
    error,
    isEmployeeLoading,
    updateEmployeeAssignment,
  };
};
