import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import { CompanyForm } from "@stories/molecules/CompanyForm/CompanyForm";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { Elements } from "@stripe/react-stripe-js";
import { catchSentryError } from "@utils/sentry";
import { useEffect, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import {
  OrganisationLineItem,
  createDefaultOrganisation,
} from "social-pro-common/interfaces/organisation";
import { ProfileStatus } from "social-pro-common/interfaces/profile";

const stripePromise = null; //loadStripe("pk_test_JnqbQaHNT8HAWBT4hEiTQ72N");

export const EditAccount = () => {
  const { user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const {
    isAuthProfileLoading,
    updateUserOrganisation,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const [tempLogoUrl, setTempLogoUrl] = useState("");

  const [loading, setLoading] = useState(false);
  const [organisation, setOrganisation] = useState(
    userOrganisation || createDefaultOrganisation(),
  );

  useEffect(() => {
    if (userOrganisation && userOrganisation.organisationLogo) {
      setTempLogoUrl(userOrganisation.organisationLogo);
    }
    if (userOrganisation) {
      setOrganisation(userOrganisation);
    }
  }, [userOrganisation]);

  const finaliseProfile = async (updatedOrganisation: OrganisationLineItem) => {
    try {
      setLoading(true);
      if (userProfile) {
        if (userProfile.isAdmin) {
          await updateUserOrganisation(updatedOrganisation, {
            ...userProfile,
            status: ProfileStatus.Active,
          });
        }
      }
    } catch (e) {
      catchSentryError(e);
      toast("Could not save account, please contact support.", {
        type: "error",
      } as ToastOptions);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <Header
        loading={loading}
        mainTitle="Account Settings"
        subTitle={userOrganisation?.organisationName}
        buttons={[]}
        tabs={[]}
      />

      <Grid item md={12} mt={2}>
        <Elements stripe={stripePromise}>
          <CompanyForm
            loading={isAuthProfileLoading}
            handleNext={finaliseProfile}
            tempLogoUrl={tempLogoUrl}
            setTempLogoUrl={setTempLogoUrl}
            organisation={organisation}
          />
        </Elements>
      </Grid>
    </PageContainer>
  );
};
