import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSustainabilityAnalytics } from "@hooks/crud/analytics/useSustainabilityAnalytics";
import { Box, Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { ContractorAnalyticsEnvSustainability } from "@stories/molecules/ContractorAnalyticsEnvSustainability/ContractorAnalyticsEnviroSustainability";
import { useEffect, useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { dateToString } from "social-pro-common/utils/date";

import { Header } from "../Header/Header";

interface PackageDetailsEnviroSustainabilityProps {
  loading: boolean;
  project?: ProjectLineItem;
  subPackage?: ContractorPackageLineItem;
}
export const PackageDetailsEnviroSustainability = ({
  loading,
  project,
  subPackage,
}: PackageDetailsEnviroSustainabilityProps) => {
  const [reportId, _setReportDate] = useState<string>(dateToString(new Date()));

  const [greenMetric, setGreenMetric] = useState<GreenMetricLineItem>();
  const { isProjectLoading } = useProjectContext();

  const { isAnalysisLoading, sustainabilityAnalytics } =
    useSustainabilityAnalytics(reportId, project?.id, subPackage?.id);

  useEffect(() => {
    if (project) {
      setGreenMetric(project.greenMetrics.find((gm) => gm));
    }
  }, [project]);

  const isLoading = loading || isProjectLoading || isAnalysisLoading;

  const tabs = project?.greenMetrics.map((gm) => ({
    label: gm.reportTargetName,
  }));
  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={4}>
          <Header
            mainTitle="Environmental Sustainability"
            subTitle="Target Summary"
            loading={isLoading}
            tabs={tabs}
            isSubheading
            headerFontSizes={[18, 14]}
            headerGap={0}
          />
          <Box
            sx={{
              padding: "0 14px",
            }}
          >
            <ContractorAnalyticsEnvSustainability
              loading={isLoading}
              greenMetric={greenMetric}
              targets={
                greenMetric && sustainabilityAnalytics?.targets.length
                  ? sustainabilityAnalytics?.targets.filter(
                      (t) => t.greenMetricId === greenMetric?.id,
                    )
                  : []
              }
            />
          </Box>
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
