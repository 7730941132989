"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeForecastData = exports.decodeForecastData = exports.encodeForecastModel = exports.decodeForecastModel = void 0;
const string_1 = require("../../utils/string");
const decodeForecastModel = (forecastData) => {
    return {
        active: forecastData.active,
        colour: forecastData.colour,
        contractorPackageId: forecastData.contractor_package_id,
        created: new Date(forecastData.created),
        data: forecastData.data.map(exports.decodeForecastData),
        id: forecastData.forecast_model_id,
        modelName: forecastData.model_name,
        projectId: forecastData.project_id,
        projectLabourHourCommitmentId: forecastData.project_labour_hour_commitment_id,
        projectSocialSpendCommitmentId: forecastData.project_social_spend_commitment_id,
        units: forecastData.units,
    };
};
exports.decodeForecastModel = decodeForecastModel;
const encodeForecastModel = (forecastData) => {
    const safeimport = (0, string_1.makePostgresSafe)(forecastData);
    return {
        active: safeimport.active,
        colour: safeimport.colour,
        contractor_package_id: safeimport.contractorPackageId,
        created: safeimport.created,
        data: safeimport.data.map(exports.encodeForecastData),
        forecast_model_id: safeimport.id,
        model_name: safeimport.modelName,
        project_id: safeimport.projectId,
        project_labour_hour_commitment_id: safeimport.projectLabourHourCommitmentId,
        project_social_spend_commitment_id: safeimport.projectSocialSpendCommitmentId,
        units: safeimport.units,
    };
};
exports.encodeForecastModel = encodeForecastModel;
const decodeForecastData = (forecastData) => {
    return {
        created: new Date(forecastData.created),
        forecastModelId: forecastData.forecast_model_id,
        id: forecastData.forecast_data_id,
        reportId: forecastData.report_id,
        value: forecastData.value,
    };
};
exports.decodeForecastData = decodeForecastData;
const encodeForecastData = (forecastData) => {
    const safeimport = (0, string_1.makePostgresSafe)(forecastData);
    return {
        created: safeimport.created,
        forecast_data_id: safeimport.id,
        forecast_model_id: safeimport.forecastModelId,
        report_id: safeimport.reportId,
        value: safeimport.value || 0,
    };
};
exports.encodeForecastData = encodeForecastData;
