import { useSupportingDocumentForEmployee } from "@hooks/crud/supportingDocuments/useSupportingDocumentForEmployee";
import { downloadFile } from "@hooks/utils/useUpload";
import { Grid } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import {
  SupportingDocumentLineItem,
  supportingDocumentTypeToString,
} from "social-pro-common/interfaces/supportingDocument";
import { getDocumentName } from "social-pro-common/utils/string";

import { EmployeeSupportingDocumentSubTableSkeleton } from "./EmployeeSupportingDocumentSubTableSkeleton";

interface EmployeeSupportingDocumentSubTableProps {
  employee: EmployeeLineItem;
}

interface ActionCellProps extends CustomCellRendererProps {
  isDownloading: boolean;
  loading: boolean;
}

export const EmployeeSupportingDocumentSubTable = ({
  employee,
}: EmployeeSupportingDocumentSubTableProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { isSupportingDocumentLoading, supportingDocuments } =
    useSupportingDocumentForEmployee(employee.id);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const loading = isSupportingDocumentLoading;

  const LoadingCell = ({ data, isDownloading, loading }: ActionCellProps) => {
    return (
      <StyledButton
        loading={loading}
        variant="contained"
        size="small"
        disabled={isDownloading}
        onClick={() =>
          data.uri
            ? onDownload(
                data.uri,
                getDocumentName(
                  supportingDocumentTypeToString(data.supportingDocumentType),
                  data.uri,
                ),
              )
            : undefined
        }
        className="blackBtn"
        sx={{
          color: "white",
          fontSize: "12px",
          lineHeight: "16px",
          padding: "5px 10px",
        }}
      >
        Download
      </StyledButton>
    );
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      cellStyle: { textAlign: "left" },
      field: "created",
      headerClass: "left-table-header",
      headerName: "Date",
      valueFormatter: (params) =>
        `${params.data.created.toLocaleDateString("en-GB")}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: { textAlign: "left" },
      field: "supportingDocumentType",
      headerClass: "left-table-header",
      headerName: "Document Name",
      valueFormatter: (params) =>
        `${supportingDocumentTypeToString(params.data.supportingDocumentType)}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: LoadingCell,
      cellRendererParams: {
        isDownloading,
        loading,
      },
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.7,
      headerClass: "centered-table-header",
      headerName: "Download",
    },
  ]);

  return (
    <Grid item md={12}>
      {loading || !supportingDocuments ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={EmployeeSupportingDocumentSubTableSkeleton}
        />
      ) : (
        <Table<SupportingDocumentLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={supportingDocuments || []}
        />
      )}
    </Grid>
  );
};
