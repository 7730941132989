import { TableCell, Typography, styled } from "@mui/material";

const StyledTableCell = styled(TableCell)({
  borderBottom: "none",
  color: "#535862",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  padding: "6px 0",
  whiteSpace: "nowrap",
});

const StyledTableHeadCell = styled(TableCell)({
  borderBottom: "none",
  color: "#717680 !important",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",
  padding: "6px 0",
  whiteSpace: "nowrap",
});

const StyledHeading = styled(Typography)({
  color: "#181D27",
  fontFamily: "'Inter', sans-serif",
  fontSize: "18px",
  fontWeight: 600,
});

const StyledSubHeading = styled(Typography)({
  color: "#535862",
  fontFamily: "'Inter', sans-serif",
  fontSize: "14px",
  fontWeight: 400,
});

export {
  StyledHeading,
  StyledTableCell,
  StyledTableHeadCell,
  StyledSubHeading,
};
