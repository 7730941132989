"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProfile = exports.decodeProfile = void 0;
const string_1 = require("../../utils/string");
const decodeProfile = (profile) => {
    return {
        created: new Date(profile.created),
        email: profile.email,
        emailNotifications: profile.email_notifications,
        id: profile.profile_id,
        isAdmin: profile.is_admin,
        isContractor: profile.is_contractor,
        isSetup: profile.is_setup,
        name: profile.name,
        organisationId: profile.organisation_id,
        status: profile.status,
    };
};
exports.decodeProfile = decodeProfile;
const encodeProfile = (profile) => {
    const safeProfile = (0, string_1.makePostgresSafe)(profile);
    return {
        created: new Date(safeProfile.created),
        email: safeProfile.email,
        email_notifications: safeProfile.emailNotifications,
        is_admin: safeProfile.isAdmin,
        is_contractor: safeProfile.isContractor,
        is_setup: safeProfile.isSetup,
        name: safeProfile.name,
        organisation_id: safeProfile.organisationId,
        profile_id: safeProfile.id,
        status: safeProfile.status,
    };
};
exports.encodeProfile = encodeProfile;
