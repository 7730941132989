import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography/Typography";
import * as RouteHelper from "@utils/routes";
import {
  faqSet1,
  faqSet2,
  faqSet3,
  faqSet4,
  faqSet5,
  faqSet6,
  faqSet7,
  faqSet8,
} from "constant";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";

import { faqSection } from "./FAQCards";
import FAQAccordion from "./FAQSectionAccordion";

export const FAQInnerContainerAccordian = () => {
  const navigate = useNavigate();
  const p = useParams();

  if (!p.faqId) {
    return <div>Invalid FAQ ID</div>;
  }

  const faqs = (() => {
    switch (p.faqId) {
      case "1":
        return faqSet1;
      case "2":
        return faqSet2;
      case "3":
        return faqSet3;
      case "4":
        return faqSet4;
      case "5":
        return faqSet5;
      case "6":
        return faqSet6;
      case "7":
        return faqSet7;
      case "8":
        return faqSet8;
      default:
        return [];
    }
  })();

  const faqHeader = faqSection.find((faq) => faq.id === p.faqId);

  const handleOnBack = () => {
    navigate({
      pathname: RouteHelper.platformSupport,
      search: createSearchParams({
        tab: "faqs",
      }).toString(),
    });
  };
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          padding: "0",
        }}
      >
        <Grid
          item
          md={12}
          sx={{ margin: "0px", padding: "20px 40px !important" }}
          className="borderBottom"
        >
          <Stack direction="column">
            <Typography
              variant="h2"
              component="div"
              fontWeight="bold"
              fontSize="28px"
            >
              FAQs
            </Typography>
            <Typography sx={{ mt: 1.5 }} variant="h4" fontWeight="normal">
              Answers to common SocialPro Questions
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ margin: "0px", padding: "20px 40px !important" }}
        >
          <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
            <Tooltip TransitionComponent={Zoom} placement="top" title="Back">
              <IconButton
                data-test-id="back-button-top"
                size="large"
                onClick={handleOnBack}
                sx={{
                  color: "black",
                  height: "auto",
                  padding: "5px",
                  width: "auto",
                }}
              >
                <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "large" }} />
              </IconButton>
            </Tooltip>
            <Stack>
              <Typography variant="h2">{faqHeader?.title}</Typography>
              <Typography variant="subtitle1">
                {faqHeader?.description}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          md={12}
          item
          sx={{
            padding: "0px 40px 10px",
          }}
        >
          {faqs.map((faq, index) => (
            <FAQAccordion key={index} index={index} faq={faq} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
