import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackageDetails } from "@hooks/crud/contractorPackages/useContractorPackageDetails";
import { useGreenMetricCategorySubmission } from "@hooks/crud/greenMetricCategorySubmission/useGreenMetricCategorySubmission";
import { useGreenMetricComment } from "@hooks/crud/greenMetricComment/useGreenMetricComment";
import { Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SustainabilitySteps } from "@stories/molecules/SustainabilitySteps/SustainabilitySteps";
import { Sustainability } from "@stories/organisms/Sustainability/Sustainability";
import * as RouteHelper from "@utils/routes";
import { useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GreenMetricCategorySubmissionLineItem } from "social-pro-common/interfaces/greenMetricCategorySubmission";

export const ReadOnlyReportSustainability = () => {
  const { contractorPackageId, packageId, projectId, reportId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (!reportId) {
    throw Error("Not report number");
  }
  if (!contractorPackageId) {
    throw Error("Not contractorPackageId");
  }
  if (!packageId) {
    throw Error("Not packageId");
  }

  const handleOnBack = () => {
    if (projectId && packageId && reportId) {
      const redirect = searchParams.get("redirect");

      if (redirect) {
        navigate(redirect);
      } else {
        navigate({
          pathname: RouteHelper.contractorPackage(
            projectId,
            contractorPackageId,
            packageId,
          ),
          search: createSearchParams({
            tab: "reports",
          }).toString(),
        });
      }
    }
  };

  const { isAuthProfileLoading } = useProfileContext();

  const { contractorPackageDetails, isContractorPackageLoading } =
    useContractorPackageDetails(packageId);

  const {
    greenMetricCategorySubmissions,
    isGreenMetricCategorySubmissionLoading,
    updateGreenMetricCategorySubmission,
  } = useGreenMetricCategorySubmission(contractorPackageDetails?.id, reportId);

  const handleUpdate = async (
    greenMetricCategorySubmission: GreenMetricCategorySubmissionLineItem,
  ) => {
    setIsSubmitting(true);
    await updateGreenMetricCategorySubmission(greenMetricCategorySubmission);
    setIsSubmitting(false);
  };

  const { isProjectLoading, selectedProject } = useProjectContext();

  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { greenMetricComments, isGreenMetricCommentsLoading } =
    useGreenMetricComment(contractorPackageDetails?.id, reportId);

  const updateComment = async () => {
    console.log("Not implemented");
  };

  const greenMetrics = selectedProject?.greenMetrics || [];

  const loading =
    (isAuthProfileLoading ||
      isProjectLoading ||
      isContractorPackageLoading ||
      isGreenMetricCategorySubmissionLoading ||
      isGreenMetricCommentsLoading) &&
    !isSubmitting;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
  };

  const wizardComponents = greenMetrics.map((metric) => (
    <Sustainability
      key={metric.id}
      loading={loading}
      project={selectedProject}
      reportId={reportId}
      greenMetric={metric}
      contractorPackage={contractorPackageDetails}
      greenMetricCategorySubmissions={greenMetricCategorySubmissions}
      isSubmitting={isSubmitting}
      commentValue={
        greenMetricComments.find((gc) => gc.greenMetricId === metric?.id)
          ?.comment || ""
      }
      setIsSubmitting={setIsSubmitting}
      createGreenMetricCategorySubmission={handleUpdate}
      updateGreenMetricCategorySubmission={handleUpdate}
      updateComment={updateComment}
      handleOnBack={handleOnBack}
      readOnly
    />
  ));
  const selectedWizardStep = wizardComponents[activeStep];

  return (
    <>
      <Grid container spacing={2} sx={{ margin: "0", padding: "30px 20px" }}>
        <Grid item xs={12} md={12} className="steps">
          <SustainabilitySteps
            loading={loading}
            activeStep={activeStep}
            greenMetrics={selectedProject?.greenMetrics || []}
            readOnly
          />
        </Grid>
      </Grid>
      {selectedProject && contractorPackageDetails
        ? selectedWizardStep
        : undefined}
      {!selectedProject || !contractorPackageDetails ? (
        <Sustainability
          loading
          project={selectedProject}
          reportId={reportId}
          contractorPackage={contractorPackageDetails}
          greenMetricCategorySubmissions={greenMetricCategorySubmissions}
          isSubmitting={isSubmitting}
          commentValue={""}
          setIsSubmitting={setIsSubmitting}
          createGreenMetricCategorySubmission={handleUpdate}
          updateGreenMetricCategorySubmission={handleUpdate}
          updateComment={updateComment}
        />
      ) : undefined}
      <Grid
        container
        sx={{
          display: "flex !important",
          justifyContent: "space-between",
          padding: "0 40px 80px",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "0 !important",
            textAlign: "right",
          }}
        >
          <Stack direction="row" spacing={1}>
            {activeStep > 0 ? (
              <StyledButton
                loading={loading}
                onClick={handleBack}
                variant="outlined"
              >
                Back
              </StyledButton>
            ) : null}

            {activeStep < wizardComponents.length - 1 ? (
              <StyledButton loading={loading} onClick={handleNext}>
                Next
              </StyledButton>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
