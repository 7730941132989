"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultProfileProjectAccessLineItem = exports.profileProjectAccessLevelToString = void 0;
const profileProjectAccess_1 = require("../../entities/profileProjectAccess");
const profileProjectAccessLevelToString = (type) => {
    switch (type) {
        case profileProjectAccess_1.ProfileProjectAccessLevel.Admin:
            return "Admin";
        case profileProjectAccess_1.ProfileProjectAccessLevel.Write:
            return "Reporter";
        case profileProjectAccess_1.ProfileProjectAccessLevel.View:
            return "Viewer";
        case profileProjectAccess_1.ProfileProjectAccessLevel.NoAccess:
            return "No Access";
    }
};
exports.profileProjectAccessLevelToString = profileProjectAccessLevelToString;
const createDefaultProfileProjectAccessLineItem = (projectId, organisationId, profileId) => {
    return {
        accessLevel: profileProjectAccess_1.ProfileProjectAccessLevel.NoAccess,
        created: new Date(),
        id: `${profileId}-${projectId}`,
        organisationId,
        profileId,
        projectId,
    };
};
exports.createDefaultProfileProjectAccessLineItem = createDefaultProfileProjectAccessLineItem;
