"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProjectReportSubmission = exports.decodeProjectReportSubmission = void 0;
const string_1 = require("../../utils/string");
const decodeProjectReportSubmission = (reportSubmission) => {
    return {
        created: new Date(reportSubmission.created),
        id: reportSubmission.project_report_submission_id,
        projectId: reportSubmission.project_id,
        reportId: reportSubmission.report_id,
        reportStatus: reportSubmission.report_status,
    };
};
exports.decodeProjectReportSubmission = decodeProjectReportSubmission;
const encodeProjectReportSubmission = (reportSubmission) => {
    const safeReportSubmission = (0, string_1.makePostgresSafe)(reportSubmission);
    return {
        created: safeReportSubmission.created,
        project_id: safeReportSubmission.projectId,
        project_report_submission_id: safeReportSubmission.id,
        report_id: safeReportSubmission.reportId,
        report_status: safeReportSubmission.reportStatus,
    };
};
exports.encodeProjectReportSubmission = encodeProjectReportSubmission;
