"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeContactInfo = exports.decodeContactInfo = void 0;
const string_1 = require("../../utils/string");
const decodeContactInfo = (contactInfo) => {
    return {
        addressLine1: contactInfo.address_line_1,
        addressLine2: contactInfo.address_line_2,
        city: contactInfo.city,
        country: contactInfo.country,
        faxNumber: contactInfo.fax_number,
        geoLocationLat: contactInfo.geo_location_lat,
        geoLocationLong: contactInfo.geo_location_long,
        id: contactInfo.contact_info_id,
        phoneNumber: contactInfo.phone_number,
        postCode: contactInfo.post_code,
        state: contactInfo.state,
    };
};
exports.decodeContactInfo = decodeContactInfo;
const encodeContactInfo = (contactInfo) => {
    const safeContactInfo = (0, string_1.makePostgresSafe)(contactInfo);
    return {
        address_line_1: safeContactInfo.addressLine1,
        address_line_2: safeContactInfo.addressLine2,
        city: safeContactInfo.city,
        contact_info_id: safeContactInfo.id,
        country: safeContactInfo.country,
        fax_number: safeContactInfo.faxNumber,
        geo_location_lat: safeContactInfo.geoLocationLat,
        geo_location_long: safeContactInfo.geoLocationLong,
        phone_number: safeContactInfo.phoneNumber,
        post_code: safeContactInfo.postCode,
        state: safeContactInfo.state,
    };
};
exports.encodeContactInfo = encodeContactInfo;
