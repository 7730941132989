import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { Grid } from "@mui/material";
import ContractorPackageModal from "@stories/organisms/ContractorPackageModal/ContractorPackageModal";
import { Header, HeaderButtonType } from "@stories/organisms/Header/Header";
import OneTimeContractorPackageModal from "@stories/organisms/OneTimeContractorPackageModal/OneTimeContractorPackageModal";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { ContractorOneTimePackageTable } from "@stories/organisms/Tables/ContractorOneTimePackageTable";
import { ContractorPackageTable } from "@stories/organisms/Tables/ContractorPackageTable/ContractorPackageTable";
import { userHasManagerAccess, userIsAdmin } from "@utils/projectAccess";
import { AddIcon } from "assets/constants";
import { useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const ContractorPackages = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleSetTabIndex = async (newValue: number) => {
    setTabIndex(newValue);
  };

  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const {
    contractorPackages,
    createContractorPackageSetUp,
    deleteContractorPackage,
    getContractorPackageSetup,
    isContractorPackageLoading,
    resendInvite,
    updateContractorOneTimePackage,
    updateContractorPackage,
  } = useContractorPackage(selectedProject?.id);

  const [selectedPackageId, setSelectedPackageId] = useState<string>();

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openOneTimeModal, setOpenOneTimeModal] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleClose = () => {
    setOpenEditModal(false);
    setSelectedPackageId(undefined);
  };
  const handleOpen = async () => {
    setSelectedPackageId(undefined);
    setOpenEditModal(true);
  };

  const handleCloseOneTime = () => {
    setOpenOneTimeModal(false);
    setSelectedPackageId(undefined);
  };

  const handleOpenOneTime = async () => {
    setSelectedPackageId(undefined);
    setOpenOneTimeModal(true);
  };

  const handleEditPackage = (contractorPackage: ContractorPackageLineItem) => {
    setSelectedPackageId(contractorPackage.id);

    if (tabIndex) {
      setOpenOneTimeModal(true);
    } else {
      setOpenEditModal(true);
    }
  };

  const handleResendInvite = (contractorPackage: ContractorPackageLineItem) => {
    resendInvite(contractorPackage.id);
  };

  const handleDeletePackage = (
    contractorPackage: ContractorPackageLineItem,
  ) => {
    deleteContractorPackage(contractorPackage);
  };

  contractorPackages.sort((a, b) => {
    if (a.contractorId !== b.contractorId) {
      return a.contractorId === userOrganisation?.id ? -1 : 1;
    }
    return a.created.getTime() - b.created.getTime();
  });

  const filteredPackages = contractorPackages.filter((contractorPackage) =>
    tabIndex === 0
      ? contractorPackage.packageType !== PackageType.OneTime
      : contractorPackage.packageType === PackageType.OneTime,
  );

  const loading =
    isContractorPackageLoading || isAuthProfileLoading || isProjectLoading;

  const tabs = [
    {
      label: "Subcontractors",
    },
    {
      label: "Suppliers",
    },
  ];

  const buttons =
    userIsAdmin(userProfile) || userHasManagerAccess(selectedProject)
      ? [
          {
            action: tabIndex ? handleOpenOneTime : handleOpen,
            icon: <AddIcon variant="secondary" />,
            text: "Add Package",
            type: HeaderButtonType.PRIMARY,
            width: 140,
          },
        ]
      : [];
  return (
    <PageContainer>
      <Header
        loading={loading}
        mainTitle="Subcontractors and Suppliers"
        subTitle="Manage Project Accounts"
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        tabs={tabs}
        handleTabChange={handleSetTabIndex}
        tabIndex={tabIndex}
      />

      <Grid item xs={12} md={12}>
        {tabIndex ? (
          <ContractorOneTimePackageTable
            loading={loading}
            project={selectedProject}
            searchTerm={searchTerm}
            userProfile={userProfile}
            contractorPackages={filteredPackages}
            resendInvite={handleResendInvite}
            handleEditPackage={handleEditPackage}
            handleDeletePackage={handleDeletePackage}
          />
        ) : (
          <ContractorPackageTable
            loading={loading}
            project={selectedProject}
            searchTerm={searchTerm}
            contractorPackage={contractorPackage}
            userProfile={userProfile}
            contractorPackages={filteredPackages}
            resendInvite={handleResendInvite}
            handleEditPackage={handleEditPackage}
            handleDeletePackage={handleDeletePackage}
          />
        )}
      </Grid>
      {selectedProject && openEditModal ? (
        <ContractorPackageModal
          open={openEditModal}
          loading={loading}
          handleClose={handleClose}
          project={selectedProject}
          createContractorPackage={createContractorPackageSetUp}
          updateContractorPackage={updateContractorPackage}
          selectedPackageId={selectedPackageId}
          packageCount={filteredPackages.length}
        />
      ) : null}
      {selectedProject && openOneTimeModal ? (
        <OneTimeContractorPackageModal
          open={openOneTimeModal}
          loading={loading}
          handleClose={handleCloseOneTime}
          getContractorOneTimePackage={getContractorPackageSetup}
          createContractorOneTimePackage={createContractorPackageSetUp}
          updateContractorOneTimePackage={updateContractorOneTimePackage}
          project={selectedProject}
          selectedPackageId={selectedPackageId}
          packageCount={filteredPackages.length}
        />
      ) : null}
    </PageContainer>
  );
};
