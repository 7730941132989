import { Box, ListItemButton, ListItemText } from "@mui/material";
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import { Link, useLocation } from "react-router-dom";
import { LinkTarget, NavItemType } from "types";

import { MenuListItemExtras } from "./MenuListItemExtras";
import { MENU_LIST_ITEM_VARIANTS, TEXT_COLORS } from "../constants";

interface MenuListItemProps extends React.PropsWithChildren {
  active: boolean;
  counter?: number;
  menuItem: NavItemType;
  openItem: string | null;
  variant: (typeof MENU_LIST_ITEM_VARIANTS)[keyof typeof MENU_LIST_ITEM_VARIANTS];
  handleToggle: (arg: string | null) => void;
}

const menuItemStyles = {
  item: {
    backgroundColor: "#143E7C",
    borderColor: "#143E7C",
  },
  subItem: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderColor: "rgba(255, 255, 255, 1)",
  },
  title: {
    backgroundColor: "transparent",
    borderColor: "rgba(255, 255, 255, 0.2)",
  },
} as const;

export function MenuListItem({
  active,
  handleToggle,
  menuItem,
  openItem,
  variant = "item",
}: MenuListItemProps) {
  const isTitleItem = variant === MENU_LIST_ITEM_VARIANTS.TITLE;
  const location = useLocation();
  const pathname = location?.pathname.split("/")[1];

  const hasActiveChild = menuItem.childrenIds?.includes(pathname);

  const isSelectedOrTitleItem = active || isTitleItem || hasActiveChild;

  const hoverStyle = isSelectedOrTitleItem
    ? { background: "none", cursor: "default", "pointer-events": "none" }
    : {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        boxShadow: "0px 1px 1px 0px #00000040",
      };

  const itemStyles = isSelectedOrTitleItem ? menuItemStyles[variant] : {};

  let itemTarget: LinkTarget = "_self";
  if (menuItem?.target) {
    itemTarget = "_blank";
  }

  const listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={menuItem.url || ""} target={itemTarget} />
    )),
  };

  listItemProps.component.displayName = "ListItemButton";

  return (
    <ListItemButton
      {...listItemProps}
      data-test-id={menuItem.id}
      onClick={() => {
        if (variant !== MENU_LIST_ITEM_VARIANTS.SUBITEM) {
          handleToggle(
            typeof menuItem.title === "string" ? menuItem.title : null,
          );
        }
      }}
      sx={{
        "&:hover": hoverStyle,
        borderRadius: "8px",
        height: "auto",
        mb: "8px",
        p: 0,
      }}
    >
      <Box
        border="solid 1px transparent"
        borderRadius="8px"
        display="grid"
        gridTemplateColumns="auto 1fr auto"
        gap="8px"
        padding="8px 12px"
        width="100%"
        {...itemStyles}
      >
        {menuItem.icon && menuItem.icon}
        <ListItemText
          primary={menuItem.title}
          sx={{
            "& .MuiTypography-root": {
              color: `${active && variant === MENU_LIST_ITEM_VARIANTS.SUBITEM ? TEXT_COLORS.ACTIVE : TEXT_COLORS.DEFAULT}`,
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "none",
            },
          }}
        />
        <MenuListItemExtras
          showDisclosure={!!menuItem.children?.length}
          count={menuItem.counter}
          open={openItem === menuItem.title}
        />
      </Box>
    </ListItemButton>
  );
}
