"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodePolicySubmissionCount = exports.encodePolicySubmission = exports.decodePolicySubmission = void 0;
const string_1 = require("../../utils/string");
const decodePolicySubmission = (policySubmission) => {
    return {
        contractorPackageId: policySubmission.contractor_package_id,
        created: new Date(policySubmission.created),
        id: policySubmission.policy_submission_id,
        projectId: policySubmission.project_id,
        projectPolicyId: policySubmission.project_policy_id,
        uri: policySubmission.uri,
    };
};
exports.decodePolicySubmission = decodePolicySubmission;
const encodePolicySubmission = (policySubmission) => {
    const safePolicySubmission = (0, string_1.makePostgresSafe)(policySubmission);
    return {
        contractor_package_id: safePolicySubmission.contractorPackageId,
        created: safePolicySubmission.created,
        policy_submission_id: safePolicySubmission.id,
        project_id: safePolicySubmission.projectId,
        project_policy_id: safePolicySubmission.projectPolicyId,
        uri: safePolicySubmission.uri,
    };
};
exports.encodePolicySubmission = encodePolicySubmission;
const decodePolicySubmissionCount = (policySubmissionCount) => {
    return {
        created: new Date(policySubmissionCount.created),
        expectedSubmissions: policySubmissionCount.expected_submissions,
        id: policySubmissionCount.project_policy_id,
        projectPolicyId: policySubmissionCount.project_policy_id,
        totalSubmissions: policySubmissionCount.total_submissions,
    };
};
exports.decodePolicySubmissionCount = decodePolicySubmissionCount;
