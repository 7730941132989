import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { Box, Grid, Typography } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  ArchiveProjectIcon,
  EditProjectIcon,
  ReloadIcon,
  ViewProjectIcon,
} from "assets/constants";
import { useState } from "react";
import {
  EmployeeLineItem,
  genderToString,
  occupationToString,
  occupationTypeOptionToString,
} from "social-pro-common/interfaces/contractorEmployee";

import { EmployeeTableRowSkeleton } from "./EmployeeTableRowSkeleton";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

interface EmployeeTableProps {
  loading: boolean;
  employees: EmployeeLineItem[];
  employeeCount: number;
  searchTerm: string;
  archived: boolean;
  handleOpenListModal: (employee: EmployeeLineItem) => void;
  handleEditEmployee: (employee: EmployeeLineItem) => void;
  handleArchiveEmployee: (employee: EmployeeLineItem) => void;
}

export const EmployeeTable = ({
  archived,
  employeeCount,
  employees,
  handleArchiveEmployee,
  handleEditEmployee,
  handleOpenListModal,
  loading,
  searchTerm,
}: EmployeeTableProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeLineItem | null>(null);

  const handleOpenConfirmDialog = (employee: EmployeeLineItem) => {
    setSelectedEmployee(employee);
    setOpenConfirmDialog(true);
  };

  const handleConfirm = () => {
    if (selectedEmployee) {
      handleArchiveEmployee(selectedEmployee);
    }
    setOpenConfirmDialog(false);
  };

  const cellActions = [
    {
      action: handleOpenListModal,
      icon: <ViewProjectIcon />,
      title: "View Employee",
    },
    {
      action: handleEditEmployee,
      icon: <EditProjectIcon />,
      title: "Edit Employee",
    },
    {
      action: handleOpenConfirmDialog,
      icon: !archived ? <ArchiveProjectIcon /> : <ReloadIcon />,
      title: !archived ? "Archive Employee" : "Restore Employee",
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "employeeName",
      flex: 2,
      headerName: "Name or Code",
      onCellClicked: (params) => handleOpenListModal(params.data),
      valueFormatter: (params) =>
        `${params.data.employeeName}${
          params.data.employeeIdentifier
            ? ` (${params.data.employeeIdentifier})`
            : ""
        }`,
    },
    {
      ...defaultCellConfig,
      field: "primaryEmployer",
      flex: 2,
      headerName: "Primary Employer",
      onCellClicked: (params) => handleOpenListModal(params.data),
    },
    {
      ...defaultCellConfig,
      field: "occupation",
      flex: 2,
      headerName: "Occupation",
      onCellClicked: (params) => handleOpenListModal(params.data),
      valueFormatter: (params) =>
        `${occupationToString(params.data.occupation)}`,
    },
    {
      ...defaultCellConfig,
      field: "occupationType",
      flex: 2,
      headerName: "Occupation Type",
      onCellClicked: (params) => handleOpenListModal(params.data),
      valueFormatter: (params) =>
        `${occupationTypeOptionToString(params.data.occupationType)}`,
    },
    {
      ...defaultCellConfig,
      field: "gender",
      headerName: "Gender",
      onCellClicked: (params) => handleOpenListModal(params.data),
      valueFormatter: (params) => `${genderToString(params.data.gender)}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{ backgroundColor: "white", borderRadius: "16px" }}
    >
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={EmployeeTableRowSkeleton}
        />
      ) : null}

      {!loading && employeeCount >= 0 ? (
        <Table<EmployeeLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={employees || []}
          context={{ cellActions }}
          searchValue={searchTerm}
          searchKeys={[
            "employeeIdentifier",
            "employeeName",
            "primaryEmployer",
            "occupation",
            "occupationType",
            "gender",
          ]}
        />
      ) : null}

      {!loading && employeeCount === -1 ? (
        <Grid
          data-test-id="empty-employee-table"
          item
          xs={12}
          md={12}
          sx={{
            alignItems: "center",
            background: "#E9F0F5",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            minHeight: "400px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
            }}
          >
            <GroupsOutlinedIcon
              sx={{ fontSize: "100px", height: "100px", width: "100px" }}
            />
            <Typography
              variant="h4"
              style={{ color: "#87959F", fontSize: "18px", fontWeight: "400" }}
            >
              Add an employee
            </Typography>
          </Box>
        </Grid>
      ) : null}

      <ConfirmationDialog
        message={
          selectedEmployee && !selectedEmployee.archived
            ? "Are you sure you want to archive this employee?"
            : "Are you sure you want to restore this employee?"
        }
        open={openConfirmDialog}
        title={
          selectedEmployee && !selectedEmployee.archived
            ? "Archive Employee"
            : "Restore Employee"
        }
        buttonText={
          selectedEmployee && !selectedEmployee.archived ? "Archive" : "Restore"
        }
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirm}
      />
    </Grid>
  );
};
