import InsightsIcon from "@mui/icons-material/Insights";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import { userHasEditorAccess } from "@utils/projectAccess";
import * as RouteHelper from "@utils/routes";
import { PieChart, ChartNoAxesCombined, HardHat, Users } from "lucide-react";
import {
  CalendarClock,
  CircleDollarSign,
  MapPinned,
  ScrollText,
} from "lucide-react";
import { ProjectAccessLevel } from "social-pro-common/entities/project";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getMaximalDate } from "social-pro-common/utils/date";
import { NavItemType } from "types";

export const getManageNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
): NavItemType => {
  const theme = useTheme();

  //   const currentDate = getMaximalDate(
  //     isOwner ? project?.projectStartDate : contractorPackage?.packageStartDate,
  //     isOwner ? project?.projectEndDate : contractorPackage?.packageEndDate,
  //   );

  const children = [];

  children.push({
    icon: <HardHat />,
    id: "packages",
    title: "Subcontractors",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.packages(project.id, contractorPackage.id)
        : "",
  });

  if (
    project?.projectAccessLevel === ProjectAccessLevel.Admin ||
    project?.projectAccessLevel === ProjectAccessLevel.Manager ||
    project?.projectAccessLevel === ProjectAccessLevel.Write
  ) {
    children.push({
      icon: <Users />,
      id: "project-employees",
      title: "Employees",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.projectEmployees(project.id, contractorPackage.id)
          : "",
    });
  }

  if (userHasEditorAccess(project)) {
    children.push({
      icon: <InsightsIcon />,
      id: "forecast",
      title: "Forecasting",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.forecast(project.id, contractorPackage.id)
          : "",
    });
  }

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "packages",
    title: "Manage",
    type: "group",
  };
};

export const getReducedPackageNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  isOwner?: boolean,
): NavItemType => {
  const currentDate = getMaximalDate(
    isOwner ? project?.projectStartDate : contractorPackage?.packageStartDate,
    isOwner ? project?.projectEndDate : contractorPackage?.packageEndDate,
  );

  const theme = useTheme();
  const children = [];

  children.push({
    icon: <PieChart />,
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.contractorDashboard(project.id, contractorPackage.id)
        : "",
  });

  const analyticsChildren = [];
  if (contractorPackage?.commitmentsHours.length) {
    analyticsChildren.push({
      icon: <CalendarClock />,
      id: "analytics-labour-hours",
      title: "Labour Hours",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsLabourHoursLevel1(
              project?.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }
  if (contractorPackage?.commitmentsSpend.length) {
    analyticsChildren.push({
      icon: <CircleDollarSign />,
      id: "analytics-spend",
      title: "Spend",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsSpendLevel1(
              project.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }

  const isPrimaryPackage = contractorPackage?.id === project?.id;
  if (project?.localContents.length) {
    if (isPrimaryPackage) {
      analyticsChildren.push({
        icon: <MapPinned />,
        id: "analytics-local-content",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsLocalContent(
                project.id,
                contractorPackage.id,
              )
            : "",
      });
    } else if (!isPrimaryPackage) {
      analyticsChildren.push({
        icon: <MapPinned />,
        id: "analytics-local-content",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsPackageLocalContentDetails(
                project.id,
                contractorPackage.id,
                contractorPackage.id,
              )
            : "",
      });
    }
  }
  if (project?.policies.length) {
    analyticsChildren.push({
      icon: <ScrollText />,
      id: "analytics-policies",
      title: "Policies",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsPolicies(project.id, contractorPackage.id)
          : "",
    });
  }

  children.push({
    children: analyticsChildren,
    icon: <ChartNoAxesCombined />,
    id: "project-employees",
    title: "Analytics",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.projectEmployees(project.id, contractorPackage.id)
        : "",
  });

  // children.push({
  //   icon: <Package />,
  //   id: "my-package",
  //   title: "My Package",
  //   type: "item",
  //   url:
  //     project && contractorPackage
  //       ? RouteHelper.userPackage(project.id, contractorPackage.id)
  //       : "",
  // });
  // if (
  //   project?.projectAccessLevel === ProjectAccessLevel.Admin ||
  //   project?.projectAccessLevel === ProjectAccessLevel.Manager ||
  //   project?.projectAccessLevel === ProjectAccessLevel.Write
  // ) {
  //   children.push({
  //     icon: <Users />,
  //     id: "project-employees",
  //     title: "Employees",
  //     type: "item",
  //     url:
  //       project && contractorPackage
  //         ? RouteHelper.projectEmployees(project.id, contractorPackage.id)
  //         : "",
  //   });
  // }

  // if (userHasEditorAccess(project)) {
  //   children.push({
  //     icon: <InsightsIcon />,
  //     id: "forecast",
  //     title: "Forecasting",
  //     type: "item",
  //     url:
  //       project && contractorPackage
  //         ? RouteHelper.forecast(project.id, contractorPackage.id)
  //         : "",
  //   });
  // }

  return {
    children: children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "packages",
    title: "Manage",
    type: "group",
  };
};
