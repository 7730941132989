import { Typography, styled } from "@mui/material";

const StyledTitle = styled(Typography)(
  ({ fontSizes }: { fontSizes: number[] }) => ({
    color: "#181D27",
    fontFamily: '"Inter", sans-serif',
    fontSize: `${fontSizes[0]}px`,
    fontWeight: 600,
    lineHeight: "32px",
    marginTop: "-8px",
  }),
);

const StyledSubHeading = styled(Typography)(
  ({ fontSizes }: { fontSizes: number[] }) => ({
    color: "#535862",
    fontFamily: '"Inter", sans-serif',
    fontSize: `${fontSizes[1]}px`,
    lineHeight: "24px",
    marginBottom: 0,
  }),
);

export { StyledSubHeading, StyledTitle };
