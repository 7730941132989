"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeFinancial = exports.decodeFinancial = void 0;
const string_1 = require("../../utils/string");
const decodeFinancial = (financial) => {
    return {
        deemedLabourHourlyRate: financial.deemed_labour_hourly_rate,
        deemedLabourRatio: financial.deemed_labour_ratio,
        estimatedBepHoursManagement: financial.estimated_bep_hours_management,
        estimatedBepHoursNonTrade: financial.estimated_bep_hours_non_trade,
        estimatedBepHoursTrade: financial.estimated_bep_hours_trade,
        estimatedContractSpend: financial.estimated_contract_spend,
        estimatedTotalWorkHours: financial.estimated_total_work_hours,
        id: financial.financial_id,
        lidpPc: financial.lidp_pc,
        margin: financial.margin,
        projectEstimateMethod: financial.project_estimate_method,
        requiredHours: financial.required_hours,
        specialisedEquipmentValue: financial.specialised_equipment_value,
        totalValue: financial.total_value,
        variations: financial.variations,
    };
};
exports.decodeFinancial = decodeFinancial;
const encodeFinancial = (financial) => {
    const safeFinancial = (0, string_1.makePostgresSafe)(financial);
    return {
        deemed_labour_hourly_rate: safeFinancial.deemedLabourHourlyRate,
        deemed_labour_ratio: safeFinancial.deemedLabourRatio,
        estimated_bep_hours_management: safeFinancial.estimatedBepHoursManagement,
        estimated_bep_hours_non_trade: safeFinancial.estimatedBepHoursNonTrade,
        estimated_bep_hours_trade: safeFinancial.estimatedBepHoursTrade,
        estimated_contract_spend: safeFinancial.estimatedContractSpend,
        estimated_total_work_hours: safeFinancial.estimatedTotalWorkHours,
        financial_id: safeFinancial.id,
        lidp_pc: safeFinancial.lidpPc,
        margin: safeFinancial.margin,
        project_estimate_method: safeFinancial.projectEstimateMethod,
        required_hours: safeFinancial.requiredHours,
        specialised_equipment_value: safeFinancial.specialisedEquipmentValue,
        total_value: safeFinancial.totalValue,
        variations: safeFinancial.variations,
    };
};
exports.encodeFinancial = encodeFinancial;
