import { downloadFile } from "@hooks/utils/useUpload";
import { Chip, Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  centerCellStyles,
  defaultCellConfig,
  ProgressCell,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { DownloadIcon } from "lucide-react";
import { useState } from "react";
import {
  PolicyAnalyticsModelLineItem,
  PolicyTargetAnalyticLineItem,
} from "social-pro-common/interfaces/analytics";
import { policyTypeToString } from "social-pro-common/interfaces/projectPolicy";

import { ContractorAnalyticsPolicyTableRowSkeleton } from "./ContractorAnalyticsPolicyTableRowSkeleton";
interface ContractorAnalyticsPolicySubRowProps {
  loading: boolean;
  policyAnalytics?: PolicyAnalyticsModelLineItem;
  searchTerm: string;
  withoutBorder?: boolean;
}

interface PolicyLevelCellProps {
  data: PolicyTargetAnalyticLineItem;
}

const PolicyLevelCell = (props: PolicyLevelCellProps) => {
  const { data } = props;
  return data.isProjectLevelPolicy ? (
    <Chip label="Project Level Policy" />
  ) : null;
};

export const ContractorAnalyticsPolicyTable = ({
  loading,
  policyAnalytics,
  searchTerm,
  withoutBorder = false,
}: ContractorAnalyticsPolicySubRowProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleAction = async (policy: PolicyTargetAnalyticLineItem) => {
    const { policyDescription, policyType, uri } = policy;
    if (!uri) return;
    const policyName = policyDescription || policyTypeToString(policyType);
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      icon: <DownloadIcon />,
      singleAction: true,
      title: "Download",
    },
  ];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "policyDescription",
      flex: 4,
      headerName: "Policy Name",
      valueGetter: (params) =>
        params.data.policyDescription ||
        policyTypeToString(params.data.policyType),
    },
    {
      ...defaultCellConfig,
      cellRenderer: PolicyLevelCell,
      cellStyle: centerCellStyles,
      field: "isProjectLevelPolicy",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "",
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "expectedSubmissions",
      headerClass: "right-table-header",
      headerName: "Expected",
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "totalSubmissions",
      headerClass: "right-table-header",
      headerName: "Submitted",
    },
    {
      ...defaultCellConfig,
      cellRenderer: ProgressCell,
      cellStyle: rightCellStyles,
      field: "value",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Progress",
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: {
        disabled: isDownloading,
        text: "Download",
      },
      cellStyle: centerCellStyles,
      field: "",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <Grid md={12} item>
      {loading || !policyAnalytics?.targets ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          noFullTableBorder={withoutBorder}
          rows={() => (
            <ContractorAnalyticsPolicyTableRowSkeleton singleAction />
          )}
        />
      ) : (
        <Table<PolicyTargetAnalyticLineItem>
          columnDefs={colDefs}
          loading={loading}
          context={{
            cellActions,
          }}
          noFullTableBorder={withoutBorder}
          searchValue={searchTerm}
          data={policyAnalytics?.targets || []}
          searchKeys={["policyDescription"]}
        />
      )}
    </Grid>
  );
};
