import { Box, Stack, Typography, styled } from "@mui/material";

// Styled Components
const MetricContainer = styled(Stack)({
  flexDirection: "row",
  gap: "8px",
});

const MetricDot = styled(Box)<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  borderRadius: "999px",
  height: "10px",
  marginTop: "5px",
  width: "10px",
}));

const MetricHeading = styled(Typography)({
  color: "#535862",
  fontFamily: "'Inter', sans-serif",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
});

const MetricFigure = styled(Typography)({
  color: "#181D27",
  fontFamily: "'Inter', sans-serif",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "32px",
});

export { MetricContainer, MetricDot, MetricFigure, MetricHeading };
