import { Grid } from "@mui/material";
import {
  CustomPagination,
  paginate,
  PAGINATION_LIMIT,
} from "@stories/organisms/Table/CustomPagination";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  defaultCellConfig,
  LabourHourInputCell,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ProjectRowSkeleton } from "@stories/organisms/Tables/ProjectRowSkeleton";
import { getEmployeeFactors } from "@utils/employeSocialFactors";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { useState } from "react";
import { useEffect } from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  EmployeeHoursToDateLineItem,
  LabourHourLineItem,
} from "social-pro-common/interfaces/labourHour";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { EmployeeSocialFactorsCell } from "../ProjectEmployeeTable/ProjectEmployeeTableCells";

interface LabourHourTableProps {
  loading: boolean;
  submitting: boolean;
  searchTerm: string;
  labourHours: LabourHourLineItem[];
  contractorEmployees: EmployeeLineItem[];
  labourHoursForEmployee: EmployeeHoursToDateLineItem[];
  project?: ProjectLineItem;
  organisation?: OrganisationLineItem;
  tabIndex: number;
  setPage: (page: number) => void;
  page: number;
  reportId: string;
  handleUpdateValue: (project: LabourHourLineItem) => Promise<void>;
  contractorPackage?: ContractorPackageLineItem;
  setLabourHours: (labourHours: LabourHourLineItem[]) => void;
  tableKey: number;
}

export const LabourHourTable = ({
  contractorEmployees,
  contractorPackage,
  handleUpdateValue,
  labourHours,
  labourHoursForEmployee,
  loading,
  organisation,
  page,
  project,
  reportId,
  searchTerm,
  setLabourHours,
  setPage,
  submitting,
  tabIndex,
  tableKey,
}: LabourHourTableProps) => {
  const [colDefs, setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "employeeName",
      flex: 2.5,
      headerName: "Name or Code",
      minWidth: 200,
    },
    {
      ...defaultCellConfig,
      cellRenderer: (params: ValueFormatterParams<any, any>) => {
        const socialFactors = getEmployeeFactors(
          params.data,
          params.context.project,
        );

        return <EmployeeSocialFactorsCell factors={socialFactors} />;
      },
      cellStyle: centerCellStyles,
      field: "socialFactors",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Social Factors",
      minWidth: 150,
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "amountOfHoursWorked",
      headerClass: "centered-table-header",
      headerName: "Hours to Date",
      minWidth: 150,
      valueFormatter: (params: any) => {
        const labourHour = params.context.labourHoursForEmployee.find(
          (lh: any) => lh.employeeId === params.data.id,
        );
        return labourHour
          ? formatDecimalPlaces(
              labourHour.hoursStudied + labourHour.hoursStudied,
              0,
            )
          : "0";
      },
    },
    {
      ...defaultCellConfig,
      autoHeight: true,
      cellRenderer: LabourHourInputCell,
      cellRendererParams: {
        valueKey: "amountOfHoursWorked",
      },
      cellStyle: {
        ...defaultCellConfig.cellStyle,
        padding: "0 5%",
      },
      field: "amountOfHoursWorked",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "Enter Hours Worked",
      minWidth: 150,
    },
  ]);

  useEffect(() => {
    setColDefs((prevColDefs) => {
      if (tabIndex === 1) {
        return [
          ...prevColDefs.filter(
            (col: ColDef) => col.field !== "amountOfHoursStudied",
          ),
          {
            ...defaultCellConfig,
            autoHeight: true,
            cellRenderer: LabourHourInputCell,
            cellRendererParams: {
              valueKey: "amountOfHoursStudied",
            },
            field: "amountOfHoursStudied",
            flex: 0.8,
            headerClass: "centered-table-header",
            headerName: "Enter Hours Studied",
            minWidth: 200,
          },
        ];
      }
      return prevColDefs.filter(
        (col: ColDef) => col.field !== "amountOfHoursStudied",
      );
    });
  }, [tabIndex]);

  const { data, pageNumbers, totalPages } = paginate<EmployeeLineItem>(
    contractorEmployees,
    page,
    10,
  );

  return (
    <Grid item xs={12} md={12} mt={4}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ProjectRowSkeleton}
        />
      ) : null}
      {!loading ? (
        <Table<EmployeeLineItem>
          key={tableKey}
          searchValue={searchTerm}
          columnDefs={colDefs}
          loading={loading}
          data={data || []}
          searchKeys={["employeeName"]}
          context={{
            contractorPackage,
            handleUpdateValue,
            labourHours: [...labourHours],
            labourHoursForEmployee: [...labourHoursForEmployee],
            loading,
            organisation,
            project,
            reportId,
            setLabourHours,
            submitting,
          }}
        />
      ) : null}
      {!loading && contractorEmployees.length > PAGINATION_LIMIT ? (
        <CustomPagination
          onPageChange={setPage}
          page={page}
          pageNumbers={pageNumbers}
          totalPages={totalPages}
        />
      ) : null}
    </Grid>
  );
};
