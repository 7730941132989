import { Button, Typography, styled, Box } from "@mui/material";

const AnimatedButton = styled(Button)<{
  bgcolor?: string;
  width?: number;
  minwidth?: number;
}>(({ bgcolor, minwidth, width }) => ({
  "&:disabled": {
    backgroundColor: "white",
    color: "#414651",
    cursor: "not-allowed",
    opacity: 0.6,
  },
  "&:hover": {
    backgroundColor: bgcolor ? bgcolor : "#F5F5F5",
    width: width ? `${width}px` : "200px",
  },
  "&:hover .animated-text": {
    opacity: 1,
  },
  backgroundColor: bgcolor || "white",
  border: bgcolor ? `1px solid ${bgcolor}` : "1px solid #D5D7DA",
  borderRadius: "8px",
  color: bgcolor ? "white" : "#414651",
  minWidth: minwidth ? `${minwidth}px` : "48px",
  overflow: "hidden",
  padding: "8px",
  paddingLeft: "14px",
  transition: "width 0.4s ease-in-out, background-color 0.3s ease-in-out",
  width: "48px",
}));

const IconWrapper = styled(Box)({
  alignItems: "center",
  display: "flex",
  transition: "margin-right 0.4s ease-in-out",
});

const AnimatedText = styled(Typography)({
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "20px",
  marginLeft: "8px",
  opacity: 0,
  overflow: "hidden",
  transition: "opacity 0.4s ease-in-out, transform 0.4s ease-in-out",
  whiteSpace: "nowrap",
});

export { AnimatedButton, IconWrapper, AnimatedText };
