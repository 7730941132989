import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import {
  Info,
  PenBox,
  UserPlus,
  Users,
  Package,
  PackageCheck,
  FolderSearch,
  SquareCheck,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

export const faqSection = [
  {
    description: "Answers to frequently asked questions about SocialPro",
    faqAccordian: {
      faq: { answer: "Answer", question: "Question 1" },
      index: 0,
    },
    id: "1",
    logo: <Info color="#0080db" size={35} />,
    title: "SocialPro 101",
  },
  {
    description:
      "Answers to frequently asked questions about creating a project",
    id: "2",
    logo: <PenBox color="#0080db" size={35} />,
    title: "Creating a Project",
  },
  {
    description:
      "Answers to frequently asked questions about adding an employee",
    id: "3",
    logo: <UserPlus color="#0080db" size={35} />,
    title: "Add Employees",
  },
  {
    description:
      "Answers to frequently asked questions about managing subcontractors",
    id: "4",
    logo: <Users color="#0080db" size={35} />,
    title: "Manage Subcontractors",
  },
  {
    description: "Answers to frequently asked questions about my package",
    id: "5",
    logo: <Package color="#0080db" size={35} />,
    title: "My Package",
  },
  {
    description:
      "Answers to frequently asked questions about package reporting",
    id: "6",
    logo: <PackageCheck color="#0080db" size={35} />,
    title: "Package Reporting",
  },
  {
    description:
      "Answers to frequently asked questions about project reporting",
    id: "7",
    logo: <SquareCheck color="#0080db" size={35} />,
    title: "Project Reporting",
  },
  {
    description:
      "Answers to frequently asked questions about Smart Directory and targets",
    id: "8",
    logo: <FolderSearch color="#0080db" size={35} />,
    title: "Smart Directory & Targets",
  },
];

export const FAQCards = (): JSX.Element => {
  const navigate = useNavigate();

  const viewFAQQuestions = (id: string) => {
    navigate(RouteHelper.faqQuestion(id));
  };
  return (
    <>
      <Grid container spacing={2} mt={2}>
        {faqSection.map((faq, index) => {
          return (
            <Grid item md={4} key={index}>
              <Card
                className="direct-card"
                variant="outlined"
                sx={{
                  "&:hover ": {
                    transform: "translateY(-10px)",
                  },
                  boxShadow: "0 .375rem .75rem hsla(225,3%,53%,.075)",
                  height: 210,
                  margin: "8px",
                  transition: " all .2s ease-in-out",
                }}
                onClick={() => viewFAQQuestions(faq.id)}
              >
                <CardContent>
                  <Stack
                    sx={{ marginTop: "10px", paddingTop: "5%" }}
                    spacing={2}
                  >
                    {faq.logo}
                    <Typography variant="h2" component="div" fontWeight="bold">
                      {faq.title}
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant="h4" fontWeight="normal">
                      {faq.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
