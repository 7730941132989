import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsLevel4 } from "@hooks/crud/analytics/useLabourHourAnalyticsLevel4";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsHoursLevel4Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel4Table/AnalyticsHoursLevel4Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import LabourHourAnalyticsSummary from "@stories/organisms/Summary/LabourHourAnalyticsSummary";
import * as RouteHelper from "@utils/routes";
import { writeXlsxTotalWorkforceIDData } from "@utils/xlsx/labourHours";
import { ExportIcon } from "assets/constants";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { getMaximalDate, stringToDate } from "social-pro-common/utils/date";

export const AnalyticsHoursLevel4 = () => {
  const {
    contractorEmployeeId,
    mode,
    projectId,
    reportId,
    subPackageId,
    targetId,
  } = useParams();

  if (!targetId) {
    throw Error("No target id");
  }
  if (!reportId) {
    throw Error("No report id");
  }
  if (!projectId) {
    throw Error("No project id");
  }
  if (!subPackageId) {
    throw Error("No sub contractor package id");
  }
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsLevel4(
      reportId,
      selectedProject?.id,
      subPackageId,
      targetId,
      contractorEmployeeId,
    );
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = isProjectLoading || isAnalysisLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  const matchingTarget = selectedProject?.commitmentsHours.find(
    (target) => target.id === targetId,
  );

  const displayText =
    mode == "all"
      ? "All"
      : labourHoursAnalytics?.subContractor.organisationName || "";

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsLabourHoursLevel1(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId,
      ),
      label: "Labour Hours",
    },
  ];

  if (isPrimaryPackage) {
    breadcrumbData.push({
      href: RouteHelper.analyticsLabourHoursLevel2(
        selectedProject?.id || "asdadas",
        contractorPackage?.id || "",
        targetId,
        reportId,
      ),
      label: matchingTarget
        ? matchingTarget.targetDescription ||
          labourHourCommitmentTypeToString(matchingTarget.targetName)
        : "",
    });
  }
  breadcrumbData.push({
    href: RouteHelper.analyticsLabourHoursLevel3(
      selectedProject?.id || "",
      contractorPackage?.id || "",
      subPackageId,
      reportId,
      targetId,
      "package",
    ),
    label: displayText,
  });

  breadcrumbData.push({
    href: RouteHelper.analyticsLabourHoursLevel4(
      selectedProject?.id || "",
      contractorPackage?.id || "",
      subPackageId,
      reportId,
      targetId,
      contractorEmployeeId,
    ),
    label: labourHoursAnalytics?.employeeName || "",
  });

  const handleExportEmployee = async () => {
    if (labourHoursAnalytics) {
      writeXlsxTotalWorkforceIDData(labourHoursAnalytics.labourHours);
    }
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportEmployee,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];
  return (
    <PageContainer>
      <Header
        loading={loading}
        breadcrumbs={breadcrumbData}
        breadcrumbIcon={<HourglassEmptyIcon />}
        mainTitle={`Labour Hours - ${
          selectedTarget
            ? selectedTarget.targetDescription ||
              labourHourCommitmentTypeToString(selectedTarget.targetName)
            : ""
        }`}
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        handleDateChange={handleDateChange}
        currentDate={currentDate}
      />
      <Grid md={12} item>
        <LabourHourAnalyticsSummary
          summary={labourHoursAnalytics?.summary}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={2}>
            <Header
              loading={loading}
              mainTitle="Target Summary"
              subTitle="Level 4 Analytics"
              isSubheading
              headerFontSizes={[18, 14]}
              headerGap={0}
            />
            <AnalyticsHoursLevel4Table
              loading={loading}
              targetId={targetId}
              reportId={reportId}
              searchTerm={searchTerm}
              labourHoursAnalytics={labourHoursAnalytics}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </PageContainer>
  );
};
