import { ProfileProvider } from "@hooks/context/useProfileContext";
import { ProjectProvider } from "@hooks/context/useProjectContext";
import { AppBar, Box, Container, CssBaseline, Toolbar } from "@mui/material";
import { styled, useTheme, Theme } from "@mui/material/styles";
import * as RouteHelper from "@utils/routes";
import { drawerWidth } from "constant";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import Sidebar from "./Sidebar";
import Header from "../MenuShared/Header";

// assets
import "./dashboard.css";
function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ open, theme }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 72),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.sharp,
      }),
    }),
    ...(open && {
      marginLeft: "20px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
      },

      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginTop: 20,
      },
      [theme.breakpoints.up("md")]: {},
      // 'margin 538ms cubic-bezier(0.4, 0, 1, 1) 0ms',
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.easeOut,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  }),
);

interface MainDashboardLayoutProps {
  children: React.ReactNode;
}
const MainDashboardLayout = ({ children }: MainDashboardLayoutProps) => {
  const theme = useTheme();
  const location = useLocation();

  const routeMatch = useRouteMatch([
    RouteHelper.listProjects,
    RouteHelper.forecast(":projectId", ":contractorPackageId"),
    RouteHelper.contractorDashboard(":projectId", ":contractorPackageId"),
    RouteHelper.packages(":projectId", ":contractorPackageId"),
    RouteHelper.contractorPackage(
      ":projectId",
      ":contractorPackageId",
      ":packageId",
    ),
    RouteHelper.userPackage(":projectId", ":contractorPackageId"),
    RouteHelper.analyticsLabourHoursLevel1(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
    RouteHelper.analyticsLabourHoursLevel2(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
      ":targetId",
    ),
    RouteHelper.analyticsLabourHoursLevel3(
      ":projectId",
      ":contractorPackageId",
      ":subPackageId",
      ":reportId",
      ":targetId",
      ":mode?",
    ),
    RouteHelper.analyticsLabourHoursLevel4(
      ":projectId",
      ":contractorPackageId",
      ":subPackageId",
      ":reportId",
      ":targetId",
      ":contractorEmployeeId?",
    ),
    RouteHelper.analyticsSpendLevel1(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
    RouteHelper.analyticsSpendLevel2(
      ":projectId",
      ":contractorPackageId",
      ":targetId",
      ":reportId",
    ),
    RouteHelper.analyticsSpendLevel3(
      ":projectId",
      ":contractorPackageId",
      ":subPackageId",
      ":reportId",
      ":targetId",
      ":mode?",
    ),
    RouteHelper.analyticsPolicies(":projectId", ":contractorPackageId"),
    RouteHelper.analyticsEnviroSustainability(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
    RouteHelper.analyticsEnviroSustainabilityDetails(
      ":projectId",
      ":contractorPackageId",
      ":packageId",
      ":reportId",
    ),
    RouteHelper.analyticsLocalContent(":projectId", ":contractorPackageId"),
    RouteHelper.analyticsLocalContentDetails(
      ":projectId",
      ":contractorPackageId",
      ":packageId",
    ),
    RouteHelper.projectSustainability(":projectId", ":contractorPackageId"),
    RouteHelper.sustainability(":projectId", ":contractorPackageId"),
    RouteHelper.projectEmployees(":projectId", ":contractorPackageId"),
    RouteHelper.wizard(":projectId", ":contractorPackageId", ":packageId"),
    RouteHelper.wizardOneTime(":projectId", ":contractorPackageId"),
    RouteHelper.projectSocialWizard(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
    RouteHelper.projectSustainabilityWizard(
      ":projectId",
      ":contractorPackageId",
      ":reportId",
    ),
    RouteHelper.contractorPackageReports(":projectId", ":contractorPackageId"),
    RouteHelper.contractorProjectReports(":projectId", ":contractorPackageId"),
    RouteHelper.projectPolicies(":projectId", ":contractorPackageId"),
    RouteHelper.directory(":projectId", ":contractorPackageId"),
    RouteHelper.readOnlyReportSocial(
      ":projectId",
      ":contractorPackageId",
      ":packageId",
      ":reportId",
    ),
    RouteHelper.readOnlyReportSustainability(
      ":projectId",
      ":contractorPackageId",
      "packageId",
      ":reportId",
    ),
  ]);

  const [isDrawOpen, setIsDrawOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);

  useEffect(() => {
    let currentPathId =
      routeMatch?.pattern?.path.split("/")[1] || "NO_ID_SELECTED";
    switch (currentPathId) {
      case "analytics-spend-subcontractors":
      case "view-analytics-spend":
        currentPathId = "analytics-spend";
        break;

      case "project-social-wizard":
      case "view-social-report":
        currentPathId = "report-project";
        break;

      case "project-sustainability":
      case "view-sustainability-report":
      case "project-sustainability-wizard":
        currentPathId = "project-sustainability";
        break;

      case "wizard":
      case "one-time-wizard":
        currentPathId = "report-packages";
        break;

      case "analytics-labour-subcontractors":
      case "view-analytics-labour-hours-employee":
      case "view-analytics-labour-hours":
        currentPathId = "analytics-labour-hours";
        break;

      default:
        break;
    }
    setSelectedItem([currentPathId]);
  }, [routeMatch?.pattern?.path, location.pathname]);

  const header = (
    <Toolbar
      sx={{
        padding: "0 !important",
        width: "260px",
      }}
    >
      <Header setIsDrawOpen={setIsDrawOpen} isDrawOpen={isDrawOpen} />
    </Toolbar>
  );

  return (
    <ProfileProvider>
      <ProjectProvider>
        <Box sx={{ display: "flex" }} className="dashboardPage">
          <CssBaseline />
          <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ background: "transparent" }}
          >
            {header}
          </AppBar>
          {/* drawer */}

          <div className="normal">
            <Sidebar
              isDrawOpen={isDrawOpen}
              selectedItem={selectedItem}
              setIsDrawOpen={setIsDrawOpen}
              setSelectedItem={setSelectedItem}
            />
          </div>

          {/* main content */}
          <Main theme={theme} open={isDrawOpen}>
            <Container maxWidth={false} sx={{ px: { xs: 0 } }}>
              <Box sx={{ borderRadius: "30px 0 0 0" }}>{children}</Box>
            </Container>
          </Main>
        </Box>
      </ProjectProvider>
    </ProfileProvider>
  );
};

export default MainDashboardLayout;
