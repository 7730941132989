import { Skeleton, Tab, Tabs } from "@mui/material";

const DashboardTabs = ({
  handleTabChange,
  loading,
  tabData,
  tabIndex,
  variant,
}: any) => {
  return (
    <Tabs
      value={tabIndex}
      onChange={handleTabChange}
      aria-label="Tabs example"
      variant={variant}
      className="custom-project-tabs"
      sx={{
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          gap: "8px",
        },
        ".MuiTabs-indicator": {
          backgroundColor: "transparent",
          display: "none",
        },
      }}
    >
      {tabData.map((tab: any, index: number) =>
        loading ? (
          <Skeleton
            animation="wave"
            key={index}
            width="33%"
            variant="rectangular"
          >
            <Tab
              disableRipple
              key={index}
              label={tab.label}
              value={tab.value}
              sx={{
                // marginRight: "8px",
                "&.Mui-selected": {
                  backgroundColor: "#FFFFFF",
                  boxShadow: "0px 1px 2px 0px #0A0D121A",
                  color: "#414651",
                },
                borderRadius: "6px",
                color: "#717680",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                minHeight: "36px",
                padding: "8px 12px",
                textTransform: "capitalize",
              }}
              style={{ fontFamily: '"Inter", sans-serif' }}
            />
          </Skeleton>
        ) : (
          <Tab
            disableRipple
            key={index}
            data-test-id={tab.dataTestId}
            label={tab.label}
            value={tab.value}
            sx={{
              // marginRight: "8px",
              "&.Mui-selected": {
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 1px 2px 0px #0A0D121A",
                color: "#414651",
              },
              borderRadius: "6px",
              color: "#717680",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              minHeight: "36px",
              padding: "8px 12px",
              textTransform: "capitalize",
            }}
            style={{ fontFamily: '"Inter", sans-serif' }}
          />
        ),
      )}
    </Tabs>
  );
};

export default DashboardTabs;
