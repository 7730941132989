"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportStatusToString = exports.reportStatusToIcon = exports.reportStatusToColor = exports.ReportStatusOptions = void 0;
const projectReportSubmission_1 = require("../../entities/projectReportSubmission");
exports.ReportStatusOptions = {
    ...projectReportSubmission_1.ReportStatus,
    All: "report-status_all",
};
const reportStatusToColor = (reportStatus) => {
    switch (reportStatus) {
        default:
        case projectReportSubmission_1.ReportStatus.Upcoming:
        case projectReportSubmission_1.ReportStatus.InProgress:
            return "warning";
        case projectReportSubmission_1.ReportStatus.Complete:
            return "success";
        case projectReportSubmission_1.ReportStatus.Overdue:
            return "error";
    }
};
exports.reportStatusToColor = reportStatusToColor;
const reportStatusToIcon = (reportStatus) => {
    switch (reportStatus) {
        default:
        case projectReportSubmission_1.ReportStatus.Upcoming:
        case projectReportSubmission_1.ReportStatus.InProgress:
            return "warning";
        case projectReportSubmission_1.ReportStatus.Complete:
            return "success";
        case projectReportSubmission_1.ReportStatus.Overdue:
            return "error";
    }
};
exports.reportStatusToIcon = reportStatusToIcon;
const reportStatusToString = (reportStatus) => {
    switch (reportStatus) {
        case projectReportSubmission_1.ReportStatus.Accepted:
            return "Accepted";
        case projectReportSubmission_1.ReportStatus.Upcoming:
            return "Upcoming";
        case projectReportSubmission_1.ReportStatus.Overdue:
            return "Overdue";
        case projectReportSubmission_1.ReportStatus.Complete:
            return "Complete";
        case projectReportSubmission_1.ReportStatus.InProgress:
            return "In Progress";
        case projectReportSubmission_1.ReportStatus.ToSubmit:
            return "To Submit";
    }
};
exports.reportStatusToString = reportStatusToString;
