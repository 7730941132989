import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Button, ButtonProps, Skeleton, styled } from "@mui/material";

export const StyledLoadingButtonBase = styled(LoadingButton)(() => ({
  color: "#fff",
  px: 2.75,
  py: 1.5,
}));

type StyledButtonProps = ButtonProps & { loading: boolean };

export const StyledButton = (props: StyledButtonProps) => {
  const { loading, ...otherProps } = props;
  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <Button size="large" variant="contained" {...otherProps}>
          {props.children}
        </Button>
      </Skeleton>
    );
  }
  return (
    <Button
      size="large"
      variant="contained"
      {...otherProps}
      sx={{
        borderRadius: "6px",
        height: "100%",
        marginBottom: "auto !important",
        marginTop: "auto !important",
        padding: "8px 32px",
      }}
    >
      {props.children}
    </Button>
  );
};

export const StyledLoadingButton = (props: LoadingButtonProps) => {
  if (props.loading) {
    return (
      <Skeleton animation="wave" variant="rounded">
        <LoadingButton {...props}>{props.children}</LoadingButton>
      </Skeleton>
    );
  }
  return (
    <LoadingButton {...props} sx={{ borderRadius: "6px", padding: "6px 18px" }}>
      {props.children}
    </LoadingButton>
  );
};
