import { useOrganisation } from "@hooks/crud/organisation/useOrganisation";
import { useOrganisationByABN } from "@hooks/crud/organisation/useOrganisationByABN";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  Grid,
  Box,
  InputAdornment,
  useTheme,
  Stack,
  Alert,
  Divider,
  Chip,
  Skeleton,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledDatePicker } from "@stories/atoms/StyledDatePicker/StyledDatePicker";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { UploadButton } from "@stories/atoms/UploadButton/UploadButton";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { EstimateType } from "social-pro-common/entities/financial";
import {
  ContractorPackageLineItem,
  ContractorPackageSetupLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";
import { FinancialLineItem } from "social-pro-common/interfaces/financial";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import {
  createDefaultProfile,
  ProfileLineItem,
} from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getBaseMultiplierForHours } from "social-pro-common/interfaces/projectLabourHourCommitment";
import { getBaseMultiplierForSpend } from "social-pro-common/interfaces/projectSocialSpendCommitment";
import { createDefaultUser } from "social-pro-common/interfaces/user";
import { validateABN } from "social-pro-common/utils/abn";
import {
  calcRequiredDeemedHours,
  calculateTargetValueRealised,
  calculatedAvailableSpend,
} from "social-pro-common/utils/calc";
import {
  dateToString,
  formatChartDate,
  stringToDate,
} from "social-pro-common/utils/date";
import { getDocumentName, getFileName } from "social-pro-common/utils/string";
import * as yup from "yup";

import { CircularProgressWithLabel } from "../CircularProgressWithLabel/CircularProgressWithLabel";

interface PackageDetailsFormProps {
  loading: boolean;
  project: ProjectLineItem;
  contractorPackageSetup: ContractorPackageSetupLineItem;
  handleSubmit: (
    contractorPackage: ContractorPackageLineItem,
    contractor: OrganisationLineItem,
    profile?: ProfileLineItem,
  ) => void;
}

export default function PackageDetailsForm({
  contractorPackageSetup,
  handleSubmit,
  loading,
  project,
}: PackageDetailsFormProps) {
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const [contractor, setContractor] = useState<
    OrganisationLineItem | undefined
  >();

  const { getOrganisation } = useOrganisation();

  const { isOrganisationLoading, searchOrganisationsByAbn } =
    useOrganisationByABN();
  const getValidationSchema = (existingEmail: string | undefined) =>
    yup.object({
      abn: yup
        .string()
        .required("ABN is required")
        .test(
          "Valid ABN",
          "Not a valid ABN - i.e. 43 665 205 185",
          function (item) {
            return validateABN(item);
          },
        ),
      contractorEmail: yup
        .string()
        .email()
        .required("Contractor email is required")
        .test(
          "validate-domain",
          "Email domain must match existing domain",
          function (value) {
            if (!existingEmail || !value) return true;
            const existingDomain = existingEmail.split("@")[1];
            const newDomain = value.split("@")[1];
            return existingDomain === newDomain;
          },
        ),
      deemedLabourHourlyRate:
        project.financial.projectEstimateMethod === EstimateType.DeemedHours
          ? yup.number().moreThan(0, "Must be more than 0")
          : yup.number(),
      deemedLabourRatio:
        project.financial.projectEstimateMethod === EstimateType.DeemedHours
          ? yup
              .number()
              .moreThan(0, "Must be more than 0")
              .max(100, "Cannot be larger than 100%")
          : yup.number(),
      endDate: yup
        .date()
        .required("End date is required")
        .test(
          "After start date",
          "Completion date must be after start date",
          function (item) {
            if (this.parent.startDate) {
              return this.parent.startDate < item;
            }
            return true;
          },
        ),
      estimatedBepHoursManagement: yup.number().min(0),

      estimatedBepHoursNonTrade: yup.number().min(0),
      estimatedBepHoursTrade: yup.number().min(0),

      estimatedContractSpend: yup.number().min(0),
      estimatedTotalWorkHours: yup.number().min(0),
      margin: yup
        .number()
        .min(0)
        .required()
        .test(
          "Less than project total",
          "Margin should be less than total project value",
          function (item) {
            if (item) {
              return this.parent.totalValue > item ? true : false;
            }
            return true;
          },
        ),
      organisationName: yup.string().required("Organisation name is required"),
      packageDescription: yup
        .string()
        .test(
          "Description is required for package type",
          "Description is required for package type",
          function (item) {
            const isOther = this.parent.packageType === PackageType.Other;
            if (isOther) {
              return !!item && item.length > 0;
            }
            return true;
          },
        ),
      packageNumber: yup.string().required("Subcontractor number is required"),
      packageType: yup.string().required("Subcontractor type is required"),
      requiredHours: yup.number().min(0),
      specialisedEquipmentValue: yup.number().min(0),
      startDate: yup.date().required("Start date is required"),
      totalValue: yup.number().min(0).required("Total value is required"),
    });

  const validationSchema = useMemo(
    () => getValidationSchema(contractor?.representativeEmail || ""),
    [contractor?.representativeEmail],
  );
  const contractorPackage = contractorPackageSetup.contractorPackage;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      abn: contractorPackage.contractorAbn,
      contractorEmail:
        contractorPackageSetup.profile?.email ||
        contractorPackageSetup.organisation.representativeEmail,
      deemedLabourHourlyRate:
        contractorPackage.financial.deemedLabourHourlyRate,
      deemedLabourRatio: contractorPackage.financial.deemedLabourRatio,
      endDate: dayjs(stringToDate(contractorPackage.packageEndDate)),
      estimatedBepHoursManagement:
        contractorPackage.financial.estimatedBepHoursManagement,
      estimatedBepHoursNonTrade:
        contractorPackage.financial.estimatedBepHoursNonTrade,
      estimatedBepHoursTrade:
        contractorPackage.financial.estimatedBepHoursTrade,
      estimatedContractSpend:
        contractorPackage.financial.estimatedContractSpend,

      estimatedTotalWorkHours:
        contractorPackage.financial.estimatedTotalWorkHours,
      lessMargin: calculatedAvailableSpend(
        contractorPackage.financial.totalValue,
        contractorPackage.financial.margin,
        0,
        contractorPackage.financial.specialisedEquipmentValue,
      ),
      margin: contractorPackage.financial.margin,
      organisationName: contractorPackageSetup.organisation.organisationName,
      packageDescription: contractorPackage.packageDescription || "",
      packageNumber: contractorPackage.packageNumber,
      packageType: contractorPackage.packageType,

      requiredHours: calcRequiredDeemedHours(
        calculatedAvailableSpend(
          contractorPackage.financial.totalValue,
          contractorPackage.financial.margin,
          contractorPackage.financial.variations,
          contractorPackage.financial.specialisedEquipmentValue,
        ),
        contractorPackage.financial.deemedLabourRatio,
        contractorPackage.financial.deemedLabourHourlyRate,
      ),
      specialisedEquipmentValue:
        contractorPackage.financial.specialisedEquipmentValue,
      startDate: dayjs(stringToDate(contractorPackage.packageStartDate)),
      totalValue: contractorPackage.financial.totalValue,
      uri: contractorPackage.uri,
    },
    onSubmit: (values) => {
      const packageStartDate = values.startDate.toDate();
      const packageEndDate = values.endDate.toDate();

      if (contractor) {
        contractor.representativeEmail = values.contractorEmail;
        contractor.organisationName = formik.values.organisationName;
        const user = createDefaultUser(values.contractorEmail, false);
        const profile = createDefaultProfile(contractor, user);

        contractorPackage.financial = {
          ...contractorPackage.financial,
          deemedLabourHourlyRate: values.deemedLabourHourlyRate,
          deemedLabourRatio: values.deemedLabourRatio,
          estimatedBepHoursManagement: values.estimatedBepHoursManagement,
          estimatedBepHoursNonTrade: values.estimatedBepHoursNonTrade,
          estimatedBepHoursTrade: values.estimatedBepHoursTrade,
          estimatedContractSpend: values.estimatedContractSpend,
          estimatedTotalWorkHours: values.estimatedTotalWorkHours,
          margin: values.margin,
          projectEstimateMethod: project.financial.projectEstimateMethod,
          requiredHours: values.requiredHours,
          specialisedEquipmentValue: values.specialisedEquipmentValue,
          totalValue: values.totalValue,
        } as FinancialLineItem;
        contractorPackage.commitmentsHours =
          contractorPackage.commitmentsHours.map((ch) => {
            const baseMultiplier = getBaseMultiplierForHours(
              contractorPackage.financial,
              ch,
            );
            return {
              ...ch,
              targetValueRealised: calculateTargetValueRealised(
                ch.targetValue,
                baseMultiplier,
              ),
            };
          });

        contractorPackage.commitmentsSpend =
          contractorPackage.commitmentsSpend.map((ch) => {
            const baseMultiplier = getBaseMultiplierForSpend(
              contractorPackage.financial,
            );
            return {
              ...ch,
              targetValueRealised: calculateTargetValueRealised(
                ch.targetValue,
                baseMultiplier,
              ),
            };
          });
        const savedData = {
          ...contractorPackage,
          contractorEmail: values.contractorEmail,
          contractorId: contractor.id,
          contractorLogo: contractor.organisationLogo,
          contractorName: contractor.organisationName,
          organisation: contractor,
          packageDescription: values.packageDescription,
          packageEndDate: dateToString(packageEndDate),
          packageNumber: values.packageNumber,
          packageStartDate: dateToString(packageStartDate),
          packageType: values.packageType,
          uri: values.uri,
        };
        handleSubmit(
          savedData as ContractorPackageLineItem,
          contractor,
          profile,
        );
      }
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    const fetchContractor = async () => {
      const org = await getOrganisation(contractorPackage.contractorId);
      if (org) {
        setContractor(org);
      }
    };
    if (!contractor) {
      fetchContractor();
    }
  }, [contractorPackageSetup]);

  useEffect(() => {
    formik.setFieldValue("estimatedContractSpend", formik.values.totalValue);
  }, [formik.values.totalValue]);

  useEffect(() => {
    const searchAbn = async () => {
      if (formik.values.abn && formik.touched.abn && !formik.errors.abn) {
        const isValid = validateABN(formik.values.abn);
        if (isValid) {
          const query = formik.values.abn.replace(/\s/g, "");
          const organisation = await searchOrganisationsByAbn(query);

          if (organisation) {
            await formik.setFieldValue(
              "organisationName",
              organisation?.organisationName,
              true,
            );

            if (!formik.values.contractorEmail) {
              await formik.setFieldValue(
                "contractorEmail",
                contractorPackageSetup.profile?.email ||
                  organisation?.representativeEmail ||
                  "",
                true,
              );
              setContractor(organisation);
            } else {
              await formik.setFieldValue("organisationName", "", false);
              await formik.setFieldValue("contractorEmail", "", false);
              setContractor(undefined);
            }
          }
        }
      }
    };
    searchAbn();
  }, [formik.values.abn, formik.errors.abn, formik.touched.abn]);

  useEffect(() => {
    //Calculate adjustedMpsgValue
    if (formik.values.totalValue > 0) {
      const lessMargin = calculatedAvailableSpend(
        formik.values.totalValue,
        formik.values.margin,
        0,
        formik.values.specialisedEquipmentValue,
      );
      formik.setFieldValue("lessMargin", lessMargin);
    }
  }, [
    formik.values.totalValue,
    formik.values.margin,
    formik.values.specialisedEquipmentValue,
  ]);

  useEffect(() => {
    //Calculate required hours
    if (
      formik.values.lessMargin > 0 &&
      formik.values.deemedLabourRatio > 0 &&
      formik.values.deemedLabourHourlyRate > 0
    ) {
      const requiredHours = calcRequiredDeemedHours(
        formik.values.lessMargin,
        formik.values.deemedLabourRatio,
        formik.values.deemedLabourHourlyRate,
      );
      formik.setFieldValue("requiredHours", Math.floor(requiredHours));
    }
  }, [
    formik.values.lessMargin,
    formik.values.deemedLabourRatio,
    formik.values.deemedLabourHourlyRate,
  ]);

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const file = e.currentTarget?.files[0] || null;
      if (file) {
        setIsUploading(true);
        //TODO: Make this URL better;
        const fileKey = `package-agreement/${contractorPackage.id}/${file.name}`;
        await uploadFile(
          fileKey,
          file,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
          },
          () => {
            setUploadProgress(0);

            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
            setIsUploading(false);
          },
        );
        formik.setFieldValue("uri", fileKey);
        setIsUploading(false);
      }
    }
  };

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const disabled =
    formik.isSubmitting || isDownloading || isOrganisationLoading;

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={0} direction={"row"}>
          <Grid md={6}>
            <Grid
              container
              md={12}
              spacing={2}
              sx={{
                margin: "0",
                padding: "9px 40px 0px 24px",
              }}
            >
              <Grid item md={12}>
                <SubTitle loading={loading} title={"Subcontractor Details"} />
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    id="packageNumber"
                    name="packageNumber"
                    label="Subcontractor Number"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.packageNumber}
                    error={
                      formik.touched.packageNumber &&
                      Boolean(formik.errors.packageNumber)
                    }
                    helperText={
                      formik.touched.packageNumber
                        ? formik.errors.packageNumber
                        : ""
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={6}>
                <FormControl fullWidth>
                  <StyledTextField
                    loading={loading}
                    id="abn"
                    name="abn"
                    label="Subcontractor ABN"
                    data-test-id="supplier-abn"
                    fullWidth
                    disabled={disabled || contractorPackage.isAccepted}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.abn}
                    error={formik.touched.abn && Boolean(formik.errors.abn)}
                    helperText={formik.touched.abn ? formik.errors.abn : ""}
                    InputProps={{
                      endAdornment: isOrganisationLoading ? (
                        <InputAdornment
                          position="start"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          <CircularProgress size={20} />
                        </InputAdornment>
                      ) : undefined,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                          }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <StyledTextField
                    loading={loading}
                    disabled
                    id="organisationName"
                    name="organisationName"
                    label="Organisation Name"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.organisationName}
                    error={
                      formik.touched.organisationName &&
                      Boolean(formik.errors.organisationName)
                    }
                    helperText={
                      formik.touched.organisationName
                        ? formik.errors.organisationName
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <StyledTextField
                    loading={loading}
                    disabled={disabled}
                    fullWidth
                    id="contractorEmail"
                    name="contractorEmail"
                    label="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contractorEmail}
                    error={
                      formik.touched.contractorEmail &&
                      Boolean(formik.errors.contractorEmail)
                    }
                    helperText={
                      formik.touched.contractorEmail
                        ? formik.errors.contractorEmail
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  {loading ? (
                    <Skeleton animation="wave" variant="rounded" width={"100%"}>
                      <TextField
                        label="DEFAULT TEXT FIELD CONTENT - LOADING"
                        fullWidth
                      />
                    </Skeleton>
                  ) : (
                    <Autocomplete
                      fullWidth
                      id="packageType"
                      options={Object.values(PackageType).filter(
                        (f) =>
                          f !== PackageType.PrimaryPackage &&
                          f !== PackageType.OneTime,
                      )}
                      defaultValue={PackageType.BalustradesAndHandrail}
                      getOptionLabel={(option) => packageTypeToString(option)}
                      value={formik.values.packageType}
                      onChange={(event: any, newValue: PackageType | null) => {
                        formik.setFieldValue("packageType", newValue);
                        // formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField
                          data-test-id="packageType"
                          {...params}
                          name="packageType"
                          label="Package Type"
                          fullWidth
                          error={
                            formik.touched.packageType &&
                            Boolean(formik.errors.packageType)
                          }
                          helperText={
                            formik.touched.packageType
                              ? formik.errors.packageType
                              : ""
                          }
                        />
                      )}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <StyledTextField
                    loading={loading}
                    id="packageDescription"
                    name="packageDescription"
                    label="Package Description (Other)"
                    fullWidth
                    disabled={formik.values.packageType !== PackageType.Other}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.packageDescription}
                    error={
                      formik.touched.packageDescription &&
                      Boolean(formik.errors.packageDescription)
                    }
                    helperText={
                      formik.touched.packageDescription
                        ? formik.errors.packageDescription
                        : ""
                    }
                    sx={{
                      "&.Mui-disabled": {
                        backgroundColor: `${theme.palette.grey[300]} !important`,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth>
                  <StyledDatePicker
                    loading={loading}
                    label="Start Date"
                    onChange={(value: dayjs.Dayjs) => {
                      if (value) {
                        formik.setFieldValue("startDate", value, true);
                      }
                    }}
                    openTo="month"
                    views={["year", "month"]}
                    value={formik.values.startDate}
                    slotProps={{
                      popper: {
                        popperOptions: {
                          modifiers: [
                            {
                              enabled: true,
                              fn: ({ state }: { state: Partial<any> }) => {
                                state.styles.popper.height = "358px";
                                if (state.placement.includes("top-start")) {
                                  state.styles.popper = {
                                    ...state.styles.popper,
                                    alignItems: "flex-end",
                                    display: "flex",
                                  };
                                }
                                if (state.placement.includes("bottom")) {
                                  state.styles.popper = {
                                    ...state.styles.popper,
                                    display: "block",
                                  };
                                }
                              },
                              name: "applyCustom",
                              phase: "beforeWrite",
                              requires: ["computeStyles"],
                            },
                          ],
                        },
                      },
                      textField: {
                        "data-test-id": "package-start-date",
                        error:
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate),
                        helperText:
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate)
                            ? "Invalid date"
                            : `Project start date - ${formatChartDate(
                                stringToDate(project.projectStartDate),
                              )}`,
                      },
                    }}
                    minDate={dayjs(project.projectStartDate)}
                    maxDate={dayjs(project.projectEndDate)}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <FormControl fullWidth>
                  <StyledDatePicker
                    loading={loading}
                    label="Target Completion Date"
                    onChange={(value: dayjs.Dayjs) => {
                      if (value) {
                        formik.setFieldValue("endDate", value, true);
                      }
                    }}
                    openTo="month"
                    views={["year", "month"]}
                    value={formik.values.endDate}
                    slotProps={{
                      popper: {
                        popperOptions: {
                          modifiers: [
                            {
                              enabled: true,
                              fn: ({ state }: { state: Partial<any> }) => {
                                state.styles.popper.height = "358px";
                                if (state.placement.includes("top-start")) {
                                  state.styles.popper = {
                                    ...state.styles.popper,
                                    alignItems: "flex-end",
                                    display: "flex",
                                  };
                                }
                                if (state.placement.includes("bottom")) {
                                  state.styles.popper = {
                                    ...state.styles.popper,
                                    display: "block",
                                  };
                                }
                              },
                              name: "applyCustom",
                              phase: "beforeWrite",
                              requires: ["computeStyles"],
                            },
                          ],
                        },
                      },
                      textField: {
                        "data-test-id": "package-end-date",
                        error:
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate),
                        helperText:
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate)
                            ? "Invalid date"
                            : `Project target completion date - ${formatChartDate(
                                stringToDate(project.projectEndDate),
                              )}`,
                      },
                    }}
                    minDate={dayjs(project.projectStartDate)}
                    maxDate={dayjs(project.projectEndDate)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{
                  display: "flex !important",
                }}
              >
                <Stack direction={"row"} sx={{ flexGrow: 1 }} spacing={2}>
                  {isUploading ? (
                    <CircularProgressWithLabel value={uploadProgress} />
                  ) : (
                    <Stack
                      direction="column"
                      justifyContent="left"
                      alignItems="left"
                      spacing={2}
                    >
                      <input
                        ref={fileInput}
                        hidden
                        accept="*/*"
                        type="file"
                        style={{
                          cursor: "pointer",
                          height: "100px",
                          opacity: 0,
                          padding: 0.5,
                          position: "fixed",
                          width: "100px",
                          zIndex: 1,
                        }}
                        onChange={onUpload}
                      />
                      <UploadButton
                        loading={loading}
                        title={"Attach Subcontractor Agreement"}
                        disabled={isUploading || disabled}
                        onClickUpload={onFileInput}
                        onClickDownload={
                          formik.values.uri
                            ? () =>
                                onDownload(
                                  formik.values.uri || "",
                                  getDocumentName(
                                    "Subcontractor Agreement",
                                    formik.values.uri || "",
                                  ),
                                )
                            : undefined
                        }
                      />
                      {formik.values.uri ? (
                        <Alert
                          severity="success"
                          data-test-id="upload-success-alert"
                        >
                          {getFileName(formik.values.uri)}
                        </Alert>
                      ) : null}
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={6}>
            <Grid
              container
              md={12}
              spacing={2}
              sx={{
                margin: "0",
                padding: "9px 40px 0px 24px",
              }}
            >
              <Grid item md={12}>
                <SubTitle loading={loading} title={"Package Financials"} />
              </Grid>
              <Grid item md={6}>
                <StyledTextField
                  loading={loading}
                  disabled={disabled}
                  id="totalValue"
                  name="totalValue"
                  label="Total Package Value (ex. GST)"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.totalValue}
                  error={
                    formik.touched.totalValue &&
                    Boolean(formik.errors.totalValue)
                  }
                  helperText={
                    formik.touched.totalValue ? formik.errors.totalValue : ""
                  }
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                        }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <StyledTextField
                  loading={loading}
                  disabled={disabled}
                  id="margin"
                  name="margin"
                  label="Margin"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.margin}
                  error={formik.touched.margin && Boolean(formik.errors.margin)}
                  helperText={formik.touched.margin ? formik.errors.margin : ""}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                        }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <StyledTextField
                  loading={loading}
                  disabled={disabled}
                  id="specialisedEquipmentValue"
                  name="specialisedEquipmentValue"
                  label="Less Specialist Equipment"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.specialisedEquipmentValue}
                  error={
                    formik.touched.specialisedEquipmentValue &&
                    Boolean(formik.errors.specialisedEquipmentValue)
                  }
                  helperText={
                    formik.touched.specialisedEquipmentValue
                      ? formik.errors.specialisedEquipmentValue
                      : ""
                  }
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                        }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <StyledTextField
                  loading={loading}
                  id="lessMargin"
                  name="lessMargin"
                  label="Less Margin"
                  fullWidth
                  disabled
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lessMargin}
                  error={
                    formik.touched.lessMargin &&
                    Boolean(formik.errors.lessMargin)
                  }
                  helperText={
                    formik.touched.lessMargin ? formik.errors.lessMargin : ""
                  }
                  sx={{
                    fieldset: {
                      backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                    },
                  }}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          backgroundColor:
                            "rgba(239, 239, 239, 0.3) !important;",
                          height: "100%",
                          m: 0,
                          maxHeight: "100%",
                          pr: "8px",
                        }}
                        position="start"
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <Divider>
                  {loading ? (
                    <Skeleton variant="rounded" animation="wave">
                      <Chip label={"Target Profiling"} variant="outlined" />
                    </Skeleton>
                  ) : (
                    <Chip label={"Target Profiling"} variant="outlined" />
                  )}
                </Divider>
              </Grid>
              {project.financial.projectEstimateMethod ===
              EstimateType.DeemedHours ? (
                <>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      disabled={disabled}
                      id="deemedLabourRatio"
                      name="deemedLabourRatio"
                      label="Deemed Labour Ratio"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.deemedLabourRatio}
                      error={
                        formik.touched.deemedLabourRatio &&
                        Boolean(formik.errors.deemedLabourRatio)
                      }
                      helperText={
                        formik.touched.deemedLabourRatio
                          ? formik.errors.deemedLabourRatio
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              color: disabled
                                ? "rgba(0, 0, 0, 0.26)"
                                : undefined,
                            }}
                          >
                            %
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom as any,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      disabled={disabled}
                      id="deemedLabourHourlyRate"
                      name="deemedLabourHourlyRate"
                      label="Deemed Hourly Rate"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.deemedLabourHourlyRate}
                      error={
                        formik.touched.deemedLabourHourlyRate &&
                        Boolean(formik.errors.deemedLabourHourlyRate)
                      }
                      helperText={
                        formik.touched.deemedLabourHourlyRate
                          ? formik.errors.deemedLabourHourlyRate
                          : ""
                      }
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              color: disabled
                                ? "rgba(0, 0, 0, 0.26)"
                                : undefined,
                            }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      id="requiredHours"
                      name="requiredHours"
                      label="Required Hours"
                      fullWidth
                      margin="dense"
                      disabled
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.requiredHours}
                      error={
                        formik.touched.requiredHours &&
                        Boolean(formik.errors.requiredHours)
                      }
                      helperText={
                        formik.touched.requiredHours
                          ? formik.errors.requiredHours
                          : ""
                      }
                      sx={{
                        fieldset: {
                          backgroundColor:
                            "rgba(239, 239, 239, 0.3) !important;",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              backgroundColor:
                                "rgba(239, 239, 239, 0.3) !important;",
                              height: "100%",
                              m: 0,
                              maxHeight: "100%",
                              p: 0,
                            }}
                            position="start"
                          >
                            hours
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom as any,
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      disabled
                      id="estimatedContractSpend"
                      name="estimatedContractSpend"
                      label="Estimated Contract Spend"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.estimatedContractSpend}
                      error={
                        formik.touched.estimatedContractSpend &&
                        Boolean(formik.errors.estimatedContractSpend)
                      }
                      helperText={
                        formik.touched.estimatedContractSpend
                          ? formik.errors.estimatedContractSpend
                          : ""
                      }
                      sx={{
                        fieldset: {
                          backgroundColor:
                            "rgba(239, 239, 239, 0.3) !important;",
                        },
                      }}
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                          <InputAdornment
                            sx={{
                              backgroundColor:
                                "rgba(239, 239, 239, 0.3) !important;",
                              height: "100%",
                              m: 0,
                              maxHeight: "100%",
                              pr: "8px",
                            }}
                            position="start"
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      disabled={disabled}
                      id="estimatedTotalWorkHours"
                      name="estimatedTotalWorkHours"
                      label="Estimated Total Work Hours"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.estimatedTotalWorkHours}
                      error={
                        formik.touched.estimatedTotalWorkHours &&
                        Boolean(formik.errors.estimatedTotalWorkHours)
                      }
                      helperText={
                        formik.touched.estimatedTotalWorkHours
                          ? formik.errors.estimatedTotalWorkHours
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              color: disabled
                                ? "rgba(0, 0, 0, 0.26)"
                                : undefined,
                            }}
                          >
                            hours
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom as any,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      disabled={disabled}
                      id="estimatedBepHoursTrade"
                      name="estimatedBepHoursTrade"
                      label="Estimated Trade Hours"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.estimatedBepHoursTrade}
                      error={
                        formik.touched.estimatedBepHoursTrade &&
                        Boolean(formik.errors.estimatedBepHoursTrade)
                      }
                      helperText={
                        formik.touched.estimatedBepHoursTrade
                          ? formik.errors.estimatedBepHoursTrade
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              color: disabled
                                ? "rgba(0, 0, 0, 0.26)"
                                : undefined,
                            }}
                          >
                            hours
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom as any,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      disabled={disabled}
                      id="estimatedBepHoursNonTrade"
                      name="estimatedBepHoursNonTrade"
                      label="Estimated Non-trade Hours"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.estimatedBepHoursNonTrade}
                      error={
                        formik.touched.estimatedBepHoursNonTrade &&
                        Boolean(formik.errors.estimatedBepHoursNonTrade)
                      }
                      helperText={
                        formik.touched.estimatedBepHoursNonTrade
                          ? formik.errors.estimatedBepHoursNonTrade
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              color: disabled
                                ? "rgba(0, 0, 0, 0.26)"
                                : undefined,
                            }}
                          >
                            hours
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom as any,
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <StyledTextField
                      loading={loading}
                      disabled={disabled}
                      id="estimatedBepHoursManagement"
                      name="estimatedBepHoursManagement"
                      label="Estimated Management/Supervisory/Specialist Hours"
                      fullWidth
                      margin="dense"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.estimatedBepHoursManagement}
                      error={
                        formik.touched.estimatedBepHoursManagement &&
                        Boolean(formik.errors.estimatedBepHoursManagement)
                      }
                      helperText={
                        formik.touched.estimatedBepHoursManagement
                          ? formik.errors.estimatedBepHoursManagement
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              color: disabled
                                ? "rgba(0, 0, 0, 0.26)"
                                : undefined,
                            }}
                          >
                            hours
                          </InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom as any,
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            margin: "0",
            padding: "0px 40px 40px 40px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              alignItems: "flex-end",
              display: "flex !important",
              justifyContent: "end",
            }}
          />
          <StyledButton loading={loading} variant="contained" type="submit">
            Next
          </StyledButton>
        </Grid>
      </form>
    </Box>
  );
}
