import { Box, styled, Typography, Button } from "@mui/material";

export const PieChartContainer = styled(Box)({
  alignItems: "center",
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  padding: 2,
});

export const TableContainer = styled(Box)({
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
  height: "100%",
  padding: "24px",
});

export const ForecastContainer = styled(Box)({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
  height: "100%",
});

export const ForecastHeader = styled(Box)({
  borderBottom: "1px solid #E9EAEB",
  padding: "16px 20px",
});

export const ForecastChartContainer = styled(Box)({
  padding: "20px 16px",
});

export const StyledButton = styled(Button)({
  "&:hover": {
    backgroundColor: "transparent",
  },
  height: "20px",
  minWidth: "20px",
  padding: 0,
});

export const Title = styled(Typography)({
  fontFamily: "'Inter', sans-serif",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "28px",
});

export const Subtitle = styled(Typography)({
  color: "#535862",
  fontFamily: "'Inter', sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  margin: 0,
});
