import * as ExcelJs from "exceljs";
import { EmployeeSocialFactor } from "social-pro-common/entities/contractorEmployee";
import {
  createDefaultEmployee,
  EmployeeLineItem,
  genderToString,
  occupationToString,
  occupationTypeOptionToString,
  stringImportToContractType,
  stringImportToEmploymentLevel,
  stringImportToEmploymentType,
  stringImportToGender,
  stringImportToNewExistingJobType,
  stringImportToOccupation,
  stringImportToOccupationType,
} from "social-pro-common/interfaces/contractorEmployee";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { validateABN } from "social-pro-common/utils/abn";
import { isValidDate } from "social-pro-common/utils/date";

import { excelRowToStringArray, isTruthy } from "./utils";

interface XlsxEmployeeResult {
  employees: EmployeeLineItem[];
  errorMessage?: string;
}

export const readXlsxFileToEmployee = async (
  userOrganisation: OrganisationLineItem,
  file: File,
): Promise<XlsxEmployeeResult> => {
  const DATA_START_ROW = 14;
  let errorMessage: string;
  return new Promise((resolve, reject) => {
    const wb = new ExcelJs.Workbook();
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        wb.xlsx.load(data).then((workbook: ExcelJs.Workbook) => {
          const employees = [] as EmployeeLineItem[];
          let count = 0;

          const idMap = new Map();

          let error = false;
          workbook.eachSheet((sheet: ExcelJs.Worksheet) => {
            if (!error && count == 0) {
              sheet.eachRow((row: ExcelJs.Row, rowIndex: number) => {
                if (error) {
                  return;
                }
                if (rowIndex >= DATA_START_ROW) {
                  const values = excelRowToStringArray(
                    row.values as ExcelJs.CellValue[],
                  );
                  if (values.length < 20) {
                    //TODO: Find a better way to check for empty rows
                    return;
                  }
                  const employeeCode = values[2];
                  if (employeeCode.length < 0) {
                    return;
                  }
                  if (
                    employeeCode.length > 0 &&
                    employeeCode !==
                      "Insert rows to enter more data as required"
                  ) {
                    const newEmployee = createDefaultEmployee(userOrganisation);
                    newEmployee.employeeName = employeeCode.trim();
                    newEmployee.employeeIdentifier = values[3];

                    const gender = stringImportToGender(values[4]);
                    if (!gender) {
                      error = true;
                      reject(
                        `Error reading gender on line ${rowIndex} - ${values[4]}`,
                      );
                    } else {
                      newEmployee.gender = gender;
                    }

                    const dob = new Date(values[5]);
                    if (isValidDate(dob)) {
                      newEmployee.dob = dob;
                    }

                    newEmployee.postCode = values[6];

                    const employmentType = stringImportToEmploymentType(
                      values[7],
                    );
                    if (!employmentType) {
                      error = true;
                      reject(
                        `Error reading employment type on line ${rowIndex} - ${values[7]}`,
                      );
                    } else {
                      newEmployee.employmentType = employmentType;
                    }

                    const primaryEmployer = values[8];
                    if (!primaryEmployer) {
                      error = true;
                      reject(
                        `Error reading primary employer on line ${rowIndex} - ${values[8]}`,
                      );
                    } else {
                      newEmployee.primaryEmployer = primaryEmployer;
                    }

                    const employerAbn = values[9];
                    if (!employerAbn || !validateABN(employerAbn.toString())) {
                      error = true;
                      reject(
                        `Error reading employer abn on line ${rowIndex} - ${values[9]}`,
                      );
                    } else {
                      newEmployee.employerAbn = employerAbn;
                    }

                    const occupation = stringImportToOccupation(values[10]);
                    if (!occupation) {
                      error = true;
                      reject(
                        `Error reading occupation on line ${rowIndex} - ${values[10]}`,
                      );
                    } else {
                      newEmployee.occupation = occupation;
                    }

                    if (!values[11]) {
                      reject(
                        `Error reading occupation type on line ${rowIndex}`,
                      );
                    }
                    const occupationType = stringImportToOccupationType(
                      values[11],
                    );
                    if (!occupationType) {
                      error = true;
                      reject(
                        `Error reading occupation type on line ${rowIndex} - ${values[11]}`,
                      );
                    } else {
                      newEmployee.occupationType = occupationType;
                    }

                    const newExistingJob = stringImportToNewExistingJobType(
                      values[12],
                    );
                    if (!occupationType) {
                      error = true;
                      reject(
                        `Error reading new existing job on line ${rowIndex} - ${values[12]}`,
                      );
                    } else {
                      newEmployee.newExistingJob = newExistingJob;
                    }

                    const contractType = stringImportToContractType(values[13]);
                    if (!contractType) {
                      error = true;
                      reject(
                        `Error reading contract type on line ${rowIndex} - ${values[13]}`,
                      );
                    } else {
                      newEmployee.contractType = contractType;
                    }

                    const employmentLevel = stringImportToEmploymentLevel(
                      values[14],
                    );
                    if (!employmentLevel) {
                      error = true;
                      reject(
                        `Error reading Apprentice/Trainee/Cadet on line ${rowIndex} - ${values[14]}`,
                      );
                    } else {
                      newEmployee.employmentLevel = employmentLevel;
                    }
                    if (isTruthy(values[15])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.AboriginalOrTorresStraitIslander,
                      );
                    }
                    if (isTruthy(values[16])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.AsylumSeekerRefugee,
                      );
                    }
                    if (isTruthy(values[17])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.DisengagedYouth,
                      );
                    }
                    if (isTruthy(values[18])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.LongTermUnemployed,
                      );
                    }
                    if (isTruthy(values[19])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.PersonWithDisability,
                      );
                    }
                    if (isTruthy(values[20])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.Veteran,
                      );
                    }

                    if (isTruthy(values[21])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.CulturallyDiverseMigrant,
                      );
                    }
                    if (isTruthy(values[22])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.PersonsAffectedByFamilyViolence,
                      );
                    }
                    if (isTruthy(values[23])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.JobseekerWithAMentalIllness,
                      );
                    }
                    if (isTruthy(values[24])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage,
                      );
                    }
                    if (isTruthy(values[25])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.MatureAgedJobSeeker,
                      );
                    }
                    if (isTruthy(values[26])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.WomenOver45,
                      );
                    }
                    if (isTruthy(values[27])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.SingleParent,
                      );
                    }
                    if (isTruthy(values[28])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.SocialHousingTenant,
                      );
                    }
                    if (isTruthy(values[29])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked,
                      );
                    }
                    if (isTruthy(values[30])) {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.YoungPeopleInOutOfHomeCare,
                      );
                    }

                    if (!idMap.has(newEmployee.employeeName)) {
                      idMap.set(newEmployee.employeeName, rowIndex);
                      employees.push(newEmployee);
                    } else {
                      error = true;
                      reject(
                        `Duplicate employee codes ${newEmployee.employeeName} at ${rowIndex} and ${idMap.get(newEmployee.employeeName)}.`,
                      );
                    }
                  }
                }
              });
            }
            count += 1;
          });
          resolve({ employees, errorMessage });
        });
      } else {
        reject("Error reading file");
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export const writeXlsxFileEmployees = async (
  employees: EmployeeLineItem[],
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const workbook = new ExcelJs.Workbook();
        const workSheet = workbook.addWorksheet("Employees");
        workSheet.columns = [
          "Employee Name",
          "Primary Employer",
          "Occupation",
          "Occupation Type",
          "Gender",
        ];
        workSheet.addRow([
          "Employee Name",
          "Primary Employer",
          "Occupation",
          "Occupation Type",
          "Gender",
        ]);

        for (const employee of employees) {
          workSheet.addRow([
            `${employee.employeeName ? employee.employeeName : ""} ${employee.employeeIdentifier ? `(${employee.employeeIdentifier})` : ""}`,
            employee.primaryEmployer,
            occupationToString(employee.occupation),
            occupationTypeOptionToString(employee.occupationType),
            genderToString(employee.gender),
          ]);
        }

        const data = await workbook.xlsx.writeBuffer();

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Social-Pro-Employees.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    })();
  });
};
