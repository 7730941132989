import { Skeleton, TableCell, TableRow } from "@mui/material";

export const OrganisationDashboardTableSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center" sx={{ width: "33%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ width: "33%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
