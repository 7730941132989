import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";

export const PackageSocialRequirementTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell
        sx={{ minWidth: "10vw", paddingBottom: "10px", paddingTop: "10px" }}
      >
        <Skeleton animation="wave" width={300} />
      </TableCell>
      <TableCell sx={{ paddingBottom: "10px", paddingTop: "10px" }}>
        <Stack direction="row" justifyContent={"center"} spacing={1}>
          <Skeleton animation="wave" width={100} />
        </Stack>
      </TableCell>
      <TableCell sx={{ paddingBottom: "10px", paddingTop: "10px" }}>
        <Stack direction="row" justifyContent={"center"} spacing={1}>
          <Skeleton animation="wave" width={100} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
