import { Skeleton, styled } from "@mui/material";
import { BarChart } from "@mui/x-charts";

const StyledBarChart = styled(BarChart)({
  "& .MuiChartsAxis-line": {
    stroke: "#F5F5F5 !important",
  },
  ".MuiBarElement-root": {
    width: "24px !important",
  },
  ".MuiChartsAxis-directionY": {
    display: "none",
  },
  ".MuiChartsAxis-tick": {
    display: "none",
  },
  ".MuiChartsAxis-tickLabel": {
    fill: "#535862 !important",
    fontFamily: "'Inter', sans-serif",
  },
  ".MuiChartsAxisHighlight-root": {
    display: "none",
  },
  ".MuiChartsGrid-horizontalLine": {
    stroke: "#f5f5f5",
  },
});

interface Props {
  data: {
    pData: number[];
    uData: number[];
    xLabels: string[];
  };
  stackColors: {
    1: string;
    2: string;
  };
  loading: boolean;
}

const CustomBarChart = ({ data, loading, stackColors }: Props) => {
  const { pData, uData, xLabels } = data;
  const { 1: primary, 2: secondary } = stackColors;

  if (loading) {
    <Skeleton
      variant="rectangular"
      width={30}
      height={`${100}%`}
      animation="wave"
      sx={{
        borderRadius: 2,
      }}
    />;
  }

  return (
    <StyledBarChart
      loading={loading}
      series={[
        { color: primary, data: pData, id: "pvId", stack: "total" },
        { color: secondary, data: uData, id: "uvId", stack: "total" },
      ]}
      xAxis={[{ data: xLabels, scaleType: "band" }]}
      grid={{ horizontal: true }}
      height={312}
      borderRadius={4}
      margin={{ bottom: 24, left: 0, right: 0, top: 0 }}
    />
  );
};

export default CustomBarChart;
