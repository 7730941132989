"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePackageLocalContent = exports.decodePackageLocalContent = void 0;
const calc_1 = require("../../utils/calc");
const string_1 = require("../../utils/string");
const decodePackageLocalContent = (packageLocalContent) => {
    const importValueAdd = (0, calc_1.calcLocalContent)(packageLocalContent.local_value_add);
    const anzValueAdd = (0, calc_1.calcANZValueAdd)(packageLocalContent.local_value_add, packageLocalContent.contract_content);
    return {
        anzValueAdd: anzValueAdd,
        contentOrigin: packageLocalContent.content_origin,
        contractContent: packageLocalContent.contract_content,
        created: new Date(packageLocalContent.created),
        id: packageLocalContent.package_local_content_id,
        importValueAdd: importValueAdd,
        itemDescription: packageLocalContent.item_description,
        localValueAdd: packageLocalContent.local_value_add,
        manufacturer: packageLocalContent.manufacturer,
        packageId: packageLocalContent.package_id,
        projectId: packageLocalContent.project_id,
        reportId: packageLocalContent.report_id
            ? packageLocalContent.report_id
            : undefined,
        smeCount: packageLocalContent.sme_count,
        steelMass: packageLocalContent.steel_mass,
        supplier: packageLocalContent.supplier,
        supplierCount: packageLocalContent.supplier_count,
        value: packageLocalContent.value,
    };
};
exports.decodePackageLocalContent = decodePackageLocalContent;
const encodePackageLocalContent = (localContent) => {
    const safeLocalContent = (0, string_1.makePostgresSafe)(localContent);
    return {
        content_origin: safeLocalContent.contentOrigin,
        contract_content: safeLocalContent.contractContent,
        created: safeLocalContent.created,
        item_description: safeLocalContent.itemDescription,
        local_value_add: safeLocalContent.localValueAdd,
        manufacturer: safeLocalContent.manufacturer,
        package_id: safeLocalContent.packageId,
        package_local_content_id: safeLocalContent.id,
        project_id: safeLocalContent.projectId,
        report_id: safeLocalContent.reportId
            ? safeLocalContent.reportId
            : undefined,
        sme_count: safeLocalContent.smeCount,
        steel_mass: safeLocalContent.steelMass,
        supplier: safeLocalContent.supplier,
        supplier_count: safeLocalContent.supplierCount,
        value: safeLocalContent.value,
    };
};
exports.encodePackageLocalContent = encodePackageLocalContent;
