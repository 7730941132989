import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Tooltip, Zoom } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";

interface ActionCellProps extends CustomCellRendererProps {
  loading: boolean;
  handleDelete: (name: string) => void;
}

interface EditorCellProps extends CustomCellRendererProps {
  placeholder: string;
}

const ActionCell = ({ data, handleDelete, loading }: ActionCellProps) => {
  return (
    <Tooltip placement="top" title="Archive" TransitionComponent={Zoom}>
      <IconButton
        color="error"
        size="large"
        disabled={loading}
        onClick={() => handleDelete(data)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

const EditorCell = ({ placeholder, value }: EditorCellProps) => {
  return (
    <Box
      style={{
        alignItems: "center",
        boxSizing: "border-box",
        color: value && value.trim() !== "" ? "black" : "gray",
        display: "flex",
        height: "100%",
        textAlign: "left",
        width: "100%",
      }}
    >
      {value && value.trim() !== "" ? value : placeholder}
    </Box>
  );
};

export { ActionCell, EditorCell };
