import { Skeleton, TableCell, TableRow } from "@mui/material";
import { DropdownIcon } from "assets/constants";

export const EmployeeTableRowSkeleton = () => {
  const skeletonCells = [
    { width: "20%" },
    { width: "20%" },
    { width: "20%" },
    { width: "15%" },
    { width: "15%" },
  ];

  return (
    <TableRow>
      {skeletonCells.map((cell, index) => (
        <TableCell
          key={index}
          sx={{
            paddingBottom: "10px",
            paddingTop: "10px",
            width: cell.width,
          }}
        >
          <Skeleton animation="wave" variant={"rounded"} />
        </TableCell>
      ))}

      <TableCell
        align="center"
        sx={{ paddingBottom: "10px", paddingTop: "10px", width: "10%" }}
      >
        <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
          <DropdownIcon />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
