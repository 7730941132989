import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { CoolDownButton } from "@stories/atoms/CooldownButton/CooldownButton";
import Avatar from "@stories/ui-component/extended/Avatar";
import { userHasManagerAccess } from "@utils/projectAccess";
import { CustomCellRendererProps } from "ag-grid-react";

const NameActionCell = ({ data }: CustomCellRendererProps) => {
  return (
    <>
      <Avatar
        size="badge"
        alt={data.contractorName}
        src={data.contractorLogo}
        sx={{ backgroundColor: "#ffffff", marginRight: "12px" }}
      />
      <Typography
        variant="caption"
        color="black"
        sx={{ fontSize: "0.875rem", fontWeight: "bold" }}
      >
        {`${data.contractorName}${data.isAccepted ? "" : " (Pending)"}`}
      </Typography>
    </>
  );
};

const ToolsCell = (props: CustomCellRendererProps) => {
  const {
    context: { cellActions, isAdmin, project },
    data,
  } = props;

  return (
    <Stack direction="row" spacing={0.5} alignItems={"center"}>
      {data.isAccepted ? (
        <Button
          color="primary"
          size="large"
          onClick={() => cellActions.viewPackageDetails(props)}
          className="blackBtn grey-outline-btn"
          sx={{
            fontSize: "12px",
            height: "32px",
            minWidth: "auto !important",
            padding: "4px 8px !important",
          }}
        >
          Details
        </Button>
      ) : (
        <CoolDownButton
          cooldownDuration={5000}
          onResend={() => {
            cellActions.resendInvite(data.id);
          }}
          title={"Resend"}
          styles={{
            fontSize: "0.8rem",
            height: "32px",
            minWidth: "auto !important",
            padding: "4px 8px !important",
          }}
        />
      )}
      {userHasManagerAccess(project) && (
        <>
          <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
            <IconButton
              color="primary"
              size="medium"
              disabled={!isAdmin}
              onClick={() => cellActions.handleEditPackage(data.id)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
            <IconButton
              color="error"
              size="medium"
              disabled={!isAdmin}
              onClick={() => cellActions.handleOpenConfirmDialog(data)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export { NameActionCell, ToolsCell };
