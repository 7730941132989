import { styled, Paper } from "@mui/material";

const StyledText = styled("text")({
  dominantBaseline: "central",
  fill: "#181D27",
  fontFamily: "'Inter'",
  fontSize: 36,
  fontWeight: 500,
  lineHeight: 44,
  textAnchor: "middle",
});

const StyledPaper = styled(Paper)({
  backgroundColor: "#0A0D12",
  borderRadius: "8px",
  color: "white",
  minWidth: "200px",
  padding: "8px 12px",
  position: "relative",
});

const StyledTypography = styled("div")(({ color }: { color?: string }) => ({
  color: color || "#D5D7DA",
  fontFamily: "'Inter'",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
}));

const PieChartContainer = styled("div")({
  "& .MuiPieArc-root": {
    stroke: "none",
  },
});

export { StyledText, StyledPaper, StyledTypography, PieChartContainer };
