import { Typography } from "@mui/material";
import { NavItemList } from "@stories/layout/MainLayout/menu-items";
import { defaultMenuItem } from "@stories/layout/ProjectsLayout/menu-items/projects";
import { NavItemType } from "types";

import { MenuListItemTree } from "./MenuListItemTree";
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

interface MenuListProps {
  loading: boolean;
  menuItem: NavItemList;
  selectedItem: string[];
  title: string;
  setSelectedItem: (item: string[]) => void;
}

const MenuList = ({
  item,
  selectedItem,
}: {
  item: NavItemType;
  selectedItem: string[];
}) => {
  return (
    <>
      <Typography
        data-test-id
        variant="caption"
        sx={{
          color: "#FFF",
          fontSize: "10px",
          fontWeight: 500,
          letterSpacing: "1px",
          opacity: 0.5,
          textTransform: "uppercase",
        }}
        display="block"
        gutterBottom
      >
        {item.title}
      </Typography>
      <MenuListItemTree items={item} selectedItem={selectedItem} />
    </>
  );
};

const Menu = ({
  loading,
  menuItem,
  selectedItem,
  setSelectedItem,
  title,
}: MenuListProps) => {
  const content = loading ? (
    <>
      {Array.from({ length: 3 }, (_, index) => (
        <NavGroup
          key={`nav-bar-item-${index}`}
          loading
          item={{
            children: [
              defaultMenuItem(1),
              defaultMenuItem(2),
              defaultMenuItem(3),
            ],
            id: "loading",
            title: "loading",
            type: "group",
          }}
          selectedItem={[]}
          setSelectedItem={setSelectedItem}
        />
      ))}
    </>
  ) : (
    menuItem.items.map((item, index) => {
      switch (item.type) {
        case "group":
          return (
            <>
              {index === 0 && (
                <>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#FFF",
                      fontSize: "10px",
                      fontWeight: 500,
                      letterSpacing: "1px",
                      opacity: 0.5,
                      textTransform: "uppercase",
                    }}
                    display="block"
                    gutterBottom
                  >
                    {title}
                  </Typography>
                </>
              )}
              <MenuList item={item} selectedItem={selectedItem} />
            </>
          );
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    })
  );
  return <>{content}</>;
};

export default Menu;
