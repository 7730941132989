"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeReportDocument = exports.decodeReportDocument = void 0;
const string_1 = require("../../utils/string");
const decodeReportDocument = (reportSubmission) => {
    return {
        contractorPackageId: reportSubmission.contractor_package_id,
        created: reportSubmission.created,
        id: reportSubmission.report_document_id,
        reportDocumentType: reportSubmission.report_document_type,
        reportId: reportSubmission.report_id,
        uri: reportSubmission.uri,
    };
};
exports.decodeReportDocument = decodeReportDocument;
const encodeReportDocument = (reportSubmission) => {
    const safeReportSubmission = (0, string_1.makePostgresSafe)(reportSubmission);
    return {
        contractor_package_id: safeReportSubmission.contractorPackageId,
        created: safeReportSubmission.created,
        report_document_id: safeReportSubmission.id,
        report_document_type: safeReportSubmission.reportDocumentType,
        report_id: safeReportSubmission.reportId,
        uri: safeReportSubmission.uri,
    };
};
exports.encodeReportDocument = encodeReportDocument;
