import { Skeleton, Stack } from "@mui/material";
import ProgressBar from "@stories/atoms/ProgressBar/ProgressBar";
import { Arrow } from "assets/constants";

import {
  CardContainer,
  CardFooter,
  CardHeader,
  ChangeText,
  PercentageText,
  ProgressText,
  StatusText,
  SubtitleText,
  TitleText,
  ValueContainer,
  ValueLabelText,
  ValueText,
} from "./components";

interface OverviewCardProps {
  title: string;
  subtitle: string;
  progress: string;
  progressbarColor: string;
  value: string;
  valueLabel: string;
  change: string;
  progressTitle: string;
  loading: boolean;
}

export const OverviewCard = ({
  change,
  progress,
  progressTitle,
  progressbarColor,
  subtitle,
  title,
  value,
  valueLabel,
}: OverviewCardProps) => {
  return (
    <CardContainer>
      <CardHeader>
        <Stack direction="column" gap="24px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Stack direction="column" gap="4px">
              <TitleText>{title}</TitleText>
              <SubtitleText>{subtitle}</SubtitleText>
            </Stack>
            <Stack direction="row" alignItems="baseline" gap={1}>
              <ProgressText>{progress}</ProgressText>
              <PercentageText>%</PercentageText>
              <StatusText>{progressTitle}</StatusText>
            </Stack>
          </Stack>
          <ProgressBar colors={[progressbarColor]} figure1={progress} />
        </Stack>
      </CardHeader>

      <CardFooter>
        <Stack direction="column" gap="4px">
          <ValueLabelText>{valueLabel}</ValueLabelText>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            gap="24px"
          >
            <ValueText>{value}</ValueText>
            <ValueContainer direction="row" alignItems="center">
              <Arrow />
              <ChangeText>{change}</ChangeText>
            </ValueContainer>
          </Stack>
        </Stack>
      </CardFooter>
    </CardContainer>
  );
};

export const OverviewCard2 = ({
  change,
  dataIdPrefix = "project",
  loading,
  progress,
  progressTitle,
  progressbarColor,
  subtitle,
  title,
  value,
  valueLabel,
}: {
  title: string;
  subtitle: string;
  progress: string;
  progressTitle: string;
  progressbarColor: string;
  valueLabel: string;
  value: string;
  change: string;
  loading: boolean;
  dataIdPrefix?: string;
}) => (
  <CardContainer>
    <CardHeader>
      <Stack direction="column" gap="24px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack direction="column" gap="4px">
            <TitleText>
              {loading ? <Skeleton animation={"wave"} width={150} /> : title}
            </TitleText>
            <SubtitleText>
              {loading ? <Skeleton animation={"wave"} width={200} /> : subtitle}
            </SubtitleText>
          </Stack>
          <Stack direction="row" alignItems="baseline" gap={1}>
            {loading ? (
              <>
                <Skeleton
                  animation={"wave"}
                  width={50}
                  height={50}
                  variant={"rounded"}
                />
                <Skeleton animation={"wave"} width={20} />
                <Skeleton animation={"wave"} width={80} />
              </>
            ) : (
              <>
                <ProgressText data-test-id={`${dataIdPrefix}-progress-value`}>
                  {progress}
                </ProgressText>
                <PercentageText>%</PercentageText>
                <StatusText>{progressTitle}</StatusText>
              </>
            )}
          </Stack>
        </Stack>
        {loading ? (
          <Skeleton animation={"wave"} variant="rectangular" height={10} />
        ) : (
          <ProgressBar
            dataIdPrefix={dataIdPrefix}
            colors={[progressbarColor]}
            figure1={`${progress}%`}
          />
        )}
      </Stack>
    </CardHeader>

    <CardFooter>
      <Stack direction="column" gap="4px">
        <ValueLabelText>
          {loading ? <Skeleton animation={"wave"} width={120} /> : valueLabel}
        </ValueLabelText>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          gap="24px"
        >
          {loading ? (
            <>
              <Skeleton animation={"wave"} width={100} />
              <Skeleton
                animation={"wave"}
                width={50}
                height={25}
                variant={"rounded"}
              />
            </>
          ) : (
            <>
              <ValueText data-test-id={`${dataIdPrefix}-total-value`}>
                {value}
              </ValueText>
              <ValueContainer direction="row" alignItems="center">
                <Arrow />
                <ChangeText data-test-id={`${dataIdPrefix}-progress-pc`}>
                  {change}
                </ChangeText>
              </ValueContainer>
            </>
          )}
        </Stack>
      </Stack>
    </CardFooter>
  </CardContainer>
);
