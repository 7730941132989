import {
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Skeleton,
  Table,
  TableBody,
} from "@mui/material";
import React from "react";

const StyledTableContainer = styled(TableContainer)`
  border-radius: 6px;
  border: 1px solid #babfc7;
  border-bottom: none;
  border-left: none;
  box-shadow:
    0px 4px 6px -1px rgba(10, 13, 18, 0.1),
    0px 2px 4px -2px rgba(10, 13, 18, 0.06);
`;

const StyledHeaderCell = styled(TableCell)`
  background-color: #f8f8f8;
  width: 170px;
  font-weight: bold;
  padding: 14px;
  border-color: #babfc7;
  border-left: 1px solid #babfc7;
  border-right: 1px solid #babfc7;
  border-radius: 6px;
`;

const StyledValueCell = styled(TableCell)`
  background-color: white;
  padding: 14px;
  width: 35%;
  border-color: #babfc7;
`;

const StyledSkeletonHeaderCell = styled(TableCell)`
  background-color: #f8f8f8;
  width: 170px;
  padding: 14px;
  border-color: #babfc7;
  border-left: 1px solid #babfc7;
  border-right: 1px solid #babfc7;
  border-radius: 6px;
`;

const StyledSkeletonValueCell = styled(TableCell)`
  background-color: white;
  padding: 14px;
  width: 35%;
  border-color: #babfc7;
  border-left: 1px solid #babfc7;
`;

const TableRowItem = ({
  header1,
  header2,
  value1,
  value2,
}: {
  header1: string;
  value1: React.ReactNode;
  header2: string;
  value2: React.ReactNode;
}) => (
  <TableRow>
    <StyledHeaderCell>{header1}</StyledHeaderCell>
    <StyledValueCell>{value1}</StyledValueCell>
    <StyledHeaderCell>{header2}</StyledHeaderCell>
    <StyledValueCell>{value2}</StyledValueCell>
  </TableRow>
);

const SummaryTableSkeleton = () => {
  return (
    <StyledTableContainer>
      <Table>
        <TableBody>
          {Array.from({ length: 3 }).map((_, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: index % 2 === 0 ? "#FBFBFB" : "white",
              }}
            >
              <StyledSkeletonHeaderCell>
                <Skeleton variant="text" width={120} height={24} />
              </StyledSkeletonHeaderCell>
              <StyledSkeletonValueCell>
                <Skeleton variant="text" width={180} height={24} />
              </StyledSkeletonValueCell>
              <StyledSkeletonHeaderCell>
                <Skeleton variant="text" width={120} height={24} />
              </StyledSkeletonHeaderCell>
              <StyledSkeletonValueCell>
                <Skeleton variant="text" width={180} height={24} />
              </StyledSkeletonValueCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export { TableRowItem, SummaryTableSkeleton, StyledTableContainer };
