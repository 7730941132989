"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseMultiplierForHours = exports.createDefaultLabourHourCommitment = exports.createDefaultLabourHourCommitmentsForProject = exports.defaultLabourHourCommmimentCriteria = exports.LOCAL_JOBS_FIRST_DISTANCE = void 0;
const uuid_1 = require("uuid");
const contractorEmployee_1 = require("../../entities/contractorEmployee");
const financial_1 = require("../../entities/financial");
const packageLabourHourCommitment_1 = require("../packageLabourHourCommitment");
exports.LOCAL_JOBS_FIRST_DISTANCE = 50;
exports.defaultLabourHourCommmimentCriteria = {
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation]: {
        colour: "#FFAB91",
        employmentLevel: [],
        gender: [],
        maxDistance: undefined,
        socialFactors: [contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander],
        targetValue: 4.1,
    },
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepManagementHours]: {
        colour: "#15cf74",
        employmentLevel: [],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        occupationType: [contractorEmployee_1.OccupationType.ManagementSupervisorSpecialistLabourRoles],
        socialFactors: [],
        targetValue: 35,
    },
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepNonTradeHours]: {
        colour: "#15cf74",
        employmentLevel: [],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        occupationType: [contractorEmployee_1.OccupationType.NonTradePosition],
        socialFactors: [],
        targetValue: 7,
    },
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepTradeHours]: {
        colour: "#15cf74",
        employmentLevel: [],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        occupationType: [contractorEmployee_1.OccupationType.TradeRole],
        socialFactors: [],
        targetValue: 3,
    },
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleApprenticeAndTrainee]: {
        colour: "#28A745",
        employmentLevel: [contractorEmployee_1.EmploymentLevel.Apprentice, contractorEmployee_1.EmploymentLevel.Trainee],
        gender: [contractorEmployee_1.Gender.Female],
        maxDistance: undefined,
        socialFactors: [],
        targetValue: 4,
    },
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleParticipation]: undefined,
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.LocalJobsFirst]: {
        colour: "#90CAF9",
        employmentLevel: [],
        gender: [],
        maxDistance: exports.LOCAL_JOBS_FIRST_DISTANCE,
        socialFactors: [],
        targetValue: 90,
    },
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.MPSGHoursRequirement]: {
        colour: "#3f99fa",
        employmentLevel: [
            contractorEmployee_1.EmploymentLevel.Apprentice,
            contractorEmployee_1.EmploymentLevel.Trainee,
            contractorEmployee_1.EmploymentLevel.Cadet,
        ],
        gender: [],
        maxDistance: undefined,
        socialFactors: [],
        targetValue: 10,
    },
    [packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce]: {
        colour: "#1565C0",
        employmentLevel: [],
        gender: [],
        maxDistance: undefined,
        socialFactors: [
            contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee,
            contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth,
            contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed,
            contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability,
            contractorEmployee_1.EmployeeSocialFactor.Veteran,
            contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence,
            contractorEmployee_1.EmployeeSocialFactor.ExOffender,
        ],
        targetValue: 2,
    },
};
const createDefaultLabourHourCommitmentsForProject = (projectId) => {
    return Object.values(packageLabourHourCommitment_1.SocialCommitmentTypeHour).reduce((acc, sc) => {
        if (sc !== packageLabourHourCommitment_1.SocialCommitmentTypeHour.DefaultHour) {
            const commitmentConfiguration = exports.defaultLabourHourCommmimentCriteria[sc];
            if (commitmentConfiguration) {
                return [
                    ...acc,
                    {
                        ...commitmentConfiguration,
                        achievedValue: 0,
                        created: new Date(),
                        id: (0, uuid_1.v4)(),
                        projectId,
                        targetName: sc,
                        targetValueRealised: 0,
                    },
                ];
            }
        }
        return [...acc];
    }, []);
};
exports.createDefaultLabourHourCommitmentsForProject = createDefaultLabourHourCommitmentsForProject;
const createDefaultLabourHourCommitment = (projectId) => {
    return {
        achievedValue: 0,
        colour: "#000000",
        created: new Date(),
        employmentLevel: [],
        gender: [],
        id: (0, uuid_1.v4)(),
        maxDistance: undefined,
        projectId,
        projectSocialSpendCommitmentId: (0, uuid_1.v4)(),
        socialFactors: [],
        targetName: packageLabourHourCommitment_1.SocialCommitmentTypeHour.DefaultHour,
        targetValue: 0,
        targetValueRealised: 0,
    };
};
exports.createDefaultLabourHourCommitment = createDefaultLabourHourCommitment;
const getBaseMultiplierForHours = (financial, c) => {
    return financial.projectEstimateMethod === financial_1.EstimateType.DeemedHours
        ? financial.requiredHours
        : (() => {
            switch (c.targetName) {
                default:
                    return financial.estimatedTotalWorkHours;
                case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepTradeHours:
                    return financial.estimatedBepHoursTrade;
                case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepNonTradeHours:
                    return financial.estimatedBepHoursNonTrade;
                case packageLabourHourCommitment_1.SocialCommitmentTypeHour.BepManagementHours:
                    return financial.estimatedBepHoursManagement;
            }
        })();
};
exports.getBaseMultiplierForHours = getBaseMultiplierForHours;
