import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import "./styles.css";
import FAQSection from "@stories/molecules/FAQSection/FAQSection";
import { TutorialSection } from "@stories/molecules/TutorialSection/TutorialSection";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const PlatformSupport = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { isAuthProfileLoading, userProfile } = useProfileContext();

  const handleChange = async (newTab: number) => {
    setTabIndex(newTab);
  };

  if (!userProfile) {
    throw new Error("No user profile to list projects");
  }

  const loading = isAuthProfileLoading;

  const pageContent = (() => {
    switch (tabIndex) {
      default:
      case 0:
        return <FAQSection loading={loading} />;
      case 1:
        return <TutorialSection loading={loading} />;
    }
  })();

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab == "tutorials") {
      setTabIndex(1);
    } else if (tab == "faqs") {
      setTabIndex(0);
    }
    setSearchParams();
  }, []);

  const tabs = [
    {
      label: "FAQs",
    },
    {
      label: "Tutorials",
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        mainTitle={"Help Center"}
        tabs={tabs}
        tabIndex={tabIndex}
        handleTabChange={handleChange}
      />

      <Grid
        md={12}
        item
        sx={{
          padding: "0px",
        }}
      >
        {pageContent}
      </Grid>
    </PageContainer>
  );
};
