"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultIntegrationInfoLineItem = exports.IntegrationType = void 0;
const uuid_1 = require("uuid");
var IntegrationType;
(function (IntegrationType) {
    IntegrationType["HammerTech"] = "integration_type-hammer_tech";
    IntegrationType["DamstraImport"] = "integration_type-damstra_import";
})(IntegrationType || (exports.IntegrationType = IntegrationType = {}));
const createDefaultIntegrationInfoLineItem = (organisationId, integrationType) => ({
    config: {},
    created: new Date(),
    enabled: false,
    id: (0, uuid_1.v4)(),
    integrationType,
    organisationId,
});
exports.createDefaultIntegrationInfoLineItem = createDefaultIntegrationInfoLineItem;
