"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectCommentTypeToString = exports.ProjectCommentType = exports.createDefaultProjectComment = void 0;
const uuid_1 = require("uuid");
const createDefaultProjectComment = (projectId, reportId, commentType, comment) => {
    return {
        comment,
        commentType,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId,
        reportId,
    };
};
exports.createDefaultProjectComment = createDefaultProjectComment;
var ProjectCommentType;
(function (ProjectCommentType) {
    ProjectCommentType["ProjectSummary"] = "project-comment-type_project-summary";
    ProjectCommentType["ProjectSustainabilitySummary"] = "project-comment-type_project-sustainability-summary";
})(ProjectCommentType || (exports.ProjectCommentType = ProjectCommentType = {}));
const projectCommentTypeToString = (projectCommentType) => {
    switch (projectCommentType) {
        case ProjectCommentType.ProjectSummary:
            return "Project Summary";
        default:
            throw new Error("Invalid ProjectCommentType");
    }
};
exports.projectCommentTypeToString = projectCommentTypeToString;
