import { Grid } from "@mui/material";
import {
  ColDef,
  ColGroupDef,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { ArchiveProjectIcon, EditProjectIcon } from "assets/constants";
import { useEffect, useMemo, useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  PackageLocalContentLineItem,
  aggregateLocalContentItems,
} from "social-pro-common/interfaces/packageLocalContent";
import {
  calculateLocalContentPackageAnzTotal,
  calculateLocalContentPackageContractTotal,
  calculateLocalContentPackageValueTotal,
} from "social-pro-common/utils/calc";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { AnalyticsLocalContentLevel2TableRowSkeleton } from "./AnalyticsLocalContentLevel2TableRowSkeleton";
import {
  generateTableHead,
  getCellStyle,
  isTotalRow,
  Table,
} from "../Table/Table";
import {
  ActionCell,
  centerCellStyles,
  rightCellStyles,
} from "../Table/TableCells";
import TableSkeleton from "../Table/TableSkeleton";

interface AnalyticsLocalContentLevel2TableProps {
  loading: boolean;
  showActions?: boolean;
  isPrimaryPackage: boolean;
  contractorPackage?: ContractorPackageLineItem;
  subPackage?: ContractorPackageLineItem;
  localContents: PackageLocalContentLineItem[];
  tabIndex: number;
  searchTerm: string;
  marginTop?: number;
  withoutBorder?: boolean;
  handleEditLocalContent?: (
    localContent: PackageLocalContentLineItem,
  ) => Promise<void>;
  handleDeleteLocalContent?: (
    localContent: PackageLocalContentLineItem,
  ) => Promise<void>;
}

export const AnalyticsLocalContentLevel2Table = ({
  handleDeleteLocalContent,
  handleEditLocalContent,
  loading,
  localContents,
  marginTop,
  searchTerm,
  showActions,
  subPackage,
  tabIndex,
  withoutBorder = false,
}: AnalyticsLocalContentLevel2TableProps) => {
  const showActual = tabIndex === 1;

  const itemsToDisplay = showActual
    ? localContents
    : aggregateLocalContentItems(subPackage?.localContent || []);

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      cellStyle: (params) => getCellStyle(params, "right"),
      colSpan: (params) => (isTotalRow(params) ? 3 : 1),
      field: "itemDescription",
      headerClass: "left-table-header",
      headerName: "Item Description",
      minWidth: 300,
      resizable: false,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "manufacturer",
      headerClass: "left-table-header",
      headerName: "Manufacturer",
      minWidth: 200,
      resizable: false,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "supplier",
      headerClass: "left-table-header",
      headerName: "Supplier",
      minWidth: 200,
      resizable: false,
    },
    {
      cellStyle: rightCellStyles,
      field: "value",
      headerClass: "right-table-header",
      headerName: "Value ($)",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      cellStyle: rightCellStyles,
      field: "steelMass",
      headerClass: "right-table-header",
      headerName: "Steel Mass (T)",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value)}T`
          : `${params.value}`;
      },
    },
    {
      cellStyle: rightCellStyles,
      field: "localValueAdd",
      headerClass: "right-table-header",
      headerName: "Local Value Add",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value)}%`
          : `${params.value}`;
      },
    },
    {
      cellStyle: { ...rightCellStyles, backgroundColor: "#f4f4f4" },
      field: "importValueAdd",
      headerClass: "right-table-header",
      headerName: "Import Value Add",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value)}%`
          : `${params.value}`;
      },
    },
    {
      cellStyle: rightCellStyles,
      field: "contractContent",
      headerClass: "right-table-header",
      headerName: "Contract Content",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
    },
    {
      cellStyle: { ...rightCellStyles, backgroundColor: "#f4f4f4" },
      field: "anzValueAdd",
      headerClass: "right-table-header",
      headerName: "ANZ value-add activity (%)",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
      wrapHeaderText: true,
    },
    {
      cellStyle: rightCellStyles,
      field: "smeCount",
      headerClass: "right-table-header",
      headerName: "SME Count",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
    },
    {
      cellStyle: rightCellStyles,
      field: "supplierCount",
      headerClass: "right-table-header",
      headerName: "Supplier Count",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
    },
  ]);

  const autoSizeStrategy = useMemo<SizeColumnsToContentStrategy>(
    () => ({
      type: "fitCellContents",
    }),
    [],
  );

  useEffect(() => {
    if (showActions) {
      _setColDefs((prev) => {
        return [
          ...prev,
          {
            cellRenderer: ActionCell,
            cellStyle: centerCellStyles,
            headerClass: "centered-table-header",
            headerName: "",
            minWidth: 50,
          },
        ];
      });
    }
  }, []);

  const cellActions = [
    {
      action: handleEditLocalContent,
      icon: <EditProjectIcon />,
      title: "Edit Local Content",
    },
    {
      action: handleDeleteLocalContent,
      icon: <ArchiveProjectIcon />,
      title: "Delete Local Content",
    },
  ];

  const contractTotal =
    calculateLocalContentPackageContractTotal(itemsToDisplay);

  const anzTotalTotal = calculateLocalContentPackageAnzTotal(itemsToDisplay);

  const valueTotal = calculateLocalContentPackageValueTotal(itemsToDisplay);

  const totalSme = itemsToDisplay.reduce(
    (acc, lc) => acc + (lc.smeCount ?? 0),
    0,
  );
  const totalSuppliers = itemsToDisplay.reduce(
    (acc, lc) => acc + (lc.supplierCount ?? 0),
    0,
  );

  const totalRow = {
    anzValueAdd: anzTotalTotal,
    contractContent: contractTotal,
    importValueAdd: "-",
    isTotalRow: true,
    itemDescription: "Total Local Content",
    localValueAdd: "-",
    smeCount: totalSme,
    steelMass: "-",
    supplier: "Total Local Content",
    supplierCount: totalSuppliers,
    value: valueTotal,
  };

  return (
    <Grid item xs={12} md={12} mt={marginTop || 0}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          noFullTableBorder={withoutBorder}
          rows={AnalyticsLocalContentLevel2TableRowSkeleton}
        />
      ) : null}
      {!loading ? (
        <Table<PackageLocalContentLineItem>
          columnDefs={colDefs}
          loading={loading}
          searchValue={searchTerm}
          autoSizeStrategy={autoSizeStrategy}
          data={itemsToDisplay}
          noFullTableBorder={withoutBorder}
          context={{ cellActions, loading, subPackage }}
          searchKeys={["itemDescription", "manufacturer", "supplier"]}
          pinnedBottomRowData={
            itemsToDisplay.length ? ([totalRow] as ColDef[]) : []
          }
        />
      ) : null}
    </Grid>
  );
};
