import { useProjectContext } from "@hooks/context/useProjectContext";
import { useMainChartData } from "@hooks/crud/dashboard/useMainChartData";
import { useForecastModel } from "@hooks/crud/forecastData/useForecastData";
import { Box, Grid } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

import { Header } from "../Header/Header";

interface PackageDetailsForecastProps {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
}
export const PackageDetailsForecast = ({
  isLoading,
  subPackage,
}: PackageDetailsForecastProps) => {
  const { isProjectLoading, selectedProject } = useProjectContext();

  const { isForecastLoading } = useForecastModel(selectedProject, subPackage);

  const { isChartDataLoading } = useMainChartData(
    selectedProject?.id,
    subPackage?.id,
  );
  // const [selectedTarget, setSelectedTarget] = useState<number>(0);
  // const [selectedRange, setSelectedRange] = useState<number>(0);

  // const handleChangeTarget = (tabIndex: number) => {
  //   setSelectedTarget(tabIndex);
  // };

  // const handleChangeRange = (event: React.SyntheticEvent, newValue: number) => {
  //   if (newValue) {
  //     setSelectedRange(newValue);
  //   }
  // };

  // const allBarChartData = {
  //   greenMetrics: {},
  //   labourHours: {},
  //   socialSpend: {},
  // };

  // const selectedChartData = (() => {
  //   if (chartData) {
  //     switch (selectedTarget) {
  //       default:
  //       case 0:
  //         return chartData.labourHours;
  //       case 1:
  //         return chartData.socialSpend;
  //       case 2:
  //         return chartData.greenMetrics;
  //     }
  //   }
  // })();

  // const chartUnits = (() => {
  //   if (allBarChartData) {
  //     switch (selectedTarget) {
  //       default:
  //       case 0:
  //         return "Hours";
  //       case 1:
  //         return "Dollars";
  //       case 2:
  //         return "Submissions";
  //     }
  //   }
  //   return "";
  // })();

  // const reportMonths = selectedProject?.projectReportMonths;

  const loading =
    isLoading || isProjectLoading || isForecastLoading || isChartDataLoading;

  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Header
          mainTitle="Forecast"
          subTitle="Target Forecast Summary"
          loading={loading}
          isSubheading
          headerFontSizes={[18, 14]}
          headerGap={0}
        />
        <Box height={30} />

        {/* <ForecastChart
          loading={loading}
          chartRangeType={selectedRange}
          chartUnits={chartUnits}
          reportMonths={reportMonths}
          forecastModals={forecastModels}
          chartData={selectedChartData}
        /> */}
      </WhiteBox>
    </Grid>
  );
};
