"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultFinancial = void 0;
const uuid_1 = require("uuid");
const financial_1 = require("../../entities/financial");
const createDefaultFinancial = () => {
    return {
        deemedLabourHourlyRate: 0,
        deemedLabourRatio: 0,
        estimatedBepHoursManagement: 0,
        estimatedBepHoursNonTrade: 0,
        estimatedBepHoursTrade: 0,
        estimatedContractSpend: 0,
        estimatedTotalWorkHours: 0,
        id: (0, uuid_1.v4)(),
        lidpPc: -1,
        margin: 0,
        projectEstimateMethod: financial_1.EstimateType.DeemedHours,
        requiredHours: 0,
        specialisedEquipmentValue: 0,
        totalValue: 0,
        variations: 0,
    };
};
exports.createDefaultFinancial = createDefaultFinancial;
