"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmployeeFactorIds = exports.encodeEmployee = exports.decodeEmployee = exports.decodeContractorEmployeeListModel = void 0;
const contractorEmployee_1 = require("../../entities/contractorEmployee");
const packageLabourHourCommitment_1 = require("../../interfaces/packageLabourHourCommitment");
const string_1 = require("../../utils/string");
const supportingDocument_1 = require("../supportingDocument");
const decodeContractorEmployeeListModel = (employeeModel) => {
    return {
        employees: employeeModel.employees.map((e) => (0, exports.decodeEmployee)(e)),
        totalEmployees: employeeModel.total_employees,
    };
};
exports.decodeContractorEmployeeListModel = decodeContractorEmployeeListModel;
const decodeEmployee = (employee) => {
    const employeeLineItem = {
        archived: employee.archived,
        assigned: employee.assigned,
        commitmentFactors: [],
        contractorId: employee.contractor_id,
        contractType: employee.contract_type,
        country: employee.country,
        created: new Date(employee.created),
        disadvantagedCostEntry: employee.disadvantaged_cost_entry,
        dob: employee.dob ? new Date(employee.dob) : undefined,
        educationalInstitute: employee.educational_institute,
        employeeIdentifier: employee.employee_identifier,
        employeeName: employee.employee_name,
        employeeSocialFactors: [],
        employerAbn: employee.employer_abn,
        employmentLevel: employee.employment_level,
        employmentType: employee.employment_type,
        gender: employee.gender,
        geoLocationLat: employee.geo_location_lat,
        geoLocationLong: employee.geo_location_long,
        id: employee.contractor_employee_id,
        newExistingJob: employee.new_existing_job,
        occupation: employee.occupation,
        occupationType: employee.occupation_type,
        postCode: employee.post_code,
        primaryEmployer: employee.primary_employer,
        qualification: employee.qualification,
        socialFactors: employee.social_factors,
        supportingDocuments: employee.supporting_documents
            ? employee.supporting_documents.map((sd) => (0, supportingDocument_1.decodeSupportingDocument)(sd))
            : [],
        traineeContractEndDate: employee.trainee_contract_end_date
            ? new Date(employee.trainee_contract_end_date)
            : undefined,
        trainingContractNumber: employee.training_contract_number,
        uniqueStudentId: employee.unique_student_id,
    };
    return employeeLineItem;
};
exports.decodeEmployee = decodeEmployee;
const encodeEmployee = (employee) => {
    const safeEmployee = (0, string_1.makePostgresSafe)(employee);
    return {
        archived: safeEmployee.archived,
        assigned: safeEmployee.assigned,
        contract_type: safeEmployee.contractType,
        contractor_employee_id: safeEmployee.id,
        contractor_id: safeEmployee.contractorId,
        country: safeEmployee.country,
        created: new Date(safeEmployee.created),
        disadvantaged_cost_entry: safeEmployee.disadvantagedCostEntry,
        dob: safeEmployee.dob ? safeEmployee.dob.toISOString() : undefined,
        educational_institute: safeEmployee.educationalInstitute,
        employee_identifier: safeEmployee.employeeIdentifier,
        employee_name: safeEmployee.employeeName,
        employer_abn: safeEmployee.employerAbn,
        employment_level: safeEmployee.employmentLevel,
        employment_type: safeEmployee.employmentType,
        gender: safeEmployee.gender,
        geo_location_lat: safeEmployee.geoLocationLat,
        geo_location_long: safeEmployee.geoLocationLong,
        new_existing_job: safeEmployee.newExistingJob,
        occupation: safeEmployee.occupation,
        occupation_type: safeEmployee.occupationType,
        post_code: safeEmployee.postCode,
        primary_employer: safeEmployee.primaryEmployer,
        qualification: safeEmployee.qualification,
        social_factors: safeEmployee.socialFactors,
        supporting_documents: safeEmployee.supportingDocuments.map((sd) => (0, supportingDocument_1.encodeSupportingDocument)(sd)),
        trainee_contract_end_date: safeEmployee.traineeContractEndDate,
        training_contract_number: safeEmployee.trainingContractNumber,
        unique_student_id: safeEmployee.uniqueStudentId,
    };
};
exports.encodeEmployee = encodeEmployee;
const getEmployeeFactorIds = (contractorPackage, employee) => {
    const socialFactors = [];
    if (!contractorPackage || !employee) {
        return socialFactors;
    }
    if (employee.gender === contractorEmployee_1.Gender.Female) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.FemaleParticipation);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if ([
        contractorEmployee_1.EmploymentLevel.Apprentice,
        contractorEmployee_1.EmploymentLevel.Cadet,
        contractorEmployee_1.EmploymentLevel.Trainee,
    ].includes(employee.employmentLevel)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.MPSGHoursRequirement);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if (employee.socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName ===
            packageLabourHourCommitment_1.SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if (employee.socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if (employee.socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if (employee.socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if (employee.socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if (employee.socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    if (employee.socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.Veteran)) {
        const factor = contractorPackage.commitmentsHours.find((c) => c.targetName === packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce);
        if (factor?.id) {
            socialFactors.push(factor.id);
        }
    }
    return socialFactors;
};
exports.getEmployeeFactorIds = getEmployeeFactorIds;
