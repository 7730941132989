import { useOrganisationDashboard } from "@hooks/crud/organisationDashboard/useOrganisationDashboard";
import { Grid } from "@mui/material";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  defaultCellConfig,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef } from "ag-grid-community";
import { useState } from "react";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { OrganisationProgressSummary } from "../../../organisms/OrgnisationSummary/OrganisationProgressSummary";
import { OrganisationDashboardTableSkeleton } from "../../../organisms/Tables/OrganisationDashboardTableSkeleton";

export const OrganisationDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [tabIndex, setTabIndex] = useState<number>(0);

  const { dashboardData, isAnalysisLoading } = useOrganisationDashboard();

  const formatValueForTable = (value: number, isSpend: boolean) => {
    return `${isSpend ? "$" : ""}${formatDecimalPlaces(value, 0)}`;
  };

  const columnDefs: ColDef[] = [
    {
      ...defaultCellConfig,
      field: "targetName",
      flex: 2,
      headerName: "Name",
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "target",
      headerClass: "right-table-header",
      headerName: "Value",
      valueFormatter: (params) =>
        `${formatValueForTable(params.value, tabIndex === 1)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "pcProgress",
      headerClass: "centered-table-header",
      headerName: "Percentage",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}%`,
    },
  ];

  // Handle tab change
  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
    setSearchTerm("");
  };

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loading = isAnalysisLoading;

  const tabs = [
    {
      label: "Labour Hours",
    },
    {
      label: "Social Spend",
    },
    {
      label: "Local Content",
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        mainTitle={"Organisation Dashboard"}
        subTitle={"Summary of Organisation Activity"}
        searchTerm={searchTerm}
        buttons={[]}
        tabs={[]}
      />

      <OrganisationProgressSummary data={dashboardData} loading={loading} />
      <Header
        loading={loading}
        mainTitle={"Metric Summaries"}
        subTitle={"Summary of Organisation Target Types"}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        buttons={[]}
        tabs={tabs}
      />
      <Grid md={12} item>
        {loading ? (
          <TableSkeleton
            tableHead={generateTableHead(columnDefs)}
            rows={OrganisationDashboardTableSkeleton}
          />
        ) : null}
        {!loading && tabIndex === 0 ? (
          <Table
            loading={loading}
            columnDefs={columnDefs}
            searchValue={searchTerm}
            data={dashboardData?.labourHours || []}
            searchKeys={["targetName"]}
          />
        ) : null}
        {!loading && tabIndex === 1 ? (
          <Table
            loading={loading}
            columnDefs={columnDefs}
            searchValue={searchTerm}
            data={dashboardData?.socialSpend || []}
            searchKeys={["targetName"]}
          />
        ) : null}
        {!loading && tabIndex === 2 ? (
          <Table
            loading={loading}
            columnDefs={columnDefs}
            searchValue={searchTerm}
            data={dashboardData?.localContent || []}
            searchKeys={["targetName"]}
          />
        ) : null}
      </Grid>
    </PageContainer>
  );
};
