import { Box, Stack, Typography, styled } from "@mui/material";

const CardContainer = styled(Box)({
  backgroundColor: "white",
  border: "1px solid #E9EAEB",
  borderRadius: "12px",
  boxShadow: "0px 1px 2px 0px #0A0D120D",
  height: "100%",
});

const CardHeader = styled(Box)({
  borderBottom: "1px solid #E9EAEB",
  padding: "24px",
});

const CardFooter = styled(Box)({
  padding: "24px",
});

const ValueContainer = styled(Stack)({
  border: "1px solid #D5D7DA",
  borderRadius: "6px",
  boxShadow: "0px 1px 2px 0px #0A0D120D",
  padding: "2px 8px",
});

const TitleText = styled(Typography)({
  fontFamily: "'Inter'",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "28px",
});

const SubtitleText = styled(Typography)({
  fontFamily: "'Inter'",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
});

const ProgressText = styled(Typography)({
  fontFamily: "'Inter'",
  fontSize: "40px",
  fontWeight: 600,
  lineHeight: "60px",
});

const PercentageText = styled(Typography)({
  fontFamily: "'Inter'",
  fontSize: "30px",
  fontWeight: 600,
  lineHeight: "44px",
});

const StatusText = styled(Typography)({
  fontFamily: "'Inter'",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
});

const ValueLabelText = styled(Typography)({
  color: "#535862",
  fontFamily: "'Inter'",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
});

const ValueText = styled(Typography)({
  color: "#181D27",
  fontFamily: "'Inter'",
  fontSize: "30px",
  fontWeight: 600,
  lineHeight: "38px",
});

const ChangeText = styled(Typography)({
  fontFamily: "'Inter'",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  margin: 0,
  paddingLeft: "5px",
});

export {
  CardContainer,
  CardFooter,
  CardHeader,
  ChangeText,
  PercentageText,
  ProgressText,
  StatusText,
  SubtitleText,
  TitleText,
  ValueContainer,
  ValueLabelText,
  ValueText,
};
