import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";

import { Header } from "../Header/Header";

interface ReportConfirmationProps {
  loading: boolean;
  project?: ProjectLineItem;
  reportId?: string;
}

export const ReportConfirmation = ({
  loading,
  project,
  reportId,
}: ReportConfirmationProps) => {
  return (
    <>
      <Grid
        container
        sx={{
          width: "100%",
        }}
      >
        <Grid item xs={12} md={12}>
          <Stack>
            {loading ? (
              <>
                <Skeleton animation="wave">
                  <Typography variant="h2">{"Report Confirmation"}</Typography>
                </Skeleton>
                <Skeleton animation="wave">
                  <Typography variant="subtitle1">
                    {"September 2000"}
                  </Typography>
                </Skeleton>
              </>
            ) : (
              <Header
                loading={loading}
                mainTitle={"Report Confirmation"}
                subTitle={`${project?.projectName}: ${
                  reportId ? formatReportDate(stringToDate(reportId)) : ""
                }`}
                headerFontSizes={[18, 14]}
                headerGap={0}
              />
            )}
          </Stack>
        </Grid>
        <Grid item md={12} sx={{ padding: "20px 0" }}>
          {loading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave">
                <Typography variant="body1" component="div">
                  I confirm that the information provided in this report is
                  accurate to the best of my knowledge and that it is subject to
                  review and verification (the reporting entity may request
                  additional evidence or supporting documentation to
                  substantiate the reported information). I agree that I am in
                  possession of appropriate supporting documentation for all
                  information reported, and to promptly provide such
                  documentation to support the accuracy and integrity of the
                  reported data.
                </Typography>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography variant="body1" component="div">
                  By submitting this report, I affirm my commitment to
                  transparent and accurate reporting in compliance with
                  applicable standards and regulations.
                </Typography>
              </Skeleton>
            </Stack>
          ) : (
            <Stack spacing={3}>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  color: "#181D27",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
              >
                I confirm that the information provided in this report is
                accurate to the best of my knowledge and that it is subject to
                review and verification (the reporting entity may request
                additional evidence or supporting documentation to substantiate
                the reported information). I agree that I am in possession of
                appropriate supporting documentation for all information
                reported, and to promptly provide such documentation to support
                the accuracy and integrity of the reported data.
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  color: "#181D27",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
              >
                By submitting this report, I affirm my commitment to transparent
                and accurate reporting in compliance with applicable standards
                and regulations.
              </Typography>
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
};
