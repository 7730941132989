import { Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  AvatarNameCellRenderer,
  CellAction,
  centerCellStyles,
  defaultCellConfig,
  ProgressCell,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import { useState } from "react";
import {
  SocialSpendAnalyticsForPackageModelLineItem,
  SocialSpendTargetAnalyticsForPackageLineItem,
} from "social-pro-common/interfaces/analytics";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { AnalyticsSpendLevel2TableRowSkeleton } from "./AnalyticsSpendLevel2TableRowSkeleton";

interface AnalyticsSpendTableLevel2TableProps {
  loading: boolean;
  targetId: string;
  searchTerm: string;
  socialSpendAnalytics?: SocialSpendAnalyticsForPackageModelLineItem;
  handleAction: (contractorPackage: ContractorPackageLineItem) => void;
}

export const AnalyticsSpendTableLevel2Table = ({
  handleAction,
  loading,
  searchTerm,
  socialSpendAnalytics,
}: AnalyticsSpendTableLevel2TableProps) => {
  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      icon: <ViewProjectIcon />,
      singleAction: true,
      title: "View",
    },
  ] as CellAction[];
  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      cellRenderer: AvatarNameCellRenderer as any,
      field: "contractorName",
      flex: 3,
      headerName: "Subcontractor",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "targetValue",
      headerClass: "right-table-header",
      headerName: "Target ($)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "totalReportedValue",
      headerClass: "right-table-header",
      headerName: "Spend to Date ($)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "monthReportedValue",
      headerClass: "right-table-header",
      headerName: "This Month",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: ProgressCell,
      field: "progress",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Progress To Target",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      cellStyle: centerCellStyles,
      headerClass: "centered-table-header",
      headerName: "",
      onCellClicked: (params) => handleAction(params.data),
    },
  ]);

  return (
    <Grid item md={12}>
      {loading || !socialSpendAnalytics?.targets ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={AnalyticsSpendLevel2TableRowSkeleton}
          noFullTableBorder
        />
      ) : (
        <Table<SocialSpendTargetAnalyticsForPackageLineItem>
          columnDefs={colDefs}
          loading={loading}
          searchValue={searchTerm}
          context={{ cellActions }}
          noFullTableBorder
          data={socialSpendAnalytics?.targets || []}
          searchKeys={["contractorName"]}
        />
      )}
    </Grid>
  );
};
