import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProject } from "@hooks/crud/project/useProject";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import ProjectEditModal from "@stories/organisms/ProjectEditModal/ProjectEditModal";
import { ProjectTable } from "@stories/organisms/Tables/ProjectTable";
import { userIsAdmin } from "@utils/projectAccess";
import * as RouteHelper from "@utils/routes";
import { AddIcon, UserIcon } from "assets/constants";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";

export const ListProjects = (): JSX.Element => {
  const navigate = useNavigate();

  const { isContractor } = useAuthContext();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();

  if (!userProfile) {
    throw new Error("No user profile to list projects");
  }

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleGoToTeams = () => {
    navigate({
      pathname: RouteHelper.users,
      search: createSearchParams({
        tab: "permissions",
      }).toString(),
    });
  };

  const {
    acceptPackage,
    createProject,
    isProjectLoading,
    projects,
    updateProject,
    updateProjectDetail,
  } = useProject(userProfile, undefined, !!tabIndex);

  const handleViewProject = (project: ProjectDetailLineItem) => {
    if (project.packageType === PackageType.OneTime) {
      navigate(
        RouteHelper.wizardOneTime(project.id, project.contractorPackageId),
      );
    } else {
      navigate(
        RouteHelper.contractorDashboard(
          project.id,
          project.contractorPackageId,
        ),
      );
    }
  };

  const handleArchiveProject = (project: ProjectDetailLineItem) => {
    updateProjectDetail({ ...project, archived: !project.archived });
  };

  const handleEditProject = async (project: ProjectDetailLineItem) => {
    setSelectedProjectId(project.id);
    setOpenEditModal(true);
  };

  const handleAcceptPackage = async (
    projectId: string,
    contractorPackageId: string,
  ) => {
    await acceptPackage(projectId, contractorPackageId);
  };

  const handleOpeEditModal = () => {
    setSelectedProjectId(undefined);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const filteredProjects = projects.sort((a, b) => {
    return a.created.getTime() - b.created.getTime();
  });

  const loading = isAuthProfileLoading || isProjectLoading;

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const buttons = [] as HeaderButtonConfig[];

  if (userIsAdmin(userProfile)) {
    buttons.push({
      action: handleGoToTeams,
      icon: <UserIcon />,
      text: "Manage Permissions",
      type: HeaderButtonType.SECONDARY,
      width: 190,
    } as HeaderButtonConfig);
  }

  if (isContractor && userIsAdmin(userProfile)) {
    buttons.push({
      action: handleOpeEditModal,
      icon: <AddIcon variant="secondary" />,
      text: "Add Project",
      type: HeaderButtonType.PRIMARY,
      width: 140,
    } as HeaderButtonConfig);
  }

  const tabs = [
    {
      label: "Active",
    },
    {
      label: "Archived",
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={userOrganisation?.organisationName || "SocialPro Projects"}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        mainTitle={"Projects"}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />

      <ProjectTable
        projects={filteredProjects}
        loading={loading}
        archived={!!tabIndex}
        isContractor={isContractor}
        userProfile={userProfile}
        handleViewProject={handleViewProject}
        handleEditProject={handleEditProject}
        handleAcceptPackage={handleAcceptPackage}
        handleArchiveProject={handleArchiveProject}
        handleOpeEditModal={handleOpeEditModal}
      />
      {openEditModal && userOrganisation ? (
        <ProjectEditModal
          open={openEditModal}
          userProfile={userProfile}
          projectId={selectedProjectId}
          userOrganisation={userOrganisation}
          handleClose={handleCloseEditModal}
          isLoading={loading}
          createProject={createProject}
          updateProject={updateProject}
        />
      ) : null}
    </PageContainer>
  );
};
