"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeOrganisation = exports.decodeOrganisation = void 0;
const contactInfo_1 = require("../../interfaces/contactInfo");
const string_1 = require("../../utils/string");
const contactInfo_2 = require("../contactInfo");
const integration_1 = require("../integration");
const decodeOrganisation = async (organisation, getImageUrl) => {
    const presignedUrl = organisation.organisation_logo && organisation.organisation_logo.length > 0
        ? await getImageUrl(organisation.organisation_logo)
        : "";
    return {
        abn: organisation.abn,
        alertNotifications: organisation.alert_notifications,
        contactInfo: organisation.contact_info
            ? (0, contactInfo_2.decodeContactInfo)(organisation.contact_info)
            : (0, contactInfo_1.createDefaultContactInfo)(),
        created: new Date(organisation.created),
        id: organisation.organisation_id,
        integrations: organisation?.integrations?.map((i) => (0, integration_1.decodeIntegration)(i)) || [],
        isContractor: organisation.is_contractor,
        isSetup: organisation.is_setup,
        maxActiveProjects: organisation.max_active_projects,
        organisationLogo: presignedUrl,
        organisationLogoSrc: organisation.organisation_logo,
        organisationName: organisation.organisation_name,
        planType: organisation.plan_type,
        representativeEmail: organisation.representative_email,
        representativeName: organisation.representative_name,
        stripeId: organisation.stripe_id,
        tradingName: organisation.trading_name,
        transient: organisation.transient,
        users: organisation.users,
    };
};
exports.decodeOrganisation = decodeOrganisation;
const encodeOrganisation = (organisation) => {
    const safeOrganisation = (0, string_1.makePostgresSafe)(organisation);
    return {
        abn: safeOrganisation.abn,
        alert_notifications: safeOrganisation.alertNotifications,
        contact_info: (0, contactInfo_2.encodeContactInfo)(safeOrganisation.contactInfo),
        contact_info_id: safeOrganisation.contactInfo.id,
        created: safeOrganisation.created,
        integrations: organisation?.integrations?.map((i) => (0, integration_1.encodeIntegration)(i)) || [],
        is_contractor: safeOrganisation.isContractor,
        is_setup: safeOrganisation.isSetup,
        max_active_projects: safeOrganisation.maxActiveProjects,
        organisation_id: safeOrganisation.id,
        organisation_logo: safeOrganisation.organisationLogoSrc,
        organisation_name: safeOrganisation.organisationName,
        plan_type: safeOrganisation.planType,
        representative_email: safeOrganisation.representativeEmail,
        representative_name: safeOrganisation.representativeName,
        stripe_id: safeOrganisation.stripeId,
        trading_name: safeOrganisation.tradingName,
        transient: safeOrganisation.transient,
        users: safeOrganisation.users,
    };
};
exports.encodeOrganisation = encodeOrganisation;
