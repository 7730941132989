import { Typography } from "@mui/material";

import {
  CustomTooltip,
  ProgressBarContainer,
  ProgressFill,
} from "./components";

interface Props {
  colors: string[];
  figure1: string;
  figure2?: string;
  dataIdPrefix?: string;
}

const ProgressBar = ({ colors, dataIdPrefix, figure1, figure2 }: Props) => {
  return (
    <CustomTooltip title={<Typography>{figure1}</Typography>} placement="top">
      <ProgressBarContainer>
        {colors.length === 1 ? (
          // Single-color Progress Bar
          <ProgressFill
            color={colors[0]}
            figure={figure1}
            data-test-id={`${dataIdPrefix}-progress-bar-1`}
          />
        ) : (
          // Multi-color Progress Bar
          <>
            {/* Base Layer */}
            <ProgressFill
              data-test-id={`${dataIdPrefix}-progress-bar-1`}
              color={colors[0]}
              figure={"100%"}
              zIndex={1}
            />
            {/* Middle Layer */}
            <ProgressFill
              data-test-id={`${dataIdPrefix}-progress-bar-2`}
              color={colors[1]}
              figure={figure2 || "0%"}
              zIndex={2}
            />
            {/* Top Layer */}
            <ProgressFill
              data-test-id={`${dataIdPrefix}-progress-bar-3`}
              color={colors[2]}
              figure={figure1}
              zIndex={3}
            />
          </>
        )}
      </ProgressBarContainer>
    </CustomTooltip>
  );
};

export default ProgressBar;
