import { useOrganisationByABN } from "@hooks/crud/organisation/useOrganisationByABN";
import { uploadImage } from "@hooks/utils/useUpload";
import UploadTwoToneIcon from "@mui/icons-material/UploadTwoTone";
import {
  Grid,
  FormControl,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Zoom,
  FormControlLabel,
  useTheme,
  FormGroup,
  Switch,
  Autocomplete,
  TextField,
  Skeleton,
  Box,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { validatePostCode } from "@utils/location";
import { catchSentryError } from "@utils/sentry";
import { Country, ICountry, State } from "country-state-city";
import { useFormik } from "formik";
import { SearchIcon } from "lucide-react";
import { phone } from "phone";
import { useRef, useState } from "react";
import { ToastOptions, toast } from "react-toastify";
import { ContactInfoLineItem } from "social-pro-common/interfaces/contactInfo";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { validateABN } from "social-pro-common/utils/abn";
import { cleanString } from "social-pro-common/utils/string";
import * as yup from "yup";

interface CompanyFormProps {
  loading: boolean;
  organisation: OrganisationLineItem;
  tempLogoUrl: string;
  setTempLogoUrl: (url: string) => void;
  handleNext: (organisation: OrganisationLineItem) => void;
}

export const CompanyForm = ({
  handleNext,
  loading,
  organisation,
  setTempLogoUrl,
  tempLogoUrl,
}: CompanyFormProps) => {
  const theme = useTheme();
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { isOrganisationLoading, searchOrganisationExistsByABN } =
    useOrganisationByABN();

  const checkAbnValidity = async (abn: string) => {
    try {
      const cleanAbn = cleanString(abn);
      if (organisation.abn === cleanAbn) {
        return true;
      }
      const org = await searchOrganisationExistsByABN(cleanAbn);
      if (!org || !org.isSetup) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const validationSchema = yup.object({
    abn: yup
      .string()
      .required("ABN is required")
      .test(
        "api-validate",
        "Not a valid ABN - i.e. 43 665 205 185",
        async (value) => {
          try {
            if (validateABN(value)) {
              return true;
            }
          } catch (error) {
            return false;
          }
          return false;
        },
      )
      .test("validate-abn", "ABN already exists", async function (value) {
        if (!value || !validateABN(value)) return false;
        return await checkAbnValidity(value);
      }),
    addressLine1: yup.string().required("Address Line 1 is required"),
    addressLine2: yup.string(),
    alertNotifications: yup.boolean(),
    city: yup.string().required("City is required"),
    country: yup.string().required("Country is required"),
    organisationLogoSrc: yup.string().required("Organisation logo is required"),
    organisationName: yup.string().required("Organisation name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .test("Valid Phone", "Phone not valid for country", function (item) {
        const res = phone(item, {
          country: this.parent.country,
          validateMobilePrefix: false,
        });
        return res.isValid;
      }),
    postCode: yup
      .string()
      .required("Postcode is required")
      .test(
        "Valid for country",
        "Not a valid postcode for country",
        function (item) {
          return validatePostCode(this.parent.country, item);
        },
      ),
    representativeEmail: yup
      .string()
      .email()
      .required("Representative email is required"),
    representativeName: yup
      .string()
      .required("Representative name is required"),
    state: yup.string().required("State is required"),
    tradingName: yup.string().required("Trading name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      abn: organisation.abn,
      addressLine1: organisation.contactInfo.addressLine1,
      addressLine2: organisation.contactInfo.addressLine2,
      alertNotifications: organisation.alertNotifications,
      city: organisation.contactInfo.city,
      country: organisation.contactInfo.country,
      id: organisation.id,
      organisationLogoSrc: organisation.organisationLogoSrc,
      organisationName: organisation.organisationName,
      phoneNumber: organisation.contactInfo.phoneNumber,
      postCode: organisation.contactInfo.postCode,
      representativeEmail: organisation.representativeEmail,
      representativeName: organisation.representativeName,
      state: organisation.contactInfo.state,
      tradingName: organisation.tradingName,
    },
    onSubmit: (values) => {
      handleNext({
        ...organisation,
        abn: values.abn,
        alertNotifications: values.alertNotifications,
        contactInfo: {
          ...organisation.contactInfo,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          country: values.country,
          phoneNumber: values.phoneNumber,
          postCode: values.postCode,
          state: values.state,
        } as ContactInfoLineItem,
        organisationLogo: values.organisationLogoSrc,
        organisationLogoSrc: values.organisationLogoSrc,
        organisationName: values.organisationName,
        representativeEmail: values.representativeEmail,
        representativeName: values.representativeName,
        tradingName: values.tradingName,
      });
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchema,
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const file = e.currentTarget?.files[0] || null;

      if (file) {
        if (file.size < 2000000) {
          setIsUploading(true);

          const fileKey = `logos/${organisation.id}/${file.name}`;
          await uploadImage(
            fileKey,
            file,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
              if (total === 100) {
                toast("Upload complete!", {
                  type: "success",
                } as ToastOptions);
              }
            },
            (error: any) => {
              setUploadProgress(0);
              catchSentryError(error);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setIsUploading(false);
            },
          );

          formik.setFieldValue("organisationLogoSrc", fileKey);

          const urlToImage = URL.createObjectURL(file);
          formik.setFieldValue("organisationLogo", urlToImage);
          setTempLogoUrl(urlToImage);
          setIsUploading(false);
        } else {
          alert("File too large. 2Mb max");
        }
      }
    }
  };

  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);

  const isDisabled = isOrganisationLoading;
  return (
    <Box sx={{ maxWidth: "900px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="organisationName"
              name="organisationName"
              label="Organisation Name"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organisationName}
              error={
                formik.touched.organisationName &&
                Boolean(formik.errors.organisationName)
              }
              helperText={
                formik.touched.organisationName
                  ? formik.errors.organisationName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="tradingName"
              name="tradingName"
              label="Trading Name"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tradingName}
              error={
                formik.touched.tradingName && Boolean(formik.errors.tradingName)
              }
              helperText={
                formik.touched.tradingName ? formik.errors.tradingName : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="abn"
              name="abn"
              label="ABN"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.abn}
              error={formik.touched.abn && Boolean(formik.errors.abn)}
              helperText={formik.touched.abn ? formik.errors.abn : ""}
              InputProps={{
                endAdornment: isOrganisationLoading ? (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: isOrganisationLoading
                        ? "rgba(0, 0, 0, 0.26)"
                        : undefined,
                    }}
                  >
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : undefined,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: isOrganisationLoading
                        ? "rgba(0, 0, 0, 0.26)"
                        : undefined,
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="representativeName"
              name="representativeName"
              label="Representative Name"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.representativeName}
              error={
                formik.touched.representativeName &&
                Boolean(formik.errors.representativeName)
              }
              helperText={
                formik.touched.representativeName
                  ? formik.errors.representativeName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="representativeEmail"
              name="representativeEmail"
              label="Representative Email"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.representativeEmail}
              error={
                formik.touched.representativeEmail &&
                Boolean(formik.errors.representativeEmail)
              }
              helperText={
                formik.touched.representativeEmail
                  ? formik.errors.representativeEmail
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="addressLine1"
              name="addressLine1"
              label="Address Line 1"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine1}
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
              helperText={
                formik.touched.addressLine1 ? formik.errors.addressLine1 : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="addressLine2"
              name="addressLine2"
              label="Address Line 2"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressLine2}
              error={
                formik.touched.addressLine2 &&
                Boolean(formik.errors.addressLine2)
              }
              helperText={
                formik.touched.addressLine2 ? formik.errors.addressLine2 : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="country"
                  disabled={isDisabled}
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  value={Country.getCountryByCode(formik.values.country)}
                  onChange={(e: any, value: ICountry | null) => {
                    formik.setFieldValue("country", value?.isoCode);
                  }}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="country"
                      {...params}
                      name="country"
                      label="Country"
                      fullWidth
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country ? formik.errors.country : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  id="state"
                  disabled={isDisabled}
                  options={states}
                  getOptionLabel={(option) => option.name}
                  value={states.find((s) => s.isoCode === formik.values.state)}
                  onChange={(event) => {
                    const state = states.find(
                      (s) => s.name === event.currentTarget.textContent,
                    );
                    formik.setFieldValue("state", state?.isoCode || "");
                    // formik.handleChange(event);
                  }}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "8px",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="state"
                      {...params}
                      name="state"
                      label="State"
                      fullWidth
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={
                        formik.touched.state ? formik.errors.state : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="city"
              name="city"
              fullWidth
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city ? formik.errors.city : ""}
              label="City"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="postCode"
              name="postCode"
              label="Postcode"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postCode}
              error={formik.touched.postCode && Boolean(formik.errors.postCode)}
              helperText={formik.touched.postCode ? formik.errors.postCode : ""}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <StyledTextField
              loading={loading}
              disabled={isDisabled}
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
              }
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled={loading || isDisabled || formik.isSubmitting}
                    checked={formik.values.alertNotifications}
                    onChange={(event, checked) => {
                      formik.setFieldValue("alertNotifications", checked);
                    }}
                  />
                }
                label={
                  loading ? (
                    <Skeleton animation="wave">
                      <Typography />
                    </Skeleton>
                  ) : (
                    "Email Notifications"
                  )
                }
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              alignItems: "center",
              display: "flex !important",
              gap: "15px",
              justifyContent: "left !important",
              padding: "30px 10px 0",
            }}
          >
            {isUploading ? (
              <CircularProgressWithLabel value={uploadProgress} />
            ) : (
              <Grid item xs={12} md={12} sx={{ display: "flex", gap: "20px" }}>
                <Grid item sx={{}}>
                  <Avatar
                    alt="User 3"
                    src={tempLogoUrl}
                    sx={{ backgroundColor: "#ffffff", height: 64, width: 64 }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Grid item xs={12}>
                    <input
                      ref={fileInput}
                      hidden
                      accept="image/*"
                      type="file"
                      style={{
                        cursor: "pointer",
                        height: "100px",
                        opacity: 0,
                        padding: 0.5,
                        position: "fixed",
                        width: "100px",
                        zIndex: 1,
                      }}
                      onChange={onUpload}
                    />
                    <FormControlLabel
                      sx={{
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "10px",
                        margin: "auto",
                      }}
                      control={
                        <Tooltip
                          TransitionComponent={Zoom}
                          placement="top"
                          title="Upload"
                        >
                          <IconButton
                            color="error"
                            sx={{
                              "&:hover ": {
                                background: theme.palette.primary.dark,
                                color: theme.palette.primary.light,
                              },
                              background: theme.palette.primary.light,
                              borderColor: "black",
                              color: theme.palette.primary.dark,
                            }}
                            disabled={isUploading || isDisabled}
                            size="large"
                            onClick={onFileInput}
                          >
                            <UploadTwoToneIcon
                              sx={{
                                fontSize: "24px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      }
                      label={"Upload Company Logo"}
                    />
                  </Grid>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "11px", marginTop: "-5px" }}
                  >
                    *Image size should not exceed 2Mb Max.
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                // padding: "0 10px 0 10px",
                alignItems: "center",
                display: "flex !important",
                justifyContent: "right !important;",
              }}
            >
              <StyledButton
                loading={loading}
                disabled={isDisabled}
                variant="contained"
                type="submit"
              >
                Save
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
