import { Box, Grid, Checkbox, FormControlLabel, Typography, TextField, FormControl, Select, MenuItem, Button } from "@mui/material";
import { useState } from "react";
import './style.css';
export const SmartDirectoryForm = ( { handleClose } : any) => {

  const [serviceLocations, setServiceLocations] = useState<string[]>([]);
  const [commitmentCategory, setCommitmentCategory] = useState<string>("");
  const [eba, setEba] = useState<string>("N/A");

  const handleLocationChange = (location: string) => {
    setServiceLocations((prev) =>
      prev.includes(location)
        ? prev.filter((l) => l !== location)
        : [...prev, location],
    );
  };

  const handleSubmit = () => {
    // Form submission logic
    console.log({ commitmentCategory, eba, serviceLocations });
  };

  // const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file)); // Generate a temporary URL for the image
    }
  };

  

  return (
    <Box className='smartdirectoryform'>
          <Grid container spacing={2}>
            {/* Supplier Name */}
            <Grid item xs={6}>
              <Typography
                gutterBottom
                sx={{
                  color: "#181D27",
                  fontFamily: "Inter !important",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                }}
              >
                Name
              </Typography>
              <TextField
                label="Supplier Name"
                placeholder="Supplier Name"
                fullWidth
                required
                sx={{
                  "&:hover": {
                    borderColor: "#155eef",
                  },
                  "&:placeholder": {
                    color: "grey !important",
                  },
                  border: "1px solid #D5D7DA",
                  borderRadius: "8px",
                  color: "#717680 !important",
                  fontFamily: "Inter !important",
                  fontSize: "14px ",
                  fontWeight: "400 !important",
                  lineHeight: "20px !important",
                  padding: "10px 14px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                gutterBottom
                sx={{
                  color: "#181D27",
                  fontFamily: "Inter !important",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                }}
              >
                Products & Services
              </Typography>
              <FormControl fullWidth required>
                <Select
                  value={""}
                  sx={{
                    "&:hover": {
                      borderColor: "#155eef",
                    },
                    "&:placeholder": {
                      color: "grey !important",
                    },
                    border: "1px solid #D5D7DA",
                    borderRadius: "8px",
                    color: "#717680 !important",
                    fontFamily: "Inter !important",
                    fontSize: "14px ",
                    fontWeight: "400 !important",
                    lineHeight: "20px !important",
                    padding: "10px 14px",
                  }}
                >
                  <MenuItem value="Option 1">Option 1</MenuItem>
                  <MenuItem value="Option 2">Option 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Description */}
          <Grid item xs={12} sx={{ marginTop: "12px" }}>
            <Typography
              gutterBottom
              sx={{
                color: "#181D27",
                fontFamily: "Inter !important",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              Description
            </Typography>
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              sx={{
                "&:hover": {
                  borderColor: "#155eef",
                },
                "&:placeholder": {
                  color: "grey !important",
                },
                border: "1px solid #D5D7DA",
                borderRadius: "8px",
                color: "#717680 !important",
                fontFamily: "Inter !important",
                fontSize: "14px ",
                fontWeight: "400 !important",
                lineHeight: "20px !important",
                padding: "10px 14px",
              }}
            />
          </Grid>

          {/* Service Location */}
          <Grid
            item
            xs={12}
            sx={{ margin: "12px 0 0 !important", padding: "0 10px !mportant" }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "#181D27",
                fontFamily: "Inter !important",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              Service Location
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                margin: "2px 0 0!important",
              }}
            >
              {[
                "Victoria",
                "Queensland",
                "Australian Capital Territory",
                "South Australia",
                "New South Wales",
                "Tasmania",
                "Northern Territory",
                "Western Australia",
              ].map((location) => (
                <Grid item xs={6} key={location}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#717680",
                          fontSize: "14px",
                          padding: "0 5px 0 0",
                        }}
                        checked={serviceLocations.includes(location)}
                        onChange={() => handleLocationChange(location)}
                      />
                    }
                    label={location}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "12px" }}>
            {/* Supplier Name */}
            <Grid item xs={6}>
              <Typography
                gutterBottom
                sx={{
                  color: "#181D27",
                  fontFamily: "Inter !important",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                }}
              >
                Commitment Category
              </Typography>
              <FormControl fullWidth required>
                <Select
                  value={commitmentCategory}
                  onChange={(e) => setCommitmentCategory(e.target.value)}
                  sx={{
                    "&:hover": {
                      borderColor: "#155eef",
                    },
                    "&:placeholder": {
                      color: "grey !important",
                    },
                    border: "1px solid #D5D7DA",
                    borderRadius: "8px",
                    color: "#717680 !important",
                    fontFamily: "Inter !important",
                    fontSize: "14px ",
                    fontWeight: "400 !important",
                    lineHeight: "20px !important",
                    padding: "10px 14px",
                  }}
                >
                  <MenuItem value="Veterans">Veterans</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography
                gutterBottom
                sx={{
                  color: "#181D27",
                  fontFamily: "Inter !important",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                }}
              >
                EBA
              </Typography>
              <FormControl fullWidth required>
                <Select
                  value={eba}
                  onChange={(e) => setEba(e.target.value)}
                  sx={{
                    "&:hover": {
                      borderColor: "#155eef",
                    },
                    "&:placeholder": {
                      color: "grey !important",
                    },
                    border: "1px solid #D5D7DA",
                    borderRadius: "8px",
                    color: "#717680 !important",
                    fontFamily: "Inter !important",
                    fontSize: "14px ",
                    fontWeight: "400 !important",
                    lineHeight: "20px !important",
                    padding: "10px 14px",
                  }}
                >
                  <MenuItem value="N/A">N/A</MenuItem>
                  <MenuItem value="Option 1">Option 1</MenuItem>
                  <MenuItem value="Option 2">Option 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Website */}
          <Grid item xs={12} sx={{ marginTop: "12px" }}>
            <Typography
              gutterBottom
              sx={{
                color: "#181D27",
                fontFamily: "Inter !important",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              Website
            </Typography>
            <TextField
              label="Website"
              fullWidth
              required
              placeholder="www.website.com"
              variant="outlined"
              sx={{
                "&:hover": {
                  borderColor: "#155eef",
                },
                "&:placeholder": {
                  color: "grey !important",
                },
                border: "1px solid #D5D7DA",
                borderRadius: "8px",
                color: "#717680 !important",
                fontFamily: "Inter !important",
                fontSize: "14px ",
                fontWeight: "400 !important",
                lineHeight: "20px !important",
                padding: "10px 14px",
              }}
            />
          </Grid>

          {/* Actions */}
          <Grid
            container
            sx={{ alignItems: "center", display: "flex", paddingTop: "10px" }}
          >
            <Grid item xs={8}>
              <TextField
                type="file"
                inputProps={{ accept: "image/*" }}
                onChange={handleImageChange}
              />

              {/* // img upload */}
              {imagePreview ? (
                <Box
                  sx={{
                    alignItems: "center",
                    border: "1px solid #D5D7DA",
                    borderRadius: "50%",
                    display: "flex",
                    height: 50,
                    justifyContent: "center",
                    mt: "10px",
                    overflow: "hidden",
                    padding: "5px",
                    width: 50,
                  }}
                >
                  <Box
                    component="img"
                    src={imagePreview}
                    alt="Uploaded Preview"
                    sx={{
                      height: "100%",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    color: "#414651",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  Upload Company Logo.
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                sx={{ borderRadius: "5px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ borderRadius: "5px" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
   
    </Box>
  );
};
