import { Grid, Stack, Tooltip, Typography, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import { defaultCellConfig } from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useEffect, useState } from "react";
import { ContractorPackageSetupLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PackageLabourHourCommitmentLineItem } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { PackageSocialSpendCommitmentLineItem } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { FormikField } from "social-pro-common/interfaces/project";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import * as yup from "yup";

import { PackageSocialRequirementTableRowSkeleton } from "./PackageSocialRequirementTableRowSkeleton";

type AllowedCommitmentTypes =
  | PackageSocialSpendCommitmentLineItem
  | PackageLabourHourCommitmentLineItem;

interface PackageSocialRequirementFormProps<T extends AllowedCommitmentTypes> {
  loading: boolean;
  commitmentLineItems: T[];
  isFinalStep: boolean;
  contractorPackageSetup: ContractorPackageSetupLineItem;
  handleNext: (contractorPackage: ContractorPackageSetupLineItem) => void;
  handleBack: () => void;
  title: string;
  calculateTargetValueFromTargetValueRealised: (
    value: number,
    multiplier: number,
  ) => number;
  calculateTargetValueRealised: (value: number, multiplier: number) => number;
  getOutcomeMultiplier: (item: T) => number;
  getDescription: (item: T) => string;
  getLabel: (item: T) => string;
  commitmentKey: "commitmentsHours" | "commitmentsSpend";
}

const cellStyles = {
  alignItems: "center",
  backgroundColor: "#f0f8ff",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
};

export const PackageSocialRequirementForm = <T extends AllowedCommitmentTypes>({
  calculateTargetValueFromTargetValueRealised,
  calculateTargetValueRealised,
  commitmentKey,
  commitmentLineItems,
  contractorPackageSetup,
  getDescription,
  getLabel,
  getOutcomeMultiplier,
  handleBack,
  handleNext,
  isFinalStep,
  loading,
  title,
}: PackageSocialRequirementFormProps<T>) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fields, setFields] = useState<FormikField[]>([]);

  useEffect(() => {
    const fieldsFromCommitments: FormikField[] = commitmentLineItems.map(
      (c) => ({
        description: getDescription(c),
        id: c.id,
        initialValue: c.targetValue,
        label: getLabel(c),
        outcomeMultiplier: getOutcomeMultiplier(c),
        targetValue: c.targetValue,
        targetValueRealised: c.targetValueRealised,
        title: getLabel(c),
        type: yup.number().required().min(0),
      }),
    );

    setFields(fieldsFromCommitments);
  }, [commitmentLineItems]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const updatedCommitments = fields.map((field) => {
      const matchingItem = commitmentLineItems.find(
        (c: any) => c.id === field.id,
      );
      if (!matchingItem) {
        throw new Error(`Field not found for ID: ${field.id}`);
      }
      return {
        ...matchingItem,
        targetValue: field.targetValue,
        targetValueRealised: field.targetValueRealised,
      };
    });

    const updatedContractorPackage = {
      ...contractorPackageSetup,
      contractorPackage: {
        ...contractorPackageSetup.contractorPackage,
        [commitmentKey]: updatedCommitments,
      },
    };

    handleNext(updatedContractorPackage);
    setIsSubmitting(false);
  };

  const getValueFormatter = (
    field: "targetValue" | "targetValueRealised",
    value: number,
    unit: string,
  ): string => {
    return field === "targetValue"
      ? `${formatDecimalPlaces(value, 2)}%`
      : unit === "$"
        ? ` ${unit}${formatDecimalPlaces(value, 2)}`
        : `${formatDecimalPlaces(value, 2)} ${unit}`;
  };

  const getUnit = (): string => {
    return commitmentKey === "commitmentsSpend" ? "$" : "hours";
  };

  const valueSetter = (
    params: any,
    field: "targetValue" | "targetValueRealised",
  ) => {
    const newValue = Number(params.newValue);
    if (isNaN(newValue)) return false;

    if (field === "targetValue") {
      params.data.targetValue = newValue;
      params.data.targetValueRealised = calculateTargetValueRealised(
        newValue,
        params.data.outcomeMultiplier,
      );
    } else {
      params.data.targetValueRealised = newValue;
      params.data.targetValue = calculateTargetValueFromTargetValueRealised(
        newValue,
        params.data.outcomeMultiplier,
      );
    }

    return true;
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      cellRenderer: (params: any) => (
        <Tooltip title={params.data.description} TransitionComponent={Zoom}>
          <Typography>{params.data.label}</Typography>
        </Tooltip>
      ),
      field: "label",
      flex: 4,
      headerClass: "left-table-header",
      headerName: "Commitment",
    },
    {
      ...defaultCellConfig,
      cellStyle: cellStyles,
      editable: true,
      field: "targetValue",
      headerClass: "centered-table-header",
      headerName: "Target %",
      valueFormatter: (params) =>
        getValueFormatter("targetValue", params.data.targetValue, "%"),
      valueSetter: (params) => valueSetter(params, "targetValue"),
    },
    {
      ...defaultCellConfig,
      cellStyle: cellStyles,
      editable: true,
      field: "targetValueRealised",
      headerClass: "centered-table-header",
      headerName: `Target Outcome`,
      valueFormatter: (params) =>
        getValueFormatter(
          "targetValueRealised",
          params.data.targetValueRealised,
          getUnit(),
        ),
      valueSetter: (params) => valueSetter(params, "targetValueRealised"),
    },
  ]);

  const onCellValueChanged = (params: any) => {
    const { data, field, newValue } = params;

    if (!field || isNaN(newValue)) return;

    setFields((prev) =>
      prev.map((f) =>
        f.id === data.id
          ? {
              ...f,
              [field]: newValue,
              targetValue:
                field === "targetValueRealised"
                  ? calculateTargetValueFromTargetValueRealised(
                      newValue,
                      f.outcomeMultiplier,
                    )
                  : f.targetValue,
              targetValueRealised:
                field === "targetValue"
                  ? calculateTargetValueRealised(newValue, f.outcomeMultiplier)
                  : f.targetValueRealised,
            }
          : f,
      ),
    );
  };

  return (
    <Box sx={{ padding: "25px 40px 0px !important" }}>
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} md={12}>
          <Grid item xs={12} md={12}>
            <SubTitle title={`${title}`} />
          </Grid>
          {loading ? (
            <TableSkeleton
              tableHead={generateTableHead(colDefs)}
              rows={PackageSocialRequirementTableRowSkeleton}
            />
          ) : (
            <Table<T>
              columnDefs={colDefs}
              loading={loading}
              data={fields as unknown as T[]}
              onCellValueChanged={onCellValueChanged}
            />
          )}

          <Grid
            item
            md={12}
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "end",
              marginTop: "16px",
              padding: "0px 0px 40px 0px",
            }}
          >
            <Stack direction="row" spacing={1}>
              <StyledButton
                loading={loading || isSubmitting}
                disabled={loading || isSubmitting}
                onClick={handleBack}
                variant="outlined"
              >
                Back
              </StyledButton>
              <StyledButton
                loading={loading || isSubmitting}
                disabled={loading || isSubmitting}
                variant="contained"
                type="submit"
              >
                {isFinalStep ? "Submit" : "Next"}
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
