import { Box, Typography } from "@mui/material";
import { ConfirmationIcon } from "assets/constants";

export const ConfirmationStep = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        bgcolor: "white",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        justifyContent: "center",
        minHeight: "460px",
      }}
    >
      <ConfirmationIcon />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          justifyContent: "centers",
        }}
      >
        <Typography
          color="#181D27"
          sx={{ fontSize: "32px", fontWeight: "600", lineHeight: "38px" }}
        >
          Report Submitted Successfully!
        </Typography>
        <Typography
          color="#414651"
          sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px" }}
        >
          You have completed this month’s project report.
        </Typography>
      </Box>
    </Box>
  );
};
