"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chartColourChoices = exports.getRandomColour = exports.getSeverityColour = void 0;
const getSeverityColour = (value) => {
    if (value < 25) {
        return "error";
    }
    else if (value < 75) {
        return "warning";
    }
    else {
        return "success";
    }
};
exports.getSeverityColour = getSeverityColour;
const getRandomColour = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
exports.getRandomColour = getRandomColour;
exports.chartColourChoices = [
    "#90CAF9",
    "#3f99fa",
    "#1565C0",
    "#15cf74",
    "#F44336",
    "#FFAB91",
    "#FFE57F",
    "#A5D6A7",
    "#FFCDD2",
];
