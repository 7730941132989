import NoSsr from "@mui/material/NoSsr";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { PieChart, useDrawingArea } from "@mui/x-charts";
import { useItemTooltip, useMouseTracker } from "@mui/x-charts/ChartsTooltip";
import {
  DashboardReportInfoLineItem,
  DashboardTableItemLineItem,
} from "social-pro-common/interfaces/dashboard";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { StyledPaper, StyledText, StyledTypography } from "./components";

export type MousePosition = {
  x: number;
  y: number;
  pointerType: "mouse" | "touch" | "pen";
  height: number;
};

/**
 * Helper faking an element bounding box for the Popper.
 */
export function generateVirtualElement(
  mousePosition: { x: number; y: number } | null,
) {
  if (mousePosition === null) {
    return {
      getBoundingClientRect: () => ({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        toJSON: () => "",
        top: 0,
        width: 0,
        x: 0,
        y: 0,
      }),
    };
  }
  const { x, y } = mousePosition;
  const boundingBox = {
    bottom: y,
    height: 0,
    left: x,
    right: x,
    top: y,
    width: 0,
    x,
    y,
  };
  return {
    getBoundingClientRect: () => ({
      ...boundingBox,
      toJSON: () => JSON.stringify(boundingBox),
    }),
  };
}

export function CustomItemTooltip() {
  const tooltipData = useItemTooltip();
  const mousePosition = useMouseTracker(); // Track the mouse position on chart.

  if (!tooltipData || !mousePosition) {
    // No data to display
    return null;
  }

  // The pointer type can be used to have different behavior based on pointer type.
  const isMousePointer = mousePosition?.pointerType === "mouse";
  // Adapt the tooltip offset to the size of the pointer.
  const yOffset = isMousePointer ? 0 : 40 - mousePosition.height;

  return (
    <NoSsr>
      <Popper
        sx={{
          pointerEvents: "none",
          zIndex: (theme) => theme.zIndex.modal,
        }}
        open
        placement={isMousePointer ? "top-end" : "top"}
        anchorEl={generateVirtualElement(mousePosition)}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, yOffset],
            },
          },
        ]}
      >
        <StyledPaper elevation={0}>
          <Stack direction="column" gap={1.5}>
            <Stack direction="column">
              <StyledTypography>{tooltipData.label}</StyledTypography>
              <StyledTypography color="#D5D7DA">
                Value Reported: {tooltipData.formattedValue}
              </StyledTypography>
            </Stack>
            <StyledTypography color="#D5D7DA">
              ANZ Value Add %: {tooltipData.formattedValue}
            </StyledTypography>
            <StyledTypography color="#D5D7DA">
              Target Local Content %: {tooltipData.formattedValue}
            </StyledTypography>
          </Stack>
        </StyledPaper>
      </Popper>
    </NoSsr>
  );
}

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { height, left, top, width } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export const pieChartData = [
  { label: "Preliminaries and Margins", value: 20 },
  { label: "Labour - Civil", value: 10 },
  { label: "Labour - General", value: 15 },
  { label: "Concrete", value: 25 },
  { label: "Project Management", value: 20 },
  { label: "Piling", value: 30 },
  { label: "Steel - Reinforcing", value: 20 },
  { label: "Design and Drafting", value: 30 },
  { label: "Plant", value: 20 },
  { label: "Labour - Hydraulics", value: 35 },
];

interface CustomPieChartProps {
  data?: DashboardReportInfoLineItem;
}
const CustomPieChart = ({ data }: CustomPieChartProps) => {
  const chartData2 =
    data?.tableItems.map((item: DashboardTableItemLineItem) => {
      return {
        label: item.targetName,
        value: parseFloat(item.actual.replace(/[$,]/g, "")), //TODO: FIX THIS HACK
      };
    }) || [];
  const totalValue = chartData2.reduce((acc, item) => acc + item.value, 0);
  return (
    <PieChart
      className="chart-container"
      series={[
        {
          cornerRadius: 0,
          cx: "50%",
          cy: "50%",
          data: chartData2,
          endAngle: 392,
          innerRadius: 100,
          outerRadius: 140,
          paddingAngle: 0,
          startAngle: 31,
          valueFormatter: (value) => `$${formatDecimalPlaces(value.value, 0)}`,
        },
      ]}
      colors={[
        "#29238A",
        "#3838D0",
        "#B692F6",
        "#D6BBFB",
        "#F4EBFF",
        "#FEF0C7",
        "#FEC84B",
        "#F79009",
        "#DC6803",
        "#B54708",
      ]}
      margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
      slotProps={{
        legend: { hidden: true },
      }}
      height={300}
      width={300}
      sx={{
        "& .MuiPieArc-root": {
          stroke: "none",
        },
      }}
    >
      <PieCenterLabel>
        {`$${formatDecimalPlaces(totalValue)}` || "$0"}
      </PieCenterLabel>
    </PieChart>
  );
};

export default CustomPieChart;
