import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePackageLocalContentAcc } from "@hooks/crud/packageLocalContent/usePackageLocalContentAcc";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsLocalContentLevel2Table } from "@stories/organisms/AnalyticsLocalContentTables/AnalyticsLocalContentLevel2Table";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  getSortedLocalContent,
  PackageLocalContentLineItem,
} from "social-pro-common/interfaces/packageLocalContent";
import { dateToString } from "social-pro-common/utils/date";

import { Header } from "../Header/Header";

interface PackageDetailsLocalContentProps {
  loading: boolean;
  subPackage?: ContractorPackageLineItem;
  localContents: PackageLocalContentLineItem[];
}

export const PackageDetailsLocalContent = ({
  loading,
  subPackage,
}: PackageDetailsLocalContentProps) => {
  const [reportId] = useState<string>(dateToString(new Date()));

  const { contractorPackage, isPrimaryPackage, isProjectLoading } =
    useProjectContext();

  const { isPackageLocalContentLoading, packageLocalContents } =
    usePackageLocalContentAcc(subPackage?.projectId, subPackage?.id, reportId);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const isLoading = loading || isPackageLocalContentLoading || isProjectLoading;

  const tabs = [
    {
      label: "Reported",
    },
    {
      label: "Actual",
    },
  ];
  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={4}>
          <Header
            mainTitle="Local Content"
            subTitle="Local Content Target Summary"
            loading={isLoading}
            tabs={tabs}
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            isSubheading
            headerFontSizes={[18, 14]}
            headerGap={0}
          />
          <AnalyticsLocalContentLevel2Table
            loading={isLoading}
            searchTerm={searchTerm}
            tabIndex={0}
            withoutBorder
            isPrimaryPackage={isPrimaryPackage}
            contractorPackage={contractorPackage}
            subPackage={subPackage}
            localContents={getSortedLocalContent(packageLocalContents)}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
