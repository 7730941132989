import { Box, Grid, Table, TableBody, Typography } from "@mui/material";
import React from "react";
import { LabourHourTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import {
  StyledTableContainer,
  SummaryTableSkeleton,
  TableRowItem,
} from "./components";

type LabourHourAnalyticsSummaryProps = {
  summary?: LabourHourTargetAnalyticsLineItem;
  loading: boolean;
};

const LabourHourAnalyticsSummary: React.FC<LabourHourAnalyticsSummaryProps> = ({
  loading,
  summary,
}) => {
  if (loading) {
    return (
      <Grid md={12} item>
        <Box mb={2}>
          <SummaryTableSkeleton />
        </Box>
      </Grid>
    );
  }

  if (!summary) {
    return (
      <Box mb={2}>
        <Typography variant="body1" color="textSecondary">
          No data available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box borderColor="grey.300" mb={2}>
      <StyledTableContainer>
        <Table>
          <TableBody>
            <TableRowItem
              header1="Target Type"
              value1={
                summary.targetDescription ||
                labourHourCommitmentTypeToString(summary.targetType)
              }
              header2="Target (Hours)"
              value2={formatDecimalPlaces(summary.targetValue, 0)}
            />
            <TableRowItem
              header1="Committed (Hours)"
              value1={formatDecimalPlaces(summary.committedHours, 0)}
              header2="Actual (Hours)"
              value2={formatDecimalPlaces(summary.actual, 0)}
            />
            <TableRowItem
              header1="This month (Hours)"
              value1={formatDecimalPlaces(summary.monthReportedValue, 0)}
              header2="Progress To Target"
              value2={`${formatDecimalPlaces(summary.progress, 0)}%`}
            />
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
};

export default LabourHourAnalyticsSummary;
