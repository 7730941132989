import { HighlightScope } from "@mui/x-charts";
import { LineChart } from "@mui/x-charts/LineChart";
import { useEffect, useState } from "react";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";

export function generateVirtualElement(
  mousePosition: { x: number; y: number } | null,
) {
  if (mousePosition === null) {
    return {
      getBoundingClientRect: () => ({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        toJSON: () => "",
        top: 0,
        width: 0,
        x: 0,
        y: 0,
      }),
    };
  }
  const { x, y } = mousePosition;
  const boundingBox = {
    bottom: y,
    height: 0,
    left: x,
    right: x,
    top: y,
    width: 0,
    x,
    y,
  };
  return {
    getBoundingClientRect: () => ({
      ...boundingBox,
      toJSON: () => JSON.stringify(boundingBox),
    }),
  };
}

interface CustomLineChartProps {
  forecastModels: ForecastModelLineItem[];
}
export default function CustomLineChart({
  forecastModels,
}: CustomLineChartProps) {
  const [data, setData] = useState<{
    data: any[];
    labels: string[];
    hasValue: boolean;
  }>({
    data: [],
    hasValue: false,
    labels: [],
  });

  useEffect(() => {
    let hasValue = false;
    const activeModels = forecastModels.filter((fm) => fm.active);

    const reportIds = activeModels.map((fm) => fm.data.map((d) => d.reportId));
    const labels = reportIds.flat().filter((v, i, a) => a.indexOf(v) === i);

    const data: any[] = [];
    for (let i = 0; i < activeModels.length; i++) {
      const fm = activeModels[i];
      const dataSet = fm.data.map((d) => d.value ?? null);
      hasValue =
        hasValue || dataSet.some((v) => typeof v === "number" && !isNaN(v));
      data.push({
        color: fm.colour,
        curve: "monotoneX",
        data: dataSet,
        highlightScope: {
          faded: "global",
          highlighted: "series",
        } as HighlightScope,
        type: "line",
      });
    }

    setData({ data, hasValue, labels });
  }, [forecastModels]);

  return (
    <LineChart
      xAxis={[{ data: data.labels, scaleType: "point" }]}
      series={data.data}
      height={400}
      grid={{ horizontal: true }}
      tooltip={{ trigger: data.hasValue ? "item" : "none" }}
      margin={{ bottom: 40, left: 24, right: 24, top: 16 }}
      sx={{
        "& .MuiChartsAxis-line": {
          stroke: "#F5F5F5 !important",
        },
        ".MuiChartsAxis-directionY": { display: "none" },
        ".MuiChartsAxis-tick": {
          display: "none",
        },
        ".MuiChartsAxis-tickLabel": {
          fill: "#535862 !important",
          fontFamily: "'Inter'",
          fontSize: 12,
          lineHeight: "20px",
          textAnchor: "middle",
          transform: "rotate(0deg) translateY(10px)",
        },
        ".MuiChartsAxisHighlight-root": {
          display: "none",
        },
        ".MuiChartsGrid-horizontalLine": {
          stroke: "#f5f5f5",
        },
      }}
    />
  );
}
