"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultGreenMetricCategory = void 0;
const uuid_1 = require("uuid");
const createDefaultGreenMetricCategory = (greenMetricId) => {
    return {
        created: new Date(),
        greenMetricId,
        id: (0, uuid_1.v4)(),
        metricType: "",
        targetCategory: "",
        targetType: "",
    };
};
exports.createDefaultGreenMetricCategory = createDefaultGreenMetricCategory;
