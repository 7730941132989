import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { Grid } from "@mui/material";
import { AnalyticsLocalContentLevel1Table } from "@stories/organisms/AnalyticsLocalContentTables/AnalyticsLocalContentLevel1Table";
import { Header } from "@stories/organisms/Header/Header";
import * as RouteHelper from "@utils/routes";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const AnalyticsEnviroSustainability = () => {
  const { reportId } = useParams();

  const navigate = useNavigate();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { contractorPackages, isContractorPackageLoading } =
    useContractorPackage(selectedProject?.id);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loading = isProjectLoading || isContractorPackageLoading;
  return (
    <>
      <Header
        mainTitle="Package Environmental Sustainability"
        loading={loading}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
      />
      <Grid item xs={12} md={12}>
        <AnalyticsLocalContentLevel1Table
          loading={loading}
          contractorPackages={contractorPackages}
          handleAction={(subPackage: ContractorPackageLineItem) => {
            if (selectedProject && contractorPackage) {
              navigate(
                RouteHelper.analyticsEnviroSustainabilityDetails(
                  selectedProject.id,
                  contractorPackage.id,
                  subPackage.id,
                  reportId || "",
                ),
              );
            }
          }}
          searchTerm={searchTerm}
        />
      </Grid>
    </>
  );
};
