import { useProfileContext } from "@hooks/context/useProfileContext";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ArchiveProjectIcon, EditProjectIcon } from "assets/constants";
import { isBMD } from "init";
import { useState } from "react";
import {
  ProfileLineItem,
  profileTypeToString,
} from "social-pro-common/interfaces/profile";

import { UserTableSkeleton } from "./UserTableSkeleton";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

interface UserTableProps {
  loading: boolean;
  profiles: ProfileLineItem[];
  searchTerm: string;
  handleOpen: () => Promise<void>;
  handleEditUser: (profile: ProfileLineItem) => Promise<void>;
  handleDeleteUser: (profile: ProfileLineItem) => void;
}

export const UserTable = ({
  handleDeleteUser,
  handleEditUser,
  handleOpen,
  loading,
  profiles,
  searchTerm,
}: UserTableProps) => {
  const { userProfile } = useProfileContext();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<ProfileLineItem>();

  const onConfirm = () => {
    if (selectedProfile) {
      handleDeleteUser(selectedProfile);
    }
    setOpenConfirmDialog(false);
  };

  const handleOpenConfirmDialog = (profile: ProfileLineItem) => {
    setSelectedProfile(profile);
    setOpenConfirmDialog(true);
  };

  const cellActions = [
    {
      action: handleEditUser,
      adminOnly: true,
      icon: <EditProjectIcon />,
      title: "Edit User",
    },
    {
      action: handleOpenConfirmDialog,
      adminOnly: true,
      icon: <ArchiveProjectIcon />,
      title: "Delete User",
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "name",
      headerClass: "left-table-header",
      headerName: "Name",
    },
    {
      ...defaultCellConfig,
      field: "email",
      headerClass: "left-table-header",
      headerName: "Email",
    },
    {
      ...defaultCellConfig,
      field: "employeeAdmin",
      headerClass: "left-table-header",
      headerName: "Account Type",
      valueFormatter: (params) => profileTypeToString(params.data.isAdmin),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: { userProfile },
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  const buttons: HeaderButtonConfig[] = !isBMD()
    ? [
        {
          action: handleOpen,
          icon: <PersonAddIcon />,
          text: "Add User",
          type: HeaderButtonType.PRIMARY,
          width: 120,
        },
      ]
    : [];

  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={2}>
          <Header
            buttons={buttons}
            loading={loading}
            mainTitle={"Users"}
            subTitle={"Manage users"}
            isSubheading
          />

          {loading ? (
            <TableSkeleton
              tableHead={generateTableHead(colDefs)}
              rows={UserTableSkeleton}
              noFullTableBorder
            />
          ) : (
            <Table<ProfileLineItem>
              columnDefs={colDefs}
              loading={loading}
              data={profiles || []}
              context={{ cellActions }}
              noFullTableBorder
              searchValue={searchTerm}
              searchKeys={["name", "email", "isAdmin"]}
            />
          )}
          <ConfirmationDialog
            message={"Are you sure you want to delete this user?"}
            open={openConfirmDialog}
            title={"Delete User"}
            intent={"error"}
            buttonText={"Delete"}
            onCancel={() => setOpenConfirmDialog(false)}
            onConfirm={onConfirm}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
