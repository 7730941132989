import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";

const TABLE_HEAD = [
  { id: "commitment", label: "Commitment", width: "40%" },
  { id: "target", label: "Target %", width: "20%" },
  { id: "targetOutcome", label: "Target Outcome", width: "20%" },
  { id: "action", label: "Action", width: "20%" },
];

interface ActionCellProps extends CustomCellRendererProps {
  loading: boolean;
  handleEdit: (name: string) => void;
  handleDelete: (name: string) => void;
}

const InfoCell = ({ data }: CustomCellRendererProps) => {
  return (
    <Stack direction="row" spacing={2}>
      <Tooltip
        placement="top"
        title={data.description}
        TransitionComponent={Zoom}
      >
        <InfoIcon color={"info"} />
      </Tooltip>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography noWrap>{data.title}</Typography>
      </Box>
    </Stack>
  );
};

const ActionCell = ({
  data,
  handleDelete,
  handleEdit,
  loading,
}: ActionCellProps) => {
  return (
    <Stack direction="row" justifyContent={"flex-end"}>
      <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
        <IconButton
          color="primary"
          size="medium"
          disabled={loading}
          onClick={() => handleEdit(data.name)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
        <IconButton
          color="error"
          size="medium"
          disabled={loading}
          onClick={() => handleDelete(data.name)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export { ActionCell, InfoCell, TABLE_HEAD };
