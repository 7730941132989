import { Skeleton, TableCell, TableRow } from "@mui/material";

export const AnalyticsLocalContentLevel1TableRowSkeleton = () => {
  return (
    <TableRow>
      {Array.from({ length: 6 }, (_, index) => (
        <TableCell
          key={index}
          align={index === 0 ? "left" : "center"}
          sx={{
            paddingBottom: "10px",
            paddingLeft: index === 3 || index === 4 ? "70px" : "5px",
            paddingTop: "10px",
          }}
        >
          <Skeleton
            animation="wave"
            width={index === 3 || index === 4 ? 100 : index === 5 ? 50 : 200}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};
