import { AnimatedButton, AnimatedText, IconWrapper } from "./components";

interface IconTextButtonProps {
  Icon: React.ReactNode;
  bgColor?: string;
  disabled?: boolean;
  handleClick: () => void;
  text?: string;
  width?: number;
  minWidth?: number;
}

const IconTextButton: React.FC<IconTextButtonProps> = ({
  Icon,
  bgColor,
  disabled,
  handleClick,
  minWidth,
  text = "",
  width,
}) => {
  return (
    <AnimatedButton
      bgcolor={bgColor}
      width={width}
      disabled={disabled}
      onClick={handleClick}
      minwidth={minWidth}
    >
      <IconWrapper className="icon-wrapper">{Icon}</IconWrapper>
      <AnimatedText
        variant="button"
        className="animated-text"
        sx={{
          color: bgColor ? "white" : "#414651",
        }}
      >
        {text}
      </AnimatedText>
    </AnimatedButton>
  );
};

export default IconTextButton;
