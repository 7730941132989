import { Skeleton, TableCell, TableRow } from "@mui/material";
import { DropdownIcon } from "assets/constants";

export const AnalyticsSpendLevel1TableRowSkeleton = ({
  singleAction,
}: {
  singleAction?: boolean;
}) => {
  return (
    <TableRow>
      {Array.from({ length: 5 }, (_, index) => (
        <TableCell
          key={index}
          align={index === 0 ? "left" : "center"}
          sx={{
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
      <TableCell
        align="center"
        sx={{ paddingBottom: "10px", paddingTop: "10px", width: "10%" }}
      >
        {singleAction ? (
          <Skeleton animation="wave" />
        ) : (
          <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
            <DropdownIcon />
          </Skeleton>
        )}
      </TableCell>
    </TableRow>
  );
};
