import { ProfileProvider } from "@hooks/context/useProfileContext";
import { AppBar, Box, Container, CssBaseline, Toolbar } from "@mui/material";
import { styled, useTheme, Theme } from "@mui/material/styles";
import * as RouteHelper from "@utils/routes";
import { drawerWidth } from "constant";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

// material-ui

// project imports
import Sidebar from "./Sidebar";
import Header from "../MenuShared/Header";

// assets

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ open, theme }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: 20,
        padding: "16px",
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 72),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.sharp,
      }),
    }),
    ...(open && {
      marginLeft: "20px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 20,
      },

      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginTop: 20,
      },
      [theme.breakpoints.up("md")]: {},
      // 'margin 538ms cubic-bezier(0.4, 0, 1, 1) 0ms',
      transition: theme.transitions.create("margin", {
        duration: theme.transitions.duration.shorter + 200,
        easing: theme.transitions.easing.easeOut,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  }),
);

interface ProjectLayoutProps {
  children: React.ReactNode;
}
const ProjectLayout = ({ children }: ProjectLayoutProps) => {
  const theme = useTheme();
  const location = useLocation();

  const routeMatch = useRouteMatch([
    RouteHelper.listProjects,
    RouteHelper.employees,
    RouteHelper.integrations,
    RouteHelper.organisationDashboard,
  ]);

  const [isDrawOpen, setIsDrawOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);

  useEffect(() => {
    const currentPathId =
      routeMatch?.pattern?.path.split("/")[1] || "NO_ID_SELECTED";

    setSelectedItem([currentPathId]);
  }, [location]);

  const header = (
    <Toolbar
      sx={{
        padding: "0 !important",
        width: "260px",
      }}
    >
      <Header setIsDrawOpen={setIsDrawOpen} isDrawOpen={isDrawOpen} />
    </Toolbar>
  );

  return (
    <ProfileProvider>
      <Box sx={{ display: "flex" }} className="dashboardPage">
        <CssBaseline />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{ background: "transparent" }}
        >
          {header}
        </AppBar>

        <div className="normal">
          <Sidebar
            isDrawOpen={isDrawOpen}
            selectedItem={selectedItem}
            setIsDrawOpen={setIsDrawOpen}
            setSelectedItem={setSelectedItem}
          />
        </div>

        {/* main content */}

        <Main theme={theme} open={isDrawOpen}>
          <Container maxWidth={false} sx={{ px: { xs: 0 } }}>
            <Box sx={{ borderRadius: "30px 0 0 0" }}>{children}</Box>
          </Container>
        </Main>
      </Box>
    </ProfileProvider>
  );
};

export default ProjectLayout;
