import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";

export const LidpTableRowSkeleton = () => {
  return (
    <TableRow>
      {[
        { align: "left", width: "10%" },
        { align: "left", width: "10%" },
        { align: "left", width: "8%" },
        { align: "left", width: "8%" },
        { align: "left", width: "8%" },
        { align: "center", width: "8%" },
        { align: "center", width: "8%" },
        { align: "center", width: "8%" },
        { align: "center", width: "8%" },
        { align: "center", width: "8%" },
        { align: "center", width: "8%" },
      ].map((cell, index) => (
        <TableCell
          align={cell.align as "left" | "center" | "right"}
          key={index}
          sx={{ paddingBottom: "5px", paddingTop: "5px", width: cell.width }}
        >
          <Skeleton animation="wave" height={20} />
        </TableCell>
      ))}

      <TableCell
        align="right"
        sx={{ paddingBottom: "5px", paddingTop: "5px", width: "8%" }}
      >
        <Stack direction="row" spacing={2} justifyContent="center">
          <Stack direction="row" spacing={1}>
            <Skeleton animation="wave" variant="circular">
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
            </Skeleton>
            <Skeleton animation="wave" variant="circular">
              <IconButton color="error" size="large">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Skeleton>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
