import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid } from "@mui/material";
import ChangePasswordForm from "@stories/molecules/ChangePasswordForm/ChangePasswordForm";
import { ProfileForm } from "@stories/molecules/ProfileForm/ProfileForm";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { catchSentryError } from "@utils/sentry";
import { ToastOptions, toast } from "react-toastify";
import {
  ProfileLineItem,
  ProfileStatus,
} from "social-pro-common/interfaces/profile";

export const EditProfile = () => {
  const { changePassword, isAuthLoading, user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const {
    isAuthProfileLoading,
    updateUserProfile,
    userOrganisation,
    userProfile,
  } = useProfileContext();

  const handleSubmit = async (profile: ProfileLineItem) => {
    try {
      if (userProfile && userOrganisation) {
        await updateUserProfile({ ...profile, status: ProfileStatus.Active });
      }
    } catch (error) {
      catchSentryError(error);
      toast("Could not save account, please contact support.", {
        type: "error",
      } as ToastOptions);
    }
  };
  const loading =
    isAuthProfileLoading || isAuthLoading || !userProfile || !userOrganisation;
  return (
    <PageContainer>
      <Header
        loading={loading}
        mainTitle="Profile Settings"
        subTitle={userProfile?.name}
      />
      <Grid item md={12} mt={2}>
        <ProfileForm
          loading={loading}
          profile={userProfile}
          handleSubmit={handleSubmit}
        />
      </Grid>
      <Grid item md={12}>
        <ChangePasswordForm loading={loading} changePassword={changePassword} />
      </Grid>
    </PageContainer>
  );
};
