import { Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { getEmployeeFactors } from "@utils/employeSocialFactors";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { useState } from "react";
import {
  EmployeeLineItem,
  genderToString,
  occupationToString,
  occupationTypeOptionToString,
} from "social-pro-common/interfaces/contractorEmployee";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { ViewEmployeeTableRowSkeleton } from "./ViewEmployeeTableRowSkeleton";
import { EmployeeSocialFactorsCell } from "../ProjectEmployeeTable/ProjectEmployeeTableCells";

interface ViewEmployeeTableProps {
  loading: boolean;
  employees: EmployeeLineItem[];
  project?: ProjectLineItem;
}

export const ViewEmployeeTable = ({
  employees,
  loading,
  project,
}: ViewEmployeeTableProps) => {
  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "employeeName",
      flex: 2,
      headerName: "Name or Code",
      valueFormatter: (params) =>
        `${params.data.employeeName}${
          params.data.employeeIdentifier
            ? `(${params.data.employeeIdentifier})`
            : ""
        }`,
    },
    {
      ...defaultCellConfig,
      field: "primaryEmployer",
      flex: 2,
      headerName: "Primary Employer",
    },
    {
      ...defaultCellConfig,
      field: "occupation",
      flex: 2,
      headerName: "Occupation",
      valueFormatter: (params) =>
        `${occupationToString(params.data.occupation)}`,
    },
    {
      ...defaultCellConfig,
      field: "occupationType",
      flex: 2,
      headerName: "Occupation Type",
      valueFormatter: (params) =>
        `${occupationTypeOptionToString(params.data.occupationType)}`,
    },
    {
      ...defaultCellConfig,
      field: "gender",
      flex: 1,
      headerName: "Gender",
      valueFormatter: (params) => `${genderToString(params.data.gender)}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: (params: ValueFormatterParams<any, any>) => {
        const socialFactors = getEmployeeFactors(
          params.data,
          params.context.project,
        );

        return <EmployeeSocialFactorsCell factors={socialFactors} />;
      },
      cellStyle: centerCellStyles,
      field: "socialFactors",
      flex: 1,
      headerClass: "centered-table-header",
      headerName: "Social Factors",
    },
  ]);

  return (
    <Grid item xs={12} md={12}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ViewEmployeeTableRowSkeleton}
          noFullTableBorder
        />
      ) : (
        <Table<EmployeeLineItem>
          noFullTableBorder
          columnDefs={colDefs}
          loading={loading}
          data={employees || []}
          context={{
            project,
          }}
        />
      )}
    </Grid>
  );
};
