import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import CustomBarChart from "@stories/atoms/CustomBarChart/CustomBarChart";
import CustomPieChart from "@stories/atoms/CustomPieChart/CustomPieChart";
import MetricScore from "@stories/atoms/MetricScore/MetricScore";
import ProgressBar from "@stories/atoms/ProgressBar/ProgressBar";
import {
  DashboardSummaryTableLocalContent,
  DashboardSummaryTableLabourHour,
  DashboardSummaryTableSocialSpend,
} from "@stories/molecules/DashboardSummaryTable/DashboardSummaryTable";
import DashboardTabs from "@stories/molecules/DashboardTabs/DashboardTabs";
import {
  SubtitleText,
  TitleText,
} from "@stories/molecules/OverviewCard/components";
import { ContractorDashboardModelLineItem } from "social-pro-common/interfaces/dashboard";

import {
  ForecastChartContainer,
  ForecastContainer,
  ForecastHeader,
  PieChartContainer,
  Subtitle,
  TableContainer,
  Title,
} from "./components";
import { chartData, tabsData } from "./constants";

interface Props {
  tabIndex: number;
  loading: boolean;
  handleTabChange: (evexnt: React.SyntheticEvent, newValue: number) => void;
  title?: string;
  subtitle?: string;
  data?: ContractorDashboardModelLineItem;
}

const DashboardSummary = ({
  data,
  handleTabChange,
  loading,
  subtitle = "Summary of Target Progress",
  tabIndex,
  title = "Target Summary",
}: Props) => {
  return (
    <Stack direction="column" gap="32px">
      <Box>
        <TitleText>
          {loading ? <Skeleton animation={"wave"} width={150} /> : title}
        </TitleText>
        <SubtitleText>
          {loading ? <Skeleton animation={"wave"} width={200} /> : subtitle}
        </SubtitleText>
      </Box>

      <DashboardTabs
        loading={loading}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        tabData={tabsData}
        variant="fullWidth"
      />

      {/* Tab 1 */}
      {tabIndex === 0 && (
        <>
          <Stack direction="column" gap={2}>
            <Grid container spacing={4}>
              <Grid item md={4}>
                <MetricScore
                  dataTestId="target-value"
                  loading={loading}
                  color="#E9EAEB"
                  heading="Total Hours Target"
                  figure={data?.labourHour.targetTotal || "0hrs"}
                />
              </Grid>
              <Grid item md={4}>
                <MetricScore
                  dataTestId="committed-value"
                  loading={loading}
                  color="#EAAA08"
                  heading="Total Hours Committed"
                  figure={data?.labourHour.totalCommitted || "0hrs"}
                />
              </Grid>
              <Grid item md={4}>
                <MetricScore
                  dataTestId="reported-value"
                  loading={loading}
                  color="#CA8504"
                  heading="Total Hours Reported"
                  figure={data?.labourHour.totalReported || "0hrs"}
                />
              </Grid>
            </Grid>
            <ProgressBar
              colors={["#E9EAEB", "#EAAA08", "#CA8504"]}
              figure2={data?.labourHour.pcProgressCommitted || "0%"}
              figure1={data?.labourHour.pcProgressReported || "0%"}
              dataIdPrefix="target"
            />
          </Stack>
          <Grid container spacing={4}>
            <Grid item md={8}>
              <TableContainer>
                <DashboardSummaryTableLabourHour
                  loading={loading}
                  data={data?.labourHour.tableItems || []}
                  heading="Target Overview"
                  subTitle="Labour Hours"
                />
              </TableContainer>
            </Grid>
            <Grid item md={4}>
              <ForecastContainer>
                <ForecastHeader>
                  <Stack direction="row" justifyContent="space-between" gap={1}>
                    <Box>
                      {loading ? (
                        <Skeleton animation="wave">
                          <Typography>Forcasting</Typography>
                        </Skeleton>
                      ) : (
                        <Title>Forecasting</Title>
                      )}
                      {loading ? (
                        <Skeleton animation="wave">
                          <Typography>
                            6 Month Participation Forecast
                          </Typography>
                        </Skeleton>
                      ) : (
                        <Subtitle paragraph>
                          6 Month Participation Forecast
                        </Subtitle>
                      )}
                    </Box>
                    {/* <StyledButton disableRipple>
                      <MenuIcon />
                    </StyledButton> */}
                  </Stack>
                </ForecastHeader>
                <ForecastChartContainer>
                  <CustomBarChart
                    loading={loading}
                    data={data?.labourHour.forecast || chartData}
                    stackColors={{ 1: "#CA8504", 2: "#E9EAEB" }}
                  />
                </ForecastChartContainer>
              </ForecastContainer>
            </Grid>
          </Grid>
        </>
      )}

      {/* Tab 2 */}
      {tabIndex === 1 && (
        <>
          <Stack direction="column" gap={2}>
            <Grid container spacing={4}>
              <Grid item md={4}>
                <MetricScore
                  dataTestId="target-value"
                  loading={loading}
                  color="#E9EAEB"
                  heading="Total Social Spend Targets"
                  figure={data?.socialSpend.targetTotal || "$0"}
                />
              </Grid>
              <Grid item md={4}>
                <MetricScore
                  loading={loading}
                  dataTestId="committed-value"
                  color="#FF9C66"
                  heading="Total Social Spend Committed"
                  figure={data?.socialSpend.totalCommitted || "$0"}
                />
              </Grid>
              <Grid item md={4}>
                <MetricScore
                  dataTestId="reported-value"
                  loading={loading}
                  color="#E04F16"
                  heading="Total Social Spend Reported"
                  figure={data?.socialSpend.totalReported || "$0"}
                />
              </Grid>
            </Grid>
            <ProgressBar
              colors={["#E9EAEB", "#FF9C66", "#E04F16"]}
              figure2={data?.socialSpend.pcProgressCommitted || "0%"}
              figure1={data?.socialSpend.pcProgressReported || "0%"}
              dataIdPrefix="target"
            />
          </Stack>
          <Grid container spacing={4}>
            <Grid item md={8}>
              <TableContainer>
                <DashboardSummaryTableSocialSpend
                  data={data?.socialSpend.tableItems || []}
                  loading={loading}
                  heading="Social Spend Target Summary"
                />
              </TableContainer>
            </Grid>
            <Grid item md={4}>
              <ForecastContainer>
                <ForecastHeader>
                  <Stack direction="row" justifyContent="space-between" gap={1}>
                    <Box>
                      <Title>Forecasting</Title>
                      <Subtitle paragraph>
                        6 Month Participation Forecast
                      </Subtitle>
                    </Box>
                    {/* <StyledButton disableRipple>
                      <MenuIcon />
                    </StyledButton> */}
                  </Stack>
                </ForecastHeader>
                <ForecastChartContainer>
                  <CustomBarChart
                    loading={loading}
                    data={data?.socialSpend.forecast || chartData}
                    stackColors={{ 1: "#E04F16", 2: "#E9EAEB" }}
                  />
                </ForecastChartContainer>
              </ForecastContainer>
            </Grid>
          </Grid>
        </>
      )}

      {/* Tab 3 */}
      {tabIndex === 2 && (
        <>
          <Stack direction="column" gap={2}>
            <Grid container spacing={4}>
              <Grid item md={4}>
                <MetricScore
                  dataTestId="target-value"
                  loading={loading}
                  color="#6938EF"
                  heading="ANZ Value Add"
                  figure={`${data?.localContent.targetTotal}%` || "0%"}
                />
              </Grid>
              <Grid item md={4}>
                <MetricScore
                  loading={loading}
                  color="transparent"
                  dataTestId="committed-value"
                  heading="Value Reported to Date"
                  figure={`$${data?.localContent.totalCommitted}` || "$0"}
                />
              </Grid>
              <Grid item md={4}>
                <MetricScore
                  dataTestId="reported-value"
                  loading={loading}
                  color="transparent"
                  heading="Local Content Target"
                  figure={`${data?.localContent.totalReported}%` || "0%"}
                />
              </Grid>
            </Grid>
            <ProgressBar
              colors={["#6938EF"]}
              figure1={`${data?.localContent.targetTotal}%` || "0%"}
              dataIdPrefix="target"
            />
          </Stack>
          <Grid container spacing={4}>
            <Grid item md={8}>
              <TableContainer>
                <DashboardSummaryTableLocalContent
                  loading={loading}
                  data={data?.localContent.tableItems || []}
                  heading="Local Content Target Summary"
                />
              </TableContainer>
            </Grid>
            <Grid item md={4}>
              <PieChartContainer>
                <CustomPieChart data={data?.localContent} />
              </PieChartContainer>
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};

export default DashboardSummary;
