import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Grid,
  FormControl,
  InputAdornment,
  Typography,
  Autocomplete,
  TextField,
  Skeleton,
  Checkbox,
} from "@mui/material";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { validatePostCode } from "@utils/location";
import { useFormik } from "formik";
import { ChangeEvent, useState } from "react";
import {
  SocialSpendCertificate,
  SocialSpendEntityType,
} from "social-pro-common/entities/socialSpend";
import {
  SocialCommitmentTypeSpend,
  socialSpendCommitmentTypeToString,
} from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ProjectSocialSpendCommitmentLineItem } from "social-pro-common/interfaces/projectSocialSpendCommitment";
import {
  socialSpendCertificateToString,
  socialSpendEntityTypeToString,
} from "social-pro-common/interfaces/socialSpend";
import { validateABN } from "social-pro-common/utils/abn";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { cleanString } from "social-pro-common/utils/string";
import * as yup from "yup";

import ColourPicker from "../ColourPicker/ColourPicker";

interface SpendTargetFormProps {
  loading: boolean;
  project: ProjectLineItem;
  socialCommitment: ProjectSocialSpendCommitmentLineItem;
  baseMultiplier: number;
  handleSubmit: (
    socialCommitment: ProjectSocialSpendCommitmentLineItem,
  ) => void;
}

export const SpendTargetForm = ({
  baseMultiplier,
  handleSubmit,
  loading,
  project,
  socialCommitment,
}: SpendTargetFormProps) => {
  const [hex, setHex] = useState(socialCommitment.colour || "#000000");
  const [abnString, setAbnString] = useState<string>(
    socialCommitment?.abns ? socialCommitment.abns.join(", ") : "",
  );
  const [postCodesString, setPostCodesString] = useState<string>(
    socialCommitment?.postCodes ? socialCommitment.postCodes.join(", ") : "",
  );
  const [selectedEntityTypes, setSelectedEntityTypes] = useState<
    SocialSpendEntityType[]
  >(socialCommitment.entityTypes || []);
  const [selectedCertifications, setSelectedCertifications] = useState<
    SocialSpendCertificate[]
  >(socialCommitment.certifications || []);

  const [errorMessage, setErrorMessage] = useState<string>();

  const validationSchema = yup.object({
    abns: yup
      .array()
      .test("Valid ABN", "Invalid ABN - i.e. 43 665 205 185", function (items) {
        if (items && items.length > 0) {
          return items.filter((i) => validateABN(i)).length === items.length;
        }
        return true;
      }),
    certifications: yup.array(),
    entityTypes: yup.array(),
    postCodes: yup
      .array()
      .test("Valid for country", "Postcode is invalid", function (items) {
        if (items && items.length > 0) {
          return (
            items.filter((i) =>
              validatePostCode(project.projectAddress.country, i),
            ).length === items.length
          );
        }
        return true;
      }),
    targetDescription: yup.string().required("Description required"),
    targetName: yup.string().required("Target name is required"),
    targetValue: yup
      .number()
      .required("Target value is required")
      .moreThan(0, "Target value must be greater than 0")
      .max(100, "Target value must be less than 100"),
    targetValueRealised: yup
      .number()
      .required("Target value is required")
      .moreThan(0, "Target value must be greater than 0"),
  });
  const formik = useFormik({
    initialValues: {
      abns: socialCommitment.abns || [],
      certifications: socialCommitment.certifications,
      colour: hex,
      entityTypes: socialCommitment.entityTypes,
      postCodes: socialCommitment.postCodes || [],
      targetDescription:
        socialCommitment.targetName == SocialCommitmentTypeSpend.DefaultSpend
          ? socialCommitment.targetDescription
          : socialSpendCommitmentTypeToString(socialCommitment.targetName),
      targetName: socialCommitment.targetName,
      targetValue: socialCommitment.targetValue,
      targetValueRealised: socialCommitment.targetValueRealised,
    },
    onSubmit: (values) => {
      const validTarget =
        selectedCertifications.length > 0 ||
        selectedEntityTypes.length > 0 ||
        postCodesString.length > 0 ||
        abnString.length > 0;

      if (validTarget) {
        handleSubmit({
          ...socialCommitment,
          abns: values.abns.map((abn) => cleanString(abn)) || [],
          certifications: selectedCertifications,
          colour: values.colour,
          deleted: false,
          entityTypes: selectedEntityTypes,
          postCodes: values.postCodes || [],
          targetDescription: values.targetDescription,
          targetName: values.targetName,
          targetValue: values.targetValue,
          targetValueRealised: values.targetValueRealised,
        });
      } else {
        formik.setSubmitting(false);
        setErrorMessage("Please select at least one employee profile factor");
      }
    },
    validationSchema: validationSchema,
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const disabled = formik.isSubmitting;
  return (
    <Box>
      <form onSubmit={formik.handleSubmit} style={{ paddingTop: "12px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="targetDescription"
                name="targetDescription"
                value={formik.values.targetDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.targetDescription &&
                  Boolean(formik.errors.targetDescription)
                }
                helperText={
                  formik.touched.targetDescription
                    ? formik.errors.targetDescription
                    : ""
                }
                label="Description"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ColourPicker hex={hex} setHex={setHex} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="targetValue"
                name="targetValue"
                value={formatDecimalPlaces(formik.values.targetValue)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const numberValue = e.target.value as any as number;
                  const target = baseMultiplier * (numberValue / 100);
                  formik.setFieldValue("targetValueRealised", target);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.targetValue &&
                  Boolean(formik.errors.targetValue)
                }
                helperText={
                  formik.touched.targetValue ? formik.errors.targetValue : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      %
                    </InputAdornment>
                  ),
                  inputComponent: NumericFormatCustom as any,
                  inputProps: { maxLength: 5 },
                }}
                label="Target Value %"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <StyledTextField
                loading={loading}
                disabled={disabled}
                id="targetValueRealised"
                name="targetValueRealised"
                value={formatDecimalPlaces(formik.values.targetValueRealised)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const numberValue = e.target.value as any as number;
                  const realisedValue = (numberValue / baseMultiplier) * 100;
                  formik.setFieldValue("targetValue", realisedValue);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.targetValue &&
                  Boolean(formik.errors.targetValueRealised)
                }
                helperText={
                  formik.touched.targetValueRealised
                    ? formik.errors.targetValueRealised
                    : ""
                }
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                      }}
                    >
                      $
                    </InputAdornment>
                  ),
                }}
                label="Total Spend ($)"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h4">
              Spend Profile Factors - Choose at least one
            </Typography>
          </Grid>

          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  id="entityTypes"
                  options={Object.values(SocialSpendEntityType)}
                  defaultValue={formik.values.entityTypes}
                  disabled={disabled}
                  getOptionLabel={(option) =>
                    socialSpendEntityTypeToString(option)
                  }
                  onChange={(
                    event: any,
                    newValue: SocialSpendEntityType[] | null,
                  ) => {
                    setSelectedEntityTypes(newValue || []);
                    // formik.handleChange(event);
                  }}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value
                      .map((v) => socialSpendEntityTypeToString(v))
                      .join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {socialSpendEntityTypeToString(option)}
                    </li>
                  )}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="entityTypes"
                      {...params}
                      name="entityTypes"
                      label="Entity Types"
                      fullWidth
                      error={
                        formik.touched.entityTypes &&
                        Boolean(formik.errors.entityTypes)
                      }
                      helperText={
                        formik.touched.entityTypes
                          ? formik.errors.entityTypes
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              {loading ? (
                <Skeleton animation="wave" variant="rounded" width={"100%"}>
                  <TextField
                    label="DEFAULT TEXT FIELD CONTENT - LOADING"
                    fullWidth
                  />
                </Skeleton>
              ) : (
                <Autocomplete
                  fullWidth
                  multiple
                  disableCloseOnSelect
                  id="certifications"
                  options={Object.values(SocialSpendCertificate)}
                  defaultValue={formik.values.certifications}
                  disabled={disabled}
                  getOptionLabel={(option) =>
                    socialSpendCertificateToString(option)
                  }
                  onChange={(
                    event: any,
                    newValue: SocialSpendCertificate[] | null,
                  ) => {
                    setSelectedCertifications(newValue || []);
                    // formik.handleChange(event);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {socialSpendCertificateToString(option)}
                    </li>
                  )}
                  renderTags={(value) => {
                    // Join all selected values into a single string with commas
                    const text = value
                      .map((v) => socialSpendCertificateToString(v))
                      .join(", ");

                    // Check if the text length exceeds 20 characters
                    if (text.length > 20) {
                      // Truncate and add ellipsis
                      return `${text.substring(0, 17)}... ${
                        value.length > 1 ? `+${value.length - 1}` : ""
                      }`;
                    }

                    // If under 20 characters, return the full text
                    return text;
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      data-test-id="certifications"
                      {...params}
                      name="certifications"
                      label="Certifications"
                      fullWidth
                      error={
                        formik.touched.certifications &&
                        Boolean(formik.errors.certifications)
                      }
                      helperText={
                        formik.touched.certifications
                          ? formik.errors.certifications
                          : ""
                      }
                    />
                  )}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <StyledTextField
                fullWidth
                loading={false}
                id="postCodes"
                name="postCodes"
                disabled={disabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const postCodes = e.target.value;
                  setPostCodesString(postCodes);
                  const cleanPostCodes = postCodes
                    .split(",")
                    .map((a: string) => cleanString(a))
                    .filter((f) => !!f);
                  formik.setFieldValue("postCodes", cleanPostCodes);
                }}
                maxLength={500}
                onBlur={formik.handleBlur}
                value={postCodesString}
                error={
                  formik.touched.postCodes && Boolean(formik.errors.postCodes)
                }
                helperText={
                  formik.touched.postCodes ? formik.errors.postCodes : ""
                }
                label={"Postcodes (Comma separated)"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <StyledTextField
                fullWidth
                loading={false}
                id="abns"
                name="abns"
                disabled={disabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const abns = e.target.value;
                  setAbnString(abns);
                  const cleanAbns = abns
                    .split(",")
                    .map((a: string) => cleanString(a))
                    .filter((f) => !!f);
                  formik.setFieldValue("abns", cleanAbns);
                }}
                onBlur={formik.handleBlur}
                value={abnString}
                error={formik.touched.abns && Boolean(formik.errors.abns)}
                helperText={formik.touched.abns ? formik.errors.abns : ""}
                label={"ABNs (Comma separated)"}
              />
            </FormControl>
          </Grid>
          {errorMessage ? (
            <Grid item xs={12} sm={12}>
              <Typography paragraph color={"error"}>
                {errorMessage}
              </Typography>
            </Grid>
          ) : undefined}
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <StyledButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={loading || formik.isSubmitting}
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
