import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Button from "@mui/material/Button";
import React, { useState, useEffect } from "react";

interface CoolDownButtonProps {
  cooldownDuration: number;
  title: string;
  onResend: () => void;
  styles?: any;
}

export const CoolDownButton = ({
  cooldownDuration,
  onResend,
  styles,
  title,
}: CoolDownButtonProps) => {
  const [cooldown, setCooldown] = useState(cooldownDuration / 1000); // Convert milliseconds to seconds
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if (cooldown > 0 && disabled) {
      interval = setInterval(() => {
        setCooldown((prev) => prev - 1);
      }, 1000); // Update cooldown every second
    } else {
      clearInterval(interval);
      setDisabled(false);
    }

    return () => clearInterval(interval);
  }, [cooldown, disabled]);

  const handleClick = () => {
    if (!disabled) {
      onResend();
      setDisabled(true);
      setCooldown(cooldownDuration / 1000); // Reset cooldown
    }
  };

  return (
    <Button
      color="primary"
      size="large"
      onClick={handleClick}
      className="blackBtn"
      sx={{ color: "black", fontSize: "12px", ...styles }}
      disabled={disabled}
    >
      {disabled ? (
        <>
          <AccessTimeIcon /> {title} ({cooldown})
        </>
      ) : (
        title
      )}
    </Button>
  );
};
