import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import { GreenMetricCategoryLineItem } from "social-pro-common/interfaces/greenMetricCategory";

import { ActionCell, EditorCell } from "./GreenMetricFormTableRow";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

interface GreenMetricFormTableProps {
  loading: boolean;
  greenMetricCategories: GreenMetricCategoryLineItem[];
  setGreenMetricCategories: (
    greenMetricCategory: GreenMetricCategoryLineItem[],
  ) => void;
}

export const GreenMetricFormTable = ({
  greenMetricCategories,
  loading,
  setGreenMetricCategories,
}: GreenMetricFormTableProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<GreenMetricCategoryLineItem | null>(null);

  const onCellValueChanged = (params: {
    api: any;
    data: GreenMetricCategoryLineItem;
    colDef: { field: string };
    newValue: string;
  }) => {
    const updatedCategories = greenMetricCategories.map((item) =>
      item.id === params.data.id
        ? { ...item, [params.colDef.field]: params.newValue }
        : item,
    );
    setGreenMetricCategories(updatedCategories);
  };

  const onDelete = () => {
    if (!selectedCategory) return;
    const updatedCategories = greenMetricCategories.filter(
      (item) => item.id !== selectedCategory.id,
    );
    setGreenMetricCategories(updatedCategories);
    setOpenConfirmDialog(false);
  };

  const handleDelete = (category: GreenMetricCategoryLineItem) => {
    setSelectedCategory(category);
    setOpenConfirmDialog(true);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      cellRenderer: EditorCell,
      cellRendererParams: {
        placeholder: "e.g. Plant and Equipment",
      },
      editable: true,
      field: "targetCategory",
      headerClass: "left-table-header",
      headerName: "Target Category",
    },
    {
      ...defaultCellConfig,
      cellRenderer: EditorCell,
      cellRendererParams: {
        placeholder: "e.g. Diesel",
      },
      editable: true,
      field: "metricType",
      headerClass: "left-table-header",
      headerName: "Sub-Category",
    },
    {
      ...defaultCellConfig,
      cellRenderer: EditorCell,
      cellRendererParams: {
        placeholder: "e.g. Litres (L)",
      },
      editable: true,
      field: "targetType",
      headerClass: "left-table-header",
      headerName: "Metric Type",
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: {
        handleDelete,
        loading,
      },
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <>
      <Table<GreenMetricCategoryLineItem>
        columnDefs={colDefs}
        loading={loading}
        data={greenMetricCategories}
        onCellValueChanged={onCellValueChanged}
      />

      <ConfirmationDialog
        message={"Are you sure you want to remove this category?"}
        open={openConfirmDialog}
        title={"Delete Category"}
        buttonText={"Delete"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={onDelete}
      />
    </>
  );
};
