import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuList from "@stories/layout/Sidebar";
import { drawerWidth } from "constant";
import { useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import MiniDrawerStyled from "./MiniDrawerStyled";
import LogoSection from "../../MenuShared/LogoSection2";
import ProfileSection from "../../MenuShared/ProfileSection";
import { getNavMenuItems } from "../menu-items";
// third-party

interface SidebarProps {
  isDrawOpen: boolean;
  selectedItem: string[];
  setIsDrawOpen: (open: boolean) => void;
  setSelectedItem: (item: string[]) => void;
}

const Sidebar = ({
  isDrawOpen,
  selectedItem,
  setIsDrawOpen,
  setSelectedItem,
}: SidebarProps) => {
  const theme = useTheme();
  const { isAuthProfileLoading, userProfile } = useProfileContext();
  const { isProjectLoading } = useProjectContext();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const logo = useMemo(
    () => (
      <Box sx={{ display: "flex", p: 2 }}>
        <LogoSection />
      </Box>
    ),
    [],
  );

  const loading = isAuthProfileLoading || isProjectLoading;

  const menuItem = getNavMenuItems(userProfile);

  const drawerContent = (
    <MenuList
      loading={loading}
      menuItem={menuItem}
      title="Manage"
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
    />
  );

  const drawerSX = {
    background: "transparent",
    marginTop: isDrawOpen ? 0 : "120px",
    paddingLeft: isDrawOpen ? "16px" : 0,
    paddingRight: isDrawOpen ? "16px" : 0,
  };

  const drawer = matchDownMd ? (
    <Box sx={drawerSX}>{drawerContent}</Box>
  ) : (
    <PerfectScrollbar
      component="div"
      style={{
        height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 89px)",
        ...drawerSX,
      }}
    >
      {drawerContent}
      <div className="sidebar-prf">
        <ProfileSection />
      </div>
    </PerfectScrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
      className="sidebar"
    >
      {matchDownMd || isDrawOpen ? (
        <Drawer
          variant={matchUpMd ? "persistent" : "temporary"}
          anchor="left"
          open={isDrawOpen}
          onClose={() => setIsDrawOpen(!isDrawOpen)}
          sx={{
            "& .MuiDrawer-paper": {
              background: "transparent",
              borderRight: "none",
              color: "white",
              mt: matchDownMd ? 0 : 11,
              width: drawerWidth,
              zIndex: 1099,
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="white"
        >
          {matchDownMd && logo}
          {drawer}
        </Drawer>
      ) : (
        <MiniDrawerStyled variant="permanent" open={isDrawOpen}>
          {/* {drawer} */}
        </MiniDrawerStyled>
      )}
    </Box>
  );
};

export default Sidebar;
