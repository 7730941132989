"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeIntegration = exports.decodeIntegration = void 0;
const string_1 = require("../../utils/string");
const decodeIntegration = (integration) => {
    return {
        config: integration.config,
        created: integration.created,
        enabled: integration.enabled,
        id: integration.integration_id,
        integrationType: integration.integration_type,
        organisationId: integration.organisation_id,
    };
};
exports.decodeIntegration = decodeIntegration;
const encodeIntegration = (integration) => {
    const safeIntegration = (0, string_1.makePostgresSafe)(integration);
    return {
        config: safeIntegration.config,
        created: safeIntegration.created,
        enabled: safeIntegration.enabled,
        integration_id: safeIntegration.id,
        integration_type: safeIntegration.integrationType,
        organisation_id: safeIntegration.organisationId,
    };
};
exports.encodeIntegration = encodeIntegration;
