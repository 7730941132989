"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrganisationName = exports.createDefaultOrganisation = void 0;
const uuid_1 = require("uuid");
const contactInfo_1 = require("../contactInfo");
const createDefaultOrganisation = (email) => {
    const defaultContactInfo = (0, contactInfo_1.createDefaultContactInfo)();
    return {
        abn: "",
        alertNotifications: true,
        contactInfo: defaultContactInfo,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        isSetup: false,
        maxActiveProjects: 0,
        organisationLogo: "",
        organisationLogoSrc: "",
        organisationName: "",
        planType: "Free",
        representativeEmail: email || "",
        representativeName: "",
        stripeId: "",
        tradingName: "",
        users: [],
    };
};
exports.createDefaultOrganisation = createDefaultOrganisation;
const getOrganisationName = (org) => {
    if (org.organisationName.length > 0) {
        return org.organisationName;
    }
    return org.representativeEmail;
};
exports.getOrganisationName = getOrganisationName;
