"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeOrganisationDashboardTableItem = exports.decodeOrganisationDashboardCardModel = exports.decodeOrganisationDashboardModel = void 0;
const decodeOrganisationDashboardModel = (data) => {
    return {
        cards: data.cards.map(exports.decodeOrganisationDashboardCardModel),
        labourHours: data.labour_hours.map(exports.decodeOrganisationDashboardTableItem),
        localContent: data.local_content.map(exports.decodeOrganisationDashboardTableItem),
        socialSpend: data.social_spend.map(exports.decodeOrganisationDashboardTableItem),
    };
};
exports.decodeOrganisationDashboardModel = decodeOrganisationDashboardModel;
const decodeOrganisationDashboardCardModel = (data) => {
    return {
        id: data.id,
        pc: data.pc,
        title: data.title,
        value: data.value,
    };
};
exports.decodeOrganisationDashboardCardModel = decodeOrganisationDashboardCardModel;
const decodeOrganisationDashboardTableItem = (data) => {
    return {
        actual: data.actual,
        pcProgress: data.pc_progress,
        target: data.target,
        targetName: data.target_name,
    };
};
exports.decodeOrganisationDashboardTableItem = decodeOrganisationDashboardTableItem;
