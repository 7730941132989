"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringImportToOccupation = exports.occupationToBEPOccupationType = exports.occupationToString = exports.Occupation = exports.hammerTechEmployeeToEmployeeLineItem = exports.EployeeTabStatus = exports.isInvalidMPSG = exports.ageGroupToString = exports.createDefaultEmployee = exports.employeeLongtermUnemployedCategoryToString = exports.LongtermUnemployedCategory = exports.employeeDisabilityCategoryToString = exports.DisabilityCategory = exports.employeeAuxillaryInfomrationToDocumentDescription = exports.employeeSocialSpendFactorToDocumentDescription = exports.employeeSocialSpendFactorToDescription = exports.employeeSocialSpendFactorToColour = exports.employeeSocialSpendFactorToAbbreviatedString = exports.employeeSocialSpendFactorToSocialTarget = exports.employeeAuxillaryInfomrationToString = exports.employeeSocialFactorToString = exports.personWithDisabilityByState = exports.EmployeeAuxillaryInformation = exports.employmentLevelToDocumentDescription = exports.employmentLevelIsMpsg = exports.employmentLevelIsFemaleTraineeApprentice = exports.isDisadvantagedFactor = exports.isAboriginalOrTorresStraitIslander = exports.isDisabilityFactor = exports.isLongTermUnemployed = exports.traineeContractRequired = exports.employeeIsFemaleParticipation = exports.employmentTypeToString = exports.stringImportToEmploymentType = exports.contractTypeToString = exports.stringImportToContractType = exports.employmentLevelToString = exports.stringImportToEmploymentLevel = exports.occupationTypeOptionToString = exports.stringImportToOccupationType = exports.existingJobTypeToVMCString = exports.existingJobTypeToString = exports.stringImportToNewExistingJobType = exports.genderToString = exports.stringImportToGender = void 0;
const uuid_1 = require("uuid");
const contractorEmployee_1 = require("../../entities/contractorEmployee");
const packageLabourHourCommitment_1 = require("../packageLabourHourCommitment");
const stringImportToGender = (gender) => {
    if (!gender) {
        return undefined;
    }
    switch (gender.toLowerCase().trim()) {
        default:
            return undefined;
        case "male":
        case "man":
            return contractorEmployee_1.Gender.Male;
        case "female":
        case "woman":
            return contractorEmployee_1.Gender.Female;
        case "prefer not to disclose":
            return contractorEmployee_1.Gender.Other;
    }
};
exports.stringImportToGender = stringImportToGender;
const genderToString = (gender) => {
    switch (gender) {
        case contractorEmployee_1.Gender.Male:
            return "Man";
        case contractorEmployee_1.Gender.Female:
            return "Woman";
        case contractorEmployee_1.Gender.Other:
            return "Prefer not to disclose";
    }
};
exports.genderToString = genderToString;
const stringImportToNewExistingJobType = (newExistingJobType) => {
    if (!newExistingJobType) {
        return contractorEmployee_1.NewExistingJobType.Existing;
    }
    switch (newExistingJobType?.toLowerCase().trim()) {
        case "new":
            return contractorEmployee_1.NewExistingJobType.New;
        default:
        case "existing":
            return contractorEmployee_1.NewExistingJobType.Existing;
    }
};
exports.stringImportToNewExistingJobType = stringImportToNewExistingJobType;
const existingJobTypeToString = (newExistingJobType) => {
    switch (newExistingJobType) {
        case contractorEmployee_1.NewExistingJobType.New:
            return "New";
        case contractorEmployee_1.NewExistingJobType.Existing:
            return "Existing";
    }
};
exports.existingJobTypeToString = existingJobTypeToString;
const existingJobTypeToVMCString = (newExistingJobType) => {
    switch (newExistingJobType) {
        case contractorEmployee_1.NewExistingJobType.New:
            return "Created";
        case contractorEmployee_1.NewExistingJobType.Existing:
            return "Retained";
    }
};
exports.existingJobTypeToVMCString = existingJobTypeToVMCString;
const stringImportToOccupationType = (occupationTypeOption) => {
    if (!occupationTypeOption) {
        return undefined;
    }
    switch (occupationTypeOption.toLowerCase().trim()) {
        default:
            return undefined;
        case "trade role":
            return contractorEmployee_1.OccupationType.TradeRole;
        case "non-trade position":
            return contractorEmployee_1.OccupationType.NonTradePosition;
        case "management, supervisor & specialist labour roles":
            return contractorEmployee_1.OccupationType.ManagementSupervisorSpecialistLabourRoles;
        case "other":
            return contractorEmployee_1.OccupationType.Other;
    }
};
exports.stringImportToOccupationType = stringImportToOccupationType;
const occupationTypeOptionToString = (occupationTypeOption) => {
    switch (occupationTypeOption) {
        default:
            throw new Error(`Unknown occupation type: ${occupationTypeOption}`);
        case contractorEmployee_1.OccupationType.TradeRole:
            return "Trade role";
        case contractorEmployee_1.OccupationType.NonTradePosition:
            return "Non-trade position";
        case contractorEmployee_1.OccupationType.ManagementSupervisorSpecialistLabourRoles:
            return "Management, supervisor & specialist labour roles";
        case contractorEmployee_1.OccupationType.Other:
            return "Other";
    }
};
exports.occupationTypeOptionToString = occupationTypeOptionToString;
const stringImportToEmploymentLevel = (employmentLevel) => {
    if (!employmentLevel) {
        return undefined;
    }
    switch (employmentLevel.toLowerCase().trim()) {
        default:
            return undefined;
        case "full employment":
        case "standard":
        case "standard employment":
            return contractorEmployee_1.EmploymentLevel.FullEmployment;
        case "apprentice":
            return contractorEmployee_1.EmploymentLevel.Apprentice;
        case "trainee":
            return contractorEmployee_1.EmploymentLevel.Trainee;
        case "cadet":
            return contractorEmployee_1.EmploymentLevel.Cadet;
    }
};
exports.stringImportToEmploymentLevel = stringImportToEmploymentLevel;
const employmentLevelToString = (employmentLevel) => {
    switch (employmentLevel) {
        default:
            throw new Error(`Unknown employment level: ${employmentLevel}`);
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return "Standard";
        case contractorEmployee_1.EmploymentLevel.Apprentice:
            return "Apprentice";
        case contractorEmployee_1.EmploymentLevel.Trainee:
            return "Trainee";
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return "Cadet";
    }
};
exports.employmentLevelToString = employmentLevelToString;
const stringImportToContractType = (employmentLevel) => {
    if (!employmentLevel) {
        return undefined;
    }
    switch (employmentLevel.toLowerCase().trim()) {
        default:
            return undefined;
        case "full time":
        case "permanent/full time":
            return contractorEmployee_1.ContractType.PermanentFullTime;
        case "fixed term":
            return contractorEmployee_1.ContractType.FixedTerm;
        case "part time":
            return contractorEmployee_1.ContractType.PartTime;
        case "casual":
            return contractorEmployee_1.ContractType.Casual;
    }
};
exports.stringImportToContractType = stringImportToContractType;
const contractTypeToString = (contractType) => {
    switch (contractType) {
        default:
            throw new Error(`Unknown contract type: ${contractType}`);
        case contractorEmployee_1.ContractType.PermanentFullTime:
            return "Permanent/Full Time";
        case contractorEmployee_1.ContractType.FixedTerm:
            return "Fixed Term";
        case contractorEmployee_1.ContractType.PartTime:
            return "Part Time";
        case contractorEmployee_1.ContractType.Casual:
            return "Casual";
    }
};
exports.contractTypeToString = contractTypeToString;
const stringImportToEmploymentType = (employmentLevel) => {
    if (!employmentLevel) {
        return undefined;
    }
    switch (employmentLevel.toLowerCase().trim()) {
        default:
            return undefined;
        case "direct":
            return contractorEmployee_1.EmploymentType.Direct;
        case "labour hire":
            return contractorEmployee_1.EmploymentType.Indirect;
    }
};
exports.stringImportToEmploymentType = stringImportToEmploymentType;
const employmentTypeToString = (employmentType) => {
    switch (employmentType) {
        case contractorEmployee_1.EmploymentType.Direct:
            return "Direct";
        case contractorEmployee_1.EmploymentType.Indirect:
            return "Labour Hire";
    }
};
exports.employmentTypeToString = employmentTypeToString;
const employeeIsFemaleParticipation = (gender) => {
    switch (gender) {
        case contractorEmployee_1.Gender.Female:
            return true;
        case contractorEmployee_1.Gender.Other:
        case contractorEmployee_1.Gender.Male:
            return false;
    }
};
exports.employeeIsFemaleParticipation = employeeIsFemaleParticipation;
const traineeContractRequired = (employmentLevel) => {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return false;
        case contractorEmployee_1.EmploymentLevel.Cadet:
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
            return true;
    }
};
exports.traineeContractRequired = traineeContractRequired;
const isLongTermUnemployed = (socialFactors) => {
    return socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed);
};
exports.isLongTermUnemployed = isLongTermUnemployed;
const isDisabilityFactor = (socialFactors) => {
    return socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability);
};
exports.isDisabilityFactor = isDisabilityFactor;
const isAboriginalOrTorresStraitIslander = (socialFactors) => {
    return socialFactors.includes(contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander);
};
exports.isAboriginalOrTorresStraitIslander = isAboriginalOrTorresStraitIslander;
const isDisadvantagedFactor = (socialFactors) => {
    return socialFactors.length > 0;
};
exports.isDisadvantagedFactor = isDisadvantagedFactor;
const employmentLevelIsFemaleTraineeApprentice = (employmentLevel) => {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return false;
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
            return true;
    }
};
exports.employmentLevelIsFemaleTraineeApprentice = employmentLevelIsFemaleTraineeApprentice;
const employmentLevelIsMpsg = (employmentLevel) => {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return false;
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return true;
    }
};
exports.employmentLevelIsMpsg = employmentLevelIsMpsg;
const employmentLevelToDocumentDescription = (employmentLevel) => {
    switch (employmentLevel) {
        case contractorEmployee_1.EmploymentLevel.FullEmployment:
            return [];
        case contractorEmployee_1.EmploymentLevel.Apprentice:
        case contractorEmployee_1.EmploymentLevel.Trainee:
        case contractorEmployee_1.EmploymentLevel.Cadet:
            return ["Registered Training Contract with an approved body"];
    }
};
exports.employmentLevelToDocumentDescription = employmentLevelToDocumentDescription;
var EmployeeAuxillaryInformation;
(function (EmployeeAuxillaryInformation) {
    EmployeeAuxillaryInformation["IdentityDocument"] = "employee-social-procurement-factor_identity-document";
})(EmployeeAuxillaryInformation || (exports.EmployeeAuxillaryInformation = EmployeeAuxillaryInformation = {}));
const personWithDisabilityByState = (state) => {
    switch (state) {
        default:
            return "Person with disability (inclusive of mental illness)";
        case "VIC":
            return "Victorians with a disability";
    }
};
exports.personWithDisabilityByState = personWithDisabilityByState;
const employeeSocialFactorToString = (employeeSocialSpendFactor, state) => {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error(`Unknown employee social factor type: ${employeeSocialSpendFactor}`);
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "Ex-offender";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "Mature aged job seekers (50+)";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "Single parents";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "Social housing tenant";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "Living in regions experiencing entrenched disadvantage";
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "Aboriginal and/or Torres Strait Islander";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "Refugees and Asylum Seekers";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "Young people aged under 25";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "Long-term unemployed (unemployed for six months or more)";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return (0, exports.personWithDisabilityByState)(state);
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "Veterans and their families";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "Ex-offender";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "Jobseeker with a mental illness";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "Living in regions experiencing entrenched disadvantage";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "Mature aged job seekers (50+)";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "Single parents";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "Social housing tenant";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "Workers in transition (retrenched workers)";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "Young people in out-of-home care";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "Migrants from culturally diverse communities";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "Women aged 45 years and over";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "Newly arrived migrants from non-English speaking backgrounds";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "Job seekers registered with Jobs Victoria Mentor Service";
    }
};
exports.employeeSocialFactorToString = employeeSocialFactorToString;
const employeeAuxillaryInfomrationToString = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error(`Unknown supporting document type: ${employeeSocialSpendFactor}`);
        case EmployeeAuxillaryInformation.IdentityDocument:
            return "Identity Document";
    }
};
exports.employeeAuxillaryInfomrationToString = employeeAuxillaryInfomrationToString;
const employeeSocialSpendFactorToSocialTarget = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error(`Unknown employee social factor type: ${employeeSocialSpendFactor}`);
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation;
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return packageLabourHourCommitment_1.SocialCommitmentTypeHour.VictorianPriorityWorkforce;
    }
};
exports.employeeSocialSpendFactorToSocialTarget = employeeSocialSpendFactorToSocialTarget;
const employeeSocialSpendFactorToAbbreviatedString = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error(`Unknown employee social factor type: ${employeeSocialSpendFactor}`);
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "IP";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "ASR";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "DY";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "LTE";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return "PDMI";
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "ADFV";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "PAFV";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "EO";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "JWAMI";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "LIRE";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "MAJS";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "SP";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "SHT";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "WITRW";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "YPOHC";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "CDM";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "WO45";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "NAMFNESB";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "JSRWJVMS";
    }
};
exports.employeeSocialSpendFactorToAbbreviatedString = employeeSocialSpendFactorToAbbreviatedString;
const employeeSocialSpendFactorToColour = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error(`Unknown employee social factor type: ${employeeSocialSpendFactor}`);
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "#F44336";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "#1565C0";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "#1565C0";
        // case EmployeeSocialFactor.FemaleParticipation:
        //   return "#15cf74";
        // case EmployeeSocialFactor.MPSGEmploymentLevel:
        //   return "#3f99fa";
        // case EmployeeSocialFactor.LocalJobsFirst:
        //   return "#90CAF9";
        // case EmployeeSocialFactor.FemaleTraineeApprentice:
        //   return "#28A745";
    }
};
exports.employeeSocialSpendFactorToColour = employeeSocialSpendFactorToColour;
const employeeSocialSpendFactorToDescription = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error(`Unknown employee social factor type: ${employeeSocialSpendFactor}`);
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "Aboriginal or Torres-Strait Islander";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "Asylum Seeker/Refugee";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "Disengaged youth - aged between 15-24";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "Long-term unemployed";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return "Person with disability (inclusive of mental illness)";
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "Veteran (ADF or Allied Forces)";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "A person who has previously been convicted of, or plead guilty to, a criminal offence who is not currently incarcerated.";
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return "Jobseeker with a mental illness";
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return "Living in regions experiencing entrenched disadvantage";
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return "Mature aged job seeker";
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return "Single parents";
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return "Social housing tenant";
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return "Workers in transition (retrenched workers)";
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return "Young people in out-of-home care";
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return "Migrants from culturally diverse communities";
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return "Women Over 45";
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return "Newly arrived migrants from non-English speaking backgrounds";
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return "Job seekers registered with Jobs Victoria Mentor Service";
    }
};
exports.employeeSocialSpendFactorToDescription = employeeSocialSpendFactorToDescription;
const employeeSocialSpendFactorToDocumentDescription = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            throw new Error(`Unknown employee social factor type: ${employeeSocialSpendFactor}`);
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return ["Documentation from Employment Program Provider"];
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return ["Certificate of Aboriginality"];
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return [
                "Permanent Protection Visa (PPV)",
                "Temporary Protection Visa (TPV)",
                "Safe Haven Enterprise Visa (SHEV)",
            ];
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return [
                "Copy of Disengaged Youth and Long-term Welfare payments made to the individual",
            ];
        // case EmployeeSocialFactor.LongTermUnemployed:
        //   return ["Documentation from Employment Program Provider"];
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return ["National Assistance Card"];
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return [
                "Certificate of Service",
                "Certificate of Discharge",
                "Extracts of Service Records",
            ];
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.JobseekerWithAMentalIllness:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.MatureAgedJobSeeker:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.SingleParent:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.SocialHousingTenant:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.YoungPeopleInOutOfHomeCare:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.CulturallyDiverseMigrant:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.WomenOver45:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.NewlyArrivedMigrantsFromNonEnglishSpeakingBackgrounds:
            return ["Valid documentation"];
        case contractorEmployee_1.EmployeeSocialFactor.JobSeekersRegisteredWithJobsVictoriaMentorService:
            return ["Valid documentation"];
    }
};
exports.employeeSocialSpendFactorToDocumentDescription = employeeSocialSpendFactorToDocumentDescription;
const employeeAuxillaryInfomrationToDocumentDescription = (employeeAusillaryInforation) => {
    switch (employeeAusillaryInforation) {
        default:
            throw new Error(`Unknown supporting document type: ${employeeAusillaryInforation}`);
        case EmployeeAuxillaryInformation.IdentityDocument:
            return ["Proof of address"];
    }
};
exports.employeeAuxillaryInfomrationToDocumentDescription = employeeAuxillaryInfomrationToDocumentDescription;
//Disability Program Provider
var DisabilityCategory;
(function (DisabilityCategory) {
    DisabilityCategory["DisabilityEnterpriseServices"] = "disability-category_disability-enterprise-sevices";
    DisabilityCategory["Other"] = "disability-category_other";
})(DisabilityCategory || (exports.DisabilityCategory = DisabilityCategory = {}));
const employeeDisabilityCategoryToString = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            return "";
        case DisabilityCategory.DisabilityEnterpriseServices:
            return "Disability Enterprise Services";
        case DisabilityCategory.Other:
            return "Other";
    }
};
exports.employeeDisabilityCategoryToString = employeeDisabilityCategoryToString;
//Employment Program Provider
var LongtermUnemployedCategory;
(function (LongtermUnemployedCategory) {
    LongtermUnemployedCategory["WorkforceAustralia"] = "long-term-unemployed-category_workforce-austrailia";
    LongtermUnemployedCategory["Other"] = "long-term-unemployed-category_other";
})(LongtermUnemployedCategory || (exports.LongtermUnemployedCategory = LongtermUnemployedCategory = {}));
const employeeLongtermUnemployedCategoryToString = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            return "";
        case LongtermUnemployedCategory.WorkforceAustralia:
            return "Workforce Australia";
        case LongtermUnemployedCategory.Other:
            return "Other";
    }
};
exports.employeeLongtermUnemployedCategoryToString = employeeLongtermUnemployedCategoryToString;
const createDefaultEmployee = (contractor) => {
    return {
        archived: false,
        contractorId: contractor.id,
        contractType: contractorEmployee_1.ContractType.PermanentFullTime,
        country: "AU",
        created: new Date(),
        employeeName: "",
        employerAbn: contractor.abn,
        employmentLevel: contractorEmployee_1.EmploymentLevel.FullEmployment,
        employmentType: contractorEmployee_1.EmploymentType.Direct,
        gender: contractorEmployee_1.Gender.Male,
        geoLocationLat: -1,
        geoLocationLong: -1,
        id: (0, uuid_1.v4)(),
        newExistingJob: contractorEmployee_1.NewExistingJobType.Existing,
        occupation: Occupation.AccountantGeneral,
        occupationType: contractorEmployee_1.OccupationType.TradeRole,
        postCode: "",
        primaryEmployer: contractor.organisationName,
        socialFactors: [],
        supportingDocuments: [],
    };
};
exports.createDefaultEmployee = createDefaultEmployee;
const ageGroupToString = (ageGroup) => {
    switch (ageGroup) {
        default:
            throw new Error(`Unknown age group: ${ageGroup}`);
        case contractorEmployee_1.AgeGroup.FifteenToTwentyFour:
            return "15-24";
        case contractorEmployee_1.AgeGroup.TwentyFiveToFourtyFour:
            return "25-44";
        case contractorEmployee_1.AgeGroup.FortyFiveToFourtyNine:
            return "45-49";
        case contractorEmployee_1.AgeGroup.FiftyPlus:
            return "50+";
    }
};
exports.ageGroupToString = ageGroupToString;
const isInvalidMPSG = (employee) => {
    return (!employee.trainingContractNumber &&
        !employee.qualification &&
        !employee.traineeContractEndDate &&
        !employee.educationalInstitute);
};
exports.isInvalidMPSG = isInvalidMPSG;
var EployeeTabStatus;
(function (EployeeTabStatus) {
    EployeeTabStatus["Active"] = "ACTIVE";
    EployeeTabStatus["Archived"] = "ARCHIVED";
})(EployeeTabStatus || (exports.EployeeTabStatus = EployeeTabStatus = {}));
const hammerTechEmployeeToEmployeeLineItem = (htEmployee, organisation) => {
    return {
        archived: false,
        contractorId: organisation.id,
        contractType: contractorEmployee_1.ContractType.PermanentFullTime,
        country: htEmployee.country,
        created: new Date(),
        dob: new Date(),
        educationalInstitute: "",
        employeeIdentifier: htEmployee.internalIdentifier,
        employeeName: `${htEmployee.firstName} ${htEmployee.lastName}`,
        employerAbn: organisation.abn,
        employmentLevel: contractorEmployee_1.EmploymentLevel.FullEmployment,
        employmentType: contractorEmployee_1.EmploymentType.Direct,
        gender: contractorEmployee_1.Gender.Male,
        geoLocationLat: -1,
        geoLocationLong: -1,
        id: htEmployee.uniqueCode,
        newExistingJob: contractorEmployee_1.NewExistingJobType.Existing,
        occupation: Occupation.AccountantGeneral,
        occupationType: contractorEmployee_1.OccupationType.TradeRole,
        postCode: htEmployee.postcode,
        primaryEmployer: organisation.organisationName,
        qualification: "",
        socialFactors: [],
        supportingDocuments: [],
        traineeContractEndDate: new Date(),
        trainingContractNumber: "",
        uniqueStudentId: "",
    };
};
exports.hammerTechEmployeeToEmployeeLineItem = hammerTechEmployeeToEmployeeLineItem;
var Occupation;
(function (Occupation) {
    Occupation["AccountantGeneral"] = "occupation_accountant-general";
    Occupation["AccountantsAuditorsAndCompanySecretaries"] = "occupation_accountants-auditors-and-company-secretaries-nfd";
    Occupation["AccountsClerk"] = "occupation_accounts-clerk";
    Occupation["AdvertisingPublicRelationsAndSalesManagers"] = "occupation_advertising-public-relations-and-sales-managers-nfd";
    Occupation["AgriculturalEngineer"] = "occupation_agricultural-engineer";
    Occupation["AirconditioningAndMechanicalServicesPlumber"] = "occupation_airconditioning-and-mechanical-services-plumber";
    Occupation["AirconditioningAndRefrigerationMechanic"] = "occupation_airconditioning-and-refrigeration-mechanic";
    Occupation["Arborist"] = "occupation_arborist";
    Occupation["Architect"] = "occupation_architect";
    Occupation["ArchitectsAndLAndscapeArchitects"] = "occupation_architects-and-landscape-architects-nfd";
    Occupation["ArchitectsDesignersPlannersAndSurveyors"] = "occupation_architects-designers-planners-and-surveyors-nfd";
    Occupation["ArchitecturalDraftsperson"] = "occupation_architectural-draftsperson";
    Occupation["ArchitecturalBuildingAndSurveyingTechnicians"] = "occupation_architectural-building-and-surveying-technicians-nfd";
    Occupation["AuditorsCompanySecretariesAndCorporateTreasurers"] = "occupation_auditors-company-secretaries-and-corporate-treasurers-nfd";
    Occupation["AutomotiveElectrician"] = "occupation_automotive-electrician";
    Occupation["BackhoeOperator"] = "occupation_backhoe-operator";
    Occupation["BoilerorEngineOperator"] = "occupation_boiler-or-engine-operator";
    Occupation["BookKeeper"] = "occupation_bookkeeper";
    Occupation["Bricklayer"] = "occupation_bricklayer";
    Occupation["BuildersLabourer"] = "occupation_builders-labourer";
    Occupation["BuildingAndEngineeringTechnicians"] = "occupation_building-and-engineering-technicians-nfd";
    Occupation["BuildingAndPlumbingLabourers"] = "occupation_building-and-plumbing-labourers-nfd";
    Occupation["BuildingAssociate"] = "occupation_building-associate";
    Occupation["BuildingInspector"] = "occupation_building-inspector";
    Occupation["BuildingInsulationInstaller"] = "occupation_building-insulation-installer";
    Occupation["BulkMaterialsHAndlingPlantOperator"] = "occupation_bulk-materials-handling-plant-operator";
    Occupation["BulldozerOperator"] = "occupation_bulldozer-operator";
    Occupation["BusinessAdministrationManagers"] = "occupation_business-administration-managers-nfd";
    Occupation["BusinessHumanResourceAndMarketingProfessionals"] = "occupation_business-human-resource-and-marketing-professionals-nfd";
    Occupation["CabinetMaker"] = "occupation_cabinetmaker";
    Occupation["CablerDataAndTelecommunications"] = "occupation_cabler-data-and-telecommunications";
    Occupation["Carpenter"] = "occupation_carpenter";
    Occupation["CarpenterAndJoiner"] = "occupation_carpenter-and-joiner";
    Occupation["CementProductionPlantOperator"] = "occupation_cement-production-plant-operator";
    Occupation["ChemicalAndMaterialsEngineers"] = "occupation_chemical-and-materials-engineers-nfd";
    Occupation["ChemicalEngineer"] = "occupation_chemical-engineer";
    Occupation["ChiefExecutiveorManagingDirector"] = "occupation_chief-executive-or-managing-director";
    Occupation["ChiefExecutivesGeneralManagersAndLegislators"] = "occupation_chief-executives-general-managers-and-legislators-nfd";
    Occupation["CivilEngineer"] = "occupation_civil-engineer";
    Occupation["CivilEngineeringDraftsperson"] = "occupation_civil-engineering-draftsperson";
    Occupation["CivilEngineeringProfessionals"] = "occupation_civil-engineering-professionals-nfd";
    Occupation["CivilEngineeringTechnician"] = "occupation_civil-engineering-technician";
    Occupation["ClayProductsMachineOperator"] = "occupation_clay-products-machine-operator";
    Occupation["ClayConcreteGlassAndStoneProcessingMachineOperators"] = "occupation_clay-concrete-glass-and-stone-processing-machine-operators-nfd";
    Occupation["ClericalAndAdministrativeWorkers"] = "occupation_clerical-and-administrative-workers-nfd";
    Occupation["ClericalAndOfficeSupportWorkers"] = "occupation_clerical-and-office-support-workers-nfd";
    Occupation["CommercialCleaner"] = "occupation_commercial-cleaner";
    Occupation["ConcreteBatchingPlantOperator"] = "occupation_concrete-batching-plant-operator";
    Occupation["ConcreteProductsMachineOperator"] = "occupation_concrete-products-machine-operator";
    Occupation["ConcretePumpOperator"] = "occupation_concrete-pump-operator";
    Occupation["Concreter"] = "occupation_concreter";
    Occupation["ConstructionAndMiningLabourers"] = "occupation_construction-and-mining-labourers-nfd";
    Occupation["ConstructionEstimator"] = "occupation_construction-estimator";
    Occupation["ConstructionManagers"] = "occupation_construction-managers-nfd";
    Occupation["ConstructionProjectManager"] = "occupation_construction-project-manager";
    Occupation["ConstructionRigger"] = "occupation_construction-rigger";
    Occupation["ConstructionTradesWorkers"] = "occupation_construction-trades-workers-nfd";
    Occupation["ConstructionDistributionAndProductionManagers"] = "occupation_construction-distribution-and-production-managers-nfd";
    Occupation["ContractAdministrator"] = "occupation_contract-administrator";
    Occupation["CorporateGeneralManager"] = "occupation_corporate-general-manager";
    Occupation["CorporateServicesManager"] = "occupation_corporate-services-manager";
    Occupation["CraneChaser"] = "occupation_crane-chaser";
    Occupation["CraneHoistorLiftOperator"] = "occupation_crane-hoist-or-lift-operator";
    Occupation["DataEntryOperator"] = "occupation_data-entry-operator";
    Occupation["DesignEngineeringScienceAndTransportProfessionals"] = "occupation_design-engineering-science-and-transport-professionals-nfd";
    Occupation["DieselMotorMechanic"] = "occupation_diesel-motor-mechanic";
    Occupation["DrainageSewerageAndStormwaterLabourer"] = "occupation_drainage-sewerage-and-stormwater-labourer";
    Occupation["Drainer"] = "occupation_drainer";
    Occupation["Driller"] = "occupation_driller";
    Occupation["DrillersAssistant"] = "occupation_drillers-assistant";
    Occupation["EarthmovingLabourer"] = "occupation_earthmoving-labourer";
    Occupation["EarthmovingPlantOperatorGeneral"] = "occupation_earthmoving-plant-operator-general";
    Occupation["ElectricalDistributionTradesWorkers"] = "occupation_electrical-distribution-trades-workers-nfd";
    Occupation["ElectricalEngineer"] = "occupation_electrical-engineer";
    Occupation["ElectricalEngineeringDraftsperson"] = "occupation_electrical-engineering-draftsperson";
    Occupation["ElectricalEngineeringTechnician"] = "occupation_electrical-engineering-technician";
    Occupation["ElectricalLinesworker"] = "occupation_electrical-linesworker";
    Occupation["ElectricianGeneral"] = "occupation_electrician-general";
    Occupation["ElectricianSpecialClass"] = "occupation_electrician-special-class";
    Occupation["ElectronicEngineeringDraftsperson"] = "occupation_electronic-engineering-draftsperson";
    Occupation["ElectronicEngineeringTechnician"] = "occupation_electronic-engineering-technician";
    Occupation["ElectronicEquipmentTradesWorker"] = "occupation_electronic-equipment-trades-worker";
    Occupation["ElectronicInstrumentTradesWorkerGeneral"] = "occupation_electronic-instrument-trades-worker-general";
    Occupation["ElectronicsEngineer"] = "occupation_electronics-engineer";
    Occupation["ElectronicsTradesWorkers"] = "occupation_electronics-trades-workers-nfd";
    Occupation["ElectrotechnologyAndTelecommunicationsTradesWorkers"] = "occupation_electrotechnology-and-telecommunications-trades-workers-nfd";
    Occupation["EngineeringManager"] = "occupation_engineering-manager";
    Occupation["EngineeringProductionWorker"] = "occupation_engineering-production-worker";
    Occupation["EngineeringProfessionals"] = "occupation_engineering-professionals-nfd";
    Occupation["EngineeringTechnologist"] = "occupation_engineering-technologist";
    Occupation["EngineeringICTAndScienceTechnicians"] = "occupation_engineering-ict-and-science-technicians-nfd";
    Occupation["EnvironmentalConsultant"] = "occupation_environmental-consultant";
    Occupation["EnvironmentalEngineer"] = "occupation_environmental-engineer";
    Occupation["EnvironmentalManager"] = "occupation_environmental-manager";
    Occupation["EnvironmentalResearchScientist"] = "occupation_environmental-research-scientist";
    Occupation["ExcavatorOperator"] = "occupation_excavator-operator";
    Occupation["FacilitiesAdministrator"] = "occupation_facilities-administrator";
    Occupation["Fencer"] = "occupation_fencer";
    Occupation["FibrousPlasterer"] = "occupation_fibrous-plasterer";
    Occupation["FinanceManager"] = "occupation_finance-manager";
    Occupation["FireProtectionEquipmentTechnician"] = "occupation_fire-protection-equipment-technician";
    Occupation["FitterGeneral"] = "occupation_fitter-general";
    Occupation["FitterAndTurner"] = "occupation_fitter-and-turner";
    Occupation["FitterWelder"] = "occupation_fitter-welder";
    Occupation["FloorFinisher"] = "occupation_floor-finisher";
    Occupation["ForkliftDriver"] = "occupation_forklift-driver";
    Occupation["GardenerGeneral"] = "occupation_gardener-general";
    Occupation["Gasfitter"] = "occupation_gasfitter";
    Occupation["GeneralManagers"] = "occupation_general-managers-nfd";
    Occupation["Geologist"] = "occupation_geologist";
    Occupation["GeotechnicalEngineer"] = "occupation_geotechnical-engineer";
    Occupation["GlassProductionMachineOperator"] = "occupation_glass-production-machine-operator";
    Occupation["Glazier"] = "occupation_glazier";
    Occupation["GraderOperator"] = "occupation_grader-operator";
    Occupation["HumanResourceAdviser"] = "occupation_human-resource-adviser";
    Occupation["HumanResourceAndTrainingProfessionals"] = "occupation_human-resource-and-training-professionals-nfd";
    Occupation["HumanResourceClerk"] = "occupation_human-resource-clerk";
    Occupation["HumanResourceManager"] = "occupation_human-resource-manager";
    Occupation["ICTBusinessAndSystemsAnalysts"] = "occupation_ict-business-and-systems-analysts-nfd";
    Occupation["ICTManagers"] = "occupation_ict-managers-nfd";
    Occupation["ICTProfessionals"] = "occupation_ict-professionals-nfd";
    Occupation["ICTSecuritySpecialist"] = "occupation_ict-security-specialist";
    Occupation["ICTSupportAndTestEngineers"] = "occupation_ict-support-and-test-engineers-nfd";
    Occupation["IndustrialEngineer"] = "occupation_industrial-engineer";
    Occupation["IndustrialSpraypainter"] = "occupation_industrial-spraypainter";
    Occupation["IndustrialMechanicalAndProductionEngineers"] = "occupation_industrial-mechanical-and-production-engineers-nfd";
    Occupation["InspectorsAndRegulatoryOfficers"] = "occupation_inspectors-and-regulatory-officers-nfd";
    Occupation["InteriorDesigner"] = "occupation_interior-designer";
    Occupation["Joiner"] = "occupation_joiner";
    Occupation["Labourers"] = "occupation_labourers-nfd";
    Occupation["Lagger"] = "occupation_lagger";
    Occupation["LandscapeArchitect"] = "occupation_landscape-architect";
    Occupation["LiftMechanic"] = "occupation_lift-mechanic";
    Occupation["Linemarker"] = "occupation_linemarker";
    Occupation["LoaderOperator"] = "occupation_loader-operator";
    Occupation["Locksmith"] = "occupation_locksmith";
    Occupation["LogisticsClerks"] = "occupation_logistics-clerks-nfd";
    Occupation["MachineAndStationaryPlantOperators"] = "occupation_machine-and-stationary-plant-operators-nfd";
    Occupation["MachineryOperatorsAndDrivers"] = "occupation_machinery-operators-and-drivers-nfd";
    Occupation["ManagementAccountant"] = "occupation_management-accountant";
    Occupation["Managers"] = "occupation_managers-nfd";
    Occupation["MaterialsEngineer"] = "occupation_materials-engineer";
    Occupation["MechanicalEngineer"] = "occupation_mechanical-engineer";
    Occupation["MechanicalEngineeringDraftsperson"] = "occupation_mechanical-engineering-draftsperson";
    Occupation["MechanicalEngineeringTechnician"] = "occupation_mechanical-engineering-technician";
    Occupation["MechanicalEngineeringTradesWorkers"] = "occupation_mechanical-engineering-trades-workers-nfd";
    Occupation["MediaProfessionals"] = "occupation_media-professionals-nfd";
    Occupation["MetalCastingTradesWorker"] = "occupation_metal-casting-trades-worker";
    Occupation["MetalCastingForgingAndFinishingTradesWorkers"] = "occupation_metal-casting-forging-and-finishing-trades-workers-nfd";
    Occupation["MetalEngineeringProcessWorker"] = "occupation_metal-engineering-process-worker";
    Occupation["MetalFabricator"] = "occupation_metal-fabricator";
    Occupation["MetalFittersAndMachinists"] = "occupation_metal-fitters-and-machinists-nfd";
    Occupation["MetalMachinistFirstClass"] = "occupation_metal-machinist-first-class";
    Occupation["MetalPolisher"] = "occupation_metal-polisher";
    Occupation["MobilePlantOperators"] = "occupation_mobile-plant-operators-nfd";
    Occupation["MotorMechanicGeneral"] = "occupation_motor-mechanic-general";
    Occupation["MultimediaDesigner"] = "occupation_multimedia-designer";
    Occupation["MultimediaSpecialistsAndWebDevelopers"] = "occupation_multimedia-specialists-and-web-developers-nfd";
    Occupation["OccupationalHealthAndSafetyAdviser"] = "occupation_occupational-health-and-safety-adviser";
    Occupation["OtherSpatialScientist"] = "occupation_other-spatial-scientist";
    Occupation["OtherConstructionAndMiningLabourers"] = "occupation_other-construction-and-mining-labourers-nfd";
    Occupation["OtherWoodProcessingMachineOperator"] = "occupation_other-wood-processing-machine-operator";
    Occupation["PaintingTradesWorker"] = "occupation_painting-trades-worker";
    Occupation["PavingAndSurfacingLabourer"] = "occupation_paving-and-surfacing-labourer";
    Occupation["PavingPlantOperator"] = "occupation_paving-plant-operator";
    Occupation["PayrollClerk"] = "occupation_payroll-clerk";
    Occupation["Plasterers"] = "occupation_plasterers-nfd";
    Occupation["PlasticsTechnician"] = "occupation_plastics-technician";
    Occupation["PlumberGeneral"] = "occupation_plumber-general";
    Occupation["PlumbersAssistant"] = "occupation_plumbers-assistant";
    Occupation["PlumbingInspector"] = "occupation_plumbing-inspector";
    Occupation["PolicyAndPlanningManager"] = "occupation_policy-and-planning-manager";
    Occupation["PrecisionMetalTradesWorkers"] = "occupation_precision-metal-trades-workers-nfd";
    Occupation["PressureWelder"] = "occupation_pressure-welder";
    Occupation["Professionals"] = "occupation_professionals-nfd";
    Occupation["ProgramOrProjectAdministrator"] = "occupation_program-or-project-administrator";
    Occupation["ProjectBuilder"] = "occupation_project-builder";
    Occupation["PublicRelationsManager"] = "occupation_public-relations-manager";
    Occupation["PublicRelationsProfessional"] = "occupation_public-relations-professional";
    Occupation["PurchasingAndSupplyLogisticsClerks"] = "occupation_purchasing-and-supply-logistics-clerks-nfd";
    Occupation["PurchasingOfficer"] = "occupation_purchasing-officer";
    Occupation["QualityAssuranceManager"] = "occupation_quality-assurance-manager";
    Occupation["QuantitySurveyor"] = "occupation_quantity-surveyor";
    Occupation["RailwaySignalOperator"] = "occupation_railway-signal-operator";
    Occupation["RailwayTrackPlantOperator"] = "occupation_railway-track-plant-operator";
    Occupation["RailwayTrackWorker"] = "occupation_railway-track-worker";
    Occupation["RecruitmentConsultant"] = "occupation_recruitment-consultant";
    Occupation["ResearchAndDevelopmentManager"] = "occupation_research-and-development-manager";
    Occupation["RoadRollerOperator"] = "occupation_road-roller-operator";
    Occupation["RoadTrafficController"] = "occupation_road-traffic-controller";
    Occupation["RoofPlumber"] = "occupation_roof-plumber";
    Occupation["RoofTiler"] = "occupation_roof-tiler";
    Occupation["SafetyInspector"] = "occupation_safety-inspector";
    Occupation["SalesMarketingAndPublicRelationsProfessionals"] = "occupation_sales-marketing-and-public-relations-professionals-nfd";
    Occupation["SandBlaster"] = "occupation_sand-blaster";
    Occupation["SawMillOrTimberYardWorker"] = "occupation_sawmill-or-timber-yard-worker";
    Occupation["SawMillingOperator"] = "occupation_sawmilling-operator";
    Occupation["Scaffolder"] = "occupation_scaffolder";
    Occupation["SecretaryGeneral"] = "occupation_secretary-general";
    Occupation["SheetmetalTradesWorker"] = "occupation_sheetmetal-trades-worker";
    Occupation["SignErector"] = "occupation_sign-erector";
    Occupation["SoftwareAndApplicationsProgrammers"] = "occupation_software-and-applications-programmers-nfd";
    Occupation["Solicitor"] = "occupation_solicitor";
    Occupation["SolidPlasterer"] = "occupation_solid-plasterer";
    Occupation["SpecialistManagers"] = "occupation_specialist-managers-nfd";
    Occupation["StationaryPlantOperators"] = "occupation_stationary-plant-operators-nfd";
    Occupation["SteelFixer"] = "occupation_steel-fixer";
    Occupation["StoneProcessingMachineOperator"] = "occupation_stone-processing-machine-operator";
    Occupation["Stonemason"] = "occupation_stonemason";
    Occupation["StreetSweeperOperator"] = "occupation_streetsweeper-operator";
    Occupation["StructuralEngineer"] = "occupation_structural-engineer";
    Occupation["StructuralSteelAndWeldingTradesWorkers"] = "occupation_structural-steel-and-welding-trades-workers-nfd";
    Occupation["StructuralSteelConstructionWorkers"] = "occupation_structural-steel-construction-workers-nfd";
    Occupation["StructuralSteelErector"] = "occupation_structural-steel-erector";
    Occupation["SupplyAndDistributionManager"] = "occupation_supply-and-distribution-manager";
    Occupation["SurveyingorSpatialScienceTechnician"] = "occupation_surveying-or-spatial-science-technician";
    Occupation["Surveyor"] = "occupation_surveyor";
    Occupation["SurveyorsAssistant"] = "occupation_surveyors-assistant";
    Occupation["TankerDriver"] = "occupation_tanker-driver";
    Occupation["TaxationAccountant"] = "occupation_taxation-accountant";
    Occupation["TechnicalCableJointer"] = "occupation_technical-cable-jointer";
    Occupation["TechniciansAndTradesWorkers"] = "occupation_technicians-and-trades-workers-nfd";
    Occupation["TelecommunicationsCableJointer"] = "occupation_telecommunications-cable-jointer";
    Occupation["TelecommunicationsEngineer"] = "occupation_telecommunications-engineer";
    Occupation["TelecommunicationsEngineeringProfessionals"] = "occupation_telecommunications-engineering-professionals-nfd";
    Occupation["TelecommunicationsLinesworker"] = "occupation_telecommunications-linesworker";
    Occupation["TelecommunicationsNetworkEngineer"] = "occupation_telecommunications-network-engineer";
    Occupation["TowTruckDriver"] = "occupation_tow-truck-driver";
    Occupation["TransportEngineer"] = "occupation_transport-engineer";
    Occupation["TruckDriverGeneral"] = "occupation_truck-driver-general";
    Occupation["UrbanAndRegionalPlanner"] = "occupation_urban-and-regional-planner";
    Occupation["WallAndFloorTiler"] = "occupation_wall-and-floor-tiler";
    Occupation["WelderFirstClass"] = "occupation_welder-first-class";
    Occupation["WoodAndWoodProductsFactoryWorker"] = "occupation_wood-and-wood-products-factory-worker";
    Occupation["WoodTradesWorkers"] = "occupation_wood-trades-workers-nfd";
    Occupation["WorkplaceRelationsAdviser"] = "occupation_workplace-relations-adviser";
})(Occupation || (exports.Occupation = Occupation = {}));
const occupationToString = (occupation) => {
    switch (occupation) {
        case Occupation.AccountantGeneral:
            return "Accountant (General)";
        case Occupation.AccountantsAuditorsAndCompanySecretaries:
            return "Accountants, Auditors and Company Secretaries nfd";
        case Occupation.AccountsClerk:
            return "Accounts Clerk";
        case Occupation.AdvertisingPublicRelationsAndSalesManagers:
            return "Advertising, Public Relations and Sales Managers nfd";
        case Occupation.AgriculturalEngineer:
            return "Agricultural Engineer";
        case Occupation.AirconditioningAndMechanicalServicesPlumber:
            return "Airconditioning and Mechanical Services Plumber";
        case Occupation.AirconditioningAndRefrigerationMechanic:
            return "Airconditioning and Refrigeration Mechanic";
        case Occupation.Arborist:
            return "Arborist";
        case Occupation.Architect:
            return "Architect";
        case Occupation.ArchitectsAndLAndscapeArchitects:
            return "Architects and Landscape Architects nfd";
        case Occupation.ArchitectsDesignersPlannersAndSurveyors:
            return "Architects, Designers, Planners and Surveyors nfd";
        case Occupation.ArchitecturalDraftsperson:
            return "Architectural Draftsperson";
        case Occupation.ArchitecturalBuildingAndSurveyingTechnicians:
            return "Architectural, Building and Surveying Technicians nfd";
        case Occupation.AuditorsCompanySecretariesAndCorporateTreasurers:
            return "Auditors, Company Secretaries and Corporate Treasurers nfd";
        case Occupation.AutomotiveElectrician:
            return "Automotive Electrician";
        case Occupation.BackhoeOperator:
            return "Backhoe Operator";
        case Occupation.BoilerorEngineOperator:
            return "Boiler or Engine Operator";
        case Occupation.BookKeeper:
            return "Bookkeeper";
        case Occupation.Bricklayer:
            return "Bricklayer";
        case Occupation.BuildersLabourer:
            return "Builder's Labourer";
        case Occupation.BuildingAndEngineeringTechnicians:
            return "Building and Engineering Technicians nfd";
        case Occupation.BuildingAndPlumbingLabourers:
            return "Building and Plumbing Labourers nfd";
        case Occupation.BuildingAssociate:
            return "Building Associate";
        case Occupation.BuildingInspector:
            return "Building Inspector";
        case Occupation.BuildingInsulationInstaller:
            return "Building Insulation Installer";
        case Occupation.BulkMaterialsHAndlingPlantOperator:
            return "Bulk Materials Handling Plant Operator";
        case Occupation.BulldozerOperator:
            return "Bulldozer Operator";
        case Occupation.BusinessAdministrationManagers:
            return "Business Administration Managers nfd";
        case Occupation.BusinessHumanResourceAndMarketingProfessionals:
            return "Business, Human Resource and Marketing Professionals nfd";
        case Occupation.CabinetMaker:
            return "Cabinetmaker";
        case Occupation.CablerDataAndTelecommunications:
            return "Cabler (Data and Telecommunications)";
        case Occupation.Carpenter:
            return "Carpenter";
        case Occupation.CarpenterAndJoiner:
            return "Carpenter and Joiner";
        case Occupation.CementProductionPlantOperator:
            return "Cement Production Plant Operator";
        case Occupation.ChemicalAndMaterialsEngineers:
            return "Chemical and Materials Engineers nfd";
        case Occupation.ChemicalEngineer:
            return "Chemical Engineer";
        case Occupation.ChiefExecutiveorManagingDirector:
            return "Chief Executive or Managing Director";
        case Occupation.ChiefExecutivesGeneralManagersAndLegislators:
            return "Chief Executives, General Managers and Legislators nfd";
        case Occupation.CivilEngineer:
            return "Civil Engineer";
        case Occupation.CivilEngineeringDraftsperson:
            return "Civil Engineering Draftsperson";
        case Occupation.CivilEngineeringProfessionals:
            return "Civil Engineering Professionals nfd";
        case Occupation.CivilEngineeringTechnician:
            return "Civil Engineering Technician";
        case Occupation.ClayProductsMachineOperator:
            return "Clay Products Machine Operator";
        case Occupation.ClayConcreteGlassAndStoneProcessingMachineOperators:
            return "Clay, Concrete, Glass and Stone Processing Machine Operators nfd";
        case Occupation.ClericalAndAdministrativeWorkers:
            return "Clerical and Administrative Workers nfd";
        case Occupation.ClericalAndOfficeSupportWorkers:
            return "Clerical and Office Support Workers nfd";
        case Occupation.CommercialCleaner:
            return "Commercial Cleaner";
        case Occupation.ConcreteBatchingPlantOperator:
            return "Concrete Batching Plant Operator";
        case Occupation.ConcreteProductsMachineOperator:
            return "Concrete Products Machine Operator";
        case Occupation.ConcretePumpOperator:
            return "Concrete Pump Operator";
        case Occupation.Concreter:
            return "Concreter";
        case Occupation.ConstructionAndMiningLabourers:
            return "Construction and Mining Labourers nfd";
        case Occupation.ConstructionEstimator:
            return "Construction Estimator";
        case Occupation.ConstructionManagers:
            return "Construction Managers nfd";
        case Occupation.ConstructionProjectManager:
            return "Construction Project Manager";
        case Occupation.ConstructionRigger:
            return "Construction Rigger";
        case Occupation.ConstructionTradesWorkers:
            return "Construction Trades Workers nfd";
        case Occupation.ConstructionDistributionAndProductionManagers:
            return "Construction, Distribution and Production Managers nfd";
        case Occupation.ContractAdministrator:
            return "Contract Administrator";
        case Occupation.CorporateGeneralManager:
            return "Corporate General Manager";
        case Occupation.CorporateServicesManager:
            return "Corporate Services Manager";
        case Occupation.CraneChaser:
            return "Crane Chaser";
        case Occupation.CraneHoistorLiftOperator:
            return "Crane, Hoist or Lift Operator";
        case Occupation.DataEntryOperator:
            return "Data Entry Operator";
        case Occupation.DesignEngineeringScienceAndTransportProfessionals:
            return "Design, Engineering, Science and Transport Professionals nfd";
        case Occupation.DieselMotorMechanic:
            return "Diesel Motor Mechanic";
        case Occupation.DrainageSewerageAndStormwaterLabourer:
            return "Drainage, Sewerage and Stormwater Labourer";
        case Occupation.Drainer:
            return "Drainer";
        case Occupation.Driller:
            return "Driller";
        case Occupation.DrillersAssistant:
            return "Driller's Assistant";
        case Occupation.EarthmovingLabourer:
            return "Earthmoving Labourer";
        case Occupation.EarthmovingPlantOperatorGeneral:
            return "Earthmoving Plant Operator (General)";
        case Occupation.ElectricalDistributionTradesWorkers:
            return "Electrical Distribution Trades Workers nfd";
        case Occupation.ElectricalEngineer:
            return "Electrical Engineer";
        case Occupation.ElectricalEngineeringDraftsperson:
            return "Electrical Engineering Draftsperson";
        case Occupation.ElectricalEngineeringTechnician:
            return "Electrical Engineering Technician";
        case Occupation.ElectricalLinesworker:
            return "Electrical Linesworker";
        case Occupation.ElectricianGeneral:
            return "Electrician (General)";
        case Occupation.ElectricianSpecialClass:
            return "Electrician (Special Class)";
        case Occupation.ElectronicEngineeringDraftsperson:
            return "Electronic Engineering Draftsperson";
        case Occupation.ElectronicEngineeringTechnician:
            return "Electronic Engineering Technician";
        case Occupation.ElectronicEquipmentTradesWorker:
            return "Electronic Equipment Trades Worker";
        case Occupation.ElectronicInstrumentTradesWorkerGeneral:
            return "Electronic Instrument Trades Worker (General)";
        case Occupation.ElectronicsEngineer:
            return "Electronics Engineer";
        case Occupation.ElectronicsTradesWorkers:
            return "Electronics Trades Workers nfd";
        case Occupation.ElectrotechnologyAndTelecommunicationsTradesWorkers:
            return "Electrotechnology and Telecommunications Trades Workers nfd";
        case Occupation.EngineeringManager:
            return "Engineering Manager";
        case Occupation.EngineeringProductionWorker:
            return "Engineering Production Worker";
        case Occupation.EngineeringProfessionals:
            return "Engineering Professionals nfd";
        case Occupation.EngineeringTechnologist:
            return "Engineering Technologist";
        case Occupation.EngineeringICTAndScienceTechnicians:
            return "Engineering, ICT and Science Technicians nfd";
        case Occupation.EnvironmentalConsultant:
            return "Environmental Consultant";
        case Occupation.EnvironmentalEngineer:
            return "Environmental Engineer";
        case Occupation.EnvironmentalManager:
            return "Environmental Manager";
        case Occupation.EnvironmentalResearchScientist:
            return "Environmental Research Scientist";
        case Occupation.ExcavatorOperator:
            return "Excavator Operator";
        case Occupation.FacilitiesAdministrator:
            return "Facilities Administrator";
        case Occupation.Fencer:
            return "Fencer";
        case Occupation.FibrousPlasterer:
            return "Fibrous Plasterer";
        case Occupation.FinanceManager:
            return "Finance Manager";
        case Occupation.FireProtectionEquipmentTechnician:
            return "Fire Protection Equipment Technician";
        case Occupation.FitterGeneral:
            return "Fitter (General)";
        case Occupation.FitterAndTurner:
            return "Fitter and Turner";
        case Occupation.FitterWelder:
            return "Fitter-Welder";
        case Occupation.FloorFinisher:
            return "Floor Finisher";
        case Occupation.ForkliftDriver:
            return "Forklift Driver";
        case Occupation.GardenerGeneral:
            return "Gardener (General)";
        case Occupation.Gasfitter:
            return "Gasfitter";
        case Occupation.GeneralManagers:
            return "General Managers nfd";
        case Occupation.Geologist:
            return "Geologist";
        case Occupation.GeotechnicalEngineer:
            return "Geotechnical Engineer";
        case Occupation.GlassProductionMachineOperator:
            return "Glass Production Machine Operator";
        case Occupation.Glazier:
            return "Glazier";
        case Occupation.GraderOperator:
            return "Grader Operator";
        case Occupation.HumanResourceAdviser:
            return "Human Resource Adviser";
        case Occupation.HumanResourceAndTrainingProfessionals:
            return "Human Resource and Training Professionals nfd";
        case Occupation.HumanResourceClerk:
            return "Human Resource Clerk";
        case Occupation.HumanResourceManager:
            return "Human Resource Manager";
        case Occupation.ICTBusinessAndSystemsAnalysts:
            return "ICT Business and Systems Analysts nfd";
        case Occupation.ICTManagers:
            return "ICT Managers nfd";
        case Occupation.ICTProfessionals:
            return "ICT Professionals nfd";
        case Occupation.ICTSecuritySpecialist:
            return "ICT Security Specialist";
        case Occupation.ICTSupportAndTestEngineers:
            return "ICT Support and Test Engineers nfd";
        case Occupation.IndustrialEngineer:
            return "Industrial Engineer";
        case Occupation.IndustrialSpraypainter:
            return "Industrial Spraypainter";
        case Occupation.IndustrialMechanicalAndProductionEngineers:
            return "Industrial, Mechanical and Production Engineers nfd";
        case Occupation.InspectorsAndRegulatoryOfficers:
            return "Inspectors and Regulatory Officers nfd";
        case Occupation.InteriorDesigner:
            return "Interior Designer";
        case Occupation.Joiner:
            return "Joiner";
        case Occupation.Labourers:
            return "Labourers nfd";
        case Occupation.Lagger:
            return "Lagger";
        case Occupation.LandscapeArchitect:
            return "Landscape Architect";
        case Occupation.LiftMechanic:
            return "Lift Mechanic";
        case Occupation.Linemarker:
            return "Linemarker";
        case Occupation.LoaderOperator:
            return "Loader Operator";
        case Occupation.Locksmith:
            return "Locksmith";
        case Occupation.LogisticsClerks:
            return "Logistics Clerks nfd";
        case Occupation.MachineAndStationaryPlantOperators:
            return "Machine and Stationary Plant Operators nfd";
        case Occupation.MachineryOperatorsAndDrivers:
            return "Machinery Operators and Drivers nfd";
        case Occupation.ManagementAccountant:
            return "Management Accountant";
        case Occupation.Managers:
            return "Managers nfd";
        case Occupation.MaterialsEngineer:
            return "Materials Engineer";
        case Occupation.MechanicalEngineer:
            return "Mechanical Engineer";
        case Occupation.MechanicalEngineeringDraftsperson:
            return "Mechanical Engineering Draftsperson";
        case Occupation.MechanicalEngineeringTechnician:
            return "Mechanical Engineering Technician";
        case Occupation.MechanicalEngineeringTradesWorkers:
            return "Mechanical Engineering Trades Workers nfd";
        case Occupation.MediaProfessionals:
            return "Media Professionals nfd";
        case Occupation.MetalCastingTradesWorker:
            return "Metal Casting Trades Worker";
        case Occupation.MetalCastingForgingAndFinishingTradesWorkers:
            return "Metal Casting, Forging and Finishing Trades Workers nfd";
        case Occupation.MetalEngineeringProcessWorker:
            return "Metal Engineering Process Worker";
        case Occupation.MetalFabricator:
            return "Metal Fabricator";
        case Occupation.MetalFittersAndMachinists:
            return "Metal Fitters and Machinists nfd";
        case Occupation.MetalMachinistFirstClass:
            return "Metal Machinist (First Class)";
        case Occupation.MetalPolisher:
            return "Metal Polisher";
        case Occupation.MobilePlantOperators:
            return "Mobile Plant Operators nfd";
        case Occupation.MotorMechanicGeneral:
            return "Motor Mechanic (General)";
        case Occupation.MultimediaDesigner:
            return "Multimedia Designer";
        case Occupation.MultimediaSpecialistsAndWebDevelopers:
            return "Multimedia Specialists and Web Developers nfd";
        case Occupation.OccupationalHealthAndSafetyAdviser:
            return "Occupational Health and Safety Adviser";
        case Occupation.OtherSpatialScientist:
            return "Other Spatial Scientist";
        case Occupation.OtherWoodProcessingMachineOperator:
            return "Other Wood Processing Machine Operator";
        case Occupation.OtherConstructionAndMiningLabourers:
            return "Other Construction and Mining Labourers nfd";
        case Occupation.PaintingTradesWorker:
            return "Painting Trades Worker";
        case Occupation.PavingAndSurfacingLabourer:
            return "Paving and Surfacing Labourer";
        case Occupation.PavingPlantOperator:
            return "Paving Plant Operator";
        case Occupation.PayrollClerk:
            return "Payroll Clerk";
        case Occupation.Plasterers:
            return "Plasterers nfd";
        case Occupation.PlasticsTechnician:
            return "Plastics Technician";
        case Occupation.PlumberGeneral:
            return "Plumber (General)";
        case Occupation.PlumbersAssistant:
            return "Plumber's Assistant";
        case Occupation.PlumbingInspector:
            return "Plumbing Inspector";
        case Occupation.PolicyAndPlanningManager:
            return "Policy and Planning Manager";
        case Occupation.PrecisionMetalTradesWorkers:
            return "Precision Metal Trades Workers nfd";
        case Occupation.PressureWelder:
            return "Pressure Welder";
        case Occupation.Professionals:
            return "Professionals nfd";
        case Occupation.ProgramOrProjectAdministrator:
            return "Program or Project Administrator";
        case Occupation.ProjectBuilder:
            return "Project Builder";
        case Occupation.PublicRelationsManager:
            return "Public Relations Manager";
        case Occupation.PublicRelationsProfessional:
            return "Public Relations Professional";
        case Occupation.PurchasingAndSupplyLogisticsClerks:
            return "Purchasing and Supply Logistics Clerks nfd";
        case Occupation.PurchasingOfficer:
            return "Purchasing Officer";
        case Occupation.QualityAssuranceManager:
            return "Quality Assurance Manager";
        case Occupation.QuantitySurveyor:
            return "Quantity Surveyor";
        case Occupation.RailwaySignalOperator:
            return "Railway Signal Operator";
        case Occupation.RailwayTrackPlantOperator:
            return "Railway Track Plant Operator";
        case Occupation.RailwayTrackWorker:
            return "Railway Track Worker";
        case Occupation.RecruitmentConsultant:
            return "Recruitment Consultant";
        case Occupation.ResearchAndDevelopmentManager:
            return "Research and Development Manager";
        case Occupation.RoadRollerOperator:
            return "Road Roller Operator";
        case Occupation.RoadTrafficController:
            return "Road Traffic Controller";
        case Occupation.RoofPlumber:
            return "Roof Plumber";
        case Occupation.RoofTiler:
            return "Roof Tiler";
        case Occupation.SafetyInspector:
            return "Safety Inspector";
        case Occupation.SalesMarketingAndPublicRelationsProfessionals:
            return "Sales, Marketing and Public Relations Professionals nfd";
        case Occupation.SandBlaster:
            return "Sand Blaster";
        case Occupation.SawMillOrTimberYardWorker:
            return "Sawmill or Timber Yard Worker";
        case Occupation.SawMillingOperator:
            return "Sawmilling Operator";
        case Occupation.Scaffolder:
            return "Scaffolder";
        case Occupation.SecretaryGeneral:
            return "Secretary (General)";
        case Occupation.SheetmetalTradesWorker:
            return "Sheetmetal Trades Worker";
        case Occupation.SignErector:
            return "Sign Erector";
        case Occupation.SoftwareAndApplicationsProgrammers:
            return "Software and Applications Programmers nfd";
        case Occupation.Solicitor:
            return "Solicitor";
        case Occupation.SolidPlasterer:
            return "Solid Plasterer";
        case Occupation.SpecialistManagers:
            return "Specialist Managers nfd";
        case Occupation.StationaryPlantOperators:
            return "Stationary Plant Operators nfd";
        case Occupation.SteelFixer:
            return "Steel Fixer";
        case Occupation.StoneProcessingMachineOperator:
            return "Stone Processing Machine Operator";
        case Occupation.Stonemason:
            return "Stonemason";
        case Occupation.StreetSweeperOperator:
            return "Streetsweeper Operator";
        case Occupation.StructuralEngineer:
            return "Structural Engineer";
        case Occupation.StructuralSteelAndWeldingTradesWorkers:
            return "Structural Steel and Welding Trades Workers nfd";
        case Occupation.StructuralSteelConstructionWorkers:
            return "Structural Steel Construction Workers nfd";
        case Occupation.StructuralSteelErector:
            return "Structural Steel Erector";
        case Occupation.SupplyAndDistributionManager:
            return "Supply and Distribution Manager";
        case Occupation.SurveyingorSpatialScienceTechnician:
            return "Surveying or Spatial Science Technician";
        case Occupation.Surveyor:
            return "Surveyor";
        case Occupation.SurveyorsAssistant:
            return "Surveyor's Assistant";
        case Occupation.TankerDriver:
            return "Tanker Driver";
        case Occupation.TaxationAccountant:
            return "Taxation Accountant";
        case Occupation.TechnicalCableJointer:
            return "Technical Cable Jointer";
        case Occupation.TechniciansAndTradesWorkers:
            return "Technicians and Trades Workers nfd";
        case Occupation.TelecommunicationsCableJointer:
            return "Telecommunications Cable Jointer";
        case Occupation.TelecommunicationsEngineer:
            return "Telecommunications Engineer";
        case Occupation.TelecommunicationsEngineeringProfessionals:
            return "Telecommunications Engineering Professionals nfd";
        case Occupation.TelecommunicationsLinesworker:
            return "Telecommunications Linesworker";
        case Occupation.TelecommunicationsNetworkEngineer:
            return "Telecommunications Network Engineer";
        case Occupation.TowTruckDriver:
            return "Tow Truck Driver";
        case Occupation.TransportEngineer:
            return "Transport Engineer";
        case Occupation.TruckDriverGeneral:
            return "Truck Driver (General)";
        case Occupation.UrbanAndRegionalPlanner:
            return "Urban and Regional Planner";
        case Occupation.WallAndFloorTiler:
            return "Wall and Floor Tiler";
        case Occupation.WelderFirstClass:
            return "Welder (First Class)";
        case Occupation.WoodAndWoodProductsFactoryWorker:
            return "Wood and Wood Products Factory Worker";
        case Occupation.WoodTradesWorkers:
            return "Wood Trades Workers nfd";
        case Occupation.WorkplaceRelationsAdviser:
            return "Workplace Relations Adviser";
    }
};
exports.occupationToString = occupationToString;
const occupationToBEPOccupationType = (occupation) => {
    switch (occupation) {
        default:
            throw new Error(`Occupation ${occupation} not found`);
        case Occupation.AccountantGeneral:
        case Occupation.AccountantsAuditorsAndCompanySecretaries:
        case Occupation.AdvertisingPublicRelationsAndSalesManagers:
        case Occupation.AgriculturalEngineer:
        case Occupation.Architect:
        case Occupation.ArchitectsAndLAndscapeArchitects:
        case Occupation.ArchitectsDesignersPlannersAndSurveyors:
        case Occupation.AuditorsCompanySecretariesAndCorporateTreasurers:
        case Occupation.BusinessAdministrationManagers:
        case Occupation.BusinessHumanResourceAndMarketingProfessionals:
        case Occupation.ChemicalAndMaterialsEngineers:
        case Occupation.ChemicalEngineer:
        case Occupation.ChiefExecutiveorManagingDirector:
        case Occupation.ChiefExecutivesGeneralManagersAndLegislators:
        case Occupation.CivilEngineer:
        case Occupation.CivilEngineeringProfessionals:
        case Occupation.ConstructionManagers:
        case Occupation.ConstructionProjectManager:
        case Occupation.ConstructionDistributionAndProductionManagers:
        case Occupation.CorporateGeneralManager:
        case Occupation.CorporateServicesManager:
        case Occupation.DesignEngineeringScienceAndTransportProfessionals:
        case Occupation.ElectricalEngineer:
        case Occupation.ElectronicsEngineer:
        case Occupation.EngineeringManager:
        case Occupation.EngineeringProfessionals:
        case Occupation.EngineeringTechnologist:
        case Occupation.EnvironmentalConsultant:
        case Occupation.EnvironmentalEngineer:
        case Occupation.EnvironmentalManager:
        case Occupation.EnvironmentalResearchScientist:
        case Occupation.FinanceManager:
        case Occupation.GeneralManagers:
        case Occupation.Geologist:
        case Occupation.GeotechnicalEngineer:
        case Occupation.HumanResourceAdviser:
        case Occupation.HumanResourceAndTrainingProfessionals:
        case Occupation.HumanResourceManager:
        case Occupation.ICTBusinessAndSystemsAnalysts:
        case Occupation.ICTManagers:
        case Occupation.ICTProfessionals:
        case Occupation.ICTSecuritySpecialist:
        case Occupation.ICTSupportAndTestEngineers:
        case Occupation.IndustrialEngineer:
        case Occupation.IndustrialMechanicalAndProductionEngineers:
        case Occupation.InteriorDesigner:
        case Occupation.LandscapeArchitect:
        case Occupation.ManagementAccountant:
        case Occupation.Managers:
        case Occupation.MaterialsEngineer:
        case Occupation.MechanicalEngineer:
        case Occupation.MediaProfessionals:
        case Occupation.MultimediaDesigner:
        case Occupation.MultimediaSpecialistsAndWebDevelopers:
        case Occupation.OccupationalHealthAndSafetyAdviser:
        case Occupation.OtherSpatialScientist:
        case Occupation.PolicyAndPlanningManager:
        case Occupation.Professionals:
        case Occupation.ProjectBuilder:
        case Occupation.PublicRelationsManager:
        case Occupation.PublicRelationsProfessional:
        case Occupation.QualityAssuranceManager:
        case Occupation.QuantitySurveyor:
        case Occupation.RecruitmentConsultant:
        case Occupation.ResearchAndDevelopmentManager:
        case Occupation.SalesMarketingAndPublicRelationsProfessionals:
        case Occupation.SoftwareAndApplicationsProgrammers:
        case Occupation.Solicitor:
        case Occupation.SpecialistManagers:
        case Occupation.StructuralEngineer:
        case Occupation.SupplyAndDistributionManager:
        case Occupation.Surveyor:
        case Occupation.TaxationAccountant:
        case Occupation.TelecommunicationsEngineer:
        case Occupation.TelecommunicationsEngineeringProfessionals:
        case Occupation.TelecommunicationsNetworkEngineer:
        case Occupation.TransportEngineer:
        case Occupation.UrbanAndRegionalPlanner:
        case Occupation.WorkplaceRelationsAdviser:
            return contractorEmployee_1.OccupationType.ManagementSupervisorSpecialistLabourRoles;
        case Occupation.AccountsClerk:
        case Occupation.BackhoeOperator:
        case Occupation.BoilerorEngineOperator:
        case Occupation.BookKeeper:
        case Occupation.BuildersLabourer:
        case Occupation.BuildingAndPlumbingLabourers:
        case Occupation.BuildingInsulationInstaller:
        case Occupation.BulkMaterialsHAndlingPlantOperator:
        case Occupation.BulldozerOperator:
        case Occupation.CementProductionPlantOperator:
        case Occupation.ClayProductsMachineOperator:
        case Occupation.ClayConcreteGlassAndStoneProcessingMachineOperators:
        case Occupation.ClericalAndAdministrativeWorkers:
        case Occupation.ClericalAndOfficeSupportWorkers:
        case Occupation.CommercialCleaner:
        case Occupation.ConcreteBatchingPlantOperator:
        case Occupation.ConcreteProductsMachineOperator:
        case Occupation.ConcretePumpOperator:
        case Occupation.Concreter:
        case Occupation.ConstructionAndMiningLabourers:
        case Occupation.ConstructionRigger:
        case Occupation.ContractAdministrator:
        case Occupation.CraneChaser:
        case Occupation.CraneHoistorLiftOperator:
        case Occupation.DataEntryOperator:
        case Occupation.DrainageSewerageAndStormwaterLabourer:
        case Occupation.Driller:
        case Occupation.DrillersAssistant:
        case Occupation.EarthmovingLabourer:
        case Occupation.EarthmovingPlantOperatorGeneral:
        case Occupation.EngineeringProductionWorker:
        case Occupation.ExcavatorOperator:
        case Occupation.FacilitiesAdministrator:
        case Occupation.Fencer:
        case Occupation.ForkliftDriver:
        case Occupation.GlassProductionMachineOperator:
        case Occupation.GraderOperator:
        case Occupation.HumanResourceClerk:
        case Occupation.IndustrialSpraypainter:
        case Occupation.InspectorsAndRegulatoryOfficers:
        case Occupation.Labourers:
        case Occupation.Lagger:
        case Occupation.Linemarker:
        case Occupation.LoaderOperator:
        case Occupation.LogisticsClerks:
        case Occupation.MachineAndStationaryPlantOperators:
        case Occupation.MachineryOperatorsAndDrivers:
        case Occupation.MobilePlantOperators:
        case Occupation.OtherWoodProcessingMachineOperator:
        case Occupation.PavingAndSurfacingLabourer:
        case Occupation.PavingPlantOperator:
        case Occupation.PayrollClerk:
        case Occupation.PlumbersAssistant:
        case Occupation.ProgramOrProjectAdministrator:
        case Occupation.PurchasingAndSupplyLogisticsClerks:
        case Occupation.PurchasingOfficer:
        case Occupation.RailwaySignalOperator:
        case Occupation.RailwayTrackPlantOperator:
        case Occupation.RailwayTrackWorker:
        case Occupation.RoadRollerOperator:
        case Occupation.RoadTrafficController:
        case Occupation.SandBlaster:
        case Occupation.SawMillOrTimberYardWorker:
        case Occupation.SawMillingOperator:
        case Occupation.Scaffolder:
        case Occupation.SecretaryGeneral:
        case Occupation.SignErector:
        case Occupation.StationaryPlantOperators:
        case Occupation.SteelFixer:
        case Occupation.StoneProcessingMachineOperator:
        case Occupation.StreetSweeperOperator:
        case Occupation.StructuralSteelConstructionWorkers:
        case Occupation.StructuralSteelErector:
        case Occupation.SurveyorsAssistant:
        case Occupation.TankerDriver:
        case Occupation.TelecommunicationsLinesworker:
        case Occupation.TowTruckDriver:
        case Occupation.TruckDriverGeneral:
        case Occupation.WoodAndWoodProductsFactoryWorker:
        case Occupation.OtherConstructionAndMiningLabourers:
            return contractorEmployee_1.OccupationType.NonTradePosition;
        case Occupation.AirconditioningAndMechanicalServicesPlumber:
        case Occupation.AirconditioningAndRefrigerationMechanic:
        case Occupation.Arborist:
        case Occupation.ArchitecturalDraftsperson:
        case Occupation.ArchitecturalBuildingAndSurveyingTechnicians:
        case Occupation.AutomotiveElectrician:
        case Occupation.Bricklayer:
        case Occupation.BuildingAndEngineeringTechnicians:
        case Occupation.BuildingAssociate:
        case Occupation.BuildingInspector:
        case Occupation.CabinetMaker:
        case Occupation.CablerDataAndTelecommunications:
        case Occupation.Carpenter:
        case Occupation.CarpenterAndJoiner:
        case Occupation.CivilEngineeringDraftsperson:
        case Occupation.CivilEngineeringTechnician:
        case Occupation.ConstructionEstimator:
        case Occupation.ConstructionTradesWorkers:
        case Occupation.DieselMotorMechanic:
        case Occupation.Drainer:
        case Occupation.ElectricalDistributionTradesWorkers:
        case Occupation.ElectricalEngineeringDraftsperson:
        case Occupation.ElectricalEngineeringTechnician:
        case Occupation.ElectricalLinesworker:
        case Occupation.ElectricianGeneral:
        case Occupation.ElectricianSpecialClass:
        case Occupation.ElectronicEngineeringDraftsperson:
        case Occupation.ElectronicEngineeringTechnician:
        case Occupation.ElectronicEquipmentTradesWorker:
        case Occupation.ElectronicInstrumentTradesWorkerGeneral:
        case Occupation.ElectronicsTradesWorkers:
        case Occupation.ElectrotechnologyAndTelecommunicationsTradesWorkers:
        case Occupation.EngineeringICTAndScienceTechnicians:
        case Occupation.FibrousPlasterer:
        case Occupation.FireProtectionEquipmentTechnician:
        case Occupation.FitterGeneral:
        case Occupation.FitterAndTurner:
        case Occupation.FitterWelder:
        case Occupation.FloorFinisher:
        case Occupation.GardenerGeneral:
        case Occupation.Gasfitter:
        case Occupation.Glazier:
        case Occupation.Joiner:
        case Occupation.LiftMechanic:
        case Occupation.Locksmith:
        case Occupation.MechanicalEngineeringDraftsperson:
        case Occupation.MechanicalEngineeringTechnician:
        case Occupation.MechanicalEngineeringTradesWorkers:
        case Occupation.MetalCastingTradesWorker:
        case Occupation.MetalCastingForgingAndFinishingTradesWorkers:
        case Occupation.MetalEngineeringProcessWorker:
        case Occupation.MetalFabricator:
        case Occupation.MetalFittersAndMachinists:
        case Occupation.MetalMachinistFirstClass:
        case Occupation.MetalPolisher:
        case Occupation.MotorMechanicGeneral:
        case Occupation.PaintingTradesWorker:
        case Occupation.Plasterers:
        case Occupation.PlasticsTechnician:
        case Occupation.PlumberGeneral:
        case Occupation.PlumbingInspector:
        case Occupation.PrecisionMetalTradesWorkers:
        case Occupation.PressureWelder:
        case Occupation.RoofPlumber:
        case Occupation.RoofTiler:
        case Occupation.SafetyInspector:
        case Occupation.SheetmetalTradesWorker:
        case Occupation.SolidPlasterer:
        case Occupation.Stonemason:
        case Occupation.StructuralSteelAndWeldingTradesWorkers:
        case Occupation.SurveyingorSpatialScienceTechnician:
        case Occupation.TechnicalCableJointer:
        case Occupation.TechniciansAndTradesWorkers:
        case Occupation.TelecommunicationsCableJointer:
        case Occupation.WallAndFloorTiler:
        case Occupation.WelderFirstClass:
        case Occupation.WoodTradesWorkers:
            return contractorEmployee_1.OccupationType.TradeRole;
    }
};
exports.occupationToBEPOccupationType = occupationToBEPOccupationType;
const stringImportToOccupation = (occupation) => {
    if (!occupation)
        return undefined;
    switch (occupation.toLowerCase().trim()) {
        default:
            return undefined;
        case "accountant (general)":
            return Occupation.AccountantGeneral;
        case "accountants, auditors and company secretaries nfd":
            return Occupation.AccountantsAuditorsAndCompanySecretaries;
        case "accounts clerk":
            return Occupation.AccountsClerk;
        case "advertising, public relations and sales managers nfd":
            return Occupation.AdvertisingPublicRelationsAndSalesManagers;
        case "agricultural engineer":
            return Occupation.AgriculturalEngineer;
        case "airconditioning and mechanical services plumber":
            return Occupation.AirconditioningAndMechanicalServicesPlumber;
        case "airconditioning and refrigeration mechanic":
            return Occupation.AirconditioningAndRefrigerationMechanic;
        case "arborist":
            return Occupation.Arborist;
        case "architect":
            return Occupation.Architect;
        case "architects and landscape architects nfd":
            return Occupation.ArchitectsAndLAndscapeArchitects;
        case "architects, designers, planners and surveyors nfd":
            return Occupation.ArchitectsDesignersPlannersAndSurveyors;
        case "architectural draftsperson":
            return Occupation.ArchitecturalDraftsperson;
        case "architectural, building and surveying technicians nfd":
            return Occupation.ArchitecturalBuildingAndSurveyingTechnicians;
        case "auditors, company secretaries and corporate treasurers nfd":
            return Occupation.AuditorsCompanySecretariesAndCorporateTreasurers;
        case "automotive electrician":
            return Occupation.AutomotiveElectrician;
        case "backhoe operator":
            return Occupation.BackhoeOperator;
        case "boiler or engine operator":
            return Occupation.BoilerorEngineOperator;
        case "bookkeeper":
            return Occupation.BookKeeper;
        case "bricklayer":
            return Occupation.Bricklayer;
        case "builder's labourer":
            return Occupation.BuildersLabourer;
        case "building and engineering technicians nfd":
            return Occupation.BuildingAndEngineeringTechnicians;
        case "building and plumbing labourers nfd":
            return Occupation.BuildingAndPlumbingLabourers;
        case "building associate":
            return Occupation.BuildingAssociate;
        case "building inspector":
            return Occupation.BuildingInspector;
        case "building insulation installer":
            return Occupation.BuildingInsulationInstaller;
        case "bulk materials handling plant operator":
            return Occupation.BulkMaterialsHAndlingPlantOperator;
        case "bulldozer operator":
            return Occupation.BulldozerOperator;
        case "business administration managers nfd":
            return Occupation.BusinessAdministrationManagers;
        case "business, human resource and marketing professionals nfd":
            return Occupation.BusinessHumanResourceAndMarketingProfessionals;
        case "cabinetmaker":
            return Occupation.CabinetMaker;
        case "cabler (data and telecommunications)":
            return Occupation.CablerDataAndTelecommunications;
        case "carpenter":
            return Occupation.Carpenter;
        case "carpenter and joiner":
            return Occupation.CarpenterAndJoiner;
        case "cement production plant operator":
            return Occupation.CementProductionPlantOperator;
        case "chemical and materials engineers nfd":
            return Occupation.ChemicalAndMaterialsEngineers;
        case "chemical engineer":
            return Occupation.ChemicalEngineer;
        case "chief executive or managing director":
            return Occupation.ChiefExecutiveorManagingDirector;
        case "chief executives, general managers and legislators nfd":
            return Occupation.ChiefExecutivesGeneralManagersAndLegislators;
        case "civil engineer":
            return Occupation.CivilEngineer;
        case "civil engineering draftsperson":
            return Occupation.CivilEngineeringDraftsperson;
        case "civil engineering professionals nfd":
            return Occupation.CivilEngineeringProfessionals;
        case "civil engineering technician":
            return Occupation.CivilEngineeringTechnician;
        case "clay products machine operator":
            return Occupation.ClayProductsMachineOperator;
        case "clay, concrete, glass and stone processing machine operators nfd":
            return Occupation.ClayConcreteGlassAndStoneProcessingMachineOperators;
        case "clerical and administrative workers nfd":
            return Occupation.ClericalAndAdministrativeWorkers;
        case "clerical and office support workers nfd":
            return Occupation.ClericalAndOfficeSupportWorkers;
        case "commercial cleaner":
            return Occupation.CommercialCleaner;
        case "concrete batching plant operator":
            return Occupation.ConcreteBatchingPlantOperator;
        case "concrete products machine operator":
            return Occupation.ConcreteProductsMachineOperator;
        case "concrete pump operator":
            return Occupation.ConcretePumpOperator;
        case "concreter":
            return Occupation.Concreter;
        case "construction and mining labourers nfd":
            return Occupation.ConstructionAndMiningLabourers;
        case "construction estimator":
            return Occupation.ConstructionEstimator;
        case "construction managers nfd":
            return Occupation.ConstructionManagers;
        case "construction project manager":
            return Occupation.ConstructionProjectManager;
        case "construction rigger":
            return Occupation.ConstructionRigger;
        case "construction trades workers nfd":
            return Occupation.ConstructionTradesWorkers;
        case "construction, distribution and production managers nfd":
            return Occupation.ConstructionDistributionAndProductionManagers;
        case "contract administrator":
            return Occupation.ContractAdministrator;
        case "corporate general manager":
            return Occupation.CorporateGeneralManager;
        case "corporate services manager":
            return Occupation.CorporateServicesManager;
        case "crane chaser":
            return Occupation.CraneChaser;
        case "crane, hoist or lift operator":
            return Occupation.CraneHoistorLiftOperator;
        case "data entry operator":
            return Occupation.DataEntryOperator;
        case "design, engineering, science and transport professionals nfd":
            return Occupation.DesignEngineeringScienceAndTransportProfessionals;
        case "diesel motor mechanic":
            return Occupation.DieselMotorMechanic;
        case "drainage, sewerage and stormwater labourer":
            return Occupation.DrainageSewerageAndStormwaterLabourer;
        case "drainer":
            return Occupation.Drainer;
        case "driller":
            return Occupation.Driller;
        case "driller's assistant":
            return Occupation.DrillersAssistant;
        case "earthmoving labourer":
            return Occupation.EarthmovingLabourer;
        case "earthmoving plant operator (general)":
            return Occupation.EarthmovingPlantOperatorGeneral;
        case "electrical distribution trades workers nfd":
            return Occupation.ElectricalDistributionTradesWorkers;
        case "electrical engineer":
            return Occupation.ElectricalEngineer;
        case "electrical engineering draftsperson":
            return Occupation.ElectricalEngineeringDraftsperson;
        case "electrical engineering technician":
            return Occupation.ElectricalEngineeringTechnician;
        case "electrical linesworker":
            return Occupation.ElectricalLinesworker;
        case "electrician (general)":
            return Occupation.ElectricianGeneral;
        case "electrician (special class)":
            return Occupation.ElectricianSpecialClass;
        case "electronic engineering draftsperson":
            return Occupation.ElectronicEngineeringDraftsperson;
        case "electronic engineering technician":
            return Occupation.ElectronicEngineeringTechnician;
        case "electronic equipment trades worker":
            return Occupation.ElectronicEquipmentTradesWorker;
        case "electronic instrument trades worker (general)":
            return Occupation.ElectronicInstrumentTradesWorkerGeneral;
        case "electronics engineer":
            return Occupation.ElectronicsEngineer;
        case "electronics trades workers nfd":
            return Occupation.ElectronicsTradesWorkers;
        case "electrotechnology and telecommunications trades workers nfd":
            return Occupation.ElectrotechnologyAndTelecommunicationsTradesWorkers;
        case "engineering manager":
            return Occupation.EngineeringManager;
        case "engineering production worker":
            return Occupation.EngineeringProductionWorker;
        case "engineering professionals nfd":
            return Occupation.EngineeringProfessionals;
        case "engineering technologist":
            return Occupation.EngineeringTechnologist;
        case "engineering, ict and science technicians nfd":
            return Occupation.EngineeringICTAndScienceTechnicians;
        case "environmental consultant":
            return Occupation.EnvironmentalConsultant;
        case "environmental engineer":
            return Occupation.EnvironmentalEngineer;
        case "environmental manager":
            return Occupation.EnvironmentalManager;
        case "environmental research scientist":
            return Occupation.EnvironmentalResearchScientist;
        case "excavator operator":
            return Occupation.ExcavatorOperator;
        case "facilities administrator":
            return Occupation.FacilitiesAdministrator;
        case "fencer":
            return Occupation.Fencer;
        case "fibrous plasterer":
            return Occupation.FibrousPlasterer;
        case "finance manager":
            return Occupation.FinanceManager;
        case "fire protection equipment technician":
            return Occupation.FireProtectionEquipmentTechnician;
        case "fitter (general)":
            return Occupation.FitterGeneral;
        case "fitter and turner":
            return Occupation.FitterAndTurner;
        case "fitter-welder":
            return Occupation.FitterWelder;
        case "floor finisher":
            return Occupation.FloorFinisher;
        case "forklift driver":
            return Occupation.ForkliftDriver;
        case "gardener (general)":
            return Occupation.GardenerGeneral;
        case "gasfitter":
            return Occupation.Gasfitter;
        case "general managers nfd":
            return Occupation.GeneralManagers;
        case "geologist":
            return Occupation.Geologist;
        case "geotechnical engineer":
            return Occupation.GeotechnicalEngineer;
        case "glass production machine operator":
            return Occupation.GlassProductionMachineOperator;
        case "glazier":
            return Occupation.Glazier;
        case "grader operator":
            return Occupation.GraderOperator;
        case "human resource adviser":
            return Occupation.HumanResourceAdviser;
        case "human resource and training professionals nfd":
            return Occupation.HumanResourceAndTrainingProfessionals;
        case "human resource clerk":
            return Occupation.HumanResourceClerk;
        case "human resource manager":
            return Occupation.HumanResourceManager;
        case "ict business and systems analysts nfd":
            return Occupation.ICTBusinessAndSystemsAnalysts;
        case "ict managers nfd":
            return Occupation.ICTManagers;
        case "ict professionals nfd":
            return Occupation.ICTProfessionals;
        case "ict security specialist":
            return Occupation.ICTSecuritySpecialist;
        case "ict support and test engineers nfd":
            return Occupation.ICTSupportAndTestEngineers;
        case "industrial engineer":
            return Occupation.IndustrialEngineer;
        case "industrial spraypainter":
            return Occupation.IndustrialSpraypainter;
        case "industrial, mechanical and production engineers nfd":
            return Occupation.IndustrialMechanicalAndProductionEngineers;
        case "inspectors and regulatory officers nfd":
            return Occupation.InspectorsAndRegulatoryOfficers;
        case "interior designer":
            return Occupation.InteriorDesigner;
        case "joiner":
            return Occupation.Joiner;
        case "labourers nfd":
            return Occupation.Labourers;
        case "lagger":
            return Occupation.Lagger;
        case "landscape architect":
            return Occupation.LandscapeArchitect;
        case "lift mechanic":
            return Occupation.LiftMechanic;
        case "linemarker":
            return Occupation.Linemarker;
        case "loader operator":
            return Occupation.LoaderOperator;
        case "locksmith":
            return Occupation.Locksmith;
        case "logistics clerks nfd":
            return Occupation.LogisticsClerks;
        case "machine and stationary plant operators nfd":
            return Occupation.MachineAndStationaryPlantOperators;
        case "machinery operators and drivers nfd":
            return Occupation.MachineryOperatorsAndDrivers;
        case "management accountant":
            return Occupation.ManagementAccountant;
        case "managers nfd":
            return Occupation.Managers;
        case "materials engineer":
            return Occupation.MaterialsEngineer;
        case "mechanical engineer":
            return Occupation.MechanicalEngineer;
        case "mechanical engineering draftsperson":
            return Occupation.MechanicalEngineeringDraftsperson;
        case "mechanical engineering technician":
            return Occupation.MechanicalEngineeringTechnician;
        case "mechanical engineering trades workers nfd":
            return Occupation.MechanicalEngineeringTradesWorkers;
        case "media professionals nfd":
            return Occupation.MediaProfessionals;
        case "metal casting trades worker":
            return Occupation.MetalCastingTradesWorker;
        case "metal casting, forging and finishing trades workers nfd":
            return Occupation.MetalCastingForgingAndFinishingTradesWorkers;
        case "metal engineering process worker":
            return Occupation.MetalEngineeringProcessWorker;
        case "metal fabricator":
            return Occupation.MetalFabricator;
        case "metal fitters and machinists nfd":
            return Occupation.MetalFittersAndMachinists;
        case "metal machinist (first class)":
            return Occupation.MetalMachinistFirstClass;
        case "metal polisher":
            return Occupation.MetalPolisher;
        case "mobile plant operators nfd":
            return Occupation.MobilePlantOperators;
        case "motor mechanic (general)":
            return Occupation.MotorMechanicGeneral;
        case "multimedia designer":
            return Occupation.MultimediaDesigner;
        case "multimedia specialists and web developers nfd":
            return Occupation.MultimediaSpecialistsAndWebDevelopers;
        case "occupational health and safety adviser":
            return Occupation.OccupationalHealthAndSafetyAdviser;
        case "other spatial scientist":
            return Occupation.OtherSpatialScientist;
        case "other wood processing machine operator":
            return Occupation.OtherWoodProcessingMachineOperator;
        case "other construction and mining labourers nfd":
            return Occupation.OtherConstructionAndMiningLabourers;
        case "painting trades worker":
            return Occupation.PaintingTradesWorker;
        case "paving and surfacing labourer":
            return Occupation.PavingAndSurfacingLabourer;
        case "paving plant operator":
            return Occupation.PavingPlantOperator;
        case "payroll clerk":
            return Occupation.PayrollClerk;
        case "plasterers nfd":
            return Occupation.Plasterers;
        case "plastics technician":
            return Occupation.PlasticsTechnician;
        case "plumber (general)":
            return Occupation.PlumberGeneral;
        case "plumber's assistant":
            return Occupation.PlumbersAssistant;
        case "plumbing inspector":
            return Occupation.PlumbingInspector;
        case "policy and planning manager":
            return Occupation.PolicyAndPlanningManager;
        case "precision metal trades workers nfd":
            return Occupation.PrecisionMetalTradesWorkers;
        case "pressure welder":
            return Occupation.PressureWelder;
        case "professionals nfd":
            return Occupation.Professionals;
        case "program or project administrator":
            return Occupation.ProgramOrProjectAdministrator;
        case "project builder":
            return Occupation.ProjectBuilder;
        case "public relations manager":
            return Occupation.PublicRelationsManager;
        case "public relations professional":
            return Occupation.PublicRelationsProfessional;
        case "purchasing and supply logistics clerks nfd":
            return Occupation.PurchasingAndSupplyLogisticsClerks;
        case "purchasing officer":
            return Occupation.PurchasingOfficer;
        case "quality assurance manager":
            return Occupation.QualityAssuranceManager;
        case "quantity surveyor":
            return Occupation.QuantitySurveyor;
        case "railway signal operator":
            return Occupation.RailwaySignalOperator;
        case "railway track plant operator":
            return Occupation.RailwayTrackPlantOperator;
        case "railway track worker":
            return Occupation.RailwayTrackWorker;
        case "recruitment consultant":
            return Occupation.RecruitmentConsultant;
        case "research and development manager":
            return Occupation.ResearchAndDevelopmentManager;
        case "road roller operator":
            return Occupation.RoadRollerOperator;
        case "road traffic controller":
            return Occupation.RoadTrafficController;
        case "roof plumber":
            return Occupation.RoofPlumber;
        case "roof tiler":
            return Occupation.RoofTiler;
        case "safety inspector":
            return Occupation.SafetyInspector;
        case "sales, marketing and public relations professionals nfd":
            return Occupation.SalesMarketingAndPublicRelationsProfessionals;
        case "sand blaster":
            return Occupation.SandBlaster;
        case "sawmill or timber yard worker":
            return Occupation.SawMillOrTimberYardWorker;
        case "sawmilling operator":
            return Occupation.SawMillingOperator;
        case "scaffolder":
            return Occupation.Scaffolder;
        case "secretary (general)":
            return Occupation.SecretaryGeneral;
        case "sheetmetal trades worker":
            return Occupation.SheetmetalTradesWorker;
        case "sign erector":
            return Occupation.SignErector;
        case "software and applications programmers nfd":
            return Occupation.SoftwareAndApplicationsProgrammers;
        case "solicitor":
            return Occupation.Solicitor;
        case "solid plasterer":
            return Occupation.SolidPlasterer;
        case "specialist managers nfd":
            return Occupation.SpecialistManagers;
        case "stationary plant operators nfd":
            return Occupation.StationaryPlantOperators;
        case "steel fixer":
            return Occupation.SteelFixer;
        case "stone processing machine operator":
            return Occupation.StoneProcessingMachineOperator;
        case "stonemason":
            return Occupation.Stonemason;
        case "streetsweeper operator":
            return Occupation.StreetSweeperOperator;
        case "structural engineer":
            return Occupation.StructuralEngineer;
        case "structural steel and welding trades workers nfd":
            return Occupation.StructuralSteelAndWeldingTradesWorkers;
        case "structural steel construction workers nfd":
            return Occupation.StructuralSteelConstructionWorkers;
        case "structural steel erector":
            return Occupation.StructuralSteelErector;
        case "supply and distribution manager":
            return Occupation.SupplyAndDistributionManager;
        case "surveying or spatial science technician":
            return Occupation.SurveyingorSpatialScienceTechnician;
        case "surveyor":
            return Occupation.Surveyor;
        case "surveyor's assistant":
            return Occupation.SurveyorsAssistant;
        case "tanker driver":
            return Occupation.TankerDriver;
        case "taxation accountant":
            return Occupation.TaxationAccountant;
        case "technical cable jointer":
            return Occupation.TechnicalCableJointer;
        case "technicians and trades workers nfd":
            return Occupation.TechniciansAndTradesWorkers;
        case "telecommunications cable jointer":
            return Occupation.TelecommunicationsCableJointer;
        case "telecommunications engineer":
            return Occupation.TelecommunicationsEngineer;
        case "telecommunications engineering professionals nfd":
            return Occupation.TelecommunicationsEngineeringProfessionals;
        case "telecommunications linesworker":
            return Occupation.TelecommunicationsLinesworker;
        case "telecommunications network engineer":
            return Occupation.TelecommunicationsNetworkEngineer;
        case "tow truck driver":
            return Occupation.TowTruckDriver;
        case "transport engineer":
            return Occupation.TransportEngineer;
        case "truck driver (general)":
            return Occupation.TruckDriverGeneral;
        case "urban and regional planner":
            return Occupation.UrbanAndRegionalPlanner;
        case "wall and floor tiler":
            return Occupation.WallAndFloorTiler;
        case "welder (first class)":
            return Occupation.WelderFirstClass;
        case "wood and wood products factory worker":
            return Occupation.WoodAndWoodProductsFactoryWorker;
        case "wood trades workers nfd":
            return Occupation.WoodTradesWorkers;
        case "workplace relations adviser":
            return Occupation.WorkplaceRelationsAdviser;
    }
};
exports.stringImportToOccupation = stringImportToOccupation;
