import { Avatar, Box, Stack, Typography } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import { ExportIcon, ViewProjectIcon } from "assets/constants";
import { useState } from "react";
import { FileGenerationStatus } from "social-pro-common/entities/fileGeneration";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";

import { Header } from "../Header/Header";
import { Table } from "../Table/Table";
import {
  ActionCell,
  centerCellStyles,
  defaultCellConfig,
} from "../Table/TableCells";
import { ReportStatusCell } from "../Tables/ProjectTableCells";

interface ReportSubmissionsProps {
  loading: boolean;
  selectedProject?: ProjectLineItem;
  contractorPackages: ContractorPackageLineItem[];
  reportSubmissions: ReportSubmissionLineItem[];
  reportId: string;
  downloadStep: FileGenerationStatus;
  downloadProgress: number;
  handlViewReport: (reportSubmission: ReportSubmissionLineItem) => void;
  handleDownloadReport: (reportSubmission: ReportSubmissionLineItem) => void;
}

export const ReportSubmissions = ({
  contractorPackages,
  // downloadPdf,
  handlViewReport,
  handleDownloadReport,
  // downloadProgress,
  loading,
  reportId,
  // downloadStep,
  reportSubmissions,
  selectedProject,
}: ReportSubmissionsProps) => {
  const cellActions = [
    {
      action: handlViewReport,
      adminOnly: true,
      icon: <ViewProjectIcon />,
      title: "View Report",
    },
    {
      action: handleDownloadReport,
      adminOnly: true,
      icon: <ExportIcon />,
      title: "Download Report",
    },
  ];

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const colDefs = [
    {
      ...defaultCellConfig,
      field: "packageNumber",
      headerName: "#",
      minWidth: 100,
      onCellClicked: (params: any) => handlViewReport(params.data),
      valueFormatter: (params: any) => {
        const matchingPackage = contractorPackages.find(
          (cp) => cp.id === params.data.contractorPackageId,
        );
        return `${matchingPackage?.packageNumber || ""}`;
      },
    },
    {
      ...defaultCellConfig,
      autoHeight: true,
      cellRenderer: (props: CustomCellRendererProps) => {
        const { data } = props;
        const contractorPackage = contractorPackages?.find(
          (cp: ContractorPackageLineItem) => cp.id === data.contractorPackageId,
        );

        return (
          <Box sx={{ alignItems: "center", display: "flex", gap: "12px" }}>
            <Avatar
              alt="Remy Sharp"
              src={contractorPackage?.contractorLogo}
              sx={{
                bgcolor: "#CFCBDC",
                border: "1px solid #00000014",
                height: 40,
                width: 40,
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0" }}>
              <Typography
                sx={{
                  color: "#181D27",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                {contractorPackage?.contractorName || ""}
              </Typography>
              <Typography
                sx={{
                  color: "#535862",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {contractorPackage
                  ? packageTypeToString(contractorPackage.packageType)
                  : ""}
              </Typography>
            </Box>
          </Box>
        );
      },
      field: "contractorPackageId",
      flex: 3,
      headerName: "Subcontractor",
      minWidth: 200,
      onCellClicked: (params: any) => handlViewReport(params.data),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ReportStatusCell,
      cellStyle: centerCellStyles,
      field: "status",
      headerClass: "centered-table-header",
      headerName: "Status",
      minWidth: 150,
      onCellClicked: (params: any) => handlViewReport(params.data),
    },
    {
      ...defaultCellConfig,
      field: "created",
      headerName: "Submitted",
      minWidth: 150,
      onCellClicked: (params: any) => handlViewReport(params.data),
      valueFormatter: (params: any) => {
        return `${formatReportDate(new Date(params.data.created))}`;
      },
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
      minWidth: 100,
    },
  ];

  return (
    <Stack gap={4}>
      <Header
        loading={loading}
        mainTitle={"Report Confirmation"}
        headerFontSizes={[18, 14]}
        subTitle={`${selectedProject?.projectName}: ${
          reportId ? formatReportDate(stringToDate(reportId)) : ""
        }`}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        headerGap={0}
      />

      <Table
        data={reportSubmissions}
        columnDefs={colDefs}
        loading={loading}
        searchValue={searchTerm}
        searchKeys={["reportId"]}
        context={{ cellActions, contractorPackages }}
      />
    </Stack>
  );
};
