import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendAnalyticsLevel2 } from "@hooks/crud/analytics/useSocialSpendAnalyticsLevel2";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsSpendTableLevel2Table } from "@stories/organisms/AnalyticsSpendTables/AnalyticsSpendLevel2Table/AnalyticsSpendLevel2Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import SocialSpendAnalyticsSummary from "@stories/organisms/Summary/SocialSpendAnalyticsSummary";
import * as RouteHelper from "@utils/routes";
import { ViewProjectIcon } from "assets/constants";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { getMaximalDate, stringToDate } from "social-pro-common/utils/date";

export const AnalyticsSpendLevel2 = () => {
  const { reportId, targetId } = useParams();
  if (!reportId) {
    throw Error("No report id");
  }
  if (!targetId) {
    throw Error("No target id");
  }
  const navigate = useNavigate();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, socialSpendAnalytics } =
    useSocialSpendAnalyticsLevel2(
      reportId,
      targetId,
      selectedProject?.id,
      contractorPackage?.id,
    );

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = isProjectLoading || isAnalysisLoading;

  if (socialSpendAnalytics) {
    socialSpendAnalytics.targets = socialSpendAnalytics.targets.sort((a, b) => {
      if (a.packageNumber > b.packageNumber) {
        return 1;
      }
      return -1;
    });
  }

  const viewSubContractorDetails = useCallback(
    (subPackage: ContractorPackageLineItem) => {
      console.log("View package details", subPackage);
      if (selectedProject && contractorPackage) {
        navigate(
          RouteHelper.analyticsSpendLevel3(
            selectedProject.id,
            contractorPackage.id,
            subPackage.id,
            reportId,
            targetId,
            "package",
          ),
        );
      }
    },
    [selectedProject, contractorPackage],
  );

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsSpendLevel1(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId,
      ),
      label: "Social Spend",
    },
    {
      href: RouteHelper.analyticsSpendLevel2(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        targetId || "",
        reportId,
      ),
      label: socialSpendAnalytics
        ? socialSpendAnalytics?.summary.targetDescription ||
          socialSpendCommitmentTypeToString(
            socialSpendAnalytics?.summary.targetType,
          )
        : "",
    },
  ];

  const handleViewAll = async () => {
    navigate(
      RouteHelper.analyticsSpendLevel3(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        contractorPackage?.id || "",
        reportId,
        targetId,
        "all",
      ),
    );
  };
  const buttons: HeaderButtonConfig[] = [
    {
      action: handleViewAll,
      icon: <ViewProjectIcon />,
      text: "View All",
      type: HeaderButtonType.SECONDARY,
      width: 110,
    },
  ];

  return (
    <PageContainer>
      <Header
        breadcrumbIcon={<AttachMoneyIcon />}
        breadcrumbs={breadcrumbData}
        loading={loading}
        subTitle={
          isPrimaryPackage
            ? `Project Analytics for ${selectedProject?.projectName}`
            : `Package Analytics for ${selectedProject?.projectName}`
        }
        mainTitle="Social Spend"
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        handleDateChange={handleDateChange}
        currentDate={currentDate}
      />
      <SocialSpendAnalyticsSummary
        summary={socialSpendAnalytics?.summary}
        loading={loading}
      />
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={2}>
            <Header
              loading={loading}
              mainTitle="Target Summary"
              subTitle="Level 2 Analytics"
              isSubheading
              headerFontSizes={[18, 14]}
              headerGap={0}
            />

            <AnalyticsSpendTableLevel2Table
              loading={loading}
              targetId={targetId}
              searchTerm={searchTerm}
              socialSpendAnalytics={socialSpendAnalytics}
              handleAction={viewSubContractorDetails}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </PageContainer>
  );
};
