import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { useFormik } from "formik";
import { useState } from "react";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import {
  GreenMetricCategoryLineItem,
  createDefaultGreenMetricCategory,
} from "social-pro-common/interfaces/greenMetricCategory";
import * as yup from "yup";

import { GreenMetricFormTable } from "./GreenMetricFormTable";

interface GreenMetricFormProps {
  loading: boolean;
  greenMetric: GreenMetricLineItem;
  handleSubmit: (greenMetric: GreenMetricLineItem) => void;
}

export const GreenMetricForm = ({
  greenMetric,
  handleSubmit,
  loading,
}: GreenMetricFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [greenMetricCategories, setGreenMetricCategories] = useState<
    GreenMetricCategoryLineItem[]
  >(greenMetric.reportCategories);

  const validationSchema = yup.object({
    reportTargetName: yup.string().required("Report target name is required"),
  });

  const clearErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reportTargetName: greenMetric.reportTargetName,
    },
    onSubmit: async (values) => {
      if (greenMetricCategories.length === 0) {
        setErrorMessage("Please add at least one category");
      } else {
        handleSubmit({
          ...greenMetric,
          reportCategories: greenMetricCategories,
          reportTargetName: values.reportTargetName,
        });
      }
    },
    validationSchema: validationSchema,
  });

  const onClick = () => {
    setGreenMetricCategories([
      ...greenMetricCategories,
      createDefaultGreenMetricCategory(greenMetric.id),
    ]);
    clearErrorMessage();
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit} style={{ paddingTop: "12px" }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="reportTargetName"
              name="reportTargetName"
              fullWidth
              value={formik.values.reportTargetName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.reportTargetName &&
                Boolean(formik.errors.reportTargetName)
              }
              helperText={
                formik.touched.reportTargetName
                  ? formik.errors.reportTargetName
                  : ""
              }
              label="Target Name"
            />
          </Grid>
          <Grid item md={12}>
            <GreenMetricFormTable
              loading={loading}
              greenMetricCategories={greenMetricCategories}
              setGreenMetricCategories={setGreenMetricCategories}
            />
          </Grid>
          {errorMessage ? (
            <Grid item md={12}>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          ) : null}
          <Grid item md={12}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              spacing={2}
              mt={2}
              mb={2}
            >
              <FormControlLabel
                control={
                  <IconButton
                    color="primary"
                    size="large"
                    disabled={loading}
                    onClick={onClick}
                  >
                    <ControlPointIcon fontSize="medium" />
                  </IconButton>
                }
                label={"Add Category"}
              />
              <StyledButton
                loading={loading}
                variant="contained"
                type="submit"
                disabled={loading || formik.isSubmitting}
              >
                Save
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
