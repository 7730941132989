import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";

export const UserTableSkeleton = () => {
  return (
    <TableRow>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ pr: 3 }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Skeleton animation="wave" variant="circular">
            <IconButton color="primary" size="large">
              <EditIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
          <Skeleton animation="wave" variant="circular">
            <IconButton color="error" size="large">
              <EditIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
