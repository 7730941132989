"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeSupportingDocument = exports.decodeSupportingDocument = void 0;
const string_1 = require("../../utils/string");
const decodeSupportingDocument = (supportingDocument) => {
    return {
        comment: supportingDocument.comment,
        contractorEmployeeId: supportingDocument.contractor_employee_id,
        created: new Date(supportingDocument.created),
        documentCategory: supportingDocument.document_category,
        documentDescription: supportingDocument.document_description,
        id: supportingDocument.supporting_document_id,
        supportingDocumentType: supportingDocument.supporting_document_type,
        uri: supportingDocument.uri,
    };
};
exports.decodeSupportingDocument = decodeSupportingDocument;
const encodeSupportingDocument = (supportingDocument) => {
    const safeSupportingDocument = (0, string_1.makePostgresSafe)(supportingDocument);
    return {
        comment: safeSupportingDocument.comment,
        contractor_employee_id: safeSupportingDocument.contractorEmployeeId,
        created: safeSupportingDocument.created,
        document_category: safeSupportingDocument.documentCategory,
        document_description: safeSupportingDocument.documentDescription,
        supporting_document_id: safeSupportingDocument.id,
        supporting_document_type: safeSupportingDocument.supportingDocumentType,
        uri: safeSupportingDocument.uri,
    };
};
exports.encodeSupportingDocument = encodeSupportingDocument;
