"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newProjectToProjectDetail = exports.ProjectStatus = exports.createDefaultProject = void 0;
const uuid_1 = require("uuid");
const contractorPackage_1 = require("../../entities/contractorPackage");
const project_1 = require("../../entities/project");
const date_1 = require("../../utils/date");
const contactInfo_1 = require("../contactInfo");
const financial_1 = require("../financial");
const greenMetric_1 = require("../greenMetric");
const projectLabourHourCommitment_1 = require("../projectLabourHourCommitment");
const projectPolicy_1 = require("../projectPolicy");
const projectSocialSpendCommitment_1 = require("../projectSocialSpendCommitment");
const createDefaultProject = (userProfile, organisation) => {
    const projectId = (0, uuid_1.v4)();
    return {
        archived: false,
        collaborators: [],
        commitmentsHours: (0, projectLabourHourCommitment_1.createDefaultLabourHourCommitmentsForProject)(projectId),
        commitmentsSpend: (0, projectSocialSpendCommitment_1.createDefaultSocialSpendCommitmentsForProject)(projectId),
        created: new Date(),
        estimatedProgress: 0,
        financial: (0, financial_1.createDefaultFinancial)(),
        greenMetrics: (0, greenMetric_1.createDefaultGreenMetricsForProject)(projectId),
        id: projectId,
        localContents: [],
        organisationId: organisation?.id || "",
        organisationLogo: organisation?.organisationLogo || "",
        organisationName: organisation?.organisationName || "",
        policies: (0, projectPolicy_1.createDefaultPoliciesForProject)(projectId),
        projectAccessLevel: project_1.ProjectAccessLevel.Admin,
        projectAddress: (0, contactInfo_1.createDefaultContactInfo)(),
        projectCode: "",
        projectEndDate: (0, date_1.dateToString)(new Date()),
        projectName: "",
        projectOwner: userProfile ? userProfile.id : "",
        projectReportMonths: [],
        projectStartDate: (0, date_1.dateToString)(new Date()),
        reportingDueDate: 14,
        representativeEmail: organisation?.representativeEmail || "",
        representativeName: organisation?.representativeName || "",
    };
};
exports.createDefaultProject = createDefaultProject;
var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["Active"] = "ACTIVE";
    ProjectStatus["Archived"] = "ARCHIVED";
})(ProjectStatus || (exports.ProjectStatus = ProjectStatus = {}));
const newProjectToProjectDetail = (project) => {
    return {
        ...project,
        contractorPackageId: project.id,
        isAccepted: true,
        packageType: contractorPackage_1.PackageType.PrimaryPackage,
        projectContactInfoId: project.projectAddress.id,
    };
};
exports.newProjectToProjectDetail = newProjectToProjectDetail;
