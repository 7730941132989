import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";
export const ViewEmployeeTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell sx={{ paddingBottom: "5px", paddingTop: "5px", width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ paddingBottom: "5px", paddingTop: "5px", width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ paddingBottom: "5px", paddingTop: "5px", width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ paddingBottom: "5px", paddingTop: "5px", width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ paddingBottom: "5px", paddingTop: "5px", width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ paddingBottom: "5px", paddingTop: "5px", width: "10%" }}>
        <Stack direction="row" justifyContent={"center"} spacing={1}>
          <Skeleton
            animation="wave"
            height={40}
            width={50}
            sx={{ borderRadius: "16px" }}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
