"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeGreenMetricCategorySubmission = exports.decodeGreenMetricCategorySubmission = void 0;
const string_1 = require("../../utils/string");
const decodeGreenMetricCategorySubmission = (greenMetricCategory) => {
    return {
        contractorPackageId: greenMetricCategory.contractor_package_id,
        created: new Date(greenMetricCategory.created),
        greenMetricCategoryId: greenMetricCategory.green_metric_category_id,
        id: greenMetricCategory.green_metric_category_submission_id,
        projectId: greenMetricCategory.project_id,
        reportId: greenMetricCategory.report_id,
        reportUri: greenMetricCategory.report_uri,
        reportValue: greenMetricCategory.report_value,
    };
};
exports.decodeGreenMetricCategorySubmission = decodeGreenMetricCategorySubmission;
const encodeGreenMetricCategorySubmission = (greenMetricCategorySubmission) => {
    const safeGreenMetric = (0, string_1.makePostgresSafe)(greenMetricCategorySubmission);
    return {
        contractor_package_id: safeGreenMetric.contractorPackageId,
        created: safeGreenMetric.created,
        green_metric_category_id: safeGreenMetric.greenMetricCategoryId,
        green_metric_category_submission_id: safeGreenMetric.id,
        project_id: safeGreenMetric.projectId,
        report_id: safeGreenMetric.reportId,
        report_uri: safeGreenMetric.reportUri,
        report_value: safeGreenMetric.reportValue,
    };
};
exports.encodeGreenMetricCategorySubmission = encodeGreenMetricCategorySubmission;
