"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultProjectReportSubmission = exports.ReportStatus = void 0;
const uuid_1 = require("uuid");
var ReportStatus;
(function (ReportStatus) {
    ReportStatus["ToSubmit"] = "report-status_to-submit";
    ReportStatus["Upcoming"] = "report-status_upcoming";
    ReportStatus["InProgress"] = "report-status_in-progress";
    ReportStatus["Complete"] = "report-status_complete";
    ReportStatus["Overdue"] = "report-status_overdue";
    ReportStatus["Accepted"] = "report-status_accepted";
})(ReportStatus || (exports.ReportStatus = ReportStatus = {}));
const createDefaultProjectReportSubmission = (projectId, reportId) => {
    return {
        created: new Date(),
        project_id: projectId,
        project_report_submission_id: (0, uuid_1.v4)(),
        report_id: reportId,
        report_status: ReportStatus.ToSubmit,
    };
};
exports.createDefaultProjectReportSubmission = createDefaultProjectReportSubmission;
