import * as ExcelJs from "exceljs";
import {
  contentOriginToString,
  createDefaultPackageLocalContent,
  PackageLocalContentLineItem,
} from "social-pro-common/interfaces/packageLocalContent";
import { calcANZValueAdd } from "social-pro-common/utils/calc";
import {
  formatDecimalPlaces,
  isValidNumber,
} from "social-pro-common/utils/number";
import { toTitleCase } from "social-pro-common/utils/string";

interface XlsxLidpResult {
  localContent: PackageLocalContentLineItem[];
  errorMessage?: string;
}

export const readXlsxFileToLidp = async (
  file: File,
  projectId: string,
  packageId?: string,
): Promise<XlsxLidpResult> => {
  const DATA_START_ROW = 8;
  return new Promise((resolve, reject) => {
    const wb = new ExcelJs.Workbook();
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        wb.xlsx.load(data as any).then((workbook: ExcelJs.Workbook) => {
          const localContent = [] as PackageLocalContentLineItem[];
          let count = 0;
          workbook.eachSheet((sheet: ExcelJs.Worksheet) => {
            if (count == 0) {
              sheet.eachRow((row: ExcelJs.Row, rowIndex: number) => {
                if (rowIndex >= DATA_START_ROW) {
                  const newLidp = createDefaultPackageLocalContent(
                    projectId,
                    undefined,
                    packageId,
                  );
                  const values = row.values as ExcelJs.CellValue[];
                  const itemDescription = values[3] as string;
                  if (
                    itemDescription &&
                    typeof itemDescription === "string" &&
                    itemDescription.length > 0
                  ) {
                    if (!itemDescription) {
                      reject(
                        `Error reading the field 'item description on line ${rowIndex}`,
                      );
                    }
                    newLidp.itemDescription = toTitleCase(itemDescription);
                    const manufacturer = values[4] as string;
                    newLidp.manufacturer = toTitleCase(manufacturer || "");

                    const supplier = values[5] as string;
                    newLidp.manufacturer = toTitleCase(supplier || "");

                    const value = values[6] as number;
                    if (!isValidNumber(value) || typeof value !== "number") {
                      reject(
                        `Error reading the field 'value' on line ${rowIndex}`,
                      );
                    }
                    newLidp.value = value;
                    const steelMass = values[7] as number;
                    if (
                      !isValidNumber(steelMass) ||
                      typeof value !== "number"
                    ) {
                      reject(
                        `Error reading the field 'steel mass' on line ${rowIndex}`,
                      );
                    }
                    newLidp.steelMass = steelMass;
                    const importValueAdd =
                      values[8] as ExcelJs.CellFormulaValue;
                    if (
                      !isValidNumber((importValueAdd.result as number) || 0) ||
                      typeof value !== "number"
                    ) {
                      reject(
                        `Error reading the field 'import value add' on line ${rowIndex}`,
                      );
                    }
                    newLidp.importValueAdd =
                      (importValueAdd?.result as number) || 0;
                    const localValueAdd = values[9] as number;
                    if (
                      !isValidNumber(localValueAdd) ||
                      typeof value !== "number"
                    ) {
                      reject(
                        `Error reading the field 'local value add' on line ${rowIndex}`,
                      );
                    }
                    newLidp.localValueAdd = localValueAdd;

                    const contractContent = values[10] as number;
                    if (
                      !isValidNumber(contractContent) ||
                      typeof value !== "number"
                    ) {
                      reject(
                        `Error reading the field 'contract' contact add on line ${rowIndex}`,
                      );
                    }

                    newLidp.contractContent = contractContent;
                    const smeCount = values[12] as number;
                    if (
                      Boolean(smeCount) &&
                      (!isValidNumber(smeCount) || typeof value !== "number")
                    ) {
                      reject(
                        `Error reading the field 'SME' count on line ${rowIndex}`,
                      );
                    }
                    newLidp.smeCount = smeCount;
                    const supplierCount = values[13] as number;
                    if (
                      Boolean(supplierCount) &&
                      (!isValidNumber(supplierCount) ||
                        typeof value !== "number")
                    ) {
                      reject(
                        `Error reading the field 'supplier' count on line ${rowIndex}`,
                      );
                    }
                    newLidp.supplierCount = supplierCount;

                    newLidp.anzValueAdd = calcANZValueAdd(
                      localValueAdd,
                      newLidp.contractContent,
                    );

                    localContent.push(newLidp);
                  }
                }
              });
            }
            count += 1;
          });
          resolve({ localContent });
        });
      } else {
        reject("Error reading file");
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export const writeXlsxLidp = async (
  localContents: PackageLocalContentLineItem[],
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const workbook = new ExcelJs.Workbook();
        const workSheet = workbook.addWorksheet("Local Content");
        workSheet.columns = [
          "ITEM DESCRIPTION",
          "MANUFACTURER",
          "SUPPLIER",
          "VALUE ($)",
          "STEEL MASS (T)",
          "ANZ VALUE ADDED ACTIVITY",
          "CONTENT ORIGIN",
          "CONTRACT CONTENT",
          "# SMEs IN SUPPLY CHAIN",
          "TOTAL # SUPPLIERS IN SUPPLY CHAIN",
          "COMMENTS",
        ];
        workSheet.addRow([
          "ITEM DESCRIPTION",
          "MANUFACTURER",
          "SUPPLIER",
          "VALUE ($)",
          "STEEL MASS (T)",
          "ANZ VALUE ADDED ACTIVITY",
          "CONTENT ORIGIN",
          "CONTRACT CONTENT",
          "# SMEs IN SUPPLY CHAIN",
          "TOTAL # SUPPLIERS IN SUPPLY CHAIN",
          "COMMENTS",
        ]);

        for (const lc of localContents) {
          workSheet.addRow([
            lc.itemDescription.toLowerCase(),
            lc.manufacturer,
            lc.supplier,
            lc.value,
            lc.steelMass,
            formatDecimalPlaces(lc.localValueAdd, 0),
            lc.contentOrigin ? contentOriginToString(lc.contentOrigin) : "",
            formatDecimalPlaces(lc.contractContent, 4),
            lc.smeCount,
            lc.supplierCount,
            "",
          ]);
        }

        const data = await workbook.xlsx.writeBuffer();
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Social-Pro-Local-Content-Targets.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    })();
  });
};
