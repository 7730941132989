import { Button, Skeleton, TableCell, TableRow } from "@mui/material";
export const PolicyRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }} />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <Button size="large" />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
