"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseMultiplierForSpend = exports.getSocialSpendFactors = exports.isValidABN = exports.isValidPostCode = exports.isValidCertifcation = exports.isValidEntityType = exports.createDefaultSocialSpendCommitment = exports.createDefaultSocialSpendCommitmentsForProject = exports.defaultSocialSpendCommmimentCriteria = void 0;
const uuid_1 = require("uuid");
const financial_1 = require("../../entities/financial");
const socialSpend_1 = require("../../entities/socialSpend");
const calc_1 = require("../../utils/calc");
const color_1 = require("../../utils/color");
const string_1 = require("../../utils/string");
const packageSocialSpendCommitment_1 = require("../packageSocialSpendCommitment");
exports.defaultSocialSpendCommmimentCriteria = {
    [packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.AboriginalTorresStraitIslanderBusinessSpend]: {
        certifications: [],
        colour: "#A5D6A7",
        entityTypes: [socialSpend_1.SocialSpendEntityType.IndigenousEnterprise],
        postCodes: [],
        targetValue: 1.5,
    },
    [packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.SocialDisabilityEnterpriseSpend]: {
        certifications: [],
        colour: "#FFE57F",
        entityTypes: [
            socialSpend_1.SocialSpendEntityType.DisabilityEnterprise,
            socialSpend_1.SocialSpendEntityType.SocialEnterprise,
        ],
        postCodes: [],
        targetValue: 5,
    },
};
const createDefaultSocialSpendCommitmentsForProject = (projectId) => {
    return Object.values(packageSocialSpendCommitment_1.SocialCommitmentTypeSpend).reduce((acc, sc) => {
        if (sc !== packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.DefaultSpend) {
            const commitmentConfiguration = exports.defaultSocialSpendCommmimentCriteria[sc];
            if (commitmentConfiguration) {
                return [
                    ...acc,
                    {
                        ...commitmentConfiguration,
                        achievedValue: 0,
                        colour: (0, color_1.getRandomColour)(),
                        created: new Date(),
                        id: (0, uuid_1.v4)(),
                        projectId,
                        targetName: sc,
                        targetValueRealised: 0,
                    },
                ];
            }
        }
        return [...acc];
    }, []);
};
exports.createDefaultSocialSpendCommitmentsForProject = createDefaultSocialSpendCommitmentsForProject;
const createDefaultSocialSpendCommitment = (projectId) => {
    return {
        achievedValue: 0,
        colour: "#000000",
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId,
        projectSocialSpendCommitmentId: (0, uuid_1.v4)(),
        targetName: packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.DefaultSpend,
        targetValue: 0,
        targetValueRealised: 0,
    };
};
exports.createDefaultSocialSpendCommitment = createDefaultSocialSpendCommitment;
const isValidEntityType = (socialSpendEntity, entities) => {
    if (entities && entities.length > 0) {
        return entities.includes(socialSpendEntity);
    }
    return true;
};
exports.isValidEntityType = isValidEntityType;
const isValidCertifcation = (socialSpendCertificates, certifications) => {
    if (certifications && certifications.length > 0) {
        return (socialSpendCertificates.filter((x) => certifications.includes(x)).length >
            0);
    }
    return true;
};
exports.isValidCertifcation = isValidCertifcation;
const isValidPostCode = (socialSpendPostCode, postCodes) => {
    if (socialSpendPostCode && postCodes && postCodes.length > 0) {
        return postCodes.includes(socialSpendPostCode);
    }
    return true;
};
exports.isValidPostCode = isValidPostCode;
const isValidABN = (socialSpendABN, abns) => {
    if (abns && abns.length > 0) {
        return abns
            .map((abn) => (0, string_1.cleanString)(abn))
            .includes((0, string_1.cleanString)(socialSpendABN));
    }
    return true;
};
exports.isValidABN = isValidABN;
const getSocialSpendFactors = (socialSpend, project) => {
    if (project) {
        return project.commitmentsSpend.filter((c) => {
            return ((0, exports.isValidEntityType)(socialSpend.entityType, c.entityTypes) &&
                (0, exports.isValidCertifcation)(socialSpend.certifications, c.certifications) &&
                (0, exports.isValidPostCode)(socialSpend.supplierPostCode, c.postCodes) &&
                (0, exports.isValidABN)(socialSpend.abn, c.abns));
        });
    }
    return [];
};
exports.getSocialSpendFactors = getSocialSpendFactors;
const getBaseMultiplierForSpend = (financial) => financial.projectEstimateMethod === financial_1.EstimateType.DeemedHours
    ? (0, calc_1.calculatedAvailableSpend)(financial.totalValue, financial.margin, financial.variations, financial.specialisedEquipmentValue)
    : financial.estimatedContractSpend;
exports.getBaseMultiplierForSpend = getBaseMultiplierForSpend;
