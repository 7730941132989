import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import usePdf from "@hooks/crud/downloadFiles/usePdf";
import { useProjectComments } from "@hooks/crud/projectComments/useProjectComments";
import { useProjectReportSocialSubmission } from "@hooks/crud/projectReportSocialSubmission/useProjectReportSocialSubmission";
import { useReportSocialSubmissionForProject } from "@hooks/crud/reportSocialSubmissionForProject/useReportSocialSubmissionForProject";
import { Box, Button, Grid, Stack, Typography, debounce } from "@mui/material";
import { ProjectWizardSteps } from "@stories/molecules/ProjectWizardSteps/ProjectWizardSteps";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { ProjectReportConfirmation } from "@stories/organisms/ProjectReportConfirmation/ProjectReportConfirmation";
import { ReportSubmissions } from "@stories/organisms/ReportSubmissions/ReportSubmissions";
import * as RouteHelper from "@utils/routes";
import { ArrowBackIosNewIcon, ArrowForwardIosIcon } from "assets/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import {
  ProjectCommentLineItem,
  ProjectCommentType,
  createDefaultProjectComment,
} from "social-pro-common/interfaces/projectComment";
import { createDefaultProjectReportSubmission } from "social-pro-common/interfaces/projectReportSubmission";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { getZipTitle } from "social-pro-common/utils/string";

import { ConfirmationStep } from "../Wizard/ConfirmationStep";

export const ProjectSocialWizard = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();

  if (!reportId) {
    throw Error("Not report number");
  }

  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const [firstLoad, setFirstLoad] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { contractorPackages, isContractorPackageLoading } =
    useContractorPackage(selectedProject?.id);

  const { comments, isCommentsLoading, upsertProjectComments } =
    useProjectComments(selectedProject?.id, reportId, contractorPackage?.id);

  const { isReportSubmissionsForProjectLoading, reportSubmissions } =
    useReportSocialSubmissionForProject(selectedProject?.id, reportId);

  const { createProjectReportSubmission, isProjectReportLoading } =
    useProjectReportSocialSubmission(selectedProject?.id);

  const [activeStep, setActiveStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
  };

  const viewReportSubmission = useCallback(
    (reportSubmission: ReportSubmissionLineItem) => {
      if (selectedProject && contractorPackage) {
        navigate({
          pathname: RouteHelper.readOnlyReportSocial(
            selectedProject.id,
            contractorPackage.id,
            reportSubmission.contractorPackageId,
            reportSubmission.reportId,
          ),
          search: createSearchParams({
            redirect: window.location.pathname,
          }).toString(),
        });
      }
    },
    [selectedProject, contractorPackage],
  );

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    if (contractorPackage) {
      const reportSubmission = createDefaultProjectReportSubmission(
        contractorPackage?.id,
        reportId,
      );
      await createProjectReportSubmission(reportSubmission);
      setShowSuccess(true);
    }
    setIsSubmitting(false);
  }, [contractorPackage, createProjectReportSubmission, reportId]);

  const [commentMap, setCommentMap] = useState(
    new Map<ProjectCommentType, string>(),
  );

  //TODO: Fix this a bit better
  const handleUpdateComment = useCallback(
    async (newComment: ProjectCommentLineItem) => {
      await upsertProjectComments([newComment]);
    },
    [upsertProjectComments, comments],
  );

  const onUpdateComment = useRef(debounce(handleUpdateComment, 1000));

  useEffect(() => {
    if (comments) {
      const commentMap = new Map<ProjectCommentType, string>();
      comments.forEach((comment) => {
        commentMap.set(comment.commentType, comment.comment);
      });
      setCommentMap(commentMap);
      onUpdateComment.current = debounce(handleUpdateComment, 1000);
    }
  }, [comments]);

  const updateComment = async (
    commentType: ProjectCommentType,
    comment: string,
    save: boolean,
  ) => {
    if (save) {
      const matchingPackageComment = comments.find(
        (c) => c.commentType === commentType,
      );
      if (matchingPackageComment) {
        onUpdateComment.current({
          ...matchingPackageComment,
          comment: comment,
        });
      } else {
        if (selectedProject && reportId) {
          onUpdateComment.current(
            createDefaultProjectComment(
              selectedProject?.id,
              reportId,
              commentType,
              comment,
            ),
          );
        }
      }
    }
    setCommentMap((currentValue) => {
      return new Map(currentValue.set(commentType, comment));
    });
  };

  const handleDownloadReport = async (
    reportSubmission: ReportSubmissionLineItem,
  ) => {
    const reportId = reportSubmission.reportId;
    const reportDate = stringToDate(reportId);
    const reportTitle = getZipTitle(reportDate);
    if (selectedProject && reportId) {
      getPdfInfo(
        selectedProject.id,
        reportSubmission.reportId,
        reportTitle,
        reportSubmission.contractorPackageId,
      );
    }
  };

  const loading =
    firstLoad &&
    (isProjectLoading ||
      isCommentsLoading ||
      isReportSubmissionsForProjectLoading ||
      isContractorPackageLoading ||
      isProjectReportLoading);

  useEffect(() => {
    if (!loading) {
      setFirstLoad(false);
    }
  }, [loading]);

  const reportDate = stringToDate(reportId);

  //Only show packages that are for this month
  const filteredPackages = contractorPackages.filter(
    (pkg) =>
      stringToDate(pkg.packageStartDate).getTime() <= reportDate.getTime() &&
      stringToDate(pkg.packageEndDate).getTime() >= reportDate.getTime(),
  );

  const wizardComponents = [
    <ReportSubmissions
      key={"report-submissions"}
      loading={loading}
      handleDownloadReport={handleDownloadReport}
      selectedProject={selectedProject}
      contractorPackages={filteredPackages}
      reportSubmissions={reportSubmissions}
      reportId={reportId}
      downloadStep={downloadStep}
      downloadProgress={downloadProgress}
      handlViewReport={viewReportSubmission}
    />,
  ];

  wizardComponents.push(
    showSuccess ? (
      <ConfirmationStep />
    ) : (
      <ProjectReportConfirmation
        loading={loading}
        project={selectedProject}
        reportCommentType={ProjectCommentType.ProjectSummary}
        reportId={reportId}
        updateComment={updateComment}
        commentMap={commentMap}
      />
    ),
  );

  const selectedWizardStep = wizardComponents[activeStep];

  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={formatReportDate(reportDate)}
        mainTitle={"Project Report Submission"}
      />
      <ProjectWizardSteps
        activeStep={activeStep}
        loading={loading}
        project={selectedProject}
      />
      <Grid
        item
        md={12}
        sx={{
          bgcolor: "white",
          border: "1px solid #E9EAEB",
          borderRadius: "12px",
          boxShadow: "0px 1px 2px 0px #0A0D120D",
          padding: "0 32px 20px 32px",
        }}
      >
        {selectedWizardStep}

        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: `${activeStep > 0 ? "space-between" : "end"}`,
            padding: "15px 0 0",
          }}
        >
          {activeStep > 0 ? (
            <StyleButtonV2
              loading={loading}
              disabled={showSuccess}
              status={isSubmitting}
              handleClick={handleBack}
              text="Back"
              isBack
              icon={<ArrowBackIosNewIcon />}
            />
          ) : null}

          {activeStep < wizardComponents.length - 1 ? (
            <StyleButtonV2
              loading={loading}
              disabled={showSuccess}
              status={isSubmitting}
              handleClick={handleNext}
              text="Next"
              icon={<ArrowForwardIosIcon />}
            />
          ) : !showSuccess ? (
            <StyleButtonV2
              loading={loading}
              status={isSubmitting}
              handleClick={onSubmit}
              text="Submit"
              bgColor={"#004EEB"}
              textColor={"#fff"}
              icon={<ArrowForwardIosIcon variant="priamry" />}
            />
          ) : null}
        </Stack>
      </Grid>
    </PageContainer>
  );
};

const StyleButtonV2 = ({
  bgColor,
  handleClick,
  icon,
  isBack = false,
  loading,
  status,
  text,
  textColor,
}: any) => {
  return (
    <Button
      disableRipple
      sx={{
        "&:disabled": {
          backgroundColor: "white",
          color: "#414651",
          cursor: "not-allowed",
          opacity: "60%",
        },
        "&:hover": {
          backgroundColor: `${textColor ? bgColor : "#fff"}`,
        },
        alignItems: "center",
        backgroundColor: `${textColor ? bgColor : "#fff"}`,
        border: "1px solid #D5D7DA",
        borderRadius: "8px",
        color: `${textColor ? "#fff" : "#414651"}`,
        display: {
          sm: "flex",
          xs: "none",
        },
        flexDirection: `${isBack ? "row-reverse" : "row"}`,
        gap: "4px",
        justifyContent: "center",
        padding: "8px 12px",
      }}
      disabled={loading || status}
      onClick={handleClick}
    >
      <Typography
        variant="button"
        style={{ fontFamily: '"Inter", sans-serif' }}
        sx={{
          color: `${textColor ? "#fff" : "#414651"}`,
          fontSize: "14px",
          fontWeight: "600",
          justifyContent: "center",
          lineHeight: "20px",
          textTransform: "capitalize",
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {icon}
      </Box>
    </Button>
  );
};
