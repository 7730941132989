import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePolicySubmission } from "@hooks/crud/policySubmission/usePolicySubmission";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { Policies } from "@stories/organisms/Policies/Policies";

export const ProjectPolicies = () => {
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const {
    createPolicySubmission,
    isPolicyLoading,
    policySubmissions,
    updatePolicySubmission,
  } = usePolicySubmission(selectedProject?.id, contractorPackage?.id);

  const loading = isAuthProfileLoading || isPolicyLoading || isProjectLoading;

  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={userOrganisation?.organisationName || "SocialPro Projects"}
        mainTitle="Project Policies"
      />
      <Policies
        loading={loading}
        project={selectedProject}
        contractorPackage={contractorPackage}
        policySubmissions={policySubmissions}
        createPolicySubmission={createPolicySubmission}
        updatePolicySubmission={updatePolicySubmission}
      />
    </PageContainer>
  );
};
