import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import { decodeDashboardModel } from "social-pro-common/decoders/dashboard";
import { ContractorDashboardModel } from "social-pro-common/entities/dashboard";
import { ContractorDashboardModelLineItem } from "social-pro-common/interfaces/dashboard";

export const useDashboard = (
  projectId?: string,
  contractorPackageId?: string,
  reportId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [dashboardData, setDashboardData] =
    useState<ContractorDashboardModelLineItem>();

  const getDashboardModel = useCallback(
    async (
      projectId: string,
      contractorPackageId: string,
      reportId: string,
    ) => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getDashboardModel",
          "dashboard",
          projectId,
          { contractorPackageId, reportId },
        );
        const decodedDashboardModel = decodeDashboardModel(
          analysisResult.data as ContractorDashboardModel,
        );
        setDashboardData(decodedDashboardModel);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getImageUrl],
  );

  useEffect(() => {
    if (projectId && contractorPackageId && reportId) {
      getDashboardModel(projectId, contractorPackageId, reportId);
    }
  }, [projectId, contractorPackageId, reportId, getDashboardModel]);

  return {
    dashboardData,
    error,
    isAnalysisLoading,
  };
};
