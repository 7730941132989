import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendAnalyticsLevel3 } from "@hooks/crud/analytics/useSocialSpendAnalyticsLevel3";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { writeXlsxSocialSpendTargetsSummary } from "@utils/xlsx/spend";
import { ExportIcon } from "assets/constants";
import { Package } from "lucide-react";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

import { AnalyticsSpendLevel3Table } from "../AnalyticsSpendTables/AnalyticsSpendLevel3Table/AnalyticsSpendLevel3Table";
import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";
import SocialSpendAnalyticsSummary from "../Summary/SocialSpendAnalyticsSummary";

interface PackageDetailsSpendTargetProps {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
  targetId?: string;
  reportId?: string;
  handleOnBack: () => void;
}

export const PackageDetailsSpendTarget = ({
  handleOnBack,
  isLoading,
  subPackage,
  targetId,
}: PackageDetailsSpendTargetProps) => {
  const [reportId, _setReportDate] = useState<string>(dateToString(new Date()));

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();
  const { isAnalysisLoading, socialSpendAnalytics } =
    useSocialSpendAnalyticsLevel3(
      selectedProject?.id,
      subPackage?.id,
      reportId,
      targetId,
      "package",
    );
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = (isLoading && isProjectLoading) || isAnalysisLoading;

  const selectedTarget = selectedProject?.commitmentsSpend.find(
    (c) => c.id === targetId,
  );

  const buttons: HeaderButtonConfig[] = [
    {
      action: async () => {
        if (socialSpendAnalytics) {
          writeXlsxSocialSpendTargetsSummary(socialSpendAnalytics.spend);
        }
      },
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];

  const breadcrumbData = [
    {
      action: handleOnBack,
      label: "My package",
    },
  ];
  return (
    <>
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <SocialSpendAnalyticsSummary
          summary={socialSpendAnalytics?.summary}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={2}>
            <Header
              mainTitle={`Social Spend`}
              subTitle={`${
                selectedTarget
                  ? selectedTarget.targetDescription ||
                    socialSpendCommitmentTypeToString(selectedTarget.targetName)
                  : ""
              }`}
              breadcrumbIcon={<Package />}
              breadcrumbs={breadcrumbData}
              loading={loading}
              buttons={buttons}
              handleBack={handleOnBack}
              isSubheading
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              handleDateChange={handleDateChange}
              currentDate={currentDate}
            />

            <AnalyticsSpendLevel3Table
              loading={loading}
              socialSpendAnalytics={socialSpendAnalytics}
              searchTerm={searchTerm}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </>
  );
};
