import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";

export const GreenMetricTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell
        align="left"
        sx={{ paddingBottom: "5px", paddingTop: "5px", width: "80%" }}
      >
        <Stack direction="row" spacing={1}>
          <Skeleton animation="wave" width={300} />
        </Stack>
      </TableCell>

      <TableCell
        align="center"
        sx={{
          paddingBottom: "5px",
          paddingTop: "5px",
          width: "20%",
        }}
      >
        <Stack direction="row" spacing={3} justifyContent={"center"}>
          <Skeleton animation="wave" variant="circular">
            <IconButton color="error" size="medium">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Skeleton>
          <Skeleton animation="wave" variant="circular">
            <IconButton color="error" size="medium">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
