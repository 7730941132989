import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsLevel1 } from "@hooks/crud/analytics/useLabourHourAnalyticsLevel1";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsHoursLevel1Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel1Table/AnalyticsHoursLevel1Table";
import { writeXlsxLabourHourTargets } from "@utils/xlsx/labourHours";
import { ExportIcon } from "assets/constants";
import { useState } from "react";
import { LabourHourTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";

interface PackageDetailsHoursProps {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
  onLabourHourTargetChange: (
    target?: LabourHourTargetAnalyticsLineItem,
  ) => void;
}

export const PackageDetailsHours = ({
  isLoading,
  onLabourHourTargetChange,
  subPackage,
}: PackageDetailsHoursProps) => {
  const [reportId, _setReportDate] = useState<string>(dateToString(new Date()));

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();
  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsLevel1(reportId, selectedProject?.id, subPackage?.id);

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = isLoading || isProjectLoading || isAnalysisLoading;

  const buttons: HeaderButtonConfig[] = [
    {
      action: async () => {
        if (labourHoursAnalytics) {
          writeXlsxLabourHourTargets(labourHoursAnalytics.targets);
        }
      },
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];

  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={4}>
          <Header
            mainTitle="Labour Hours"
            subTitle="Labour Hour Target Summary"
            loading={loading}
            buttons={buttons}
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            isSubheading
            currentDate={currentDate}
            handleDateChange={handleDateChange}
            headerFontSizes={[18, 14]}
            headerGap={0}
          />
          <AnalyticsHoursLevel1Table
            loading={loading}
            searchTerm={searchTerm}
            labourHourAnalytics={labourHoursAnalytics}
            handleAction={onLabourHourTargetChange}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
