"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateProjectProgress = exports.calculatePackageProgress = exports.calculateProjectProgressLineItem = exports.calculatePackageProgressLineItem = exports.calcANZValueAdd = exports.calcLocalContent = exports.calcRequiredDeemedHours = exports.calcRequiredSpend = exports.calculatedAvailableSpend = exports.calculateTargetValueFromTargetValueRealised = exports.calculateTargetValueRealised = exports.calculateLocalContentPackageLocalValueAddTotal = exports.calculateLocalContentPackageAnzTotal = exports.calculateLocalContentPackageContractTotal = exports.calculateLocalContentPackageValueTotal = void 0;
const number_1 = require("./number");
const calculateLocalContentPackageValueTotal = (localContents) => {
    return localContents.reduce((acc, lc) => acc + lc.value, 0);
};
exports.calculateLocalContentPackageValueTotal = calculateLocalContentPackageValueTotal;
const calculateLocalContentPackageContractTotal = (localContents) => {
    return localContents.reduce((acc, lc) => acc + lc.contractContent, 0);
};
exports.calculateLocalContentPackageContractTotal = calculateLocalContentPackageContractTotal;
const calculateLocalContentPackageAnzTotal = (localContents) => {
    return localContents.reduce((acc, lc) => acc + lc.anzValueAdd, 0);
};
exports.calculateLocalContentPackageAnzTotal = calculateLocalContentPackageAnzTotal;
const calculateLocalContentPackageLocalValueAddTotal = (localContents) => {
    return localContents.reduce((acc, lc) => acc + lc.localValueAdd, 0);
};
exports.calculateLocalContentPackageLocalValueAddTotal = calculateLocalContentPackageLocalValueAddTotal;
const calculateTargetValueRealised = (targetValue, baseMultiplier) => {
    return (0, number_1.formatDecimalPlacesNumerical)(baseMultiplier * (targetValue / 100));
};
exports.calculateTargetValueRealised = calculateTargetValueRealised;
const calculateTargetValueFromTargetValueRealised = (targetValueRealised, baseMultiplier) => {
    return (0, number_1.formatDecimalPlacesNumerical)((targetValueRealised / baseMultiplier) * 100);
};
exports.calculateTargetValueFromTargetValueRealised = calculateTargetValueFromTargetValueRealised;
const calculatedAvailableSpend = (totalValue, margin, variations, offset) => {
    return totalValue - margin + variations - offset;
};
exports.calculatedAvailableSpend = calculatedAvailableSpend;
const calcRequiredSpend = (mpsgHoursRequirement, requiredHours) => {
    return (mpsgHoursRequirement / 100) * requiredHours;
};
exports.calcRequiredSpend = calcRequiredSpend;
const calcRequiredDeemedHours = (availableSpend, deemedLabourRatio, deemedLabourHourlyRate) => {
    if (deemedLabourHourlyRate === 0) {
        return 0;
    }
    return (availableSpend * deemedLabourRatio) / 100 / deemedLabourHourlyRate;
};
exports.calcRequiredDeemedHours = calcRequiredDeemedHours;
const calcLocalContent = (importValueAdd) => {
    return 100 - importValueAdd;
};
exports.calcLocalContent = calcLocalContent;
const calcANZValueAdd = (localImportPc, contractContent) => {
    return (localImportPc / 100) * contractContent;
};
exports.calcANZValueAdd = calcANZValueAdd;
const calculatePackageProgressLineItem = (hourCommitments, spendCommitments) => {
    const achievedHours = hourCommitments.length
        ? hourCommitments.reduce((acc, c) => acc + c.achievedValue, 0)
        : 0;
    const targetHours = hourCommitments.length
        ? hourCommitments.reduce((acc, c) => acc + c.targetValueRealised, 0)
        : 0;
    const progressHours = targetHours ? (achievedHours / targetHours) * 100 : 0;
    const achievedSpend = spendCommitments.length
        ? spendCommitments.reduce((acc, c) => acc + c.achievedValue, 0)
        : 0;
    const targetSpend = spendCommitments.length
        ? spendCommitments.reduce((acc, c) => acc + c.targetValueRealised, 0)
        : 0;
    const progressSpend = targetSpend ? (achievedSpend / targetSpend) * 100 : 0;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculatePackageProgressLineItem = calculatePackageProgressLineItem;
const calculateProjectProgressLineItem = (hourCommitments, spendCommitments) => {
    const achievedHours = hourCommitments.length
        ? hourCommitments.reduce((acc, c) => acc + c.achievedValue, 0)
        : 0;
    const targetHours = hourCommitments.length
        ? hourCommitments.reduce((acc, c) => acc + c.targetValueRealised, 0)
        : 0;
    const progressHours = targetHours ? (achievedHours / targetHours) * 100 : 0;
    const achievedSpend = spendCommitments.length
        ? spendCommitments.reduce((acc, c) => acc + c.achievedValue, 0)
        : 0;
    const targetSpend = spendCommitments.length
        ? spendCommitments.reduce((acc, c) => acc + c.targetValueRealised, 0)
        : 0;
    const progressSpend = targetSpend ? (achievedSpend / targetSpend) * 100 : 0;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculateProjectProgressLineItem = calculateProjectProgressLineItem;
const calculatePackageProgress = (hourCommitments, spendCommitments) => {
    const achievedHours = hourCommitments.length
        ? hourCommitments.reduce((acc, c) => acc + c.achieved_value, 0)
        : 0;
    const targetHours = hourCommitments.length
        ? hourCommitments.reduce((acc, c) => acc + c.target_value_realised, 0)
        : 0;
    const progressHours = targetHours ? (achievedHours / targetHours) * 100 : 0;
    const achievedSpend = spendCommitments.length
        ? spendCommitments.reduce((acc, c) => acc + c.achieved_value, 0)
        : 0;
    const targetSpend = spendCommitments.length
        ? spendCommitments.reduce((acc, c) => acc + c.target_value_realised, 0)
        : 0;
    const progressSpend = targetSpend ? (achievedSpend / targetSpend) * 100 : 0;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculatePackageProgress = calculatePackageProgress;
const calculateProjectProgress = (hourCommitments, spendCommitments) => {
    const achievedHours = hourCommitments.reduce((acc, c) => acc + c.achieved_value, 0);
    const targetHours = hourCommitments.reduce((acc, c) => acc + c.target_value_realised, 0);
    const progressHours = (achievedHours / targetHours) * 100;
    const achievedSpend = spendCommitments.reduce((acc, c) => acc + c.achieved_value, 0);
    const targetSpend = spendCommitments.reduce((acc, c) => acc + c.target_value_realised, 0);
    const progressSpend = (achievedSpend / targetSpend) * 100;
    return (Math.min(100, progressHours) + Math.min(100, progressSpend)) / 2;
};
exports.calculateProjectProgress = calculateProjectProgress;
