import { Grid } from "@mui/material";
import { OverviewCard2 } from "@stories/molecules/OverviewCard/OverviewCard";
import { ContractorDashboardModelLineItem } from "social-pro-common/interfaces/dashboard";

interface MatricesHomeProps {
  loading: boolean;
  data?: ContractorDashboardModelLineItem;
}
const MatricesHome = ({ data, loading }: MatricesHomeProps) => {
  return (
    <Grid container spacing={4}>
      <Grid item md={6} sx={{ height: "auto" }}>
        <OverviewCard2
          loading={loading}
          title={"Project Overview"}
          subtitle={"Overall Project Progress"}
          progress={data?.projectProgress?.timeProgress || "0"}
          progressbarColor={"#1570EF"}
          value={data?.projectProgress?.totalValue || "0"}
          valueLabel={"Project Value"}
          change={"0"}
          progressTitle={"complete"}
        />
      </Grid>
      <Grid item md={6} sx={{ height: "auto" }}>
        <OverviewCard2
          loading={loading}
          title={"Subcontractor Overview"}
          subtitle={"Monthly Report Submissions"}
          progress={data?.reportProgress?.reportSubmissionPcProgress || "0"}
          progressbarColor={"#535862"}
          value={data?.reportProgress?.count || "0"}
          valueLabel={"Active Subcontractors"}
          change={data?.reportProgress?.reportSubmissionPcChange || "0"}
          progressTitle={"complete"}
          dataIdPrefix="report"
        />
      </Grid>
    </Grid>
  );
};

export default MatricesHome;
