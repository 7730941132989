import { FormControlLabel, styled, Switch, SwitchProps } from "@mui/material";
import React from "react";

interface CustomSwitchProps {
  disabled: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  colour?: string;
  checked: boolean;
}

export const CustomSwitch = ({
  checked,
  colour,
  disabled,
  handleChange,
}: CustomSwitchProps) => {
  return (
    <FormControlLabel
      control={
        <IOSSwitch
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          colour={colour}
        />
      }
      sx={{ margin: "0 !important" }}
      label=""
    />
  );
};

interface IOSwitchProps extends SwitchProps {
  colour?: string;
}

const IOSSwitch = styled((props: IOSwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ colour, theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: colour || "#65C466",
        border: 0,
        opacity: 1,
        ...theme.applyStyles("dark", {
          backgroundColor: colour || "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      color: "#fff",
      transform: "translateX(16px)",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
      color: "#33cf4d",
    },
    margin: 2,
    padding: 0,
    transitionDuration: "300ms",
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    height: 22,
    width: 22,
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#E9E9EA",
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
  height: 26,
  padding: 0,
  width: 42,
}));
