import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useProjectEmployee } from "@hooks/crud/projectEmployee/useProjectEmployee";
import { ProjectEmployeeTable } from "@stories/molecules/ProjectEmployeeTable/ProjectEmployeeTable";
import EmployeeListModal from "@stories/organisms/EmployeeListModal/EmployeeListModal";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { UserIcon } from "assets/constants";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ContractorEmployeeProjectLineItem } from "social-pro-common/interfaces/contractorEmployeeProject";

export const ProjectEmployees = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const { isAuthProfileLoading, userProfile } = useProfileContext();

  const [openListModal, setOpenListModal] = useState(false);

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeLineItem>();

  const {
    employeeCount,
    employees,
    isEmployeeLoading,
    updateEmployeeAssignment,
  } = useProjectEmployee(
    projectId,
    contractorPackage?.id,
    userProfile?.organisationId,
  );

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleEmployeeAssignment = useCallback(
    async (employee: EmployeeLineItem, toggle: boolean) => {
      if (selectedProject && contractorPackage) {
        const employeeAssignment = {
          assigned: toggle,
          contractorEmployeeId: employee.id,
          contractorPackageId: contractorPackage.id,
          id: `${employee.id}-${contractorPackage.id}`,
          projectId: selectedProject.id,
        } as ContractorEmployeeProjectLineItem;

        updateEmployeeAssignment([employeeAssignment]);
      }
    },
    [selectedProject, contractorPackage, employees],
  );

  const handleCloseListModal = () => {
    setOpenListModal(false);
  };

  const handleOpenListModal = (employee: EmployeeLineItem) => {
    setSelectedEmployee(employee);
    setOpenListModal(true);
  };

  const loading = isProjectLoading || isEmployeeLoading || isAuthProfileLoading;

  const buttons = [
    {
      action: () => navigate(RouteHelper.employees),
      icon: <UserIcon variant="secondary" />,
      text: "Manage Employees",
      type: HeaderButtonType.PRIMARY,
    },
  ] as HeaderButtonConfig[];
  return (
    <PageContainer>
      <Header
        loading={loading}
        mainTitle="Project Employees"
        subTitle={`Project Assignment (${Math.max(employeeCount, 0)})`}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
      />

      <ProjectEmployeeTable
        employees={employees}
        project={selectedProject}
        loading={loading}
        searchTerm={searchTerm}
        employeeCount={employeeCount}
        handleEmployeeAssignment={handleEmployeeAssignment}
        handleOpenListModal={handleOpenListModal}
      />
      {openListModal && selectedEmployee ? (
        <EmployeeListModal
          open={openListModal}
          existingEmployee={selectedEmployee}
          handleClose={handleCloseListModal}
        />
      ) : null}
    </PageContainer>
  );
};
