import usePdf from "@hooks/crud/downloadFiles/usePdf";
import { useReportSustainabilitySubmission } from "@hooks/crud/reportSubmissionSustainability/useReportSubmissionSustainability";
import { Grid, Typography } from "@mui/material";
import { ReportStatusChip } from "@stories/atoms/ReportStatusChip/SuccessChip";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import * as RouteHelper from "@utils/routes";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import {
  formatReportDate,
  reportIdToReportingDate,
  stringToDate,
} from "social-pro-common/utils/date";
import { getZipTitle } from "social-pro-common/utils/string";

import { ReportTableSubRowSkeleton } from "./ReportTableRowSkeleton";
import { TableContextProps } from "./ReportTableSocial";
import { AcceptReportDialog } from "../AcceptReportDialog/AcceptReportDialog";
import { PdfDownlodDialog } from "../PdfDownlodDialog/PdfDownlodDialog";
import { RejectReportDialog } from "../RejectReportDialog/RejectReportDialog";

interface ReportTableSustainabilityProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  subPackage?: ContractorPackageLineItem;
  isPrimaryPackage: boolean;
}

export const ReportTableSustainability = ({
  contractorPackage,
  isLoading,
  isPrimaryPackage,
  project,
  subPackage,
}: ReportTableSustainabilityProps) => {
  const navigate = useNavigate();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<
    ReportSubmissionLineItem | undefined
  >(undefined);

  const {
    deleteReportSubmission,
    isReportLoading,
    reportSubmissions,
    sendReportReminder,
    updateReportSubmission,
  } = useReportSustainabilitySubmission(subPackage?.id);
  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const loading = isLoading || isReportLoading;

  const orderedSubmissions = reportSubmissions.slice().reverse();

  const tableData =
    project && contractorPackage && subPackage
      ? orderedSubmissions
          .filter((r) => !!r)
          .filter(
            (r) =>
              r.reportStatus === ReportStatus.Overdue ||
              r.reportStatus === ReportStatus.Accepted ||
              r.reportStatus === ReportStatus.Complete,
          )
      : [];

  const handleOpenRejectModal = (sub: ReportSubmissionLineItem) => {
    setSelectedSubmission(sub);
    setOpenRejectModal(true);
  };

  const handleOpenAcceptModal = (sub: ReportSubmissionLineItem) => {
    setSelectedSubmission(sub);
    setOpenAcceptModal(true);
  };

  const handleDownloadReport = async (
    contractorPackageId: string,
    reportId: string,
  ) => {
    const reportDate = stringToDate(reportId);
    const reportTitle = getZipTitle(reportDate);
    if (project && contractorPackageId && reportId) {
      getPdfInfo(project.id, reportId, reportTitle, contractorPackageId);
    }
  };

  const handleRevertReport = async (
    reportSubmission: ReportSubmissionLineItem,
    rejectMessage: string,
  ): Promise<void> => {
    await deleteReportSubmission({
      ...reportSubmission,
      rejectMessage,
    });
  };

  const handlViewReport = (reportMonthId: string): void => {
    if (project && contractorPackage && subPackage) {
      navigate({
        pathname: RouteHelper.readOnlyReportSocial(
          project.id,
          contractorPackage.id,
          subPackage.id,
          reportMonthId,
        ),
        search: createSearchParams({
          redirect: window.location.pathname,
        }).toString(),
      });
    }
  };

  const handleAcceptReport = async (
    reportSubmission: ReportSubmissionLineItem,
  ): Promise<void> => {
    await updateReportSubmission({
      ...reportSubmission,
      accepted: true,
      reportStatus: ReportStatus.Accepted,
    });
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "reportId",
      flex: 2,
      headerName: "Report Month",
      valueFormatter: (params) =>
        `${formatReportDate(stringToDate(params.data.reportId))}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "dueDate",
      headerClass: "centered-table-header",
      headerName: "Due Date",
      valueFormatter: (params) =>
        `${reportIdToReportingDate(
          params.data.reportId,
          params.context.project?.reportingDueDate,
        ).toLocaleDateString("en-GB")}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: (params: ValueFormatterParams<any, any>) => {
        return (
          <Typography align="center">
            <ReportStatusChip reportStatus={params.data.reportStatus} />
          </Typography>
        );
      },

      cellStyle: centerCellStyles,
      field: "status",
      headerClass: "centered-table-header",
      headerName: "Status",
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      field: "",
      flex: 1.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <Grid item xs={12} md={12}>
      {loading || !reportSubmissions ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ReportTableSubRowSkeleton}
          noFullTableBorder
        />
      ) : (
        <Table<ReportSubmissionLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={tableData as ReportSubmissionLineItem[]}
          noFullTableBorder
          context={
            {
              handlViewReport,
              handleDownloadReport,
              handleOpenAcceptModal,
              handleOpenRejectModal,
              isPrimaryPackage,
              project,
              sendReportReminder,
              subPackage,
            } as unknown as TableContextProps
          }
        />
      )}
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Export Report"}
      />

      {isPrimaryPackage && selectedSubmission && (
        <RejectReportDialog
          open={openRejectModal}
          reportSubmission={selectedSubmission}
          onCancel={() => setOpenRejectModal(false)}
          onConfirm={async (
            reportSubmission: ReportSubmissionLineItem,
            rejectionMessage: string,
          ): Promise<void> => {
            setOpenRejectModal(false);
            await handleRevertReport(reportSubmission, rejectionMessage);
          }}
        />
      )}

      {isPrimaryPackage && selectedSubmission && (
        <AcceptReportDialog
          open={openAcceptModal}
          reportSubmission={selectedSubmission}
          onCancel={() => setOpenAcceptModal(false)}
          onConfirm={async (
            reportSubmission: ReportSubmissionLineItem,
          ): Promise<void> => {
            setOpenAcceptModal(false);
            await handleAcceptReport(reportSubmission);
          }}
        />
      )}
    </Grid>
  );
};
