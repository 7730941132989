import { Box, Grid, Stack } from "@mui/material";
import {
  StyledButton,
  StyledLoadingButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import { ContractorPackageSetupLineItem } from "social-pro-common/interfaces/contractorPackage";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { PackageSustainabilityTableRowSkeleton } from "../PackageSustainabilityTableRow/PackageSustainabilityTableRowSkeleton";

interface PackageSustainabilityRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  contractorPackageSetup: ContractorPackageSetupLineItem;
  handleNext: (contractorPackageSetup: ContractorPackageSetupLineItem) => void;
  handleBack: () => void;
}

export const PackageSustainabilityRequirementForm = ({
  contractorPackageSetup,
  handleBack,
  handleNext,
  loading,
  project,
}: PackageSustainabilityRequirementFormProps) => {
  const handleSubmit = () => {
    handleNext(contractorPackageSetup);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      cellStyle: {
        textAlign: "left",
      },
      field: "reportTargetName",
      headerClass: "left-table-header",
      headerName: "Target Name",
    },
  ]);

  return (
    <Box sx={{ padding: "25px 40px 0px !important" }}>
      <Grid container spacing={2} md={12}>
        <Grid
          item
          md={7}
          sx={{
            alignContent: "left",
            display: "flex",
            justifyContent: "left",
          }}
        >
          <SubTitle title={"Sustainability"} loading={loading} />
        </Grid>
        <Grid item xs={12} md={12}>
          {loading ? (
            <TableSkeleton
              tableHead={generateTableHead(colDefs)}
              rows={PackageSustainabilityTableRowSkeleton}
            />
          ) : (
            <Table<GreenMetricLineItem>
              columnDefs={colDefs}
              loading={loading}
              data={project.greenMetrics as GreenMetricLineItem[]}
            />
          )}
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            marginTop: "16px",
            padding: "0px 0px 40px 0px",
          }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton
              loading={loading}
              onClick={handleBack}
              disabled={loading}
              variant="outlined"
            >
              Back
            </StyledButton>
            <StyledLoadingButton
              loading={loading}
              disabled={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </StyledLoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
