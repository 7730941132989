import { Grid } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  CellAction,
  centerCellStyles,
  defaultCellConfig,
  ProgressCell,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import * as RouteHelper from "@utils/routes";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  ArchiveProjectIcon,
  EditProjectIcon,
  ViewProjectIcon,
} from "assets/constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { stringToDate } from "social-pro-common/utils/date";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import {
  NameActionCell,
  ToolsCell,
} from "./ContractorPackageTable/ContractorPackageTableRowCells";
import { ContractorPackageTableRowSkeleton } from "./ContractorPackageTable/ContractorPackageTableRowSkeleton";

interface ContractorPackageTableProps {
  loading: boolean;
  userProfile?: ProfileLineItem;
  project?: ProjectLineItem;
  searchTerm: string;
  contractorPackage?: ContractorPackageLineItem;
  contractorPackages: ContractorPackageLineItem[];
  handleEditPackage: (contractorPackage: ContractorPackageLineItem) => void;
  handleDeletePackage: (contractorPackage: ContractorPackageLineItem) => void;
  resendInvite: (contractorPackage: ContractorPackageLineItem) => void;
}

export const ContractorOneTimePackageTable = ({
  contractorPackage,
  contractorPackages,
  handleDeletePackage,
  handleEditPackage,
  loading,
  project,
  resendInvite,
  searchTerm,
  userProfile,
}: ContractorPackageTableProps) => {
  const [selectedPackage, setSelectedPackage] =
    useState<ContractorPackageLineItem | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const navigate = useNavigate();

  const handleOpenConfirmDialog = (packageData: ContractorPackageLineItem) => {
    setSelectedPackage(packageData);
    setOpenConfirmDialog(true);
  };

  let filteredPackages = contractorPackages.filter(
    (p) => p.packageType !== PackageType.PrimaryPackage,
  );

  filteredPackages = filteredPackages.sort((a, b) => {
    if (a.packageNumber > b.packageNumber) {
      return 1;
    }
    return -1;
  });

  const viewPackageDetails = (subPackage: ContractorPackageLineItem) => {
    if (project && contractorPackage && subPackage) {
      navigate(
        RouteHelper.contractorPackage(
          project.id,
          contractorPackage.id,
          subPackage.id,
        ),
      );
    }
  };

  const confirmDelete = () => {
    if (selectedPackage) {
      handleDeletePackage(selectedPackage);
    }
    setOpenConfirmDialog(false);
  };

  const cellActions = [
    {
      action: viewPackageDetails,
      icon: <ViewProjectIcon />,
      title: "View Package",
    },
    {
      action: handleEditPackage,
      adminOnly: true,
      icon: <EditProjectIcon />,
      title: "Edit Package",
    },
    {
      action: handleOpenConfirmDialog,
      adminOnly: true,
      icon: <ArchiveProjectIcon />,
      title: "Delete Package",
    },
    {
      action: resendInvite,
      icon: <ViewProjectIcon />,
      title: "Resend Invite",
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "packageNumber",
      flex: 0.5,
      headerName: "#",
      onCellClicked: (params) => viewPackageDetails(params.data),
      valueFormatter: (params) => params.data.packageNumber || "",
    },
    {
      ...defaultCellConfig,
      cellRenderer: NameActionCell,
      cellStyle: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        justifyContent: "flex-start",
      },
      field: "contractorName",
      flex: 3,
      headerClass: "left-table-header",
      headerName: "Subcontractor",
      onCellClicked: (params) => viewPackageDetails(params.data),
    },
    {
      ...defaultCellConfig,
      cellStyle: { cursor: "pointer", textAlign: "left" },
      field: "packageDescription",
      flex: 2,
      headerClass: "left-table-header",
      headerName: "Work Package",
      onCellClicked: (params) => viewPackageDetails(params.data),
      valueFormatter: (params) =>
        `${
          params.data.packageDescription
            ? params.data.packageDescription
            : packageTypeToString(params.data.packageType)
        }`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "value",
      headerClass: "right-table-header",
      headerName: "Value ($)",
      onCellClicked: (params) => viewPackageDetails(params.data),
      valueFormatter: (params) =>
        `$ ${formatDecimalPlaces(params.data.financial.totalValue)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "startDate",
      headerClass: "centered-table-header",
      headerName: "Start Date",
      onCellClicked: (params) => viewPackageDetails(params.data),
      valueFormatter: (params) =>
        stringToDate(params.data.packageStartDate).toLocaleDateString("en-GB"),
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "targetDate",
      headerClass: "centered-table-header",
      headerName: "Target Date",
      onCellClicked: (params) => viewPackageDetails(params.data),
      valueFormatter: (params) =>
        stringToDate(params.data.packageEndDate).toLocaleDateString("en-GB"),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ProgressCell,
      cellStyle: rightCellStyles,
      field: "progress",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Progress",
      onCellClicked: (params) => viewPackageDetails(params.data),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ToolsCell,
      cellRendererParams: { userProfile },
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <>
      <Grid item xs={12} md={12} sx={{ padding: "0 !important" }}>
        {loading || !filteredPackages ? (
          <TableSkeleton
            tableHead={generateTableHead(colDefs)}
            rows={ContractorPackageTableRowSkeleton}
          />
        ) : (
          <Table<ContractorPackageLineItem>
            columnDefs={colDefs}
            loading={loading}
            data={filteredPackages || []}
            searchKeys={[
              "packageDescription",
              "packageNumber",
              "contractorName",
            ]}
            searchValue={searchTerm}
            context={{
              cellActions,
            }}
          />
        )}
      </Grid>
      <ConfirmationDialog
        message={"Are you sure you want to delete this package?"}
        open={openConfirmDialog}
        title={"Delete Package"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={confirmDelete}
      />
    </>
  );
};
