import { Box, tooltipClasses, styled, Tooltip } from "@mui/material";

const ProgressBarContainer = styled(Box)({
  backgroundColor: "#E9EAEB",
  borderRadius: "10px",
  height: "8px",
  overflow: "hidden",
  position: "relative",
  width: "100%",
});

const ProgressFill = styled(Box)<{
  color: string;
  figure: string;
  zIndex?: number;
}>(({ color, figure, zIndex = 1 }) => ({
  backgroundColor: color,
  borderRadius: "10px",
  height: "8px",
  left: 0,
  position: "absolute",
  top: 0,
  width: figure,
  zIndex,
}));

const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    border: "1px solid #E9EAEB",
    borderRadius: "8px",
    boxShadow:
      "0px 2px 2px -1px #0A0D120A, 0px 4px 6px -2px #0A0D1208, 0px 12px 16px -4px #0A0D1214",
    color: "#414651",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    padding: "8px 12px",
  },
});

export { ProgressBarContainer, ProgressFill, CustomTooltip };
