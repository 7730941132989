import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { NavItemType } from "types";

import { getProjectsNav } from "./projects";

export interface NavItemList {
  items: NavItemType[];
}

export const getNavMenuItems = (userProfile?: ProfileLineItem): NavItemList => {
  const items = [getProjectsNav(userProfile)];

  return { items };
};
