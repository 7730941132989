
import { Box, Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, styled } from "@mui/material";
export type StepConfig = { label: string; icon?: React.ReactNode };

interface StepsProps {
  activeStep: number;
  steps: StepConfig[];
}

const QontoConnector = styled(StepConnector)(({ theme } : any) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderRadius: 1,
    borderTopWidth: 3,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#E9EAEB',
    },
  },
  
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: 'calc(-50% + 9px)',
    right: 'calc(50% + 9px)',
    top: 10,

  },

  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#E9EAEB',
    },
  },

}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme }) => ({
    '& .QontoStepIcon-circle': {
      backgroundColor: 'currentColor',
      borderRadius: '50%',
      height: 8,
      width: 8,
    },
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      fontSize: 18,
      zIndex: 1,
    },
 
    alignItems: 'center',
    color: 'red',
    display: 'flex',
    height: 24,

    ...theme.applyStyles('dark', {
      color: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          color: '#E9EAEB',
        },
      },
    ],
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, className ,completed} = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
    {completed ? (
      <Box sx={{ alignItems:"center",bgcolor: "white", border:"1px solid #004EEB",borderRadius:"9999px", display:"flex", height:"31px",justifyContent:"center" , width: "31px", zIndex:'1'}}>
        <Box sx={{alignItems:"center",bgcolor:"#004EEB", borderRadius:"9999px",display:"flex",height:"24px",  justifyContent:"center", width: "24px"}}>
          <Box sx={{bgcolor:"#fff", borderRadius:"9999px",height:"8px", width: "8px"}}/>
        </Box>
      </Box>
    ) : (
      
        <Box sx={{alignItems:"center", bgcolor:"transparent", border:"1px solid #E9EAEB", borderRadius:"9999px",display:"flex", height:"24px",justifyContent:"center", width: "24px"}}>
          <Box sx={{bgcolor:"#D5D7DA", borderRadius:"9999px",height:"8px", width: "8px"}}/>
        </Box>
    )}
  </QontoStepIconRoot>
  );
}


export const Steps = ({ activeStep , steps }: StepsProps) => {
  return (
       <Stepper alternativeLabel activeStep={activeStep +1} connector={<QontoConnector />}>
          {steps.map((step) => (
            <Step key={step.label} >
              <StepLabel StepIconComponent={QontoStepIcon}  
            >{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
    // <Box sx={{ width: "100%" }}>
    //   <Stepper activeStep={activeStep} alternativeLabel>
    //     {steps.map((step) => (
    //       <Step
    //         key={step.label}
    //         sx={{
    //           "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
    //           {
    //             color: "grey.500", // Just text label (COMPLETED)
    //           },
    //           "& .MuiStepLabel-root .Mui-active": {
    //             color: "secondary.main", // circle color (ACTIVE)
    //           },
    //           "& .MuiStepLabel-root .Mui-completed": {
    //             color: "secondary.dark", // circle color (COMPLETED)
    //           },
    //           "& .MuiStepLabel-root .MuiStepIcon-text": {
    //             fill: "#fff", // circle's number (ACTIVE)
    //           },
    //         }}
    //       >
    //         <StepLabel sx={{ color: "#fff" }} icon={step.icon}>
    //           {step.label}
    //         </StepLabel>
    //       </Step>
    //     ))}
    //   </Stepper>
    // </Box>
  );
};






























// import { Box, Step, StepLabel, Stepper } from "@mui/material";
// import React from "react";

// export type StepConfig = { label: string; icon?: React.ReactNode };

// interface StepsProps {
//   activeStep: number;
//   steps: StepConfig[];
// }

// export const Steps = ({ activeStep, steps }: StepsProps) => {
//   return (
//     <Box sx={{ width: "100%" }}>
//       <Stepper activeStep={activeStep} alternativeLabel>
//         {steps.map((step) => (
//           <Step
//             key={step.label}
//             sx={{
//               "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
//               {
//                 color: "grey.500", // Just text label (COMPLETED)
//               },
//               "& .MuiStepLabel-root .Mui-active": {
//                 color: "secondary.main", // circle color (ACTIVE)
//               },
//               "& .MuiStepLabel-root .Mui-completed": {
//                 color: "secondary.dark", // circle color (COMPLETED)
//               },
//               "& .MuiStepLabel-root .MuiStepIcon-text": {
//                 fill: "#fff", // circle's number (ACTIVE)
//               },
//             }}
//           >
//             <StepLabel sx={{ color: "#fff" }} icon={step.icon}>
//               {step.label}
//             </StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   );
// };
