import { Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import { useState } from "react";
import {
  ContractorPackageLineItem,
  packageTypeToString,
} from "social-pro-common/interfaces/contractorPackage";

import { AnalyticsLocalContentLevel1TableRowSkeleton } from "./AnalyticsLocalContentLevel1TableRowSkeleton";
import {
  ActionCell,
  AvatarNameCellRenderer,
  CellAction,
  centerCellStyles,
  defaultCellConfig,
} from "../Table/TableCells";

interface AnalyticsLocalContentLevel1TableProps {
  loading: boolean;
  contractorPackages: ContractorPackageLineItem[];
  handleAction: (contractorPackage: ContractorPackageLineItem) => void;
  searchTerm: string;
}

export const AnalyticsLocalContentLevel1Table = ({
  contractorPackages,
  handleAction,
  loading,
  searchTerm,
}: AnalyticsLocalContentLevel1TableProps) => {
  const cellActions = [
    {
      action: handleAction,
      icon: <ViewProjectIcon />,
      singleAction: true,
      title: "View",
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "packageNumber",
      headerName: "#",
      onCellClicked: (params) => handleAction(params.data),
      valueGetter: (params: any) => {
        return `${params.data.packageNumber}`;
      },
    },
    {
      ...defaultCellConfig,
      cellRenderer: AvatarNameCellRenderer as any,
      field: "contractorName",
      flex: 3,
      headerClass: "left-table-header",
      headerName: "Subcontractor",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...defaultCellConfig,
      field: "packageType",
      flex: 3,
      headerClass: "left-table-header",
      headerName: "Work Package",
      onCellClicked: (params) => handleAction(params.data),
      valueGetter: (params: any) => {
        return packageTypeToString(params.data.packageType);
      },
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "packageStartDate",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Start Date",
      onCellClicked: (params) => handleAction(params.data),
      valueGetter: (params: any) => {
        return new Date(params.data.packageStartDate).toLocaleDateString();
      },
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "packageEndDate",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Target Date",
      onCellClicked: (params) => handleAction(params.data),
      valueGetter: (params: any) => {
        return new Date(params.data.packageEndDate).toLocaleDateString();
      },
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      cellStyle: centerCellStyles, // Center the button
      field: "",
      headerClass: "centered-table-header",
      headerName: "",
      onCellClicked: (params) => handleAction(params.data),
    },
  ]);

  return (
    <Grid item xs={12} md={12}>
      {loading || !contractorPackages ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={AnalyticsLocalContentLevel1TableRowSkeleton}
        />
      ) : (
        <Table<ContractorPackageLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={contractorPackages}
          context={{
            cellActions,
          }}
          searchKeys={["contractorName", "packageType"]}
          searchValue={searchTerm}
        />
      )}
    </Grid>
  );
};
