import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack, Tooltip, Zoom } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";

interface ActionCellProps extends CustomCellRendererProps {
  loading: boolean;
  handleEdit: (metric: GreenMetricLineItem) => void;
  handleDelete: (policy: GreenMetricLineItem) => void;
}

const TABLE_HEAD = [
  { id: "reportTargetName", label: "Target Name", width: "85%" },
  { id: "action", label: "Actions", width: "15%" },
];

const ActionCell = ({
  data,
  handleDelete,
  handleEdit,
  loading,
}: ActionCellProps) => {
  return (
    <Stack direction="row" spacing={1} justifyContent={"center"}>
      <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
        <IconButton
          color="primary"
          size="medium"
          disabled={loading}
          onClick={() => handleEdit(data)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
        <IconButton
          color="error"
          size="medium"
          disabled={loading}
          onClick={() => handleDelete(data)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export { ActionCell, TABLE_HEAD };
