import {
  SocialBusinessSearchParams,
  useSocialBusiness,
} from "@hooks/crud/socialBusiness/useSocialBusiness";
import { Grid, Table, TableCell, TableRow } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DirectoryCard } from "@stories/atoms/DirectoryCard/DirectoryCard";
import { DirectoryCardSkeleton } from "@stories/atoms/DirectoryCard/DirectoryCardSkeleton";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { SmartDirectModal } from "@stories/organisms/SmartDirectModal/SmartDirectModal";
import { AddIcon, ImportIcon } from "assets/constants";
import { useState } from "react";

// const serviceLocations = [
//   "All",
//   "ACT",
//   "NSW",
//   "NT",
//   "QLD",
//   "SA",
//   "TAS",
//   "VIC",
//   "WA",
// ];

export const Companies = () => {
  const [searchParams] = useState<SocialBusinessSearchParams>({});

  // const { isProjectLoading, selectedProject } = useProjectContext();

  const { isSocialBusinessLoading, socialBusinesses } =
    useSocialBusiness(searchParams);

  const loading = isSocialBusinessLoading;

  // const selectableCommitmentHours = selectedProject?.commitmentsHours || [];
  // const selectableCommitmentSpend = selectedProject?.commitmentsSpend || [];
  // const allTargets = [
  //   {
  //     id: "All",
  //     label: "All",
  //   },
  //   ...selectableCommitmentHours.map((c) => ({
  //     id: c.id,
  //     label:
  //       c.targetDescription || labourHourCommitmentTypeToString(c.targetName),
  //   })),
  //   ...selectableCommitmentSpend.map((c) => ({
  //     id: c.id,
  //     label:
  //       c.targetDescription || socialSpendCommitmentTypeToString(c.targetName),
  //   })),
  // ];

  // const [tabIndex, setTabIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChangev2 = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  // const handleTabChange = async (tabIndex: number) => {
  //   setTabIndex(tabIndex);
  // };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const buttons = [
    {
      // action: handleGoToTeams,
      icon: <ImportIcon />,
      text: "Export",
      type: HeaderButtonType.SECONDARY,
    },
    {
      action: openModal,
      icon: <AddIcon variant="secondary" />,
      text: "Add Directory Card",
      type: HeaderButtonType.PRIMARY,
    },
  ] as HeaderButtonConfig[];

  console.log(buttons);
  // const tabs = [
  //   {
  //     label: "Project Directory",
  //   },
  //   {
  //     label: "SocialPro Directory",
  //   },
  // ];

  const filteredSocialBusinesses = socialBusinesses.filter(
    (c) =>
      c.socialBusinessName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      c.socialBusinessBlurb.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <>
      <SmartDirectModal open={isModalOpen} handleClose={closeModal} />
      <PageContainer>
        <Header
          loading={loading}
          subTitle={" "}
          handleSearchChange={handleSearchChangev2}
          searchTerm={searchTerm}
          mainTitle={"Smart Directory"}
          // tabs={tabs}
          // handleTabChange={handleTabChange}
          // tabIndex={tabIndex}
        />
        {!loading && (
          <Grid container columnSpacing={2} rowSpacing={2}>
            {filteredSocialBusinesses.map((c, index) => (
              <Grid
                item
                key={c.socialBusinessName + index}
                md={4}
                sx={{ display: "flex" }}
                justifyContent={"stretch"}
              >
                <DirectoryCard
                  key={c.socialBusinessName + index}
                  socialBusiness={c}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {!loading && filteredSocialBusinesses.length === 0 ? (
          <Table>
            <TableRow key={"no-data-row-1"}>
              <TableCell colSpan={4} align="center">
                <Typography variant={"h4"} component="div">
                  No companies found
                </Typography>
              </TableCell>
            </TableRow>
          </Table>
        ) : null}

        {loading ? (
          <Grid container spacing={2}>
            {loading
              ? Array.from(Array(12).keys()).map((i, index: number) => (
                  <Grid
                    key={index}
                    item
                    md={4}
                    sx={{ display: "flex" }}
                    justifyContent={"stretch"}
                  >
                    <DirectoryCardSkeleton key={i} />
                  </Grid>
                ))
              : null}
          </Grid>
        ) : null}
      </PageContainer>
    </>
  );
};
