import { Table as MUITable, TableBody } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

type Props = {
  tableHead: TableHeaderCell[];
  rows: React.ElementType<any>;
  isSearchHidden?: boolean;
  searchWidth?: number;
  noFullTableBorder?: boolean;
};

const styles = (noFullTableBorder: boolean) => ({
  border: `${noFullTableBorder ? "none" : "1px solid #E9EAEB"}`,
  borderBottomLeftRadius: `${noFullTableBorder ? "12px" : "12px"}`,
  borderBottomRightRadius: `${noFullTableBorder ? "12px" : "12px"}`,
  borderCollapse: "unset !important",
  borderColor: "rgb(233, 234, 235)",
  borderTop: `${noFullTableBorder ? "1px solid #E9EAEB" : "1px solid #E9EAEB"}`,
  borderTopLeftRadius: `${noFullTableBorder ? "0" : "12px"}`,
  borderTopRightRadius: `${noFullTableBorder ? "0" : "12px"}`,
});

const TableSkeleton = ({
  noFullTableBorder = false,
  rows: RowSkeleton,
  tableHead,
}: Props) => {
  return (
    <MUITable sx={styles(noFullTableBorder)}>
      <ListTableHead loading headLabel={tableHead} />
      <TableBody>
        {Array.from({ length: 8 }, (_, index) => (
          <RowSkeleton key={index} />
        ))}
      </TableBody>
    </MUITable>
  );
};

export default TableSkeleton;
