"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProjectPolicy = exports.decodeProjectPolicy = void 0;
const string_1 = require("../../utils/string");
const decodeProjectPolicy = (projectPolicy) => {
    return {
        created: new Date(projectPolicy.created),
        id: projectPolicy.project_policy_id,
        isProjectLevelPolicy: projectPolicy.is_project_level_policy,
        policyDescription: projectPolicy.policy_description,
        policyType: projectPolicy.policy_type,
        projectId: projectPolicy.project_id,
    };
};
exports.decodeProjectPolicy = decodeProjectPolicy;
const encodeProjectPolicy = (projectPolicy) => {
    const safeProjectPolicy = (0, string_1.makePostgresSafe)(projectPolicy);
    return {
        created: safeProjectPolicy.created,
        is_project_level_policy: safeProjectPolicy.isProjectLevelPolicy,
        policy_description: safeProjectPolicy.policyDescription,
        policy_type: safeProjectPolicy.policyType,
        project_id: safeProjectPolicy.projectId,
        project_policy_id: safeProjectPolicy.id,
    };
};
exports.encodeProjectPolicy = encodeProjectPolicy;
