import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import LidpModal from "@stories/organisms/LidpModal/LidpModal";
import {
  generateTableHead,
  getCellStyle,
  isTotalRow,
  Table,
} from "@stories/organisms/Table/Table";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  calculateLocalContentPackageAnzTotal,
  calculateLocalContentPackageContractTotal,
  calculateLocalContentPackageValueTotal,
} from "social-pro-common/utils/calc";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import LidpImport, { ImportStep } from "../LidpImport/LidpImport";
import { ActionCell } from "../LidpTableRow/LidpTableRowCells";
import { LidpTableRowSkeleton } from "../LidpTableRow/LidpTableRowSkeleton";

interface LidpRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  isPrimaryPackage: boolean;
  projectLocalContent: PackageLocalContentLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const LidpRequirementForm = ({
  handleBack,
  handleNext,
  isPrimaryPackage,
  loading,
  project,
  projectLocalContent,
  setDirtyOnChange,
  setProject,
}: LidpRequirementFormProps) => {
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [localContents, setLocalContents] =
    useState<PackageLocalContentLineItem[]>(projectLocalContent);
  const [selectedLocalContent, setSelectedLocalContent] = useState<
    PackageLocalContentLineItem | undefined
  >();
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const confirmDelete = (lc: PackageLocalContentLineItem) => {
    setSelectedLocalContent(lc);
    setOpenConfirmDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };

  const createLocalContent = async (
    lc: PackageLocalContentLineItem,
  ): Promise<void> => {
    setLocalContents([...localContents, lc]);
    setDirtyOnChange(true);
  };

  const upsertLocalContent = async (
    lc: PackageLocalContentLineItem[],
  ): Promise<void> => {
    setLocalContents([...localContents, ...lc]);
    setDirtyOnChange(true);
  };

  const updateLocalContent = async (
    lc: PackageLocalContentLineItem,
  ): Promise<void> => {
    setLocalContents(
      localContents.map((f) => {
        return lc.id === f.id ? lc : f;
      }),
    );
    setDirtyOnChange(true);
  };

  const goBack = (lcs: PackageLocalContentLineItem[]) => {
    setProject({
      ...project,
      financial: {
        ...project.financial,
        lidpPc: lcs.length ? calculateLocalContentPackageAnzTotal(lcs) : -1,
      },
      localContents: lcs,
    });
    handleBack();
  };

  const setProjectLocalContents = (lcs: PackageLocalContentLineItem[]) => {
    setProject({
      ...project,
      financial: {
        ...project.financial,
        lidpPc: lcs.length ? calculateLocalContentPackageAnzTotal(lcs) : -1,
      },
      localContents: lcs,
    });
    handleNext();
  };

  const handleEdit = (lc: PackageLocalContentLineItem) => {
    setSelectedLocalContent(lc);
    setOpen(true);
  };

  const handleDelete = () => {
    setLocalContents((prev) =>
      prev.filter((f) => f.id !== selectedLocalContent?.id),
    );
    setDirtyOnChange(true);
    setOpenConfirmDialog(false);
    setSelectedLocalContent(undefined);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      cellStyle: (params) => getCellStyle(params, "right"),
      colSpan: (params) => (isTotalRow(params) ? 3 : 1),
      field: "itemDescription",
      headerClass: "centered-table-header",
      headerName: "Item Descripti",
      resizable: false,
      wrapHeaderText: true,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "manufacturer",
      headerClass: "centered-table-header",
      headerName: "Manufacturer",
      resizable: false,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "supplier",
      headerClass: "centered-table-header",
      headerName: "Supplier",
      resizable: false,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "value",
      headerClass: "centered-table-header",
      headerName: "Value ($)",
      resizable: false,
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "steelMass",
      headerClass: "centered-table-header",
      headerName: "Steel Mass (T)",
      resizable: false,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value)}T`
          : `${params.value}`;
      },
      wrapHeaderText: true,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "localValueAdd",
      headerClass: "centered-table-header",
      headerName: "Local Value Add",
      resizable: false,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value)}%`
          : `${params.value}`;
      },
      wrapHeaderText: true,
    },
    {
      cellStyle: { backgroundColor: "#f4f4f4", textAlign: "center" },
      field: "importValueAdd",
      headerClass: "centered-table-header",
      headerName: "Import Value Add",
      resizable: false,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value)}%`
          : `${params.value}`;
      },
      wrapHeaderText: true,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "contractContent",
      headerClass: "centered-table-header",
      headerName: "Contract Content",
      resizable: false,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
      wrapHeaderText: true,
    },
    {
      cellStyle: (params) => {
        return {
          ...getCellStyle(params),
          backgroundColor: "#f4f4f4",
        };
      },

      field: "anzValueAdd",
      headerClass: "centered-table-header",
      headerName: "ANZ value-add activity (%)",
      resizable: false,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
      wrapHeaderText: true,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "smeCount",
      headerClass: "centered-table-header",
      headerName: "SME Count",
      resizable: false,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "supplierCount",
      headerClass: "centered-table-header",
      headerName: "Supplier Count",
      resizable: false,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
      wrapHeaderText: true,
    },
    {
      cellRenderer: (params: any) =>
        params.data?.itemDescription === "Total Local Content" ? null : (
          <ActionCell {...params} />
        ),
      cellRendererParams: {
        confirmDelete,
        handleEdit,
        loading,
      },
      cellStyle: (params) => getCellStyle(params),
      field: "",
      headerClass: "centered-table-header",
      headerName: "",
      resizable: false,
    },
  ]);

  const contractTotal =
    calculateLocalContentPackageContractTotal(localContents);

  const anzTotalTotal = calculateLocalContentPackageAnzTotal(localContents);

  const valueTotal = calculateLocalContentPackageValueTotal(localContents);

  const totalSme = localContents.reduce(
    (acc, lc) => acc + (lc.smeCount ?? 0),
    0,
  );
  const totalSuppliers = localContents.reduce(
    (acc, lc) => acc + (lc.supplierCount ?? 0),
    0,
  );

  const totalRow = {
    anzValueAdd: anzTotalTotal,
    contractContent: contractTotal,
    importValueAdd: "-",
    isTotalRow: true,
    itemDescription: "Total Local Content",
    localValueAdd: "-",
    smeCount: totalSme,
    steelMass: "-",
    supplier: "Total Local Content",
    supplierCount: totalSuppliers,
    value: valueTotal,
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: "25px 40px 0px 40px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
            width: "100%",
          }}
        >
          <SubTitle title={"Agreed Local Content"} />

          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              data-test-id="import-localContent-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              onClick={handleOpenImportModal}
              startIcon={<CloudUploadIcon />}
            >
              Import Local Content
            </StyledButton>
            <StyledButton
              data-test-id="add-localContent-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Add Local Content
            </StyledButton>
          </Stack>
        </Box>

        <Grid item md={12}>
          {loading ? (
            <TableSkeleton
              tableHead={generateTableHead(colDefs)}
              rows={LidpTableRowSkeleton}
            />
          ) : (
            <Table<PackageLocalContentLineItem>
              columnDefs={colDefs}
              loading={loading}
              data={localContents || []}
              pinnedBottomRowData={
                localContents.length ? ([totalRow] as ColDef[]) : []
              }
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          padding: "20px 40px 40px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <StyledButton
            loading={loading}
            onClick={() => goBack(localContents)}
            disabled={loading}
            variant="outlined"
          >
            Back
          </StyledButton>
          <StyledButton
            loading={loading}
            onClick={() => setProjectLocalContents(localContents)}
            variant="contained"
            type="submit"
          >
            Next
          </StyledButton>
        </Stack>
      </Grid>

      {open ? (
        <LidpModal
          open={open}
          loading={loading}
          projectId={project.id}
          isCreateModal
          isPrimaryPackage={isPrimaryPackage}
          existingLocalContent={selectedLocalContent}
          packageValue={project.financial.totalValue || 0}
          createLocalContent={createLocalContent}
          updateLocalContent={updateLocalContent}
          handleClose={handleClose}
        />
      ) : null}
      <LidpImport
        open={openImportModal}
        step={step}
        project={project}
        setIsOpen={setOpenImportModal}
        setStep={setStep}
        upsertLidp={upsertLocalContent}
      />

      <ConfirmationDialog
        message={"Are you sure you want to delete this Local Content item?"}
        open={openConfirmDialog}
        title={"Delete Local Content"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleDelete()}
      />
    </>
  );
};
