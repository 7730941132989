"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultProjectReportSubmission = void 0;
const uuid_1 = require("uuid");
const projectReportSubmission_1 = require("../../entities/projectReportSubmission");
const createDefaultProjectReportSubmission = (projectId, reportId) => {
    return {
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId: projectId,
        reportId,
        reportStatus: projectReportSubmission_1.ReportStatus.Upcoming,
    };
};
exports.createDefaultProjectReportSubmission = createDefaultProjectReportSubmission;
