import { Stack, Typography } from "@mui/material";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { packageTypeToString } from "social-pro-common/interfaces/contractorPackage";
import { NavItemType } from "types";

export const getProjectsNav = (
  projectName: string,
  packageType: PackageType,
): NavItemType => {
  return {
    children: [
      {
        breadcrumbs: false,
        id: "list-projects",
        static: true,
        title: (
          <Stack direction="column" spacing={1}>
            <Typography variant="body1" fontWeight={500}>
              {projectName || ""}
            </Typography>
            <Typography variant="caption">
              {packageType !== PackageType.PrimaryPackage
                ? packageTypeToString(packageType)
                : null}
            </Typography>
          </Stack>
        ),
        type: "item",
      },
    ],
    id: "projects",
    title: "",
    type: "group",
  };
};
