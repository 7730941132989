"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeSubcontractorNote = exports.decodeSubcontractorNote = void 0;
const string_1 = require("../../utils/string");
const decodeSubcontractorNote = (comment) => {
    return {
        comment: comment.comment,
        contractorPackageId: comment.contractor_package_id,
        created: new Date(comment.created),
        id: comment.package_comment_id,
        projectId: comment.project_id,
    };
};
exports.decodeSubcontractorNote = decodeSubcontractorNote;
const encodeSubcontractorNote = (comment) => {
    const safeComment = (0, string_1.makePostgresSafe)(comment);
    return {
        comment: safeComment.comment,
        contractor_package_id: safeComment.contractorPackageId,
        created: new Date(safeComment.created),
        package_comment_id: safeComment.id,
        project_id: safeComment.projectId,
    };
};
exports.encodeSubcontractorNote = encodeSubcontractorNote;
