import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import LabourHourTargetModal from "@stories/organisms/LabourHourTargetModal/LabourHourTargetModal";
import { Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  labourHourCommitmentTypeToDescription,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  ProjectLabourHourCommitmentLineItem,
  createDefaultLabourHourCommitment,
  getBaseMultiplierForHours,
} from "social-pro-common/interfaces/projectLabourHourCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import * as yup from "yup";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import {
  ActionCell,
  InfoCell,
  TABLE_HEAD,
} from "../LabourHourTableRow/LabourHourTableRowCells";
import { LabourHourTableRowSkeleton } from "../LabourHourTableRow/LabourHourTableRowSkeleton";

interface SocialRequirementFormHoursProps {
  loading: boolean;
  title: string;
  project: ProjectLineItem;
  commitmentLineItems: ProjectLabourHourCommitmentLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

interface FormikField {
  name: string;
  title: string;
  description: string;
  label: string;
  outcomeMultiplier: number;
  initialValue: number;
  type: any;
  deleted?: boolean;
}

export const SocialRequirementFormHours = ({
  commitmentLineItems,
  handleBack,
  handleNext,
  loading,
  project,
  setDirtyOnChange,
  setProject,
  title,
}: SocialRequirementFormHoursProps) => {
  const [commitments, setCommitments] =
    useState<ProjectLabourHourCommitmentLineItem[]>(commitmentLineItems);

  const [selectedCommitment, setSelectedCommitment] = useState<
    ProjectLabourHourCommitmentLineItem | undefined
  >();

  const [fields, setFields] = useState<FormikField[]>([]);
  const [initialValues, setInitialValues] = useState<{ [k: string]: number }>(
    {},
  );
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<
      {
        [x: string]: any;
      },
      yup.AnyObject,
      {
        [x: string]: any;
      },
      ""
    >
  >(yup.object());

  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [commitmentToDelete, setCommitmentToDelete] = useState("");

  useEffect(() => {
    const fieldsFromCommitments: FormikField[] = commitments.map((c) => {
      return {
        deleted: c.deleted,
        description:
          c.targetDescription ||
          labourHourCommitmentTypeToDescription(c.targetName),
        initialValue: c.targetValue,
        label:
          c.targetDescription || labourHourCommitmentTypeToString(c.targetName),
        name: c.id,
        outcomeMultiplier: getBaseMultiplierForHours(project.financial, c),
        title:
          c.targetDescription || labourHourCommitmentTypeToString(c.targetName),
        type: yup.number().required().min(0),
      } as FormikField;
    }) as FormikField[];

    const initialValues = Object.fromEntries(
      fieldsFromCommitments.map((field) => [field.name, field.initialValue]),
    );

    const SchemaObject = Object.fromEntries(
      fieldsFromCommitments.map((field) => [field.name, field.type]),
    );
    const newValidationSchema = yup.object().shape(SchemaObject);

    setFields(fieldsFromCommitments);
    setInitialValues(initialValues);
    setValidationSchema(newValidationSchema);
  }, [commitments]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const updatedCommitments = commitments
        .map((c) => {
          const value = parseFloat(values[c.id] as unknown as string);
          return {
            ...c,
            targetValue: value,
            targetValueRealised: Math.round(
              getBaseMultiplierForHours(project.financial, c) * (value / 100),
            ),
          } as ProjectLabourHourCommitmentLineItem;
        })
        .filter((c) => !c.deleted) as ProjectLabourHourCommitmentLineItem[];

      setProject({
        ...project,
        commitmentsHours: updatedCommitments,
      });
      handleNext();
    },
    validationSchema,
  });

  const filteredFields = fields.filter((f) => !f.deleted);

  const handleEdit = (name: string) => {
    setSelectedCommitment(commitments.find((c) => c.id === name));
    setOpen(true);
  };

  const handleDelete = (name: string) => {
    setCommitmentToDelete(name);
    setOpenConfirmDialog(true);
  };

  const confirmDelete = () => {
    if (commitmentToDelete) {
      const updatedCommitments = commitments.reduce((acc, c) => {
        if (c.id === commitmentToDelete) {
          return [...acc, { ...c, deleted: true }];
        }
        return [...acc, c];
      }, [] as ProjectLabourHourCommitmentLineItem[]);
      setCommitments(updatedCommitments);
      setDirtyOnChange(true);
    }
    setOpenConfirmDialog(false);
    setCommitmentToDelete("");
  };

  const goBack = () => {
    setProject({
      ...project,
      commitmentsHours: commitments,
    });
    handleBack();
  };

  const handleClose = () => {
    setSelectedCommitment(undefined);
    setOpen(false);
  };

  const createSocialCommitment = (
    socialCommitment: ProjectLabourHourCommitmentLineItem,
  ) => {
    const matchingCommitment = commitments.find(
      (c) => c.id === socialCommitment.id,
    );
    if (matchingCommitment) {
      const updatedCommitments = commitments.reduce(
        (
          acc: ProjectLabourHourCommitmentLineItem[],
          c: ProjectLabourHourCommitmentLineItem,
        ) => {
          if (c.id === socialCommitment.id) {
            return [...acc, socialCommitment];
          }
          return [...acc, c];
        },
        [],
      );
      setCommitments(updatedCommitments);
    } else {
      setCommitments([...commitments, socialCommitment]);
    }
    setDirtyOnChange(true);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      cellRenderer: InfoCell,
      field: "title",
      flex: 2,
      headerName: "Commitment",
    },
    {
      ...defaultCellConfig,
      field: "initialValue",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "Target %",
      valueFormatter: (params) =>
        `${params.value ? formatDecimalPlaces(params.value) : 0}%`,
    },
    {
      ...defaultCellConfig,
      field: "targetOutcome",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "Target Outcome",
      valueFormatter: (params) => {
        return params.data.outcomeMultiplier
          ? `${Math.round((params.data.outcomeMultiplier * params.data.initialValue) / 100).toLocaleString()} hours`
          : "0 hours";
      },
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: {
        handleDelete,
        handleEdit,
        loading,
      },
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            padding: "25px 40px 0px 40px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "25px",
              width: "100%",
            }}
          >
            {loading ? (
              <Skeleton animation="wave">
                <SubTitle title={`Target ${title}`} />
              </Skeleton>
            ) : (
              <SubTitle title={`Target ${title}`} />
            )}

            <StyledButton
              data-test-id="add-hours-commitment-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setSelectedCommitment(
                  createDefaultLabourHourCommitment(project.id),
                );
                setOpen(true);
              }}
            >
              {`Add ${title} Target`}
            </StyledButton>
          </Box>
          <Grid item xs={12} md={12}>
            <Box>
              {loading ? (
                <TableSkeleton
                  tableHead={TABLE_HEAD}
                  rows={LabourHourTableRowSkeleton}
                />
              ) : (
                <Table<ProjectLabourHourCommitmentLineItem>
                  columnDefs={colDefs}
                  loading={loading}
                  data={
                    filteredFields as unknown as ProjectLabourHourCommitmentLineItem[]
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            padding: "20px 40px 30px !important",
          }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton
              loading={loading}
              onClick={goBack}
              disabled={loading || formik.isSubmitting}
              variant="outlined"
            >
              Back
            </StyledButton>
            <StyledButton
              loading={loading}
              disabled={loading || formik.isSubmitting}
              variant="contained"
              type="submit"
            >
              Next
            </StyledButton>
          </Stack>
        </Grid>
      </form>

      <ConfirmationDialog
        message={"Are you sure you want to delete this commitment?"}
        open={openConfirmDialog}
        title={"Delete Commitment"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => confirmDelete()}
      />

      {open && selectedCommitment ? (
        <LabourHourTargetModal
          open={open}
          loading={loading}
          project={project}
          labourHourCommitment={selectedCommitment}
          createSocialCommitment={createSocialCommitment}
          handleClose={handleClose}
        />
      ) : null}
    </Box>
  );
};
