"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSortedLocalContent = exports.aggregateLocalContentItems = exports.aggregateContentOrigin = exports.contentOriginToString = exports.createDefaultPackageLocalContent = void 0;
const uuid_1 = require("uuid");
const packageLocalContent_1 = require("../../entities/packageLocalContent");
const date_1 = require("../../utils/date");
const createDefaultPackageLocalContent = (projectId, reportId, packageId) => {
    return {
        anzValueAdd: 0,
        contractContent: 0,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        importValueAdd: 0,
        itemDescription: "",
        localValueAdd: 0,
        manufacturer: "",
        packageId: packageId,
        projectId,
        reportId,
        steelMass: 0,
        supplier: "",
        value: 0,
    };
};
exports.createDefaultPackageLocalContent = createDefaultPackageLocalContent;
const contentOriginToString = (origin) => {
    switch (origin) {
        default:
            throw new Error(`Unknown content origin: ${origin}`);
        case packageLocalContent_1.ContentOrigin.Victoria:
            return "Victoria";
        case packageLocalContent_1.ContentOrigin.NewSouthWales:
            return "New South Wales";
        case packageLocalContent_1.ContentOrigin.Queensland:
            return "Queensland";
        case packageLocalContent_1.ContentOrigin.SouthAustralia:
            return "South Australia";
        case packageLocalContent_1.ContentOrigin.WesternAustralia:
            return "Western Australia";
        case packageLocalContent_1.ContentOrigin.Tasmania:
            return "Tasmania";
        case packageLocalContent_1.ContentOrigin.NorthernTerritory:
            return "Northern Territory";
        case packageLocalContent_1.ContentOrigin.AustralianCapitalTerritory:
            return "Australian Capital Territory";
        case packageLocalContent_1.ContentOrigin.AllAustralia:
            return "All Australia";
        case packageLocalContent_1.ContentOrigin.NewZealand:
            return "New Zealand";
        case packageLocalContent_1.ContentOrigin.International:
            return "International";
    }
};
exports.contentOriginToString = contentOriginToString;
const aggregateContentOrigin = (items) => {
    if (items.length === 0) {
        return packageLocalContent_1.ContentOrigin.AllAustralia;
    }
    const australianOrigins = new Set([
        packageLocalContent_1.ContentOrigin.Victoria,
        packageLocalContent_1.ContentOrigin.NewSouthWales,
        packageLocalContent_1.ContentOrigin.Queensland,
        packageLocalContent_1.ContentOrigin.SouthAustralia,
        packageLocalContent_1.ContentOrigin.WesternAustralia,
        packageLocalContent_1.ContentOrigin.Tasmania,
        packageLocalContent_1.ContentOrigin.NorthernTerritory,
        packageLocalContent_1.ContentOrigin.AustralianCapitalTerritory,
        packageLocalContent_1.ContentOrigin.AllAustralia,
    ]);
    const hasInternationalOrNZ = items.includes(packageLocalContent_1.ContentOrigin.International) ||
        items.includes(packageLocalContent_1.ContentOrigin.NewZealand);
    const hasAustralian = items.some((origin) => australianOrigins.has(origin));
    if (hasInternationalOrNZ && hasAustralian) {
        return packageLocalContent_1.ContentOrigin.International;
    }
    const firstOrigin = items[0];
    const allSame = items.every((origin) => origin === firstOrigin);
    if (allSame) {
        return firstOrigin;
    }
    return packageLocalContent_1.ContentOrigin.AllAustralia;
};
exports.aggregateContentOrigin = aggregateContentOrigin;
const aggregateLocalContentItems = (items) => {
    const result = {};
    items.forEach((item) => {
        const description = item.itemDescription;
        if (!result[description]) {
            result[description] = {
                anzValueProduct: 0,
                item: {
                    ...item,
                    anzValueAdd: 0,
                    contractContent: 0,
                    importValueAdd: 0,
                    localValueAdd: 0,
                    manufacturer: "",
                    smeCount: 0,
                    steelMass: 0,
                    supplier: "",
                    supplierCount: 0,
                    value: 0,
                },
                manufacturers: [],
                origins: [],
                suppliers: [],
            };
        }
        const aggregatedItem = result[description].item;
        aggregatedItem.value += item.value;
        aggregatedItem.steelMass += item.steelMass;
        aggregatedItem.importValueAdd += item.importValueAdd;
        aggregatedItem.localValueAdd += (item.localValueAdd * item.value) / 100;
        aggregatedItem.contractContent += item.contractContent;
        aggregatedItem.anzValueAdd += item.anzValueAdd;
        aggregatedItem.smeCount =
            (aggregatedItem.smeCount ?? 0) + (item?.smeCount ?? 0);
        aggregatedItem.supplierCount =
            (aggregatedItem.supplierCount ?? 0) + (item?.supplierCount ?? 0);
        result[description].anzValueProduct += item.value * item.anzValueAdd;
        if (item.contentOrigin) {
            result[description].origins.push(item.contentOrigin);
        }
        result[description].manufacturers.push({
            name: item.manufacturer,
            value: item.value,
        });
        result[description].suppliers.push({
            name: item.supplier,
            value: item.value,
        });
    });
    const aggregatedItems = Object.values(result).map((entry) => {
        const { item, manufacturers, origins, suppliers } = entry;
        item.contentOrigin = (0, exports.aggregateContentOrigin)(origins);
        item.manufacturer = manufacturers
            .sort((a, b) => b.value - a.value)
            .slice(0, 3)
            .map((m) => m.name)
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(", ");
        item.supplier = suppliers
            .sort((a, b) => b.value - a.value)
            .slice(0, 3)
            .map((s) => s.name)
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(", ");
        return item;
    });
    const totalValue = aggregatedItems.reduce((acc, item) => acc + item.value, 0);
    aggregatedItems.forEach((item) => {
        item.localValueAdd = item.value
            ? (item.localValueAdd / item.value) * 100
            : 0;
        item.importValueAdd = 100 - item.localValueAdd;
        item.contractContent = totalValue ? (item.value / totalValue) * 100 : 0;
    });
    aggregatedItems.forEach((item) => {
        item.anzValueAdd = item.contractContent * (item.localValueAdd / 100);
    });
    return (0, exports.getSortedLocalContent)(aggregatedItems);
};
exports.aggregateLocalContentItems = aggregateLocalContentItems;
const getSortedLocalContent = (items, reportId) => {
    const itemsToSort = items || [];
    const sortedItems = [...itemsToSort].sort((a, b) => {
        return a.itemDescription
            .toLowerCase()
            .localeCompare(b.itemDescription.toLowerCase());
    });
    if (reportId) {
        const reportDate = (0, date_1.stringToDate)(reportId);
        return sortedItems.filter((item) => {
            const itemDate = (0, date_1.stringToDate)(item.reportId);
            return reportDate.getTime() >= itemDate.getTime();
        });
    }
    return sortedItems;
};
exports.getSortedLocalContent = getSortedLocalContent;
