export const MENU_LIST_ITEM_VARIANTS = {
  ITEM: "item",
  SUBITEM: "subItem",
  TITLE: "title",
} as const;

export const TEXT_COLORS = {
  ACTIVE: "#0055C6",
  DEFAULT: "#FFF",
} as const;
