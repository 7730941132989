import {
  CardActionArea,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
} from "@mui/material";
import { useState } from "react";

interface SupportServicesTabsProps {
  loading: boolean;
  title: string;
  description: string;
  url: string;
  handleChange: (url: string) => void;
}

export const TutorialCard = ({
  description,
  loading,
  title,
  url,
}: SupportServicesTabsProps): JSX.Element => {
  const [clicked, setClicked] = useState(false);
  return (
    <Card
      sx={{
        backgroundColor: "#e9f0f5",
        margin: "4%",
      }}
      onClick={() => {
        setClicked(true);
      }}
    >
      <CardActionArea>
        {loading ? (
          <Skeleton animation="wave">
            <CardMedia component="video" height="200" controls />
          </Skeleton>
        ) : clicked ? (
          <CardMedia
            component="video"
            src={url}
            sx={{
              borderRadius: "10px",
              display: "flex",
              margin: "auto",
              marginTop: "5%",
              width: "80%",
            }}
            autoPlay
            controls
          />
        ) : (
          <CardMedia
            component="img"
            src={"./overlay.png"}
            sx={{
              borderRadius: "10px",
              display: "flex",
              margin: "auto",
              marginTop: "5%",
              width: "80%",
            }}
          />
        )}
        <CardContent>
          {loading ? (
            <>
              <Skeleton animation="wave">
                <Typography gutterBottom variant="h5" component="div">
                  {title}
                </Typography>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              </Skeleton>
            </>
          ) : (
            <>
              <Typography gutterBottom variant="h3" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
