"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSocialBusiness = exports.getSocialBusinessLogo = exports.SOCIAL_BUSINESS_LOGO_PREFIX = void 0;
exports.SOCIAL_BUSINESS_LOGO_PREFIX = "companies";
const getSocialBusinessLogo = async (url, getImageUrl) => {
    const presignedUrl = url.length
        ? await getImageUrl(`${exports.SOCIAL_BUSINESS_LOGO_PREFIX}/${url}`)
        : "";
    return presignedUrl;
};
exports.getSocialBusinessLogo = getSocialBusinessLogo;
const decodeSocialBusiness = async (socialBusiness, getImageUrl) => {
    const presignedUrl = socialBusiness?.social_business_logo?.length
        ? await (0, exports.getSocialBusinessLogo)(socialBusiness.social_business_logo, getImageUrl)
        : "";
    return {
        created: new Date(socialBusiness.created),
        registered: socialBusiness.registered,
        socialBusinessAbn: socialBusiness.social_business_abn,
        socialBusinessBeneficiaries: socialBusiness.social_business_beneficiaries,
        socialBusinessBlurb: socialBusiness.social_business_blurb,
        socialBusinessCountry: socialBusiness.social_business_country,
        socialBusinessEba: socialBusiness.social_business_eba,
        socialBusinessId: socialBusiness.social_business_id,
        socialBusinessLocations: socialBusiness.social_business_locations,
        socialBusinessLogo: presignedUrl,
        socialBusinessName: socialBusiness.social_business_name,
        socialBusinessPostCode: socialBusiness.social_business_post_code,
        socialBusinessServices: socialBusiness.social_business_services,
        socialBusinessState: socialBusiness.social_business_state,
        socialBusinessWebsite: socialBusiness.social_business_website,
    };
};
exports.decodeSocialBusiness = decodeSocialBusiness;
