"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePackageSocialSpendCommitment = exports.decodePackageSocialSpendCommitment = void 0;
const string_1 = require("../../utils/string");
const decodePackageSocialSpendCommitment = (socialCommitment) => {
    return {
        achievedValue: socialCommitment.achieved_value,
        created: new Date(socialCommitment.created),
        id: socialCommitment.package_social_spend_commitment_id,
        packageId: socialCommitment.contractor_package_id,
        projectId: socialCommitment.project_id,
        projectSocialSpendCommitmentId: socialCommitment.project_social_spend_commitment_id,
        targetDescription: socialCommitment.target_description,
        targetName: socialCommitment.target_type,
        targetValue: socialCommitment.target_value,
        targetValueRealised: socialCommitment.target_value_realised,
    };
};
exports.decodePackageSocialSpendCommitment = decodePackageSocialSpendCommitment;
const encodePackageSocialSpendCommitment = (socialCommitment) => {
    if (!socialCommitment.id) {
        throw new Error("No id supplied");
    }
    const safeSocialCommitment = (0, string_1.makePostgresSafe)(socialCommitment);
    return {
        achieved_value: safeSocialCommitment.achievedValue,
        contractor_package_id: safeSocialCommitment.packageId,
        created: safeSocialCommitment.created,
        package_social_spend_commitment_id: safeSocialCommitment.id,
        project_id: safeSocialCommitment.projectId,
        project_social_spend_commitment_id: safeSocialCommitment.projectSocialSpendCommitmentId,
        target_description: safeSocialCommitment.targetDescription,
        target_type: safeSocialCommitment.targetName,
        target_value: safeSocialCommitment.targetValue,
        target_value_realised: safeSocialCommitment.targetValueRealised,
    };
};
exports.encodePackageSocialSpendCommitment = encodePackageSocialSpendCommitment;
