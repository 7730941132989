"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeSideBarMenuModel = void 0;
const decodeSideBarMenuModel = (sideBarMenuModel) => {
    return {
        packageReportSocialCount: sideBarMenuModel.package_report_social_count,
        packageReportSustainabilityCount: sideBarMenuModel.package_report_sustainability_count,
        policyReportCount: sideBarMenuModel.policy_report_count,
        projectReportSocialCount: sideBarMenuModel.project_report_social_count,
        projectReportSustainabilityCount: sideBarMenuModel.project_report_social_count,
    };
};
exports.decodeSideBarMenuModel = decodeSideBarMenuModel;
