"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultUser = void 0;
const uuid_1 = require("uuid");
const createDefaultUser = (email, isContractor, id) => {
    return {
        email: email.toLowerCase(),
        id: id ? id : (0, uuid_1.v4)(),
        isContractor,
        isSocialProAdmin: false,
    };
};
exports.createDefaultUser = createDefaultUser;
