import { getApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeAnalyticsLabourHoursLevel1PageModel,
  decodeLabourHoursAnalyticsModel,
} from "social-pro-common/decoders/analytics";
import { AnalyticsLabourHoursLevel1PageModel } from "social-pro-common/entities/analytics";
import { AnalyticsLabourHoursLevel1PageModelLineItem } from "social-pro-common/interfaces/analytics";

export const useLabourHourAnalyticsLevel1 = (
  reportId: string,
  projectId?: string,
  contractorPackageId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [labourHoursAnalytics, setLabourHoursAnalytics] =
    useState<AnalyticsLabourHoursLevel1PageModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getLabourHoursAnalyticsModel = useCallback(
    async (
      reportId: string,
      projectId: string,
      contractorPackageId?: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getAnalyticsLabourHoursLevel1PageModel",
          "analytics",
          projectId,
          { contractorPackageId, reportId },
        );

        const res = decodeAnalyticsLabourHoursLevel1PageModel(
          analysisResult.data as AnalyticsLabourHoursLevel1PageModel,
        );
        setLabourHoursAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [decodeLabourHoursAnalyticsModel, getApiData],
  );

  useEffect(() => {
    if (projectId && contractorPackageId) {
      getLabourHoursAnalyticsModel(reportId, projectId, contractorPackageId);
    }
  }, [reportId, projectId, contractorPackageId, getLabourHoursAnalyticsModel]);

  return {
    error,
    isAnalysisLoading,
    labourHoursAnalytics,
  };
};
