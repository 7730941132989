import { Box, Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

interface PackageDetailsTabProps {
  isLoading: boolean;
  activeTab: string;
  project?: ProjectLineItem;
  subPackage?: ContractorPackageLineItem;
  showComments?: boolean;
  setActiveTab: (activeTab?: PackageDetailsTabOption) => void;
}

export enum PackageDetailsTabOption {
  Hours = "hours",
  Spend = "spend",
  Policy = "policy",
  LocalContent = "local-content",
  Enviro = "enviro",
  Reports = "reports",
  Comments = "comments",
  Forecast = "forecasting",
  Details = "details",
}
export const PackageDetailsTab = ({
  activeTab,
  isLoading,
  project,
  setActiveTab,
  showComments,
  subPackage,
}: PackageDetailsTabProps): JSX.Element => {
  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: PackageDetailsTabOption,
  ) => {
    if (newValue) {
      setActiveTab(newValue);
    }
  };
  return (
    <Box
      sx={{
        justifyContent: "flex-start",
        marginTop: isLoading ? "2px !important" : "8px !important",
      }}
    >
      {isLoading ? (
        <Skeleton animation="wave" variant="rectangular">
          <ToggleButtonGroup
            color="primary"
            value={activeTab}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value={PackageDetailsTabOption.Hours} size="small">
              Labour Hours
            </ToggleButton>
            <ToggleButton value={PackageDetailsTabOption.Spend} size="small">
              Spend
            </ToggleButton>
            <ToggleButton value={PackageDetailsTabOption.Policy} size="small">
              Policies
            </ToggleButton>
            <ToggleButton value={PackageDetailsTabOption.Policy} size="small">
              Local Content
            </ToggleButton>
            <ToggleButton value={PackageDetailsTabOption.Enviro} size="small">
              Sustainability
            </ToggleButton>
            <ToggleButton value={PackageDetailsTabOption.Forecast} size="small">
              Forecast
            </ToggleButton>
            <ToggleButton value={PackageDetailsTabOption.Reports} size="small">
              Reports
            </ToggleButton>
            <ToggleButton value={PackageDetailsTabOption.Details} size="small">
              Subcontractor Details
            </ToggleButton>
            {showComments ? (
              <ToggleButton
                value={PackageDetailsTabOption.Details}
                size="small"
              >
                Comments
              </ToggleButton>
            ) : undefined}
          </ToggleButtonGroup>
        </Skeleton>
      ) : (
        <ToggleButtonGroup
          color="primary"
          value={activeTab}
          exclusive
          onChange={handleChange}
          sx={{ height: "40px" }}
        >
          {subPackage?.commitmentsHours.length ? (
            <ToggleButton
              data-test-id="labour-hours-button"
              value={PackageDetailsTabOption.Hours}
              size="small"
            >
              Labour Hours
            </ToggleButton>
          ) : null}
          {subPackage?.commitmentsSpend.length ? (
            <ToggleButton
              data-test-id="spend-button"
              value={PackageDetailsTabOption.Spend}
              size="small"
            >
              Spend
            </ToggleButton>
          ) : null}
          {project?.localContents.length ? (
            <ToggleButton
              data-test-id="localContent-button"
              value={PackageDetailsTabOption.LocalContent}
              size="small"
            >
              Local Content
            </ToggleButton>
          ) : null}
          {project?.policies.length ? (
            <ToggleButton
              data-test-id="policy-button"
              value={PackageDetailsTabOption.Policy}
              size="small"
            >
              Policies
            </ToggleButton>
          ) : null}
          {project?.greenMetrics.length ? (
            <ToggleButton
              data-test-id="green-metric-button"
              value={PackageDetailsTabOption.Enviro}
              size="small"
            >
              Sustainability
            </ToggleButton>
          ) : null}
          <ToggleButton
            data-test-id="forecast-button"
            value={PackageDetailsTabOption.Forecast}
            size="small"
          >
            Forecast
          </ToggleButton>
          <ToggleButton
            data-test-id="reports-button"
            value={PackageDetailsTabOption.Reports}
            size="small"
          >
            Reports
          </ToggleButton>
          {showComments ? (
            <ToggleButton
              data-test-id="comments-button"
              value={PackageDetailsTabOption.Comments}
              size="small"
            >
              Notes
            </ToggleButton>
          ) : undefined}
          <ToggleButton
            data-test-id="details-button"
            value={PackageDetailsTabOption.Details}
            size="small"
          >
            Subcontractor Details
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
};
