import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";
import { DropdownIcon } from "assets/constants";

export const ReportTableSubRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell sx={{ paddingBottom: "7px", paddingTop: "7px", width: "28%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ paddingBottom: "7px", paddingTop: "7px", width: "28%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ paddingBottom: "7px", paddingTop: "7px", width: "28%" }}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Skeleton
            variant={"rounded"}
            sx={{ borderRadius: "16px" }}
            width={70}
            height={25}
          />
        </Stack>
      </TableCell>
      <TableCell sx={{ paddingBottom: "7px", paddingTop: "7px", width: "28%" }}>
        <TableCell
          align="center"
          sx={{ paddingBottom: "5px", paddingTop: "5px", width: "14%" }}
        >
          <Skeleton animation="wave" variant="circular" sx={{ margin: "auto" }}>
            <DropdownIcon />
          </Skeleton>
        </TableCell>
      </TableCell>
    </TableRow>
  );
};
