"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportingDocumentTypeToString = exports.createDefaultSupportingDocument = void 0;
const uuid_1 = require("uuid");
const contractorEmployee_1 = require("../../entities/contractorEmployee");
const contractorEmployee_2 = require("../contractorEmployee");
const createDefaultSupportingDocument = (contractorEmployeeId, employeeSocialFactor, fileKey) => {
    return {
        contractorEmployeeId: contractorEmployeeId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        supportingDocumentType: employeeSocialFactor,
        uri: fileKey,
    };
};
exports.createDefaultSupportingDocument = createDefaultSupportingDocument;
const supportingDocumentTypeToString = (employeeSocialSpendFactor) => {
    switch (employeeSocialSpendFactor) {
        default:
            return "Evidence of status";
        case contractorEmployee_1.EmployeeSocialFactor.AboriginalOrTorresStraitIslander:
            return "Aboriginal or Torres-Strait Islander";
        case contractorEmployee_1.EmployeeSocialFactor.AsylumSeekerRefugee:
            return "Asylum Seeker/Refugee";
        case contractorEmployee_1.EmployeeSocialFactor.DisengagedYouth:
            return "Disengaged youth - aged between 15-24";
        case contractorEmployee_1.EmployeeSocialFactor.LongTermUnemployed:
            return "Long-term unemployed";
        case contractorEmployee_1.EmployeeSocialFactor.PersonWithDisability:
            return "Person with disability (inclusive of mental illness)";
        case contractorEmployee_1.EmployeeSocialFactor.Veteran:
            return "Veteran (ADF or Allied Forces)";
        case contractorEmployee_1.EmployeeSocialFactor.PersonsAffectedByFamilyViolence:
            return "Persons Affected by Family Violence";
        case contractorEmployee_1.EmployeeSocialFactor.ExOffender:
            return "Ex-offender";
        case contractorEmployee_2.EmployeeAuxillaryInformation.IdentityDocument:
            return "Identity Document";
    }
};
exports.supportingDocumentTypeToString = supportingDocumentTypeToString;
