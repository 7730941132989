import { Box, Collapse, List } from "@mui/material";
import { useState } from "react";
import { NavItemType } from "types";

import { MENU_LIST_ITEM_VARIANTS } from "../constants";
import { MenuListItem } from "../MenuListItem";
import { beforeStyles, StyledList } from "./components";

export const MenuListItemTree = ({
  items,
  selectedItem,
}: React.PropsWithChildren<{ items: NavItemType; selectedItem: string[] }>) => {
  if (!items.children) {
    return null;
  }

  const variant =
    items.id === "projects"
      ? MENU_LIST_ITEM_VARIANTS.TITLE
      : MENU_LIST_ITEM_VARIANTS.ITEM;
  const [openItem, setOpenItem] = useState<string | null>(() => {
    return localStorage.getItem("openItem");
  });

  const handleToggle = (itemTitle: string | null) => {
    const newOpenItem = openItem === itemTitle ? null : itemTitle;
    setOpenItem(newOpenItem);
    if (newOpenItem) {
      localStorage.setItem("openItem", newOpenItem);
    } else {
      localStorage.removeItem("openItem");
    }
  };

  const renderMenu = (
    menu: NavItemType[],
    selectedItem: string[],
    level = 0,
  ) => {
    // deriving MenuEntry variant.
    // This may be better added on the data
    const derivedVariant =
      level > 0 ? MENU_LIST_ITEM_VARIANTS.SUBITEM : variant;

    return menu.map((item) => {
      // STUB
      const active = selectedItem?.includes(item.id as string);

      return (
        <Box
          component="li"
          key={item.id}
          sx={{
            ...(active && derivedVariant === MENU_LIST_ITEM_VARIANTS.SUBITEM
              ? {
                  "&:before": beforeStyles,
                }
              : {}),
            position: "relative",
          }}
        >
          <MenuListItem
            active={active}
            menuItem={item}
            openItem={openItem}
            variant={derivedVariant}
            handleToggle={handleToggle}
          />
          {item.children && (
            <Collapse in={openItem === item.title} timeout="auto">
              <StyledList>
                {renderMenu(item.children, selectedItem, level + 1)}
              </StyledList>
            </Collapse>
          )}
        </Box>
      );
    });
  };

  return (
    <List component="nav">
      <Box component="ul" sx={{ listStyleType: "none", m: 0, p: 0 }}>
        {renderMenu(items.children, selectedItem)}
      </Box>
    </List>
  );
};
