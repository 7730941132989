import { Grid } from "@mui/material";
import SupportingDocumentDialog from "@stories/molecules/SupportingDocumentDialog/SupportingDocumentDialog";
import {
  ActionCell,
  centerCellStyles,
  defaultCellConfig,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState } from "react";
import { AnalyticsLabourHoursLevel4PageModelLineItem } from "social-pro-common/interfaces/analytics";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { AnalyticsHoursLevel4TableRowSkeleton } from "./AnalyticsHoursLevel4TableRowSkeleton";
import { generateTableHead, Table } from "../../Table/Table";

interface AnalyticsHoursLevel4TableProps {
  loading: boolean;
  labourHoursAnalytics?: AnalyticsLabourHoursLevel4PageModelLineItem;
  targetId: string;
  reportId: string;
  mode?: string;
  searchTerm: string;
}

export const AnalyticsHoursLevel4Table = ({
  labourHoursAnalytics,
  loading,
  searchTerm,
}: AnalyticsHoursLevel4TableProps) => {
  const [selectedLabourHour, setSelectedLabourHour] =
    useState<LabourHourForTargetLineItem>();

  const handleClose = () => {
    setSelectedLabourHour(undefined);
  };

  const handleAction = (labourHour: LabourHourForTargetLineItem) => {
    setSelectedLabourHour(labourHour);
  };
  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      singleAction: true,
      title: "View",
    },
  ];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "reportId",
      flex: 1.5,
      headerName: "Report Date",
      valueFormatter: (params: any) =>
        formatReportDate(stringToDate(params.value)),
    },
    {
      ...defaultCellConfig,
      field: "employeeName",
      flex: 2,
      headerName: "Name or Code",
    },
    {
      ...defaultCellConfig,
      field: "organisationName",
      flex: 2,
      headerName: "Subcontractor Name",
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "previousHoursWorked",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Previous Hours Worked",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "currentHoursWorked",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Current Hours Worked",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "totalHoursWorked",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Total Hours Worked",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "totalHoursStudied",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Total Hours Studied",
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      cellStyle: centerCellStyles,
      field: "",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          padding: "20px 0 !important",
        }}
      >
        {loading ? (
          <TableSkeleton
            tableHead={generateTableHead(colDefs)}
            rows={AnalyticsHoursLevel4TableRowSkeleton}
            noFullTableBorder
          />
        ) : null}
        {!loading ? (
          <Table<LabourHourForTargetLineItem>
            columnDefs={colDefs}
            loading={loading}
            context={{ cellActions }}
            noFullTableBorder
            data={labourHoursAnalytics?.labourHours || []}
            searchKeys={["organisationName", "employeeName"]}
            searchValue={searchTerm}
          />
        ) : null}
      </Grid>

      {selectedLabourHour ? (
        <SupportingDocumentDialog
          open
          handleClose={handleClose}
          labourHour={selectedLabourHour}
        />
      ) : null}
    </>
  );
};
