import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import { decodeAnalyticsSocialSpendLevel3PageModel } from "social-pro-common/decoders/analytics";
import { AnalyticsSocialSpendLevel3PageModel } from "social-pro-common/entities/analytics";
import { AnalyticsSocialSpendLevel3PageModelLineItem } from "social-pro-common/interfaces/analytics";

export const useSocialSpendAnalyticsLevel3 = (
  projectId?: string,
  contractorPackageId?: string,
  reportId?: string,
  targetId?: string,
  mode?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [socialSpendAnalytics, setSocialSpendAnalytics] =
    useState<AnalyticsSocialSpendLevel3PageModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getSocialSpendAnalyticsModel = useCallback(
    async (
      projectId: string,
      reportId: string,
      targetId: string,
      contractorPackageId?: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getSocialSpendLevel3PageModel",
          "analytics",
          projectId,
          { contractorPackageId, mode, reportId, targetId },
        );

        const res = await decodeAnalyticsSocialSpendLevel3PageModel(
          analysisResult.data as AnalyticsSocialSpendLevel3PageModel,
          getImageUrl,
        );
        setSocialSpendAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [getApiData, decodeAnalyticsSocialSpendLevel3PageModel, catchSentryError],
  );

  useEffect(() => {
    if (reportId && projectId && targetId && contractorPackageId) {
      getSocialSpendAnalyticsModel(
        projectId,
        reportId,
        targetId,
        contractorPackageId,
      );
    }
  }, [reportId, projectId, contractorPackageId, getSocialSpendAnalyticsModel]);

  return {
    error,
    isAnalysisLoading,
    socialSpendAnalytics,
  };
};
