import { Box, Stack, Typography } from "@mui/material";

interface LocalContentDataType {
  id: number;
  target: string;
  value_report: string;
  anz_value_add: string;
  target_local_content: string;
  color: string;
}

export const chartData = {
  pData: [],
  uData: [],
  xLabels: ["-", "-", "-", "-", "-", "-"],
};

export const columns = [
  { id: "target", label: "Target" },
  { align: "right", id: "actual_hours", label: "Actual Hours" },
  { align: "right", id: "target_hours", label: "Target Hours" },
  { align: "right", id: "overall", label: "Overall %" },
];

export const localContentData: LocalContentDataType[] = [
  {
    anz_value_add: "98.47%",
    color: "#29238A",
    id: 1,
    target: "Preliminaries and Margins",
    target_local_content: "100.00%",
    value_report: "$29,164,184.46",
  },
  {
    anz_value_add: "99.78%",
    color: "#3838D0",
    id: 2,
    target: "Labour - Civil",
    target_local_content: "100.00%",
    value_report: "$20,478,578.02",
  },
  {
    anz_value_add: "100.00%",
    color: "#B692F6",
    id: 3,
    target: "Labour - General",
    target_local_content: "0.00%",
    value_report: "$15,965,057.51",
  },
  {
    anz_value_add: "96.08%",
    color: "#D6BBFB",
    id: 4,
    target: "Concrete",
    target_local_content: "100.00%",
    value_report: "$10,140,932.30",
  },
  {
    anz_value_add: "99.76%",
    color: "#F4EBFF",
    id: 5,
    target: "Project Management",
    target_local_content: "98.00%",
    value_report: "$9,648,152.49",
  },
  {
    anz_value_add: "99.76%",
    color: "#FEF0C7",
    id: 6,
    target: "Piling",
    target_local_content: "100.00%",
    value_report: "$7,932,040.00",
  },
  {
    anz_value_add: "99.21%",
    color: "#FEC84B",
    id: 7,
    target: "Steel - Reinforcing",
    target_local_content: "0.00%",
    value_report: "$7,065,783.09",
  },
  {
    anz_value_add: "94.65%",
    color: "#F79009",
    id: 8,
    target: "Design and Drafting",
    target_local_content: "95.00%",
    value_report: "$6,041,216.57",
  },
  {
    anz_value_add: "68.34%",
    color: "#DC6803",
    id: 9,
    target: "Plant",
    target_local_content: "100.00%",
    value_report: "$5,440,161.49",
  },
  {
    anz_value_add: "100.00%",
    color: "#B54708",
    id: 10,
    target: "Labour - Hydraulics",
    target_local_content: "100.00%",
    value_report: "$3,292,311.87",
  },
];

export const localContentsColumns = [
  {
    id: "target",
    label: "Target",
    render: (value: any, row: any) => (
      <Stack direction="row" alignItems="center" gap={1}>
        <Box
          sx={{
            bgcolor: row.color || "black",
            borderRadius: "9999px",
            height: "8px",
            width: "8px",
          }}
        />
        <Typography>{value}</Typography>
      </Stack>
    ),
  },
  { align: "right", id: "actual", label: "Value Reported" },
  { align: "right", id: "target", label: "ANZ Value Add %" },
  {
    align: "right",
    id: "target",
    label: "Target Local Content %",
  },
];

export const tabsData = [
  { dataTestId: "labour-hour-tab", label: "Labour Hours", value: 0 },
  { dataTestId: "social-spend-tab", label: "Social Spend", value: 1 },
  { dataTestId: "local-content-tab", label: "Local Content", value: 2 },
];
