"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectToPackageLabourHourCommitment = exports.cloneProjectLabourHourCommitmentsForPackage = exports.labourHourCommitmentTypeToDescription = exports.labourHourCommitmentTypeToAbbreviatedString = exports.labourHourCommitmentTypeToString = exports.SocialCommitmentTypeHour = exports.projectLevelLabourHourCommitmentType = void 0;
const uuid_1 = require("uuid");
exports.projectLevelLabourHourCommitmentType = [];
var SocialCommitmentTypeHour;
(function (SocialCommitmentTypeHour) {
    SocialCommitmentTypeHour["DefaultHour"] = "social-commitment-type_default-hours";
    SocialCommitmentTypeHour["LocalJobsFirst"] = "social-commitment-type_local-jobs-first";
    SocialCommitmentTypeHour["MPSGHoursRequirement"] = "social-commitment-mpsg-hours-requirement";
    SocialCommitmentTypeHour["VictorianPriorityWorkforce"] = "social-commitment-type_victorian-priority-workforce";
    SocialCommitmentTypeHour["FemaleParticipation"] = "social-commitment-type_female-participation";
    SocialCommitmentTypeHour["BepTradeHours"] = "social-commitment-type_bep-trade";
    SocialCommitmentTypeHour["BepNonTradeHours"] = "social-commitment-type_bep-non-trade";
    SocialCommitmentTypeHour["BepManagementHours"] = "social-commitment-type_bep-management";
    SocialCommitmentTypeHour["FemaleApprenticeAndTrainee"] = "social-commitment-type_female-apprentices-and-trainees";
    SocialCommitmentTypeHour["AboriginalTorresStraitIslanderParticipation"] = "social-commitment-type_aboriginal-torres-strait-island-participation";
})(SocialCommitmentTypeHour || (exports.SocialCommitmentTypeHour = SocialCommitmentTypeHour = {}));
const labourHourCommitmentTypeToString = (targetName) => {
    switch (targetName) {
        default:
        case SocialCommitmentTypeHour.DefaultHour:
            return "Custom Hour";
        case SocialCommitmentTypeHour.LocalJobsFirst:
            return "Local Jobs First";
        case SocialCommitmentTypeHour.MPSGHoursRequirement:
            return "MPSG Hours Requirement";
        case SocialCommitmentTypeHour.VictorianPriorityWorkforce:
            return "Victorian Priority Workforce";
        case SocialCommitmentTypeHour.FemaleParticipation:
            return "Female Participation";
        case SocialCommitmentTypeHour.BepTradeHours:
            return "BEP hours (trade roles)";
        case SocialCommitmentTypeHour.BepNonTradeHours:
            return "BEP hours (non-trade roles)";
        case SocialCommitmentTypeHour.BepManagementHours:
            return "BEP hours (management or supervisory roles)";
        case SocialCommitmentTypeHour.FemaleApprenticeAndTrainee:
            return "Female Apprentices and Trainees";
        case SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation:
            return "Indigenous Participation";
    }
};
exports.labourHourCommitmentTypeToString = labourHourCommitmentTypeToString;
const labourHourCommitmentTypeToAbbreviatedString = (targetName) => {
    switch (targetName) {
        case SocialCommitmentTypeHour.DefaultHour:
            return "Custom";
        case SocialCommitmentTypeHour.LocalJobsFirst:
            return "LJF";
        case SocialCommitmentTypeHour.MPSGHoursRequirement:
            return "MPSG";
        case SocialCommitmentTypeHour.VictorianPriorityWorkforce:
            return "VPW";
        case SocialCommitmentTypeHour.FemaleParticipation:
            return "FP";
        case SocialCommitmentTypeHour.BepTradeHours:
            return "BEPT";
        case SocialCommitmentTypeHour.BepNonTradeHours:
            return "BEPNT";
        case SocialCommitmentTypeHour.BepManagementHours:
            return "BEPM";
        case SocialCommitmentTypeHour.FemaleApprenticeAndTrainee:
            return "FT";
        case SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation:
            return "IP";
    }
};
exports.labourHourCommitmentTypeToAbbreviatedString = labourHourCommitmentTypeToAbbreviatedString;
const labourHourCommitmentTypeToDescription = (socialCommitmentType) => {
    switch (socialCommitmentType) {
        default:
        case SocialCommitmentTypeHour.DefaultHour:
            return "Custom Hours: Description missing - please contact support";
        case SocialCommitmentTypeHour.LocalJobsFirst:
            return "Workers on a project residing within 50km of the Project address.";
        case SocialCommitmentTypeHour.MPSGHoursRequirement:
            return "Contribution of local apprentices, trainees and cadets to overall labour hours on a project.";
        case SocialCommitmentTypeHour.VictorianPriorityWorkforce:
            return "Contribution in labour hours to a Project by cohorts of disadvantaged Victorians under the Social Procurement Framework (SPF)";
        case SocialCommitmentTypeHour.FemaleParticipation:
            return "Contribution in labour hours to a Project by females in trade, non-trade, and on-site managerial/supervisory and specialist roles under the Building Equality Policy (BEP) implemented by the Social Procurement Framework (SPF).";
        case SocialCommitmentTypeHour.BepTradeHours:
            return "ontribution in labour hours to a Project by females in trade roles under the Building Equality Policy (BEP) implemented by the Social Procurement Framework (SPF)";
        case SocialCommitmentTypeHour.BepNonTradeHours:
            return "ontribution in labour hours to a Project by females in non-trade roles under the Building Equality Policy (BEP) implemented by the Social Procurement Framework (SPF)";
        case SocialCommitmentTypeHour.BepManagementHours:
            return "ontribution in labour hours to a Project by females in on-site managerial/supervisory and specialist roles under the Building Equality Policy (BEP) implemented by the Social Procurement Framework (SPF)";
        case SocialCommitmentTypeHour.FemaleApprenticeAndTrainee:
            return "Contribution in labour hours to a Project by female apprentices and trainees";
        case SocialCommitmentTypeHour.AboriginalTorresStraitIslanderParticipation:
            return "Contribution in labour hours to a Project by people that are of Aboriginal or Torres Strait Islander descent, or who identify as an Aboriginal or Torres Strait Islander person and are accepted as such by the community in which they live or formerly lived.";
    }
};
exports.labourHourCommitmentTypeToDescription = labourHourCommitmentTypeToDescription;
const cloneProjectLabourHourCommitmentsForPackage = (packageId, commitments) => {
    return commitments
        .map((c) => {
        return {
            ...c,
            achievedValue: 0,
            id: (0, uuid_1.v4)(),
            packageId,
            projectLabourHourCommitmentId: c.id,
        };
    })
        .filter((c) => !exports.projectLevelLabourHourCommitmentType.includes(c.targetName));
};
exports.cloneProjectLabourHourCommitmentsForPackage = cloneProjectLabourHourCommitmentsForPackage;
const projectToPackageLabourHourCommitment = (packageId, commitment) => {
    return {
        achieved_value: commitment.achieved_value,
        contractor_package_id: packageId,
        created: commitment.created,
        package_labour_hour_commitment_id: (0, uuid_1.v4)(),
        project_id: commitment.project_id,
        project_labour_hour_commitment_id: commitment.project_labour_hour_commitment_id,
        target_description: commitment.target_description,
        target_type: commitment.target_type,
        target_value: commitment.target_value,
        target_value_realised: commitment.target_value_realised,
    };
};
exports.projectToPackageLabourHourCommitment = projectToPackageLabourHourCommitment;
