import { Skeleton, TableCell, TableRow } from "@mui/material";

export const PackageSustainabilityTableRowSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell
          align="left"
          sx={{ minWidth: "10vw", paddingBottom: "10px", paddingTop: "10px" }}
        >
          <Skeleton variant="text" animation="wave" width={300} />
        </TableCell>
      </TableRow>
    </>
  );
};
