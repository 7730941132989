import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";

export const LabourHourTableRowSkeleton = () => {
  return (
    <>
      <TableRow>
        <TableCell
          align="left"
          sx={{ paddingBottom: "5px", paddingTop: "5px", width: "50%" }}
        >
          <Stack direction="row" spacing={1}>
            <Skeleton animation="wave" variant="circular">
              <IconButton size="large" sx={{ height: 20, width: 20 }} />
            </Skeleton>
            <Skeleton animation="wave" width={300} />
          </Stack>
        </TableCell>

        <TableCell
          align="center"
          sx={{
            paddingBottom: "5px",
            paddingTop: "5px",
            textAlign: "center !important",
            width: "10%",
          }}
          className="projectTextField"
        >
          <Skeleton animation="wave" />
        </TableCell>

        <TableCell
          align="center"
          sx={{
            paddingBottom: "5px",
            paddingTop: "5px",
            textAlign: "center !important",
            width: "10%",
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>

        <TableCell
          sx={{
            paddingBottom: "5px",
            paddingTop: "5px",
            width: "15%",
          }}
        >
          <Stack direction="row" spacing={2} justifyContent={"center"}>
            <Stack direction="row" spacing={1}>
              <Skeleton animation="wave" variant="circular">
                <IconButton>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Skeleton>
              <Skeleton animation="wave" variant="circular">
                <IconButton color="error" size="large">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Skeleton>
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
