import { Skeleton, Stack, Typography } from "@mui/material";

import {
  MetricContainer,
  MetricDot,
  MetricFigure,
  MetricHeading,
} from "./components";

interface Props {
  color: string;
  loading: boolean;
  figure: number | string;
  heading: string;
  dataTestId?: string;
}

const MetricScore = ({
  color,
  dataTestId,
  figure,
  heading,
  loading,
}: Props) => {
  return (
    <MetricContainer>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="circular"
          width={"10px"}
          height={"10px"}
        />
      ) : (
        <MetricDot color={color} />
      )}
      <Stack direction="column" gap={1}>
        {loading ? (
          <Skeleton animation="wave">
            <Typography>Target Name</Typography>
          </Skeleton>
        ) : (
          <MetricHeading>{heading}</MetricHeading>
        )}
        {loading ? (
          <Skeleton animation="wave">
            <Typography>100%</Typography>
          </Skeleton>
        ) : (
          <MetricFigure data-test-id={dataTestId}>{figure}</MetricFigure>
        )}
      </Stack>
    </MetricContainer>
  );
};

export default MetricScore;
