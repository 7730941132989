"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeDashboardForecastData = exports.decodeDashboardReportInfo = exports.decodeDashboardTableItem = exports.decodeReportSubmissionProgress = exports.decodeProjectProgress = exports.decodeDashboardModel = void 0;
const decodeDashboardModel = (data) => {
    return {
        labourHour: (0, exports.decodeDashboardReportInfo)(data.labour_hour),
        localContent: (0, exports.decodeDashboardReportInfo)(data.local_content),
        projectName: data.projectName,
        projectProgress: (0, exports.decodeProjectProgress)(data.project_progress),
        reportProgress: (0, exports.decodeReportSubmissionProgress)(data.report_progress),
        socialSpend: (0, exports.decodeDashboardReportInfo)(data.social_spend),
    };
};
exports.decodeDashboardModel = decodeDashboardModel;
const decodeProjectProgress = (data) => {
    return {
        timeProgress: data.time_progress,
        totalValue: data.total_value,
    };
};
exports.decodeProjectProgress = decodeProjectProgress;
const decodeReportSubmissionProgress = (data) => {
    return {
        count: data.count,
        reportSubmissionPcChange: data.report_submission_pc_change,
        reportSubmissionPcProgress: data.report_submission_pc_progress,
    };
};
exports.decodeReportSubmissionProgress = decodeReportSubmissionProgress;
const decodeDashboardTableItem = (data) => {
    return {
        actual: data.actual,
        pcProgress: data.pc_progress,
        target: data.target,
        targetName: data.target_name,
    };
};
exports.decodeDashboardTableItem = decodeDashboardTableItem;
const decodeDashboardReportInfo = (data) => {
    return {
        forecast: (0, exports.decodeDashboardForecastData)(data.forecast),
        pcProgressCommitted: data.pc_progress_committed,
        pcProgressReported: data.pc_progress_reported,
        tableItems: data.table_items.map((i) => (0, exports.decodeDashboardTableItem)(i)),
        targetTotal: data.target_total,
        totalCommitted: data.total_committed,
        totalReported: data.total_reported,
    };
};
exports.decodeDashboardReportInfo = decodeDashboardReportInfo;
const decodeDashboardForecastData = (data) => {
    return {
        pData: data.pData,
        uData: data.uData,
        xLabels: data.xLabels,
    };
};
exports.decodeDashboardForecastData = decodeDashboardForecastData;
