import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsLevel1 } from "@hooks/crud/analytics/useLabourHourAnalyticsLevel1";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsHoursLevel1Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel1Table/AnalyticsHoursLevel1Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { writeXlsxLabourHourTargets } from "@utils/xlsx/labourHours";
import { ExportIcon } from "assets/constants";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LabourHourTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

export const AnalyticsHoursLevel1 = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();

  if (!reportId) {
    throw Error("No report id");
  }
  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsLevel1(
      reportId,
      selectedProject?.id,
      contractorPackage?.id,
    );

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const onLabourHourTargetChange = useCallback(
    (target: LabourHourTargetAnalyticsLineItem) => {
      if (selectedProject && contractorPackage && reportId) {
        if (isPrimaryPackage) {
          navigate(
            RouteHelper.analyticsLabourHoursLevel2(
              selectedProject.id,
              contractorPackage.id,
              target.id,
              reportId,
            ),
          );
        } else {
          navigate(
            RouteHelper.analyticsLabourHoursLevel3(
              selectedProject.id,
              contractorPackage.id,
              contractorPackage.id,
              reportId,
              target.id,
              "package",
            ),
          );
        }
      }
    },
    [selectedProject, contractorPackage, reportId, isPrimaryPackage],
  );

  const loading = isProjectLoading || isAnalysisLoading;

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsLabourHoursLevel1(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        dateToString(currentDate),
      ),
      label: "Labour Hours",
    },
  ];

  const handleExportEmployee = async () => {
    if (labourHoursAnalytics) {
      writeXlsxLabourHourTargets(labourHoursAnalytics.targets);
    }
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportEmployee,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];

  return (
    <PageContainer>
      <Header
        breadcrumbIcon={<HourglassEmptyIcon />}
        breadcrumbs={breadcrumbData}
        loading={loading}
        subTitle={
          isPrimaryPackage
            ? `Project Analytics for ${selectedProject?.projectName}`
            : `Package Analytics for ${selectedProject?.projectName}`
        }
        mainTitle="Labour Hours"
        handleSearchChange={handleSearchChange}
        handleDateChange={handleDateChange}
        currentDate={currentDate}
        searchTerm={searchTerm}
        buttons={buttons}
      />
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={2}>
            <Header
              loading={loading}
              mainTitle="Target Summary"
              subTitle="Level 1 Analytics"
              isSubheading
              headerFontSizes={[18, 14]}
              headerGap={0}
            />
            <AnalyticsHoursLevel1Table
              loading={loading}
              searchTerm={searchTerm}
              labourHourAnalytics={labourHoursAnalytics}
              handleAction={onLabourHourTargetChange}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </PageContainer>
  );
};
