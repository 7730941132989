import { List, styled } from "@mui/material";

const StyledList = styled(List)({
  "&:after": {
    background: "#FFF",
    content: '""',
    display: "block",
    height: "calc(100% - 40px)",
    left: "24px",
    opacity: "0.5",
    position: "absolute",
    top: "20px",
    width: "1px",
  },
  margin: 0,
  padding: "0 0 0 32px",
  position: "relative",
});

const beforeStyles = {
  background: "rgba(255, 255, 255, 1)",
  borderRadius: "100%",
  content: '""',
  display: "block",
  height: "3px",
  left: "-9px",
  position: "absolute",
  top: "50%",
  width: "3px",
};
export { StyledList, beforeStyles };
