import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeAnalyticsLabourHoursLevel4PageModel,
  decodeLabourHoursAnalyticsModel,
} from "social-pro-common/decoders/analytics";
import { AnalyticsLabourHoursLevel4PageModel } from "social-pro-common/entities/analytics";
import { AnalyticsLabourHoursLevel4PageModelLineItem } from "social-pro-common/interfaces/analytics";

export const useLabourHourAnalyticsLevel4 = (
  reportId: string,
  projectId?: string,
  contractorPackageId?: string,
  targetId?: string,
  contractorEmployeeId?: string,
) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(true);
  const [labourHoursAnalytics, setLabourHoursAnalytics] =
    useState<AnalyticsLabourHoursLevel4PageModelLineItem>();
  const [error, setError] = useState<string | null>(null);

  const getLabourHoursAnalyticsModel = useCallback(
    async (
      reportId: string,
      targetId: string,
      projectId: string,
      contractorPackageId?: string,
      contractorEmployeeId?: string,
    ): Promise<void> => {
      try {
        setIsAnalysisLoading(true);
        const analysisResult = await getApiData(
          "getAnalyticsLabourHoursLevel4PageModel",
          "analytics",
          projectId,
          { contractorEmployeeId, contractorPackageId, reportId, targetId },
        );

        const res = await decodeAnalyticsLabourHoursLevel4PageModel(
          analysisResult.data as AnalyticsLabourHoursLevel4PageModel,
          getImageUrl,
        );
        setLabourHoursAnalytics(res);
      } catch (e) {
        catchSentryError(e);
        setError("Could not fetch chart data");
      } finally {
        setIsAnalysisLoading(false);
      }
    },
    [decodeLabourHoursAnalyticsModel, getApiData],
  );

  useEffect(() => {
    if (projectId && contractorPackageId && targetId) {
      getLabourHoursAnalyticsModel(
        reportId,
        targetId,
        projectId,
        contractorPackageId,
        contractorEmployeeId,
      );
    }
  }, [reportId, projectId, contractorPackageId, getLabourHoursAnalyticsModel]);

  return {
    error,
    isAnalysisLoading,
    labourHoursAnalytics,
  };
};
