import {
  Typography,
  Card,
  Button,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useState } from "react";
import { SocialBusinessLineItem } from "social-pro-common/interfaces/socialBusiness";

interface DirectoryCardProps {
  socialBusiness: SocialBusinessLineItem;
}
export const DirectoryCard = ({ socialBusiness }: DirectoryCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = socialBusiness.socialBusinessBlurb.length > 150;
  return (
    <Card
      className="direct-card"
      variant="outlined"
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <CardHeader sx={{   
            color:'#181D27',
            fontFamily:'Inter',
            fontSize: "16px",
            fontWeight:600,
            lineHeight: "24px",
          }}
        avatar={
          <img
            style={{ backgroundColor: "#F5F5F5" }}
            width={80}
            alt={socialBusiness.socialBusinessName}
            src={socialBusiness.socialBusinessLogo}
          />
        }
        title={socialBusiness.socialBusinessName}
      />
      <CardContent sx={{padding:'20px'}}>
        <Typography
          variant="caption"
          component="p"
          id="fullDescription"
          mb={2}
          sx={{
            color: "#535862",
            fontFamily:'Inter',
            fontSize: "14px",
            fontWeight:400,
            lineHeight: "20px",
          }}
        >
          {truncateText
            ? isExpanded
              ? socialBusiness.socialBusinessBlurb
              : `${socialBusiness.socialBusinessBlurb
                  .substr(0, 150)
                  .replace("<p>", "")}...`
            : socialBusiness.socialBusinessBlurb}

          {truncateText ? (
            <Button
              onClick={toggleDescription}
              className={isExpanded ? "readmore active" : "readmore"}
              // endIcon={<ArrowRightIcon sx={{ transition: "0.3s all" }} />}
              sx={{
                color: "#008CE6",
                display:'block',
                fontFamily:'Inter',
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
                padding: "0",
                paddingLeft: "2px",
              }}
            >
              {isExpanded ? "Read Less" : "Read More..."}
            </Button>
          ) : null}
        </Typography>

        <span
          style={{
            color: "#414651",
            display: "block",
            fontFamily:'Inter',
            fontSize: "14px",
            fontWeight: "600",
            lineHeight:'20px',
            marginBottom: "1px",
          }}
        >
          Service Locations
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#535862", fontSize: "14px" }}
        >
          {socialBusiness.socialBusinessLocations}
        </Typography>
        <span
          style={{
            color: "#414651",
            display: "block",
            fontFamily:'Inter',
            fontSize: "14px",
            fontWeight: "600",
            lineHeight:'20px',
            marginBottom: "1px",
          }}
        >
          Products and Service
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#535862", fontSize: "14px" }}
        >
          {socialBusiness.socialBusinessServices}
        </Typography>
        <span
          style={{
            color: "#414651",
            display: "block",
            fontFamily:'Inter',
            fontSize: "14px",
            fontWeight: "600",
            lineHeight:'20px',
            marginBottom: "1px",
          }}
        >
          Commitment Category
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#535862", fontSize: "14px" }}
        >
          {socialBusiness.socialBusinessBeneficiaries}
        </Typography>

        <span
          style={{
            color: "#414651",
            display: "block",
            fontFamily:'Inter',
            fontSize: "14px",
            fontWeight: "600",
            lineHeight:'20px',
            marginBottom: "1px",
          }}
        >
          EBA
        </span>
        <Typography
          variant="caption"
          component="p"
          mb={2}
          sx={{ color: "#535862", fontSize: "14px" }}
        >
          {`Current EBA: ${
            socialBusiness.socialBusinessEba.length
              ? socialBusiness.socialBusinessEba
              : "N/A"
          }`}
        </Typography>
        <span
          style={{
            color: "#414651",
            display: "block",
            fontFamily:'Inter',
            fontSize: "14px",
            fontWeight: "600",
            lineHeight:'20px',
            marginBottom: "1px",
          }}
        >
          Website
        </span>
        <Typography
          mb={2}

        >
          <a href={socialBusiness.socialBusinessWebsite} target="_blank" rel="noreferrer" 
          style={{
            color: "#008CE6",
            fontSize: "14px",
            fontWeight:'400',
            margin: "0",
            wordBreak: 'break-all',
          }}>
            {socialBusiness.socialBusinessWebsite}
          </a>
        </Typography>
      </CardContent>
    </Card>
  );
};
