import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { Arrow } from "assets/constants";
import {
  OrganisationDashboardCardModelLineItem,
  OrganisationDashboardModelLineItem,
} from "social-pro-common/interfaces/organisationDashboard";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

export const summaryData = [
  {
    id: "1",
    pc: 0,
    title: "Active Projects",
    value: "-",
  },
  {
    id: "2",
    pc: 0,
    title: "Total Project Value",
    value: "$ -",
  },
  {
    id: "3",
    pc: 0,
    title: "Active Employees",
    value: "-",
  },
  {
    id: "4",
    pc: 0,
    title: "Social Procurement",
    value: "- hrs",
  },
  {
    id: "5",
    pc: 0,
    title: "Priority Job Seekers",
    value: "- hrs",
  },
  {
    id: "6",
    pc: 0,
    title: "Aboriginal Employment",
    value: "- hrs",
  },
  {
    id: "7",
    pc: 0,
    title: "Apprentices, Trainees & Cadets",
    value: "- hrs",
  },
  {
    id: "8",
    pc: 0,
    title: "Female Participation",
    value: "- hrs",
  },
  {
    id: "9",
    pc: 0,
    title: "Jobs Created",
    value: "-",
  },
] as OrganisationDashboardCardModelLineItem[];

interface OrganisationProgressSummaryCardProps {
  loading: boolean;
  data: OrganisationDashboardCardModelLineItem;
}

export const OrganisationProgressSummaryCard = ({
  data,
  loading,
}: OrganisationProgressSummaryCardProps) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
      sx={{
        padding: 0,
      }}
      data-test-id={data.id}
    >
      <Box
        sx={{
          "&:hover": {
            borderColor: "#3838D0",
          },
          backgroundColor: "#fff",
          border: "1.8px solid #E9EAEB",
          borderRadius: "12px",
          boxShadow: "0px 1px 2px 0px #0A0D120D",
          height: "100%",
          padding: "20px",
          transition: "0.3s all",
        }}
      >
        <Stack direction={"column"} spacing={1}>
          <Typography
            color={"#535862"}
            variant={"h3"}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
            }}
            style={{ fontFamily: '"Inter", sans-serif' }}
          >
            {loading ? (
              <Skeleton animation="wave" sx={{ width: "30%" }} />
            ) : (
              data.title
            )}
          </Typography>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {loading ? (
              <Skeleton animation="wave">
                <Typography
                  color={"#181D27"}
                  variant={"h3"}
                  sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "38px",
                  }}
                  style={{ fontFamily: '"Inter", sans-serif' }}
                >
                  100%
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                color={"#181D27"}
                variant={"h3"}
                sx={{
                  fontSize: "30px",
                  fontWeight: "600",
                  lineHeight: "38px",
                }}
                style={{ fontFamily: '"Inter", sans-serif' }}
              >
                {data.value}
              </Typography>
            )}
            <Stack
              direction="row"
              alignItems={"center"}
              sx={{
                alignItems: "center",
                border: "1px solid #D5D7DA",
                borderRadius: "6px",
                boxShadow: "0px 1px 2px 0px #0A0D120D",
                padding: "2px 8px 2px 6px",
              }}
            >
              {loading ? (
                <Skeleton animation="wave">
                  <Arrow />
                </Skeleton>
              ) : (
                <Arrow />
              )}

              <Typography
                color={"#414651"}
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  margin: 0,
                  pl: "5px",
                }}
                style={{ fontFamily: '"Inter", sans-serif' }}
                paragraph
              >
                {loading ? (
                  <Skeleton animation="wave">
                    <Typography>100%</Typography>
                  </Skeleton>
                ) : (
                  `${formatDecimalPlaces(data.pc, 0)}%`
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
};

interface OrganisationProgressSummaryProps {
  loading: boolean;
  data?: OrganisationDashboardModelLineItem;
}

export const OrganisationProgressSummary = ({
  data,
  loading,
}: OrganisationProgressSummaryProps) => {
  console.log(data);

  const itemsToDisplay = loading || !data?.cards ? summaryData : data?.cards;
  return (
    <Stack direction={"column"} gap={4}>
      <Grid container spacing={3} sx={{ pt: "0" }}>
        {itemsToDisplay.map((i: any) => (
          <OrganisationProgressSummaryCard
            key={i.id}
            data={i}
            loading={loading}
          />
        ))}
      </Grid>
    </Stack>
  );
};
