import { getImageUrl } from "@hooks/utils/useUpload";
import { Avatar, AvatarGroup, TableCell, Typography, Box } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import { useEffect, useState } from "react";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { packageTypeToString } from "social-pro-common/interfaces/contractorPackage";
import { reportStatusToString } from "social-pro-common/interfaces/reportStatus";

const reportStatusToFill = (reportStatus: ReportStatus) => {
  switch (reportStatus) {
    case ReportStatus.Accepted:
    case ReportStatus.Complete:
      return "#B3D2B1";
    case ReportStatus.InProgress:
    case ReportStatus.ToSubmit:
      return "#faf7bb";
    case ReportStatus.Upcoming:
      return "#CDD1E8";
    case ReportStatus.Overdue:
      return "#FEC0CA";
  }
};

const reportStatusToBorder = (reportStatus: ReportStatus) => {
  switch (reportStatus) {
    case ReportStatus.Accepted:
    case ReportStatus.Complete:
      return "#499943";
    case ReportStatus.InProgress:
    case ReportStatus.ToSubmit:
      return "#ffb74d";
    case ReportStatus.Upcoming:
      return "#748DFD";
    case ReportStatus.Overdue:
      return "#FF3B35";
  }
};

export const ReportStatusCell = ({ data }: CustomCellRendererProps) => {
  return (
    <Box
      sx={{
        alignContent: "center",
        alignItems: "center",
        border: "1px solid #D5D7DA",
        borderRadius: "6px",
        display: "flex",
        gap: "4px",
        margin: "0 auto",
        padding: "2px 6px",
      }}
    >
      <span
        style={{
          background: reportStatusToBorder(data.reportStatus),
          borderRadius: "50%",
          boxShadow: reportStatusToFill(data.reportStatus),
          display: "block",
          height: "7px",
          margin: "0 auto",
          width: "7px",
        }}
      />
      <Typography
        sx={{
          color: data.archived ? "#535862" : "#181D27",
          fontFamily: '"Inter", sans-serif',
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
        }}
      >
        {reportStatusToString(data.reportStatus)}
      </Typography>
    </Box>
  );
};

const StatusCell = ({ data }: CustomCellRendererProps) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        border: "1px solid #D5D7DA",
        borderRadius: "6px",
        display: "flex",
        gap: "4px",
        padding: "2px 6px",
      }}
    >
      <span
        className={data.archived ? "archivedIcon" : "checkIcon"}
        style={{
          borderRadius: "100px",
          height: "7px",
          width: "7px",
        }}
      />
      <Typography
        sx={{
          color: data.archived ? "#535862" : "#181D27",
          fontFamily: '"Inter", sans-serif',
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
        }}
      >
        {data.archived ? "Archived" : "Active"}
      </Typography>
    </Box>
  );
};

const IconActionCell = ({ data }: CustomCellRendererProps) => {
  return (
    <TableCell>
      <span
        className={data.archived ? "archivedIcon" : "checkIcon"}
        style={{ boxShadow: "none", height: "12px", width: "12px" }}
      />
    </TableCell>
  );
};

const ProjectNameActionCell = ({ data }: CustomCellRendererProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0",
      }}
    >
      <Typography
        sx={{
          color: "#181D27",
          fontFamily: '"Inter", sans-serif',
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          whiteSpace: "nowrap",
        }}
      >
        {data.projectName || ""}
      </Typography>
      <Typography
        sx={{
          color: "#535862",
          fontFamily: '"Inter", sans-serif',
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
        }}
      >
        {data.packageType !== PackageType.PrimaryPackage
          ? packageTypeToString(data.packageType)
          : data.organisationName}
      </Typography>
    </Box>
  );
};

const CollaboratorsActionCell = ({ data }: CustomCellRendererProps) => {
  const [collaboratorImages, setCollaboratorImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        data.collaborators.map((collaborator: string) =>
          getImageUrl(collaborator),
        ),
      );
      setCollaboratorImages(images);
    };

    if (data.collaborators && data.collaborators.length > 0) {
      fetchImages();
    }
  }, [data.collaborators]);

  return collaboratorImages.length > 0 ? (
    <AvatarGroup
      max={5}
      sx={{
        "& .MuiAvatar-root": {
          backgroundColor: "#ffffff",
          fontSize: "10px",
          height: 23,
          width: 23,
        },
      }}
    >
      {collaboratorImages.map((img, index) => {
        return (
          <Avatar
            key={index}
            alt={`${index}`}
            src={img}
            sx={{ backgroundColor: "#ffffff" }}
          />
        );
      })}
    </AvatarGroup>
  ) : (
    <Typography align="left">-</Typography>
  );
};

export {
  StatusCell,
  CollaboratorsActionCell,
  IconActionCell,
  ProjectNameActionCell,
};
