import { Skeleton, TableCell, TableRow } from "@mui/material";

export const AnalyticsLocalContentLevel2TableRowSkeleton = () => {
  const skeletonCells = [
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
    { width: "10%" },
  ];
  return (
    <TableRow>
      {skeletonCells.map((cell, index) => (
        <TableCell
          key={index}
          sx={{
            paddingBottom: "10px",
            paddingTop: "10px",
            width: cell.width,
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );
};
