import { usePackageLocalContent } from "@hooks/crud/packageLocalContent/usePackageLocalContent";
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
} from "@mui/material";
import { NumericFormatCustom } from "@stories/atoms/NumericFormatCustom/NumericFormatCustom";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ContentOrigin } from "social-pro-common/entities/packageLocalContent";
import {
  PackageLocalContentLineItem,
  contentOriginToString,
  createDefaultPackageLocalContent,
} from "social-pro-common/interfaces/packageLocalContent";
import { calcANZValueAdd } from "social-pro-common/utils/calc";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import * as yup from "yup";

import { LidpFinder } from "../LidpFinder/LidpFinder";

interface LidpFormProps {
  loading: boolean;
  isCreateModal: boolean;
  projectId: string;
  packageId?: string;
  isPrimaryPackage: boolean;
  packageValue: number;
  existingLocalContent?: PackageLocalContentLineItem;
  reportId?: string;
  handleSubmit: (localContent: PackageLocalContentLineItem) => void;
}

export const LidpForm = ({
  existingLocalContent,
  handleSubmit,
  isCreateModal,
  isPrimaryPackage,
  loading,
  packageId,
  packageValue,
  projectId,
  reportId,
}: LidpFormProps) => {
  const { isPackageLocalContentLoading, searchLocalContent } =
    usePackageLocalContent();

  const [localContent] = useState<PackageLocalContentLineItem>(
    existingLocalContent ||
      createDefaultPackageLocalContent(projectId, reportId),
  );

  const validationSchema = yup.object({
    anzValueAdd: yup.number().min(0).required("ANZ value add is required"),
    contentOrigin: !isCreateModal
      ? yup.string().required("Content origin is required")
      : yup.string().nullable(),
    contractContent: yup
      .number()
      .min(0)
      .required("Contract content is required"),
    importValueAdd: yup
      .number()
      .min(0)
      .max(100)
      .required("Import value add is required"),
    itemDescription: yup.string().required("Item description is required"),
    localValueAdd: yup
      .number()
      .min(0)
      .max(100, "Local value add must be less than 100")
      .required("Local value add is required"),
    manufacturer: yup.string(),
    smeCount: yup.number(),
    steelMass: yup.number().min(0).required("Steel mass add is required"),
    supplier: yup.string(),
    supplierCount: yup.number(),
    value: yup.number().min(0).required("Value add is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      anzValueAdd: localContent.anzValueAdd,
      contentOrigin: localContent.contentOrigin,
      contractContent: localContent.contractContent,
      importValueAdd: localContent.importValueAdd,
      itemDescription: localContent.itemDescription,
      localValueAdd: localContent.localValueAdd,
      manufacturer: localContent.manufacturer,
      smeCount: localContent.smeCount,
      steelMass: localContent.steelMass,
      supplier: localContent.supplier,
      supplierCount: localContent.supplierCount,
      value: localContent.value,
    },
    onSubmit: (values) => {
      handleSubmit({
        ...localContent,
        anzValueAdd: values.anzValueAdd,
        contentOrigin: values.contentOrigin,
        contractContent: values.contractContent,
        importValueAdd: values.importValueAdd,
        itemDescription: values.itemDescription,
        localValueAdd: values.localValueAdd,
        manufacturer: values.manufacturer,
        packageId: packageId,
        smeCount: values.smeCount,
        steelMass: values.steelMass,
        supplier: values.supplier,
        supplierCount: values.supplierCount,
        value: values.value,
      });
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    const anzValueAdd = calcANZValueAdd(
      formik.values.localValueAdd,
      formik.values.contractContent,
    );
    formik.setFieldValue("anzValueAdd", parseFloat(anzValueAdd.toFixed(4)));
  }, [formik.values.localValueAdd, formik.values.contractContent]);

  useEffect(() => {
    const importValueAdd = 100 - formik.values.localValueAdd;
    formik.setFieldValue("importValueAdd", importValueAdd);
  }, [formik.values.localValueAdd]);

  useEffect(() => {
    const localValueAdd = (formik.values.value / packageValue) * 100;
    formik.setFieldValue("contractContent", localValueAdd);
  }, [formik.values.value]);

  const onPackageChange = (localContent?: PackageLocalContentLineItem) => {
    if (localContent) {
      formik.setFieldValue(
        "itemDescription",
        localContent?.itemDescription || "",
      );
      if (isPrimaryPackage) {
        formik.setFieldValue("manufacturer", localContent.manufacturer);
        formik.setFieldValue("supplier", localContent.supplier);
      }

      // formik.setFieldValue("value", localContent.value);
      // formik.setFieldValue("steelMass", localContent.steelMass);
      // formik.setFieldValue("importValueAdd", localContent.importValueAdd);
      // formik.setFieldValue("localValueAdd", localContent.localValueAdd);
      // formik.setFieldValue("smeCount", localContent.smeCount);
      // formik.setFieldValue("supplierCount", localContent.supplierCount);
    } else {
      formik.setFieldValue("itemDescription", "");
      formik.setFieldValue("manufacturer", "");
      formik.setFieldValue("supplier", "");
      formik.setFieldValue("value", 0);
      formik.setFieldValue("steelMass", 0);
      formik.setFieldValue("importValueAdd", "");
      formik.setFieldValue("anzValueAdd", "");
      formik.setFieldValue("smeCount", "");
      formik.setFieldValue("supplierCount", "");
    }
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit} style={{ paddingTop: "12px" }}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            {isCreateModal ? (
              <StyledTextField
                loading={loading}
                id="itemDescription"
                name="itemDescription"
                fullWidth
                disabled={isPackageLocalContentLoading}
                value={formik.values.itemDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.itemDescription &&
                  Boolean(formik.errors.itemDescription)
                }
                helperText={
                  formik.touched.itemDescription
                    ? formik.errors.itemDescription
                    : ""
                }
                label="Item Description"
              />
            ) : (
              <LidpFinder
                loading={loading}
                id="itemDescription"
                name="itemDescription"
                label="Item Description"
                projectId={projectId}
                existingLocalContent={localContent}
                disabled={formik.isSubmitting}
                handleSearch={searchLocalContent}
                onChange={onPackageChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.itemDescription &&
                  Boolean(formik.errors.itemDescription)
                }
                helperText={
                  formik.touched.itemDescription
                    ? formik.errors.itemDescription
                    : ""
                }
              />
            )}
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="manufacturer"
              name="manufacturer"
              fullWidth
              disabled={isPackageLocalContentLoading}
              value={formik.values.manufacturer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.manufacturer &&
                Boolean(formik.errors.manufacturer)
              }
              helperText={
                formik.touched.manufacturer ? formik.errors.manufacturer : ""
              }
              label="Manufacturer"
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="supplier"
              name="supplier"
              fullWidth
              disabled={isPackageLocalContentLoading}
              value={formik.values.supplier}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.supplier && Boolean(formik.errors.supplier)}
              helperText={formik.touched.supplier ? formik.errors.supplier : ""}
              label="Supplier"
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="value"
              name="value"
              fullWidth
              disabled={isPackageLocalContentLoading}
              value={formik.values.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.value && Boolean(formik.errors.value)}
              helperText={formik.touched.value ? formik.errors.value : ""}
              label="Value ($)"
              InputProps={{
                inputComponent: NumericFormatCustom as any,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: isPackageLocalContentLoading
                        ? "rgba(0, 0, 0, 0.26)"
                        : undefined,
                    }}
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="steelMass"
              name="steelMass"
              fullWidth
              disabled={isPackageLocalContentLoading}
              value={formik.values.steelMass}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.steelMass && Boolean(formik.errors.steelMass)
              }
              helperText={
                formik.touched.steelMass ? formik.errors.steelMass : ""
              }
              label="Steel Mass (T)"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: isPackageLocalContentLoading
                        ? "rgba(0, 0, 0, 0.26)"
                        : undefined,
                    }}
                  >
                    T
                  </InputAdornment>
                ),
                inputComponent: NumericFormatCustom as any,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="localValueAdd"
              name="localValueAdd"
              disabled={isPackageLocalContentLoading}
              fullWidth
              value={formatDecimalPlaces(formik.values.localValueAdd, 2)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.localValueAdd &&
                Boolean(formik.errors.localValueAdd)
              }
              helperText={
                formik.touched.localValueAdd ? formik.errors.localValueAdd : ""
              }
              label="Local Value Add"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      color: isPackageLocalContentLoading
                        ? "rgba(0, 0, 0, 0.26)"
                        : undefined,
                    }}
                    position="end"
                  >
                    %
                  </InputAdornment>
                ),
                inputComponent: NumericFormatCustom as any,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="importValueAdd"
              name="importValueAdd"
              fullWidth
              disabled
              value={formatDecimalPlaces(formik.values.importValueAdd)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.importValueAdd &&
                Boolean(formik.errors.importValueAdd)
              }
              helperText={
                formik.touched.importValueAdd
                  ? formik.errors.importValueAdd
                  : ""
              }
              label="Import Value Add"
              sx={{
                fieldset: {
                  backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                      height: "100%",
                      m: 0,
                      maxHeight: "100%",
                      p: 0,
                    }}
                  >
                    %
                  </InputAdornment>
                ),
                inputComponent: NumericFormatCustom as any,
              }}
            />
          </Grid>

          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="contractContent"
              name="contractContent"
              fullWidth
              value={formatDecimalPlaces(formik.values.contractContent, 4)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contractContent &&
                Boolean(formik.errors.contractContent)
              }
              helperText={
                formik.touched.contractContent
                  ? formik.errors.contractContent
                  : ""
              }
              label="Contract Content"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      color: isPackageLocalContentLoading
                        ? "rgba(0, 0, 0, 0.26)"
                        : undefined,
                    }}
                    position="end"
                  >
                    %
                  </InputAdornment>
                ),
                inputComponent: NumericFormatCustom as any,
              }}
            />
          </Grid>
          {!isCreateModal ? (
            <Grid item md={6}>
              {loading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={50}
                  width={"100%"}
                >
                  <TextField />
                </Skeleton>
              ) : (
                <>
                  <Autocomplete
                    fullWidth
                    disableCloseOnSelect
                    id="entityType"
                    options={Object.values(ContentOrigin)}
                    defaultValue={formik.values.contentOrigin}
                    disabled={loading || formik.isSubmitting}
                    getOptionLabel={(option) => contentOriginToString(option)}
                    renderOption={(props, option) => (
                      <li {...props}>{contentOriginToString(option)}</li>
                    )}
                    renderTags={(value) => {
                      // Join all selected values into a single string with commas
                      const text = value
                        .map((v) => contentOriginToString(v))
                        .join(", ");

                      // Check if the text length exceeds 20 characters
                      if (text.length > 20) {
                        // Truncate and add ellipsis
                        return `${text.substring(0, 17)}... ${
                          value.length > 1 ? `+${value.length - 1}` : ""
                        }`;
                      }

                      // If under 20 characters, return the full text
                      return text;
                    }}
                    onChange={(event: any, newValue: ContentOrigin | null) => {
                      formik.setFieldValue("contentOrigin", newValue);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        data-test-id="contentOrigin"
                        {...params}
                        name="contentOrigin"
                        label="Content Origin"
                        fullWidth
                        error={
                          formik.touched.contentOrigin &&
                          Boolean(formik.errors.contentOrigin)
                        }
                        helperText={
                          formik.touched.contentOrigin
                            ? formik.errors.contentOrigin
                            : ""
                        }
                      />
                    )}
                  />
                </>
              )}
            </Grid>
          ) : null}
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="anzValueAdd"
              name="anzValueAdd"
              disabled
              fullWidth
              value={formatDecimalPlaces(formik.values.anzValueAdd, 4)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.anzValueAdd && Boolean(formik.errors.anzValueAdd)
              }
              helperText={
                formik.touched.anzValueAdd ? formik.errors.anzValueAdd : ""
              }
              label="ANZ Value Add"
              sx={{
                fieldset: {
                  backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    sx={{
                      backgroundColor: "rgba(239, 239, 239, 0.3) !important;",
                      height: "100%",
                      m: 0,
                      maxHeight: "100%",
                      p: 0,
                    }}
                    position="end"
                  >
                    %
                  </InputAdornment>
                ),
                inputComponent: NumericFormatCustom as any,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="smeCount"
              name="smeCount"
              fullWidth
              disabled={isPackageLocalContentLoading}
              value={formik.values.smeCount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.smeCount && Boolean(formik.errors.smeCount)}
              helperText={formik.touched.smeCount ? formik.errors.smeCount : ""}
              label="SME Count"
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
            />
          </Grid>
          <Grid item md={6}>
            <StyledTextField
              loading={loading}
              id="supplierCount"
              name="supplierCount"
              fullWidth
              disabled={isPackageLocalContentLoading}
              value={formik.values.supplierCount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.supplierCount &&
                Boolean(formik.errors.supplierCount)
              }
              helperText={
                formik.touched.supplierCount ? formik.errors.supplierCount : ""
              }
              label="Supplier Count"
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
            />
          </Grid>
          <Grid item md={6} />
          {isCreateModal ? <Grid item md={6} /> : null}
          <Grid
            item
            md={6}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <StyledButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={
                loading || formik.isSubmitting || isPackageLocalContentLoading
              }
            >
              Save
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
