// import { userHasEditorAccess } from "@utils/projectAccess";
import { userHasEditorAccess } from "@utils/projectAccess";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { SideBarMenuModelLineItem } from "social-pro-common/interfaces/sidebarMenu";
import { NavItemType } from "types";

// import { getAnalysisNav } from "./analysis";
import { getDirectoryNav } from "./directory";
import { getManageNav } from "./manage";
import { getOneTimeReportingNav } from "./one-time";
import { getPackageNav, getReducedPackageNav } from "./package";
import { getProjectsNav } from "./projects";
import { getReducedReportNav, getReportsNav } from "./reports";

export interface NavItemList {
  items: NavItemType[];
}

export const getNavMenuItems = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
  isOwner = false,
): NavItemList => {
  if (contractorPackage?.packageType == PackageType.OneTime) {
    return getOneTimeNavMenu(project, contractorPackage);
  }
  const items = [
    getProjectsNav(
      project?.projectName || "",
      contractorPackage?.packageType || PackageType.PrimaryPackage,
    ),
  ];

  if (isOwner) {
    items.push(getPackageNav(project, contractorPackage));
  } else {
    items.push(getReducedPackageNav(project, contractorPackage));
  }

  if (
    (project?.commitmentsHours.length ||
      project?.commitmentsSpend.length ||
      project?.localContents.length) &&
    userHasEditorAccess(project)
  ) {
    items.push(getManageNav(project, contractorPackage));
  }

  if (isOwner) {
    items.push(getReportsNav(project, contractorPackage, sideBarMenuModel));
  } else {
    items.push(
      getReducedReportNav(project, contractorPackage, sideBarMenuModel),
    );
  }

  items.push(getDirectoryNav(project?.id, contractorPackage?.id));

  return { items };
};

export const getOneTimeNavMenu = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
): { items: NavItemType[] } => {
  const items = [
    getOneTimeReportingNav(project, contractorPackage),
    getDirectoryNav(project?.id, contractorPackage?.id),
  ];

  return { items };
};
