"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeOrganisationListModel = void 0;
const organisation_1 = require("../organisation");
const decodeOrganisationListModel = async (occupationrganisationListModel, getImageUrl) => {
    const organisations = await Promise.all(occupationrganisationListModel.organisations.map((e) => (0, organisation_1.decodeOrganisation)(e, getImageUrl)));
    return {
        organisations,
        totalOrganisations: occupationrganisationListModel.total_organisations,
    };
};
exports.decodeOrganisationListModel = decodeOrganisationListModel;
