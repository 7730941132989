import {
  Button,
  // Pagination,
  Box,
  Typography,
  // PaginationItem,
} from "@mui/material";
import { ArrowBackIosNewIcon, ArrowForwardIosIcon } from "assets/constants";

export const PAGINATION_LIMIT = 15;

type PaginationResult<T> = {
  totalPages: number;
  currentPage: number;
  data: T[];
  pageNumbers: number[];
};

export function paginate<T>(
  items: T[],
  page: number,
  totalItems: number,
  limit = 10,
): PaginationResult<T> {
  const totalPages = Math.ceil(totalItems / limit);

  // Ensure the requested page is within valid range
  const currentPage = Math.min(Math.max(page, 1), totalPages);

  // Calculate the start and end index for slicing
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;

  // Slice the items array to get the data for the current page
  const data = items.slice(startIndex, endIndex);

  // Generate the array of page numbers
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return {
    currentPage,
    data,
    pageNumbers,
    totalPages,
  };
}

interface CustomPaginationProps {
  onPageChange: (page: number) => void;
  page: number;
  pageNumbers: number[];
  totalPages: number;
}

export const CustomPagination = ({
  onPageChange,
  page,
  pageNumbers,
  totalPages,
}: CustomPaginationProps) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: { sm: "space-between", xs: "center" },
        p: "12px 24px",
      }}
    >
      {/* Previous Button with Arrow */}
      <Button
        disableRipple
        sx={{
          "&:disabled": {
            backgroundColor: "white",
            color: "#414651",
            cursor: "not-allowed",
            opacity: "60%",
          },
          "&:hover": {
            backgroundColor: "white",
          },
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #D5D7DA",
          borderRadius: "8px",
          color: "#414651",
          display: {
            sm: "flex",
            xs: "none",
          },
          padding: "8px 12px",
        }}
        disabled={page === 1}
        onClick={() => onPageChange(page - 1)}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            mr: 1,
          }}
        >
          <ArrowBackIosNewIcon />
        </Box>
        <Typography
          variant="button"
          style={{ fontFamily: '"Inter", sans-serif' }}
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            textTransform: "capitalize",
          }}
        >
          Previous
        </Typography>
      </Button>

      {/* Pagination (Page Numbers) */}
      {/* <Pagination
        count={count}
        page={page}
        onChange={(event, value) => onPageChange(value)}
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            sx={{
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  backgroundColor: "#FAFAFA",
                  color: "#535862",
                },
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                alignItems: "center",
                color: "#535862",
                display: "flex",
                fontWeight: "500",
                height: "40px",
                justifyContent: "center",
                margin: "0 1px",
                width: "40px",
              },
              "& .MuiPaginationItem-text": {
                fontFamily: '"Inter", sans-serif',
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          />
        )}
        sx={{
          "& .MuiPagination-ul": {
            "& li": {
              "&:first-child": {
                sm: {
                  display: "none",
                },
              },
              "&:last-child": {
                sm: {
                  display: "none",
                },
              },
            },
          },
        }}
      /> */}
      <Box sx={{ alignItems: "center", display: "flex", gap: "4px" }}>
        {pageNumbers.map((p: any) => (
          <Button
            disableRipple
            key={p}
            onClick={() => onPageChange(p)}
            sx={{
              "&:hover": {
                backgroundColor: "#FAFAFA",
              },
              alignItems: "center",
              backgroundColor: `${page === p ? "#FAFAFA" : "transparent"}`,
              borderRadius: "8px",
              color: "#535862",
              display: "flex",
              fontFamily: '"Inter", sans-serif',
              fontSize: "14px",
              fontWeight: "500",
              height: "40px",
              lineHeight: "20px",
              minWidth: "40px",
              outline: "none",
              padding: "0px",
              width: "40px",
            }}
          >
            {p}
          </Button>
        ))}
      </Box>
      {/* Next Button with Arrow */}
      <Button
        disableRipple
        sx={{
          "&:disabled": {
            backgroundColor: "white",
            color: "#414651",
            cursor: "not-allowed",
            opacity: "60%",
          },
          "&:hover": {
            backgroundColor: "white",
          },
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #D5D7DA",
          borderRadius: "8px",
          color: "#414651",
          display: {
            sm: "flex",
            xs: "none",
          },
          padding: "8px 12px",
        }}
        disabled={page === totalPages}
        onClick={() => onPageChange(page + 1)}
      >
        <Typography
          variant="button"
          style={{ fontFamily: '"Inter", sans-serif' }}
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            textTransform: "capitalize",
          }}
        >
          Forward
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            ml: 1,
          }}
        >
          <ArrowForwardIosIcon />
        </Box>
      </Button>
    </Box>
  );
};
