import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Header } from "@stories/organisms/Header/Header";
import { PackageDetailsEnviroSustainability } from "@stories/organisms/PackageDetails/PackageDetailsEnviroSustainability";
import { PackageDetailsForecast } from "@stories/organisms/PackageDetails/PackageDetailsForecast";
import { PackageDetailsHours } from "@stories/organisms/PackageDetails/PackageDetailsHours";
import { PackageDetailsHoursTarget } from "@stories/organisms/PackageDetails/PackageDetailsHoursTarget";
import { PackageDetailsLocalContent } from "@stories/organisms/PackageDetails/PackageDetailsLocalContent";
import { PackageDetailsPolicy } from "@stories/organisms/PackageDetails/PackageDetailsPolicy";
import { PackageReports } from "@stories/organisms/PackageDetails/PackageDetailsReports";
import { PackageDetailsSpend } from "@stories/organisms/PackageDetails/PackageDetailsSpend";
import { PackageDetailsSpendTarget } from "@stories/organisms/PackageDetails/PackageDetailsSpendTarget";
import { SummaryDetails } from "@stories/organisms/PackageDetails/PackageDetailsSummary";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { useState } from "react";
import {
  LabourHourTargetAnalyticsLineItem,
  SocialSpendTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";

export const MyPackage = () => {
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();
  const [labourHourTargetId, setLabourHourTargetId] = useState<string>();

  const onLabourHourTargetChange = (
    target?: LabourHourTargetAnalyticsLineItem,
  ) => {
    setLabourHourTargetId(target?.id);
  };

  const [socialSpendTargetId, setSocialSpendTargetId] = useState<string>();

  const onSocialSpendTargetChange = (
    target?: SocialSpendTargetAnalyticsLineItem,
  ) => {
    setSocialSpendTargetId(target?.id);
  };

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = async (tabIndex: number) => {
    setLabourHourTargetId(undefined);
    setSocialSpendTargetId(undefined);
    setTabIndex(tabIndex);
  };

  const loading = isAuthProfileLoading || isProjectLoading;

  const selectedPackageStep = (() => {
    switch (tabIndex) {
      case 0:
        return !labourHourTargetId ? (
          <PackageDetailsHours
            isLoading={loading}
            subPackage={contractorPackage}
            onLabourHourTargetChange={onLabourHourTargetChange}
          />
        ) : (
          <PackageDetailsHoursTarget
            isLoading={loading}
            subPackage={contractorPackage}
            targetId={labourHourTargetId}
            handleOnBack={() => {
              setLabourHourTargetId(undefined);
            }}
          />
        );

      case 1:
        return !socialSpendTargetId ? (
          <PackageDetailsSpend
            isLoading={loading}
            subPackage={contractorPackage}
            onSocialSpendTargetChange={onSocialSpendTargetChange}
          />
        ) : (
          <PackageDetailsSpendTarget
            isLoading={loading}
            subPackage={contractorPackage}
            targetId={socialSpendTargetId}
            handleOnBack={() => {
              setSocialSpendTargetId(undefined);
            }}
          />
        );
      case 2:
        return (
          <PackageDetailsLocalContent
            loading={loading}
            subPackage={contractorPackage}
            localContents={getSortedLocalContent(
              selectedProject?.localContents,
            )}
          />
        );
      case 3:
        return (
          <PackageDetailsPolicy
            isLoading={loading}
            subPackage={contractorPackage}
          />
        );
      case 4:
        return (
          <PackageDetailsEnviroSustainability
            loading={loading}
            subPackage={contractorPackage}
            project={selectedProject}
          />
        );
      case 5:
        return (
          <PackageDetailsForecast
            isLoading={loading}
            subPackage={contractorPackage}
          />
        );
      case 6:
        return (
          <PackageReports
            isLoading={loading}
            contractorPackage={contractorPackage}
            subPackage={contractorPackage}
          />
        );
      default:
      case 7:
        return (
          <SummaryDetails
            isLoading={loading}
            project={selectedProject}
            contractorOrganisation={userOrganisation}
            subPackage={contractorPackage}
          />
        );
    }
  })();

  const tabs = [
    {
      label: "Labour Hours",
    },
    {
      label: "Social Spend",
    },
    {
      label: "Local Content",
    },
    {
      label: "Policy",
    },
    {
      label: "Enviro & Sustainability",
    },
    {
      label: "Forecast",
    },
    {
      label: "Reports",
    },
    {
      label: "Details",
    },
  ];
  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={userOrganisation?.organisationName || "Organisation"}
        mainTitle="My Package"
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />

      {selectedPackageStep}
    </PageContainer>
  );
};
