import { useProfileContext } from "@hooks/context/useProfileContext";
import { useEmployee } from "@hooks/crud/employee/useEmployee";
import { useSearchEmployee } from "@hooks/crud/employee/useSearchEmployee";
import EmployeeImport, {
  ImportStep,
} from "@stories/molecules/EmployeeImport/EmployeeImport";
import { EmployeeNameExistsDialog } from "@stories/molecules/EmployeeNameExistsDialog/EmployeeNameExistsDialog";
import { EmployeeTable } from "@stories/molecules/EmployeeTable/EmployeeTable";
import EmployeeEditModal from "@stories/organisms/EmployeeEditModal/EmployeeEditModal";
import EmployeeListModal from "@stories/organisms/EmployeeListModal/EmployeeListModal";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { writeXlsxFileEmployees } from "@utils/xlsx/employee";
import { AddIcon, ExportIcon, ImportIcon } from "assets/constants";
import { useCallback, useState } from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";

export const Employees = () => {
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openListModal, setOpenListModal] = useState(false);
  const [openDupeNameModal, setOpenDupeNameModal] = useState(false);

  const [openImportModal, setOpenImportModal] = useState(false);
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);

  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeLineItem>();

  const { isSearchEmployeeLoading, searchEmployeesByNameOrCode } =
    useSearchEmployee();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const {
    createEmployee,
    employeeCount,
    employees,
    isEmployeeLoading,
    listEmployees,
    updateEmployee,
    upsertEmployees,
  } = useEmployee(userProfile?.organisationId, !!tabIndex);

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = useCallback(
    async (searchTerm: string) => {
      setSearchTerm(searchTerm);
    },
    [employees],
  );

  const handleEditEmployee = useCallback(
    async (employee: EmployeeLineItem) => {
      setSelectedEmployee(employee);
      setOpenEditModal(true);
    },
    [employees],
  );

  const handleArchiveEmployee = useCallback(
    async (employee: EmployeeLineItem) => {
      let allowUser = true;
      if (employee.archived) {
        const employeeRes = await searchEmployeesByNameOrCode([
          employee.employeeName,
        ]);
        if (employeeRes.length) {
          allowUser = false;
        }
      }

      if (allowUser) {
        updateEmployee({ ...employee, archived: !employee.archived });
      } else {
        setOpenDupeNameModal(true);
      }
    },
    [employees, searchEmployeesByNameOrCode, updateEmployee],
  );

  const handleCreateEmployee = useCallback(
    (employee: EmployeeLineItem) => {
      createEmployee(employee);
      setOpenEditModal(true);
    },
    [createEmployee, employees],
  );

  const handleUpdateEmployee = useCallback(
    (employee: EmployeeLineItem) => {
      updateEmployee(employee);
      setOpenEditModal(true);
    },
    [createEmployee, employees],
  );

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseListModal = () => {
    setOpenListModal(false);
    setSelectedEmployee(undefined);
  };

  const handleOpeEditModal = () => {
    setSelectedEmployee(undefined);
    setOpenEditModal(true);
  };

  const handleOpenListModal = (employee: EmployeeLineItem) => {
    setSelectedEmployee(employee);
    setOpenListModal(true);
  };

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };

  const handleCloseImportModal = () => {
    setOpenImportModal(false);
    setStep(ImportStep.Init);
  };

  const handleReloadEmployees = () => {
    if (userProfile) {
      listEmployees(userProfile?.organisationId, !!tabIndex);
    }
  };

  const loading =
    isSearchEmployeeLoading || isAuthProfileLoading || isEmployeeLoading;

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const handleExportEmployees = async () => {
    if (employees.length > 0) {
      writeXlsxFileEmployees(employees);
    }
  };

  const buttons = [
    {
      action: handleExportEmployees,
      icon: <ExportIcon />,
      text: "Export Employees",
      type: HeaderButtonType.SECONDARY,
      width: 180,
    },
    {
      action: handleOpenImportModal,
      icon: <ImportIcon />,
      text: "Import Employees",
      type: HeaderButtonType.SECONDARY,
      width: 180,
    },
    {
      action: handleOpeEditModal,
      icon: <AddIcon variant="secondary" />,
      text: "Add Employee",
      type: HeaderButtonType.PRIMARY,
      width: 150,
    },
  ] as HeaderButtonConfig[];

  const tabs = [
    {
      label: "Active",
    },
    {
      label: "Archived",
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={`Manage (${employees.length >= 0 ? employees.length : 0})`}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        mainTitle={"Employees"}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />

      <EmployeeTable
        archived={!!tabIndex}
        employees={employees}
        searchTerm={searchTerm}
        employeeCount={employeeCount}
        loading={loading}
        handleOpenListModal={handleOpenListModal}
        handleEditEmployee={handleEditEmployee}
        handleArchiveEmployee={handleArchiveEmployee}
      />

      {openEditModal && userOrganisation ? (
        <EmployeeEditModal
          loading={loading}
          open={openEditModal}
          contractorOrganisation={userOrganisation}
          existingEmployee={selectedEmployee}
          createEmployee={handleCreateEmployee}
          updateEmployee={handleUpdateEmployee}
          handleClose={handleCloseEditModal}
          handleReloadEmployees={handleReloadEmployees}
        />
      ) : null}
      {openListModal && selectedEmployee ? (
        <EmployeeListModal
          open={openListModal}
          existingEmployee={selectedEmployee}
          handleClose={handleCloseListModal}
        />
      ) : null}

      {openImportModal ? (
        <EmployeeImport
          open={openImportModal}
          step={step}
          userProfile={userProfile}
          userOrganisation={userOrganisation}
          handleCloseImportModal={handleCloseImportModal}
          setStep={setStep}
          upsertEmployees={upsertEmployees}
          handleReloadEmployees={handleReloadEmployees}
        />
      ) : null}
      <EmployeeNameExistsDialog
        isOpen={openDupeNameModal}
        setIsOpen={setOpenDupeNameModal}
      />
    </PageContainer>
  );
};
