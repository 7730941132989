import { usePolicyAnalytics } from "@hooks/crud/analytics/usePolicyAnalytics";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { ContractorAnalyticsPolicyTable } from "@stories/molecules/ContractorAnalyticsPolicy/ContractorAnalyticsPolicyTable";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

import { Header } from "../Header/Header";

interface PackageDetailsPolicyProps {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
}

export const PackageDetailsPolicy = ({
  isLoading,
  subPackage,
}: PackageDetailsPolicyProps) => {
  const { isAnalysisLoading, policyAnalytics } = usePolicyAnalytics(
    subPackage?.projectId,
    subPackage?.id,
  );

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loading = isLoading || isAnalysisLoading;
  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={4}>
          <Header
            mainTitle="Policies"
            subTitle="Policy Submissions Summary"
            isSubheading
            loading={loading}
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            headerFontSizes={[18, 14]}
            headerGap={0}
          />

          <ContractorAnalyticsPolicyTable
            loading={loading}
            withoutBorder
            policyAnalytics={policyAnalytics}
            searchTerm={searchTerm}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
