import { Skeleton, TableCell, TableRow } from "@mui/material";

export const AnalyticsHoursLevel3TableRowSkeleton = () => {
  return (
    <TableRow>
      {Array.from({ length: 8 }, (_, index) => (
        <TableCell
          key={index}
          align={index === 0 ? "left" : "center"}
          sx={{
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );
};
