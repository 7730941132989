import { useLabourHourForEmployee } from "@hooks/crud/labourHours/useLabourHoursForEmployee";
import { downloadFile } from "@hooks/utils/useUpload";
import { Button, CircularProgress, Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  centerCellStyles,
  defaultCellConfig,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { LabourHourLineItem } from "social-pro-common/interfaces/labourHour";
import { formatChartDate, stringToDate } from "social-pro-common/utils/date";
import { getDocumentName } from "social-pro-common/utils/string";

import { EmployeeHoursSubTableSkeleton } from "./EmployeeHoursSubTableSkeleton";

interface EmployeeLabourHoursSubTableProps {
  employee: EmployeeLineItem;
}

interface ActionCellProps extends CustomCellRendererProps {
  isDownloading: boolean;
}

export const EmployeeLabourHoursSubTable = ({
  employee,
}: EmployeeLabourHoursSubTableProps) => {
  const { isLabourHourLoading, labourHours } = useLabourHourForEmployee(
    employee.id,
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const loading = isLabourHourLoading;

  const LoadingCell = ({ data, isDownloading }: ActionCellProps) => {
    return (
      data?.uri &&
      (isDownloading ? (
        <CircularProgress />
      ) : (
        <Button
          color="primary"
          size="medium"
          disabled={isDownloading}
          onClick={() =>
            data.uri
              ? onDownload(
                  data.uri,
                  getDocumentName(
                    `${data.projectName}-${data.reportId}`,
                    data.uri,
                  ),
                )
              : undefined
          }
          className="blackBtn grey-outline-btn"
          sx={{
            color: "white",
            fontSize: "12px",
            lineHeight: "16px",
            padding: "5px 10px",
          }}
        >
          Download
        </Button>
      ))
    );
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "reportId",
      headerClass: "left-table-header",
      headerName: "Date",
      valueFormatter: (params) =>
        formatChartDate(stringToDate(params.data.reportId)),
    },
    {
      ...defaultCellConfig,
      field: "projectName",
      headerClass: "left-table-header",
      headerName: "Project",
      valueFormatter: (params) => `${params.data.projectName}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "amountOfHoursWorked",
      headerClass: "right-table-header",
      headerName: "Hours",
      valueFormatter: (params) => `${params.data.amountOfHoursWorked}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "amountOfHoursStudied",
      headerClass: "right-table-header",
      headerName: "Studied",
      valueFormatter: (params) => `${params.data.amountOfHoursStudied}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: LoadingCell,
      cellRendererParams: { isDownloading },
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  return (
    <Grid item md={12}>
      {loading || !labourHours ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={EmployeeHoursSubTableSkeleton}
        />
      ) : (
        <Table<LabourHourLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={labourHours || []}
        />
      )}
    </Grid>
  );
};
