"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProfileProjectAccess = exports.decodeProfileProjectAccess = void 0;
const string_1 = require("../../utils/string");
const decodeProfileProjectAccess = (profileProjectAccess) => {
    return {
        accessLevel: profileProjectAccess.access_level,
        created: new Date(profileProjectAccess.created),
        id: profileProjectAccess.profile_project_access_id,
        organisationId: profileProjectAccess.organisation_id,
        profileId: profileProjectAccess.profile_id,
        projectId: profileProjectAccess.project_id,
    };
};
exports.decodeProfileProjectAccess = decodeProfileProjectAccess;
const encodeProfileProjectAccess = (profileProjectAccess) => {
    const safeProfileProjectAccess = (0, string_1.makePostgresSafe)(profileProjectAccess);
    return {
        access_level: safeProfileProjectAccess.accessLevel,
        created: new Date(safeProfileProjectAccess.created),
        organisation_id: safeProfileProjectAccess.organisationId,
        profile_id: safeProfileProjectAccess.profileId,
        profile_project_access_id: safeProfileProjectAccess.id,
        project_id: safeProfileProjectAccess.projectId,
    };
};
exports.encodeProfileProjectAccess = encodeProfileProjectAccess;
