import { useProjectContext } from "@hooks/context/useProjectContext";
import { useMainChartData } from "@hooks/crud/dashboard/useMainChartData";
import { useForecastModel } from "@hooks/crud/forecastData/useForecastData";
import { Box } from "@mui/material";
import { ForecastChart } from "@stories/molecules/ForecastChart/ForecastChart";
import ForecastModal from "@stories/molecules/ForecastTable/ForecastModal/ForecastModal";
import { ForecastTable } from "@stories/molecules/ForecastTable/ForecastTable";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { ExportIcon, FilterIcon } from "assets/constants";
import { useCallback, useState } from "react";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";
import { getReportMonths, stringToDate } from "social-pro-common/utils/date";

import CustomLineChart from "./CustomLineChart";

export const Forecast = () => {
  const [open, setOpen] = useState(false);
  const [selectedForecastModel, setSelectedForecastModel] =
    useState<ForecastModelLineItem>();
  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const {
    forecastModels,
    isForecastLoading,
    isSubmittingForecast,
    setForecastModels,
    updateForecastModel,
  } = useForecastModel(selectedProject, contractorPackage);

  const { chartData, isChartDataLoading } = useMainChartData(
    selectedProject?.id,
    contractorPackage?.id,
  );

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedRange, setSelectedRange] = useState<number>(0);

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const handleSet12MonthDateRange = () => {
    setSelectedRange(0);
  };

  const handleSetAllTimeDateRange = () => {
    setSelectedRange(1);
  };
  const reportMonths = contractorPackage
    ? getReportMonths(
        stringToDate(contractorPackage.packageStartDate),
        stringToDate(contractorPackage.packageEndDate),
      )
    : selectedProject?.projectReportMonths;

  const selectedChartData = (() => {
    if (chartData) {
      switch (tabIndex) {
        default:
        case 0:
          return chartData.labourHours;
        case 1:
          return chartData.socialSpend;
        case 2:
          return chartData.greenMetrics;
      }
    }
  })();

  const chartUnits = (() => {
    if (chartData) {
      switch (tabIndex) {
        default:
        case 0:
          return "Hours";
        case 1:
          return "Dollars";
        case 2:
          return "Submissions";
      }
    }
    return "";
  })();

  const loading = isProjectLoading || isForecastLoading || isChartDataLoading;

  const handleOpen = useCallback(
    async (forecast: ForecastModelLineItem) => {
      setSelectedForecastModel(forecast);
      setOpen(true);
    },
    [setOpen, setSelectedForecastModel],
  );

  const handleClose = () => {
    setSelectedForecastModel(undefined);
    setOpen(false);
  };

  const handleUpdateForecastModel = useCallback(
    async (forecastModel: ForecastModelLineItem) => {
      setForecastModels((prevModels) =>
        prevModels.map((model) =>
          model.id === forecastModel.id
            ? { ...model, ...forecastModel }
            : model,
        ),
      );
      try {
        const updatedModel = {
          ...forecastModel,
        };
        await updateForecastModel([updatedModel]);
      } catch (error) {
        setForecastModels((prevModels) =>
          prevModels.map((model) =>
            model.id === forecastModel.id
              ? { ...model, active: !forecastModel.active }
              : model,
          ),
        );
      }
    },
    [updateForecastModel],
  );

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: async () => handleSet12MonthDateRange(),
      icon: <FilterIcon />,
      text: "Filter",
      type: HeaderButtonType.SECONDARY,
      width: 120,
    },
    {
      action: async () => handleSetAllTimeDateRange(),
      icon: <ExportIcon />,
      text: "Import",
      type: HeaderButtonType.SECONDARY,
      width: 120,
    },
  ];

  const tabs = [
    {
      label: "Labour Hours",
    },
    {
      label: "Social Spend",
    },
  ];
  return (
    <Box sx={{ background: "#fff", borderRadius: "32px 0 0 0" }}>
      <PageContainer>
        <Header
          loading={loading}
          subTitle={"Add target projections"}
          mainTitle={"Forecasting"}
          tabs={tabs}
          buttons={buttons}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          handleTabChange={handleTabChange}
          tabIndex={tabIndex}
        />

        <CustomLineChart forecastModels={forecastModels} />

        <Box sx={{ display: "none" }}>
          <ForecastChart
            loading={loading}
            chartRangeType={selectedRange}
            chartUnits={chartUnits}
            reportMonths={reportMonths}
            forecastModals={forecastModels}
            chartData={selectedChartData}
          />
        </Box>

        <ForecastTable
          loading={loading}
          disabled={isSubmittingForecast}
          forecastModels={forecastModels}
          chartType={tabIndex}
          project={selectedProject}
          contractorPackage={contractorPackage}
          editForecastModel={handleOpen}
          updateForecastModel={handleUpdateForecastModel}
        />

        {open && contractorPackage && selectedForecastModel ? (
          <ForecastModal
            open={open}
            loading={loading}
            contractorPackage={contractorPackage}
            existingForecast={selectedForecastModel}
            updateForecast={handleUpdateForecastModel}
            handleClose={handleClose}
          />
        ) : null}
      </PageContainer>
    </Box>
  );
};
