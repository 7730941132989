import CloudSyncIcon from "@mui/icons-material/CloudSync";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import * as RouteHelper from "@utils/routes";
import { Box, NetworkIcon } from "lucide-react";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { NavItemType } from "types";

export const defaultMenuItem = (index: number) => {
  return {
    breadcrumbs: false,
    icon: (
      <Box
        className="projectIcon"
        style={{
          color: "white !important",
          fill: "transparent !important",
          opacity: "1",
        }}
      />
    ),
    id: `list-projects-${index}`,
    title: "Projects",
    type: "item",
    url: "",
  };
};

export const getProjectsNav = (userProfile?: ProfileLineItem): NavItemType => {
  const navs = {
    children: [
      {
        breadcrumbs: false,
        icon: (
          <Box
            className="projectIcon"
            style={{
              color: "white !important",
              fill: "transparent !important",
              opacity: "1",
            }}
          />
        ),
        id: "list-projects",
        title: "Projects",
        type: "item",
        url: RouteHelper.listProjects,
      },
      {
        breadcrumbs: false,
        icon: <PeopleAltOutlinedIcon sx={{ color: "white", opacity: "1" }} />,
        id: "employees",
        title: "Employees",
        type: "item",
        url: RouteHelper.employees,
      },
    ],
    icon: <Box className="projectIcon" />,
    id: "manage",
    title: "",
    type: "group",
  };
  if (userProfile?.isAdmin) {
    // navs.children.push({
    //   id: "teams",
    //   title: "Teams",
    //   type: "item",
    //   url: RouteHelper.profileGroups,
    //   icon: <Groups2Icon sx={{ color: "white", opacity: "1" }} />,
    //   breadcrumbs: false,
    // });

    navs.children.push({
      breadcrumbs: false,
      icon: <NetworkIcon />,
      id: "organisation-dashboard",
      title: "Organisation",
      type: "item",
      url: RouteHelper.organisationDashboard,
    });
    navs.children.push({
      breadcrumbs: false,
      icon: <CloudSyncIcon sx={{ color: "white", opacity: "1" }} />,
      id: "integrations",
      title: "App Integrations",
      type: "item",
      url: RouteHelper.integrations,
    });
  }
  return navs;
};
