import EditIcon from "@mui/icons-material/Edit";
import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";

export const ProjectRowSkeleton = () => {
  const skeletonCells = [
    { width: "10%" },
    { width: "20%" },
    { width: "20%" },
    { width: "20%" },
    { width: "20%" },
  ];

  return (
    <TableRow>
      {skeletonCells.map((cell, index) => (
        <TableCell
          key={index}
          sx={{
            paddingBottom: "15px",
            paddingTop: "15px",
            width: cell.width,
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
      <TableCell>
        <Stack direction="row" justifyContent={"center"}>
          <Skeleton animation="wave" variant="circular">
            <EditIcon />
          </Skeleton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
