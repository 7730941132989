"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultGreenMetricCategorySubmission = void 0;
const uuid_1 = require("uuid");
const createDefaultGreenMetricCategorySubmission = (greenMetricCategoryId, projectId, contractorPackageId, reportId) => {
    return {
        contractorPackageId,
        created: new Date(),
        greenMetricCategoryId,
        id: (0, uuid_1.v4)(),
        projectId,
        reportId,
        reportValue: 0,
    };
};
exports.createDefaultGreenMetricCategorySubmission = createDefaultGreenMetricCategorySubmission;
