import { Box, Button, Stack, Typography } from "@mui/material";
import Logo from "assets/images/home/logo-white.png";


const ErrorPage = () => {
  return (
    <Box
    sx={{
      alignItems: "center",
      background: "linear-gradient(41.49deg, #0288E1 -3.74%, #055E99 74.15%)",
      backgroundAttachment: "fixed",
      display: "flex",
      fontFamily: "'Inter', sans-serif",
      justifyContent: "center",
      minHeight: "100dvh",
      p: "64px 24px",
    }}
    >
    <Box
      sx={{
        bgcolor: "#FFFFFF26",
        borderRadius: "25px",
        fontFamily: "'Inter', sans-serif",
        margin: "0 auto",
        maxWidth: "792px",
        p: "89px 72px",
        width: "100%",
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        // height={"768px"}
        gap={6}
      >
         <img src={Logo} alt=""  />
        <Typography
          color="#ffffff"
          sx={{
            fontFamily: "'Inter', sans-serif",
            fontSize: "150px",
            fontWeight: "600",
            lineHeight: "175.78px",
            textAlign: "center",
          }}
        >
          404
        </Typography>
        <Stack direction={"column"} gap={"50px"}>
          <Stack direction={"column"} gap={"30px"}>
            <Typography
              color="#ffffff"
              sx={{
                fontFamily: "'Inter', sans-serif",
                fontSize: "50px",
                fontWeight: "500",
                lineHeight: "70px",
                textAlign: "center",
                
              }}
            >
              An error has occurred.
            </Typography>
            <Box sx={{ margin: "0 auto", maxWidth: "647px", }}>
              <Stack direction={"column"} gap={"30px"}>
                <Typography
                  color="#ffffff"
                  sx={{
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "25px",
                    fontWeight: "500",
                    lineHeight: "30px",
                    textAlign: "center",
                  }}
                >
                  We are sorry for the inconvenience. Please check your
                  internet connection, firewall, proxies or try again later.
                </Typography>
                <Typography
                  color="#ffffff"
                  sx={{
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "25px",
                    fontWeight: "500",
                    lineHeight: "30px",
                    textAlign: "center",
                  }}
                >
                  If the problem persists, please reach out to support through
                  the chat icon.
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Button
              disableRipple
              sx={{
                '&:hover':{
                  backgroundColor:'#05609D',
                },
                bgcolor: "#0B4A6F",
                border:
                  "2px solid linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
                borderRadius: "8px",
                color: "white",
                fontFamily: "'Inter', sans-serif",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                minWidth: "360px",
                padding: "10px 16px",
                textTransform: "initial",
              }}
            >
              Return to Home
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
    </Box>
  );
};

export default ErrorPage;
