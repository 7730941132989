"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProjectDetails = exports.decodeProjectDetail = exports.encodeProject = exports.decodeProject = void 0;
const date_1 = require("../../utils/date");
const string_1 = require("../../utils/string");
const contactInfo_1 = require("../contactInfo");
const financial_1 = require("../financial");
const greenMetric_1 = require("../greenMetric");
const packageLocalContent_1 = require("../packageLocalContent");
const projectLabourHourCommitment_1 = require("../projectLabourHourCommitment");
const projectPolicy_1 = require("../projectPolicy");
const projectSocialSpendCommitment_1 = require("../projectSocialSpendCommitment");
const decodeProject = async (project, getImageUrl) => {
    const presignedUrl = await getImageUrl(project.organisation_logo);
    return {
        archived: project.archived,
        collaborators: project.collaborators || [],
        commitmentsHours: project.commitments_hours.map((c) => (0, projectLabourHourCommitment_1.decodeProjectLabourHourCommitment)(c)),
        commitmentsSpend: project.commitments_spend.map((c) => (0, projectSocialSpendCommitment_1.decodeProjectSocialSpendCommitment)(c)),
        created: new Date(project.created),
        estimatedProgress: project.estimated_progress,
        financial: (0, financial_1.decodeFinancial)(project.financial),
        firstPackageStartDate: project.first_package_start_date
            ? project.first_package_start_date
            : undefined,
        greenMetrics: project.green_metrics.map((g) => (0, greenMetric_1.decodeGreenMetric)(g)),
        id: project.project_id,
        lastPackageEndDate: project.last_package_end_date
            ? project.last_package_end_date
            : undefined,
        localContents: project?.local_content?.map((lc) => (0, packageLocalContent_1.decodePackageLocalContent)(lc)) || [],
        organisationId: project.organisation_id,
        organisationLogo: presignedUrl,
        organisationName: project.organisation_name,
        policies: project.policies.map((p) => (0, projectPolicy_1.decodeProjectPolicy)(p)),
        projectAccessLevel: project.project_access_level,
        projectAddress: (0, contactInfo_1.decodeContactInfo)(project.project_address),
        projectCode: project.project_code,
        projectEndDate: project.project_end_date,
        projectName: project.project_name,
        projectOwner: project.project_owner,
        projectReportMonths: (0, date_1.getReportMonths)((0, date_1.stringToDate)(project.project_start_date), (0, date_1.stringToDate)(project.project_end_date)),
        projectStartDate: project.project_start_date,
        reportingDueDate: project.reporting_due_date,
        representativeEmail: project.representative_email,
        representativeName: project.representative_name,
        userPackageId: project.user_package_id,
    };
};
exports.decodeProject = decodeProject;
const encodeProject = (project) => {
    const safeProject = (0, string_1.makePostgresSafe)(project);
    return {
        archived: safeProject.archived,
        collaborators: safeProject.collaborators || [],
        commitments_hours: safeProject.commitmentsHours.map((c) => (0, projectLabourHourCommitment_1.encodeProjectLabourHourCommitment)(c)),
        commitments_spend: safeProject.commitmentsSpend.map((c) => (0, projectSocialSpendCommitment_1.encodeProjectSocialSpendCommitment)(c)),
        created: new Date(safeProject.created),
        estimated_progress: safeProject.estimatedProgress,
        financial: (0, financial_1.encodeFinancial)(safeProject.financial),
        financial_id: safeProject.financial.id,
        first_package_start_date: safeProject.firstPackageStartDate,
        green_metrics: safeProject.greenMetrics.map((g) => (0, greenMetric_1.encodeGreenMetric)(g)),
        last_package_end_date: safeProject.lastPackageEndDate,
        local_content: project?.localContents?.map((lc) => (0, packageLocalContent_1.encodePackageLocalContent)(lc)) || [], //TODO: Remove this when local content is added to backend
        organisation_id: safeProject.organisationId,
        organisation_logo: safeProject.organisationLogo,
        organisation_name: safeProject.organisationName,
        policies: safeProject.policies.map((p) => (0, projectPolicy_1.encodeProjectPolicy)(p)),
        project_access_level: safeProject.projectAccessLevel,
        project_address: (0, contactInfo_1.encodeContactInfo)(safeProject.projectAddress),
        project_code: safeProject.projectCode,
        project_contact_info_id: safeProject.projectAddress.id,
        project_end_date: safeProject.projectEndDate,
        project_id: safeProject.id,
        project_name: safeProject.projectName,
        project_owner: safeProject.projectOwner,
        project_start_date: safeProject.projectStartDate,
        reporting_due_date: safeProject.reportingDueDate,
        representative_email: safeProject.representativeEmail,
        representative_name: safeProject.representativeName,
        user_package_id: safeProject.userPackageId || "",
    };
};
exports.encodeProject = encodeProject;
const decodeProjectDetail = async (project, getImageUrl) => {
    const presignedUrl = project.organisation_logo.length > 0
        ? await getImageUrl(project.organisation_logo)
        : "";
    return {
        archived: project.archived,
        collaborators: project.collaborators || [],
        contractorPackageId: project.contractor_package_id,
        created: new Date(project.created),
        estimatedProgress: project.estimated_progress,
        id: project.project_id,
        isAccepted: project.is_accepted,
        organisationId: project.organisation_id,
        organisationLogo: presignedUrl,
        organisationName: project.organisation_name,
        packageType: project.package_type,
        projectAccessLevel: project.project_access_level,
        projectAddress: (0, contactInfo_1.decodeContactInfo)(project.project_address),
        projectContactInfoId: project.project_contact_info_id,
        projectEndDate: project.project_end_date,
        projectName: project.project_name,
        projectOwner: project.project_owner,
        projectStartDate: project.project_start_date,
        reportingDueDate: project.reporting_due_date,
        representativeEmail: project.representative_email,
        representativeName: project.representative_name,
    };
};
exports.decodeProjectDetail = decodeProjectDetail;
const encodeProjectDetails = (project) => {
    const safeProject = (0, string_1.makePostgresSafe)(project);
    return {
        archived: safeProject.archived,
        collaborators: safeProject.collaborators || [],
        contractor_package_id: safeProject.contractorPackageId,
        created: new Date(safeProject.created),
        estimated_progress: safeProject.estimatedProgress,
        is_accepted: safeProject.isAccepted,
        organisation_id: safeProject.organisationId,
        organisation_logo: safeProject.organisationLogo,
        organisation_name: safeProject.organisationName,
        package_type: safeProject.packageType,
        project_access_level: project.projectAccessLevel,
        project_address: (0, contactInfo_1.encodeContactInfo)(safeProject.projectAddress),
        project_contact_info_id: safeProject.projectAddress.id,
        project_end_date: safeProject.projectEndDate,
        project_id: safeProject.id,
        project_name: safeProject.projectName,
        project_owner: safeProject.projectOwner,
        project_start_date: safeProject.projectStartDate,
        reporting_due_date: safeProject.reportingDueDate,
        representative_email: safeProject.representativeEmail,
        representative_name: safeProject.representativeName,
    };
};
exports.encodeProjectDetails = encodeProjectDetails;
