import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Typography,
  Card,
  Skeleton,
  CardContent,
  CardHeader,
  Button,
} from "@mui/material";

export const DirectoryCardSkeleton = () => {
  return (
    <Card
      className="direct-card"
      variant="outlined"
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <CardHeader
        sx={{ paddingBottom: "0px !important" }}
        avatar={<Skeleton animation={"wave"} variant="rounded" />}
        title={
          <Skeleton animation={"wave"}>
            <Typography variant="h1">Social Business Name</Typography>
          </Skeleton>
        }
      />
      <CardContent sx={{ paddingTop: "0px !important" }}>
        <Skeleton animation={"wave"}>
          <Typography
            variant="caption"
            component="p"
            mb={2}
            sx={{ fontSize: "16px", lineHeight: "24px", marginTop: "5px" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis
            <Button
              endIcon={<ArrowRightIcon />}
              sx={{ padding: "0 !important" }}
            >
              Read Less
            </Button>
          </Typography>
        </Skeleton>
        <span
          style={{
            color: "#000",
            display: "block",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          <Skeleton animation={"wave"} />
        </span>
        <Skeleton animation={"wave"}>
          <Typography variant="caption" component="p" mb={2}>
            Service Locations
          </Typography>
        </Skeleton>
        <span
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          <Skeleton animation={"wave"} />
        </span>
        <Skeleton animation={"wave"}>
          <Typography variant="caption" component="p" mb={2}>
            Social business services
          </Typography>
        </Skeleton>
        <span
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          <Skeleton animation={"wave"} />
        </span>
        <Skeleton animation={"wave"}>
          <Typography variant="caption" component="p" mb={2} />
        </Skeleton>

        <span
          style={{
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          <Skeleton animation={"wave"} />
        </span>
        <Skeleton animation={"wave"}>
          <Typography variant="caption" component="p" mb={2}>
            {`Current EBA:  "N/A"`}
          </Typography>
        </Skeleton>
        <span
          style={{
            color: "#000",
            display: "block",
            fontSize: "14px",
            fontWeight: "bold",
            margin: "0",
          }}
        >
          <Skeleton animation={"wave"} />
        </span>

        <Skeleton animation={"wave"}>
          <Typography mb={2} sx={{ margin: "0" }}>
            https://socialproapp.com
          </Typography>
        </Skeleton>
      </CardContent>
    </Card>
  );
};
