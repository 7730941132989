"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultGreenMetricComment = void 0;
const uuid_1 = require("uuid");
const createDefaultGreenMetricComment = (greenMetricId, contractorPackageId, reportId, comment) => {
    return {
        comment: comment,
        contractorPackageId,
        created: new Date(),
        greenMetricId,
        id: (0, uuid_1.v4)(),
        reportId,
    };
};
exports.createDefaultGreenMetricComment = createDefaultGreenMetricComment;
