import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import {
  FileBox,
  SproutIcon,
  BadgeCheck,
  NetworkIcon,
  Boxes,
} from "lucide-react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { SideBarMenuModelLineItem } from "social-pro-common/interfaces/sidebarMenu";
import { NavItemType } from "types";

export const getReportsNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();

  //   const currentDate = getMaximalDate(
  //     isOwner ? project?.projectStartDate : contractorPackage?.packageStartDate,
  //     isOwner ? project?.projectEndDate : contractorPackage?.packageEndDate,
  //   );

  const children = [];

  const socialReportChildren = [
    {
      counter: sideBarMenuModel?.projectReportSocialCount,
      icon: <FileBox />,
      id: "report-project",
      title: "Project",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.contractorProjectReports(
              project.id,
              contractorPackage.id,
            )
          : "",
    },
    {
      counter: sideBarMenuModel?.packageReportSocialCount,
      icon: <Boxes />,
      id: "report-packages",
      title: "Package",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.contractorPackageReports(
              project.id,
              contractorPackage.id,
            )
          : "",
    },
  ];

  children.push({
    children: socialReportChildren,
    childrenIds: [
      ...socialReportChildren.map((c) => c.id),
      "wizard",
      "project-social-wizard",
    ],
    counter:
      (sideBarMenuModel?.packageReportSocialCount || 0) +
      (sideBarMenuModel?.projectReportSocialCount || 0),
    icon: <NetworkIcon />,
    id: "social-report",
    title: "Social",
    type: "item",
    url: socialReportChildren.find((c) => !!c)?.url || "",
  });

  // const sustainabilityReportChildren = [
  //   {
  //     counter:
  //       (sideBarMenuModel?.packageReportSustainabilityCount || 0) +
  //       (sideBarMenuModel?.projectReportSustainabilityCount || 0),
  //     icon: <FileBox />,
  //     id: "project-sustainability",
  //     title: "Project",
  //     type: "item",
  //     url:
  //       project && contractorPackage
  //         ? RouteHelper.projectSustainability(project.id, contractorPackage.id)
  //         : "",
  //   },

  //   {
  //     counter: sideBarMenuModel?.packageReportSustainabilityCount,
  //     icon: <Box />,
  //     id: "sustainability",
  //     title: "Package",
  //     type: "item",
  //     url:
  //       project && contractorPackage
  //         ? RouteHelper.sustainability(project.id, contractorPackage.id)
  //         : "",
  //   },
  // ];

  // children.push({
  //   children: sustainabilityReportChildren,
  //   counter: sideBarMenuModel?.packageReportSustainabilityCount,
  //   icon: <SproutIcon />,
  //   id: "project-policies",
  //   title: "Sustainability",
  //   type: "item",
  //   url:
  //     project && contractorPackage
  //       ? RouteHelper.projectPolicies(project.id, contractorPackage.id)
  //       : "",
  // });

  const policyReportChildren = [
    {
      counter: sideBarMenuModel?.policyReportCount || 0,
      icon: <FileBox />,
      id: "project-policies",
      title: "Project",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.projectPolicies(project.id, contractorPackage.id)
          : "",
    },
  ];

  children.push({
    children: policyReportChildren,
    childrenIds: [...policyReportChildren.map((c) => c.id)],
    counter: sideBarMenuModel?.policyReportCount,
    icon: <BadgeCheck />,
    id: "governance",
    title: "Governance",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.projectPolicies(project.id, contractorPackage.id)
        : "",
  });

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "packages",
    title: "Reports",
    type: "group",
  };
};

export const getReducedReportNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  sideBarMenuModel?: SideBarMenuModelLineItem,
): NavItemType => {
  const theme = useTheme();

  const children = [];

  children.push({
    counter: sideBarMenuModel?.packageReportSocialCount,
    icon: <NetworkIcon />,
    id: "social-report",
    title: "Social",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.contractorPackageReports(project.id, contractorPackage.id)
        : "",
  });

  children.push({
    counter: sideBarMenuModel?.packageReportSustainabilityCount,
    icon: <SproutIcon />,
    id: "project-sustainability",
    title: "Sustainability",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.projectSustainability(project.id, contractorPackage.id)
        : "",
  });

  children.push({
    counter: sideBarMenuModel?.policyReportCount,
    icon: <BadgeCheck />,
    id: "project-policies",
    title: "Governance",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.projectPolicies(project.id, contractorPackage.id)
        : "",
  });

  return {
    children: children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "packages",
    title: "Reports",
    type: "group",
  };
};
