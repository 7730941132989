"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultGreenMetric = exports.createDefaultGreenMetricsForProject = void 0;
const uuid_1 = require("uuid");
const greenMetricCategory_1 = require("../greenMetricCategory");
const createDefaultGreenMetricsForProject = (projectId) => {
    const defaultGreenMetrics = [];
    const fuelMetricId = (0, uuid_1.v4)();
    defaultGreenMetrics.push({
        created: new Date(),
        id: fuelMetricId,
        projectId: projectId,
        reportCategories: [
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Plant & Equipment",
                targetCategory: "Diesel",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Heavy Vehicles (i.e. trucks or other road registered heavy vehicle/plant)",
                targetCategory: "Diesel",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Liquid fuel for stationary use - diesel for generators",
                targetCategory: "Diesel",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "B5 biodiesel",
                targetCategory: "Diesel",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "B20 biodiesel",
                targetCategory: "Diesel",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Plant and equipment",
                targetCategory: "Petrol",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Heavy vehicles (i.e. trucks or other road registered heavy vehicle/plant)",
                targetCategory: "Petrol",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "LPG – Plant and Equipment",
                targetCategory: "Other",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Natural Gas - Plant and Equipment",
                targetCategory: "Other",
                targetType: "Cubic Metres (m\u00B3')",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Oil (Engine oil)",
                targetCategory: "Other",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Oil (Non combusted – hydraulic, gear oil etc)",
                targetCategory: "Other",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Grease",
                targetCategory: "Other",
                targetType: "Kilograms (Kg)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Bulk Solvents (e.g. Thinners, WD40, RP7, etc)",
                targetCategory: "Other",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: fuelMetricId,
                id: (0, uuid_1.v4)(),
                metricType: "Acetylene (Cylinder size E; G or GX)",
                targetCategory: "Other",
                targetType: "# of Cylinders",
            },
        ],
        reportTargetName: "Fuels, Lubricants and Solvents",
    });
    const waterUsageId = (0, uuid_1.v4)();
    defaultGreenMetrics.push({
        created: new Date(),
        id: waterUsageId,
        projectId: projectId,
        reportCategories: [
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Mains - Office Use",
                targetCategory: "Potable",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Water Carts (Off-site fill source)",
                targetCategory: "Potable",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Mains - Construction Use",
                targetCategory: "Potable",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Water Carts - Non Recycled (Off-site fill source)",
                targetCategory: "Non-Potable",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Water Carts - Recycled (Off-site fill source)",
                targetCategory: "Non-Potable",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Rainwater Tanks",
                targetCategory: "Reused on Site",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Other - Including Sediment Basins",
                targetCategory: "Reused on Site",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Surface Water",
                targetCategory: "Water Discharge",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Groundwater",
                targetCategory: "Water Discharge",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Trade Waste",
                targetCategory: "Water Discharge",
                targetType: "Litres (L)",
            },
            {
                created: new Date(),
                greenMetricId: waterUsageId,
                id: (0, uuid_1.v4)(),
                metricType: "Other",
                targetCategory: "Water Discharge",
                targetType: "Litres (L)",
            },
        ],
        reportTargetName: "Water Usage",
    });
    const wasteId = (0, uuid_1.v4)();
    defaultGreenMetrics.push({
        created: new Date(),
        id: wasteId,
        projectId: projectId,
        reportCategories: [
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Bricks / Tiles",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Concrete",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Rubble / Asphalt",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Ferrous Metals",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Non-ferrous Metals (i.e. Aluminium, Copper)",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Timber",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Green Waste",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Paper / Cardboard",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Plastic",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Plasterboard",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "General Waste",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Contaminated - Oil / Hydrocarbon Waste",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Contaminated - Other (incl. treated) Waste",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Aggregate",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Sand",
                targetCategory: "Waste Disposed to Landfill",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Bricks / Tiles",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Concrete",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Rubble / Asphalt",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Ferrous Metals",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Non-ferrous Metals (i.e. Aluminium, Copper)",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Timber",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Green Waste",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Paper / Cardboard",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Plastic",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Plasterboard",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "General Waste",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Aggregate Reused Off-site",
                targetCategory: "Waste Reused or Recycled",
                targetType: "Tonnes (t)",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Aggregate Reused On-site",
                targetCategory: "Waste Reused or Recycled",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Sand Reused Off-site",
                targetCategory: "Waste Reused or Recycled",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Sand Reused On-site",
                targetCategory: "Waste Reused or Recycled",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Fill Material Reused On-site",
                targetCategory: "Soil",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Fill Material Reused Off-site",
                targetCategory: "Soil",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Fill Material Disposed to Landfill",
                targetCategory: "Soil",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Contaminated Soil Reused On-site",
                targetCategory: "Soil",
                targetType: "m\u00B3",
            },
            {
                created: new Date(),
                greenMetricId: wasteId,
                id: (0, uuid_1.v4)(),
                metricType: "Contaminated Soil Disposed to Landfill ",
                targetCategory: "Soil",
                targetType: "m\u00B3",
            },
        ],
        reportTargetName: "Waste (Landfill, Reused, Soil)",
    });
    return defaultGreenMetrics;
};
exports.createDefaultGreenMetricsForProject = createDefaultGreenMetricsForProject;
const createDefaultGreenMetric = (projectId) => {
    const greenMetricId = (0, uuid_1.v4)();
    return {
        created: new Date(),
        id: greenMetricId,
        projectId,
        reportCategories: [(0, greenMetricCategory_1.createDefaultGreenMetricCategory)(greenMetricId)],
        reportTargetName: "",
    };
};
exports.createDefaultGreenMetric = createDefaultGreenMetric;
