import { useSubcontractorNotes } from "@hooks/crud/subcontractorNotes/useSubcontractorNotes";
import { Grid } from "@mui/material";
import PackageCommentsSection from "@stories/molecules/PackageCommentsSection/PackageCommentsSection";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { createDefaultSubcontractorNote } from "social-pro-common/interfaces/subcontractorNote";

import { Header } from "../Header/Header";

interface PackageDetailsCommentsProps {
  isLoading: boolean;
  organisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  subPackage?: ContractorPackageLineItem;
}
export const PackageDetailsComments = ({
  isLoading,
  organisation,
  project,
  subPackage,
}: PackageDetailsCommentsProps) => {
  const { comments, isCommentsLoading, upsertSubcontractorNotes } =
    useSubcontractorNotes(project?.id, subPackage?.id);

  const loading = isLoading || isCommentsLoading;

  return (
    <>
      <Header
        mainTitle="Package Comments"
        loading={loading}
        headerFontSizes={[18, 14]}
        headerGap={0}
        paddingTop="0"
      />
      <Grid item md={12}>
        <PackageCommentsSection
          loading={loading}
          notes={comments}
          organisation={organisation}
          onSubmit={async (commentText: string) => {
            if (project && subPackage) {
              const newComment = createDefaultSubcontractorNote(
                project.id,
                subPackage?.id,
                commentText,
              );
              await upsertSubcontractorNotes([newComment]);
            }
          }}
        />
      </Grid>
    </>
  );
};
