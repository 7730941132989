import { Grid, Skeleton } from "@mui/material";
import { StepConfig, Steps } from "@stories/atoms/Steps/Steps";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

interface WizardStepsProps {
  loading: boolean;
  activeStep: number;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  readOnly?: boolean;
}

export const WizardSteps = ({
  activeStep,
  contractorPackage,
  loading,
  project,
  readOnly,
}: WizardStepsProps) => {
  if (loading) {
    const skeletonSteps = [
      { label: "Labour Hours" },
      { label: "Spend" },
      { label: "Local Content" },
      { label: "Report Confirmation" },
    ] as StepConfig[];

    if (!readOnly) {
      skeletonSteps.push({ label: "Submit" });
    }

    return (
      <Grid item xs={12} md={12}>
        <Skeleton animation="wave" sx={{ margin: "auto" }}>
          <Steps activeStep={activeStep} steps={skeletonSteps} />
        </Skeleton>
      </Grid>
    );
  }
  const steps = [] as StepConfig[];
  if (contractorPackage) {
    if (contractorPackage.commitmentsHours.length > 0) {
      steps.push({ label: "Labour Hours" });
    }
    if (contractorPackage.commitmentsSpend.length > 0) {
      steps.push({ label: "Social Spend" });
    }
    if (project?.localContents.length) {
      steps.push({ label: "Local Content" });
    }
  }
  if (!readOnly) {
    steps.push({ label: "Submit" });
  }

  return (
    <Grid item xs={12} md={12}>
      <Steps steps={steps} activeStep={activeStep} />
    </Grid>
  );
};
