import { Header } from "@stories/organisms/Header/Header";

import { FAQCards } from "./FAQCards";

interface FAQSectionProps {
  loading: boolean;
}
export default function FAQSection({ loading }: FAQSectionProps) {
  return (
    <>
      <Header
        paddingTop="0"
        loading={loading}
        mainTitle={"FAQs"}
        subTitle={"Answers to common questions and queries"}
      />
      <FAQCards />
    </>
  );
}
