import { Grid } from "@mui/material";
import {
  CustomPagination,
  paginate,
  PAGINATION_LIMIT,
} from "@stories/organisms/Table/CustomPagination";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  centerCellStyles,
  defaultCellConfig,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ValueFormatterParams } from "ag-grid-community";
import { ArchiveProjectIcon, EditProjectIcon } from "assets/constants";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getSocialSpendFactors } from "social-pro-common/interfaces/projectSocialSpendCommitment";
import {
  SocialSpendLineItem,
  socialSpendServiceCategoryToString,
} from "social-pro-common/interfaces/socialSpend";
import { formatReportDate } from "social-pro-common/utils/date";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { SocialSpendTableRowSkeleton } from "./SocialSpendTableRowSkeleton";
import { SocialSpendFactorsCell } from "../ProjectEmployeeTable/ProjectEmployeeTableCells";

interface SocialSpendTableProps {
  loading: boolean;
  searchTerm: string;
  project?: ProjectLineItem;
  socialSpends: SocialSpendLineItem[];
  setPage: (page: number) => void;
  page: number;
  handleDeleteSocialSpends: (
    socialSpends: SocialSpendLineItem,
  ) => Promise<void>;
  handleEditSocialSpend: (socialSpend: SocialSpendLineItem) => Promise<void>;
}

export const SocialSpendTable = ({
  handleDeleteSocialSpends,
  handleEditSocialSpend,
  loading,
  page,
  project,
  searchTerm,
  setPage,
  socialSpends,
}: SocialSpendTableProps) => {
  const cellActions = [
    {
      action: handleEditSocialSpend,
      adminOnly: true,
      icon: <EditProjectIcon />,
      title: "Edit Spend",
    },
    {
      action: handleDeleteSocialSpends,
      adminOnly: true,
      icon: <ArchiveProjectIcon />,
      title: "Delete Spend",
    },
  ];
  const colDefs = [
    {
      ...defaultCellConfig,
      field: "transactionDate",
      headerName: "Transaction Date",
      valueFormatter: (params: any) =>
        `${formatReportDate(new Date(params.data.transactionDate))}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: (params: ValueFormatterParams<any, any>) => {
        const socialFactors = getSocialSpendFactors(
          params.data,
          params.context.project,
        );
        return <SocialSpendFactorsCell factors={socialFactors} />;
      },
      cellStyle: centerCellStyles,
      field: "socialFactors",
      flex: 2,
      headerClass: "centered-table-header",
      headerName: "Social Factors",
    },
    {
      ...defaultCellConfig,
      field: "supplier",
      headerName: "Supplier",
    },
    {
      ...defaultCellConfig,
      field: "abn",
      headerName: "ABN",
    },
    {
      ...defaultCellConfig,
      field: "natureOfExpenditure",
      flex: 1.5,
      headerName: "Nature of Expenditure",
      valueFormatter: (params: any) =>
        `${socialSpendServiceCategoryToString(params.data.natureOfExpenditure)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: centerCellStyles,
      field: "invoiceValue",
      headerClass: "centered-table-header",
      headerName: "Invoice Value (Ex. GST)",
      valueFormatter: (params: any) =>
        `$${formatDecimalPlaces(params.data.invoiceValue, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellStyle: centerCellStyles,
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "",
    },
  ];

  const { data, pageNumbers, totalPages } = paginate<SocialSpendLineItem>(
    socialSpends,
    page,
    10,
  );

  return (
    <Grid item xs={12} md={12} mt={4}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={SocialSpendTableRowSkeleton}
        />
      ) : null}
      {!loading ? (
        <Table
          searchValue={searchTerm}
          columnDefs={colDefs}
          loading={loading}
          data={data || []}
          searchKeys={[
            "supplier",
            "abn",
            "natureOfExpenditure",
            "transactionDate",
          ]}
          context={{ cellActions, loading, project }}
        />
      ) : null}
      {!loading && socialSpends.length > PAGINATION_LIMIT ? (
        <CustomPagination
          onPageChange={setPage}
          page={page}
          pageNumbers={pageNumbers}
          totalPages={totalPages}
        />
      ) : null}
    </Grid>
  );
};
