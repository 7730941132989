const LAYOUT_CONST = {
  DEFAULT_DRAWER: "default",
  HORIZONTAL_LAYOUT: "horizontal",
  MINI_DRAWER: "mini-drawer",
  VERTICAL_LAYOUT: "vertical",
};

// theme constant
export const gridSpacing = 2;
export const drawerWidth = 320;
// export const drawerWidth = 230;
export const appDrawerWidth = 320;

export default LAYOUT_CONST;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export enum SortDirection {
  ASC = 0,
  DESC = 1,
}

export const faqSet1 = [
  {
    answer:
      "SocialPro is a web-based application that serves as a SaaS platform for reporting on social procurement performance, on major building, rail and infrastructure projects. It collects and consolidates social procurement information for a project, and provides analytics and insights into performance at both the package and project level.",
    id: 1,
    question: "What is SocialPro?",
  },
  {
    answer:
      "SocialPro provides a comprehensive solution for contractors and subcontractors to effectively report on and monitor their Social Procurement spend, across all commitments applicable to their project. It streamlines the data input process, automates consolidation and reporting with analytics and insights, and enhances transparency, to help compliance requirements while promoting social and economic benefits. Better information leads to better decision-making, in optimising social procurement performance.",
    id: 2,
    question: "How can SocialPro help contractors and subcontractors? ",
  },
  {
    answer:
      "Social Procurement plays a vital role in the construction industry as it helps foster inclusive economic growth, supports local communities, and promotes sustainable business practices. It allows contractors and subcontractors to make a positive impact, and create social value above and beyond the value of goods and services procured, through their purchasing decisions.",
    id: 3,
    question:
      "Why is Social Procurement important in the construction industry?",
  },
  {
    answer:
      "SocialPro employs advanced tracking mechanisms to monitor Social Procurement spend and labour participation, specific to the commitments applicable to each individual project. Data across packages to a project are automatically consolidated to provide project-level insights. It integrates with project management tools, collects relevant labour and spend data, and generates insightful reports that illustrate performance against commitments, whilst highlighting areas for improvement.",
    id: 4,
    question:
      "How does SocialPro track and report on Social Procurement spend?",
  },
  {
    answer:
      "Yes, SocialPro is designed to seamlessly integrate with various project management tools commonly used in the construction industry. This integration ensures a smooth workflow and allows users to leverage their existing systems to streamline the data input process and minimise duplication in business processes.",
    id: 5,
    question:
      "Can SocialPro integrate with existing project management tools? ",
  },
  {
    answer: `SocialPro is suitable for all projects for which the Social Procurement Framework, Local Jobs First Policy, and Indigenous Participation Policy are applicable. The platform is bespoke to address the various commitments that are expected of sellers under these Frameworks.

    Additionally, the platform can be tailored to meet any other type of project in which discretionary social procurement targets have been applied, in order to promote social and economic benefits to groups identified as beneficiaries to the project.
    `,
    id: 6,
    question: "What types of projects is SocialPro suitable for? ",
  },

  {
    answer:
      "Yes, SocialPro is scalable and can accommodate projects of different sizes. Whether it's a small-scale residential project or a large-scale multi-year infrastructure development, SocialPro can effectively track and report on Social Procurement performance.",
    id: 7,
    question: "Is SocialPro compatible with different project sizes? ",
  },
  {
    answer:
      "SocialPro collects relevant data related to Social Procurement spend, supplier information, project details, and social impact metrics. This data is used to generate reports, analytics, and insights that enable users to make informed decisions and demonstrate their social value.",
    id: 8,
    question: "What data does SocialPro collect and how is it used? ",
  },
  {
    answer:
      "SocialPro improves transparency in Social Procurement reporting by providing a centralized platform where contractors and subcontractors can track, document, and report their Social Procurement activities. It ensures accurate and reliable reporting, making it easier to demonstrate compliance and promote transparency to stakeholders.",
    id: 9,
    question:
      "How can SocialPro improve transparency in Social Procurement reporting? ",
  },
  {
    answer:
      "Yes, SocialPro is equipped with robust reporting and analytics capabilities. It can generate comprehensive reports that showcase Social Procurement spend, supplier diversity, community impact, and other key metrics. These reports can be shared with stakeholders to showcase the social value created through procurement practices.",
    id: 10,
    question: "Can SocialPro generate reports and analytics for stakeholders?",
  },
  {
    answer:
      "Yes, data security is a top priority for SocialPro. We implement stringent security measures to protect user data from unauthorized access, loss, or misuse. We use industry-standard encryption protocols, secure data storage, and regular backups to ensure the highest level of data security.",
    id: 11,
    question: "Is my data secure with SocialPro?",
  },
  {
    answer:
      "Onboarding and using SocialPro is designed to be user-friendly and intuitive. We provide user guides, tutorials, and customer support to help users navigate the platform easily. Our goal is to ensure a smooth onboarding experience and minimize the learning curve for new users.",
    id: 12,
    question: "How easy is it to onboard and use SocialPro?",
  },
  {
    answer:
      "Yes, we offer comprehensive training and support resources for SocialPro users. We provide documentation, video tutorials, and a dedicated support team to assist users with any questions or issues they may encounter. We are committed to ensuring our users have the resources they need to effectively utilize SocialPro.",
    id: 13,
    question:
      "Are there any training or support resources available for SocialPro users?",
  },
  {
    answer:
      "We offer flexible pricing options for SocialPro based on the needs of our clients. Our pricing structure takes into account factors such as project size, user requirements, and additional features. Please contact our sales team to discuss pricing details and find a plan that suits your specific needs.",
    id: 14,
    question: "What pricing options are available for SocialPro?",
  },

  {
    answer:
      "Yes, we offer demos and trials of SocialPro to interested customers. This allows you to explore the platform's features, interface, and functionality before making a purchasing decision. Please reach out to our sales team to schedule a demo or discuss trial options.      ",
    id: 15,
    question: "Can I request a demo or trial of SocialPro before purchasing?",
  },

  {
    answer:
      "Getting in touch with our support team is easy. You can reach out to us through our dedicated support channels, including email, phone, or our online support portal. Our team is available to assist you with any questions, technical issues, or support requests you may have.",
    id: 16,
    question: "How can I get in touch with the SocialPro support team?",
  },
  {
    answer:
      "Yes, SocialPro offers customisation options to meet the specific needs of your Organisation. We understand that different projects and companies have unique requirements, and we strive to accommodate them. Our team can work with you to tailor SocialPro to align with your specific goals and workflows.",
    id: 17,
    question:
      "Does SocialPro offer customisation options to meet specific needs?",
  },
  {
    answer:
      "Absolutely, SocialPro is designed to support multiple users and teams. It allows you to invite team members, assign roles and permissions, and collaborate efficiently. Whether you have a small team or a large Organisation, SocialPro can accommodate your user and team management needs.",
    id: 18,
    question: "Can SocialPro accommodate multiple users and teams?",
  },
];

export const faqSet2 = [
  {
    answer: `To initiate a project on SocialPro, commence by selecting the "Create Project" button. Once clicked, you'll be prompted to input essential project details, including the project's name, address, duration (start and end dates), financial specifics, and reporting date. Within the financial section, you'll have the option to specify project value and select your preferred target profiling method, choosing between Deemed Hours or Estimated Hours.<br><br>
Following this, navigate to the "Target Hours" tab, enabling you to tailor targets to suit your project's requirements and even create bespoke targets if necessary. Similarly, within the "Target Spend" tab, adjust spending targets and devise custom targets to align with your project's financial objectives.<br><br>
The subsequent step involves setting up the Local Content. This component can be efficiently prepopulated or seamlessly imported via a CSV template, streamlining the process for your convenience.<br><br>
Moving forward, the "Project Policies" tab allows for the establishment of customized policies tailored to your project's needs. Here, you can define specific guidelines and regulations to ensure project compliance and efficiency.<br><br>
Lastly, the "Environmental" tab provides a platform to report on various environmental sustainability metrics, reinforcing your project's commitment to ecological responsibility. Upon completion of these steps, your project on SocialPro is officially established, ready to facilitate seamless management and reporting throughout its duration.`,
    id: 1,
    question: "What is SocialPro?",
  },
  {
    answer: `Prior to creating a project in SocialPro, it's essential to collect several key factors to streamline the onboarding process and ensure effective integration with the project's data and reporting procedures.These factors include:

    <br><b>Direct internal hours:</b> Identify where staff and workforce hours are recorded and whether actual timesheets or rationalised assumptions will be used. Determine the status of timesheets accepted and any caps on staff hours to minimise errors.

    <br><b>Direct workforce/subcontractor hours:</b> Establish methods for managing multiple site accesses per shift, shift assumptions, and tracking hours accurately while adhering to industrial relations practices.

    <br><b>Offsite hours:</b> Consider implications for safety reporting, auditing, and rationalisation of offsite hours. Determine suitable evidence of offsite hours worked and procedures for managing duplicate data.

    <br><b>Assumptions and logic:</b> Define what constitutes an hour worked on the project and determine ownership of total project hours. Consider whether actuals or assumptions will be used, and if offsite training/study hours will be included.

    <br><b>Inclusions/exclusions:</b> Clarify wording around alternative employment arrangements and inclusion of trucking and transport hours. Determine the timing for hours worked in a monthly period and protocols for retrospective hour updates.

    <br><br>Establishing a governance model early in the project lifecycle is crucial to ensure data integrity and streamline onboarding. SocialPro will collaborate closely to facilitate integration but emphasises the importance of project team responsibility in governance modelling changes. Rigorous governance will protect the project from scrutiny and expedite onboarding. In upcoming discussions, implementation steps related to SocialPro will be addressed, and it's recommended to watch the onboarding content in the Help Centre beforehand for a productive meeting.`,
    id: 2,
    question:
      "What do I need to collect prior to creating my project in SocialPro?",
  },
  {
    answer: `To complete the contract value and margin fields accurately, enter the total contract value and corresponding margin as specified in the contract documentation. These values directly impact project targets within SocialPro, ensuring alignment with the project's financial parameters for accurate reporting and progress tracking.`,
    id: 3,
    question: "How should I complete contract value and margin?",
  },
  {
    answer: `Target profiling in SocialPro refers to the method used to estimate a project's total labour hours, which is essential for setting project targets. There are two main methods of target profiling: <b>Deemed Hours</b> and <b>Estimated Hours.</b>

    <br><br>Deemed Hours Formula is a calculation method that estimates a project's total labour hours based on factors such as project type and estimated contract value. This formula employs various labour rates and ratios specific to the project type to determine the total estimated labour hours. For example, bidders for MPSG projects typically use the Deemed Hours Formula to calculate the total estimated labour hours, with 10 percent of this total becoming the minimum requirement for the project.

    <br><br>On the other hand, Estimated Hours involves directly estimating the labour hours required for the project based on various factors such as project scope, complexity, and resources. This method allows for a more tailored and specific estimation of labour hours, without relying on predefined formulas or ratios.

    As SocialPro evolves to streamline processes, there is a move towards simplifying the estimation of labour hours. This involves bidders submitting a single employment commitment in the Local Content, which informs both MPSG and Victorian Industry Participation Policy (VIPP) requirements. This streamlined approach aims to enhance efficiency and reduce duplication of effort in estimating project labour hours for bidding purposes.`,
    id: 4,
    question:
      "What is target profiling? What is the difference between Deemed Hours and Estimated Hours?",
  },
  {
    answer: `Estimated trade hours, non-trade hours, and management/supervisory/specialist hours are categories used to allocate labour hours within a project:

    <br><b>Estimated Trade Hours:</b> These refer to the anticipated number of labour hours allocated to trade-specific tasks or activities within the project. Trade hours typically include activities directly related to construction, such as carpentry, plumbing, electrical work, and other specialised trades involved in the project.

   <br><b> Non-Trade Hours:</b> Non-trade hours encompass labour hours allocated to tasks or activities that are not directly related to specific trades but are essential for project completion. This category may include administrative tasks, project management, site supervision, safety inspections, and other non-trade-related activities necessary for project execution.

    <br><b>Management/Supervisory/Specialist Hours:</b> These hours pertain to the labour allocated to managerial, supervisory, or specialist roles within the project. This category includes hours dedicated to project management, site supervision, quality assurance, health and safety management, engineering, design, and other specialised roles critical for project oversight and execution.`,
    id: 5,
    question:
      "What are estimated trade hours, non-trade hours and management/supervisory/specialist hours?",
  },
  {
    answer: `If the commitments outlined in your agreement with the client differ from those suggested in SocialPro, it's crucial to rectify this discrepancy before proceeding further. Misalignment between commitments can lead to confusion, inaccuracies in reporting, and potential issues with project management.<br><br>
To address this issue effectively, it's recommended to engage with both the SocialPro technical support team and the client.`,
    id: 6,
    question:
      "The commitments in my agreement with the client looks different to those suggested in SocialPro?",
  },
  {
    answer: `To create custom hours and spend targets in SocialPro, begin by clicking on the button located at the top right corner of the screen.<br><br>
          Within this tool, provide a clear description for the target, assigning a distinctive colour for easy identification. Then, input the desired target percentage or number of hours.<br><br>
          Next, profile the target by selecting relevant criteria such as distance from the project, gender, social factors, employment level, occupation type, postcodes, and ABNs (if known). These criteria ensure the target aligns precisely with your project's parameters and objectives.`,
    id: 7,
    question: "How to create custom hours and spend targets?",
  },

  {
    answer: `All bids for Local Jobs First projects are required to provide a <b>Local Content</b> which clearly identifies local content, job commitments, including opportunities for <b>apprentices, trainees,</b> and <b>cadets</b> within the project.`,
    id: 8,
    question: "What is Local Content?	",
  },

  {
    answer: `As part of the Local Content process for Local Jobs First projects, a downloadable CSV (Comma-Separated Values) template is provided to streamline data entry and reduce manual workload.<br><br>This can be both downloaded and uploaded within the tab.`,
    id: 9,
    question: "Is there a more efficient method of populating my agreed LIDP?	",
  },
  {
    answer: `Policies for projects and subcontractors may be required based on policy and project specifications. These policies ensure compliance with relevant regulations and project guidelines.<br><br>
To import policies into SocialPro, upload documents or enter policy details directly. Review project requirements and standards to determine necessary policies for proper implementation within SocialPro.`,
    id: 10,
    question:
      "Are policies required for project and subcontractor? Is it required and how do I import it?",
  },
  {
    answer: `Environmental reporting involves documenting and communicating a project's environmental performance to stakeholders. This process includes measuring, assessing, and reporting various aspects of the project's impact on the environment, such as carbon emissions, energy consumption, waste generation, and resource usage.<br><br>
It aims to provide transparency and accountability regarding the project's environmental footprint and efforts to mitigate any negative impacts. Additionally, environmental reporting may cover specific criteria such as carbon embodied concrete and greenhouse gas emissions, offering insights into the project's sustainability efforts and compliance with environmental regulations.`,
    id: 11,
    question: "What is environmental reporting?",
  },
  {
    answer: `Yes, you can edit a project after it has been created. Simply select the edit tool on the project to make any necessary changes or updates. This allows for flexibility and ensures that project details can be adjusted as needed throughout the project lifecycle.`,
    id: 12,
    question: "Can I edit a project once it's been created?",
  },
];
export const faqSet3 = [
  {
    answer: `To add a new employee in SocialPro, follow these steps:<br><br>
Enter the employee details:<br><br>
- Name/code<br>
- Registered training number (if applicable)<br>
- Unique employee ID (if assigned)<br>
- Identifier (unique identifier used only in SocialPro)<br>
- Date of birth (DOB)<br>
- Gender<br>
- Address<br>
- Social factors (such as Aboriginal or Torres-Strait Islander, Disengaged Youth, Long-term unemployed, person with disability, veteran, Culturally & Linguistically diverse, persons affected by family violence, Ex-offender)<br><br>
Specify the employment type:<br><br>
Direct or indirect employment<br><br>
Define the employment level:<br><br>
- Standard<br>
- Apprentice<br>
- Trainee<br>
- Cadet<br><br>
Indicate the contract type:<br><br>
- Permanent/full-time<br>
- Fixed-term<br>
- Part-time<br>
- Casual<br><br>
Optionally, add social factors supporting documentation. While not mandatory, it's advisable to retain these records within your HR function for reference.<br><br>
By following these steps, you can successfully add a new employee to SocialPro, ensuring accurate and comprehensive records of your project's workforce.`,
    id: 1,
    question: "How to add a new employee?",
  },
  {
    answer: `To maintain confidentiality and de-identify employee information in SocialPro, you can implement custom coding for each employee. This involves assigning unique codes or identifiers to replace sensitive personal information. These custom codes can be created within SocialPro and applied to each employee record.<br><br>
Additionally, consider storing the original employee records containing sensitive information offline or in a separate secure database outside of SocialPro. By de-identifying employee information and storing sensitive records separately, you can enhance data privacy and security while still maintaining accurate workforce documentation within SocialPro.`,
    id: 2,
    question:
      "When dealing with sensitive information, I would like to keep my employees information de-identified. How can I do this?",
  },
  {
    answer: `Social factors encompass a range of demographic and contextual elements that significantly influence an individual's social circumstances and experiences. These factors include Aboriginal or Torres-Strait Islander status, Disengaged Youth, Long-term unemployed status, Person with disability, Veteran status, Culturally & Linguistically diverse background, Persons affected by family violence, and Ex-offender status. It is imperative to tag these factors accurately within reporting systems, as they directly impact targets and reporting outcomes.`,
    id: 3,
    question: "What are social factors?",
  },
  {
    answer: `While supporting documents are not mandatory within SocialPro, it's important to note that they may be required by the contractor or regulatory authorities. Therefore, it's advisable to retain supporting documentation within your company's records in case of an audit or verification process. This ensures compliance with any potential requirements and facilitates transparency and accountability in your reporting processes. By maintaining comprehensive records internally, you can confidently address any requests for documentation and demonstrate adherence to relevant guidelines and standards.`,
    id: 4,
    question: "Do I need supporting documents?",
  },
  {
    answer: `If your employee does not have a training contract number or a unique student ID, you can proceed with entering the other required details into the system. However, please note that this information will be necessary when reporting your package labor hours each month. Therefore, it's essential to ensure that you obtain and input this information accurately before the reporting period to fulfill the mandatory requirements for reporting labor hours.`,
    id: 5,
    question:
      "My employee doesn't have a training contract number AND a unique student ID. How do I complete both mandatory fields?",
  },
  {
    answer: `To import your employee list into SocialPro, download the provided CSV template, populate it with employee details, and upload it to the platform. This streamlined process minimises manual work and ensures accurate employee records.`,
    id: 6,
    question: "How do I import my employee list?",
  },
  {
    answer: `To assign employees to projects, navigate to the project portal and access the 'Employees' tab. Here, you can toggle employees on and off for the project, determining their inclusion in package reports. This step is crucial for accurate reporting and ensures that only relevant employees are associated with the project.`,
    id: 7,
    question: "How do I assign employees to projects?",
  },
];
export const faqSet4 = [
  {
    answer: `To add a subcontractor to the project, navigate to the project portal and click on the <b>Add Subcontractor</b> button. Enter the subcontractor's details, including their email, package type, start date, and target completion date. Attach any necessary subcontractor agreements. Then, input package financials such as the package value, margin, and target profiling, including deemed labor ratio and hourly rate if applicable.
    <br>
    Next, specify labor hours targets to track project milestones accurately. Finally, enter spend details related to the subcontractor's package, including expenditure and budget allocations. This streamlined process ensures efficient management of subcontractor information within the SocialPro platform.`,
    id: 1,
    question: "How do I add a subcontractor to the project?",
  },
  {
    answer: `Subcontractors do not have the capability to edit their commitments within SocialPro. This functionality is controlled solely by the contractor account. By maintaining control over commitments, contractors can ensure consistency and accuracy in reporting and project management processes.`,
    id: 2,
    question: "Can subcontractors edit their commitments?",
  },
  {
    answer: `To facilitate a seamless onboarding process for subcontractors, it's imperative to gather comprehensive information beforehand. This includes obtaining their email address for communication purposes and collecting details about the subcontractor's package, such as scope of work, start and completion dates, and specific requirements outlined in the subcontractor agreement. Additionally, financial information such as package value, margin, and target profiling should be documented, along with confirmed labor hours and spend targets as per the agreement. By proactively gathering these details, you can ensure clarity and alignment with project objectives, paving the way for a successful collaboration.`,
    id: 3,
    question: "What do I need from the subcontractor?",
  },
  {
    answer: `You have the flexibility to customise targets for subcontractors within SocialPro.`,
    id: 4,
    question: "How do I customise targets for the subcontractor?",
  },
  {
    answer: `To gain insights into subcontractor performance, navigate to the <br>Subcontractor</b> tab within the platform. Here, you'll have access to various metrics, including labor hours, expenditure, Local Content, policies, reports, and subcontractor details. This comprehensive view enables you to assess performance and track progress effectively, facilitating informed decision-making and project management.`,
    id: 5,
    question: "How do I see insights into subcontractor performance?",
  },
  {
    answer: `It's crucial to add packages for every subcontractor to the project within SocialPro. Doing so enables a comprehensive project-wide analysis of performance, allowing for better monitoring and management of subcontractor contributions.`,
    id: 6,
    question:
      "Do I have to add packages for every subcontractor to the project?",
  },
];

export const faqSet5 = [
  {
    answer: `Your package provides comprehensive oversight into various aspects of the project, including labor hours, expenditure, Local Content, policies, reports, and subcontractor details. As the head contractor, these target values gradually decrease as the packages are allocated for the project, reflecting the evolving scope and distribution of responsibilities. This enables effective management and tracking of project performance across all key metrics.`,
    id: 1,
    question: "What is my package?",
  },
];

export const faqSet6 = [
  {
    answer: `A package report is a tool used by both the contractor and subcontractor to report progress and performance against their allocated package of works for the project. It allows for the documentation of key metrics, such as labor hours, expenditure, completion status, and any other relevant information pertaining to the specific scope of work assigned to the subcontractor. By regularly updating and reviewing package reports, stakeholders can ensure transparency, accountability, and effective management of project activities.`,
    id: 1,
    question: "What is a package report?",
  },
  {
    answer: `To submit a package report, access the <b>Package Report</b> tab on the platform's side menu and select the corresponding month. Ensure that all relevant employees are toggled on for inclusion in the report. You can then input labor hours manually or utilise the CSV import function for efficiency. Additionally, provide spend details, including invoices and supplier information like ABNs. Once all necessary information is entered, submit the report. After submission, you have the option to download the report in both PDF and CSV formats for record-keeping and further analysis. This streamlined process ensures accurate reporting and facilitates effective project management.`,
    id: 2,
    question: "How do I submit a package report?",
  },
  {
    answer: `If an employee is not appearing in the Labour Hours Reporting screen, you'll need to ensure they are toggled on in the 'Employees' tab. Simply navigate to the side menu, click on the 'Employees' tab, and toggle the employee's profile to the 'on' position. This action will make them visible and accessible for labor hours reporting. Once toggled on, you can proceed with entering their labor hours as required.`,
    id: 3,
    question:
      "My employee is not registered in the Labour Hours Reporting screen",
  },
  {
    answer: `Yes, for a large number of employees, utilising a CSV import is a more efficient method of recording their monthly labor hours. This feature allows you to upload a CSV file containing the necessary labor hour data for multiple employees simultaneously, saving time and reducing manual data entry.`,
    id: 4,
    question:
      "I have a large number of employees. Is there a more efficient method of recording their monthly labour hours?",
  },
  {
    answer: `The labor hours are directly linked to individual commitments for the project through the process of adding employees and toggling them on. When adding an employee to the project, their social factors are already included. As labor hours are reported, they are attributed to the specific employees who have been added to the project and have their social factors accounted for. This ensures that labor hour reporting is directly tied to the commitments and attributes of individual employees on the project.`,
    id: 5,
    question:
      "How does the labour hours form link to my individual commitments for the project?",
  },
  {
    answer: `If you're unable to edit your employees' training and study hours for the month, it's likely because both a training contract number and a unique student ID are required for each employee. Ensure that these details are accurately entered for each employee in order to enable editing of their training and study hours. Once the necessary information is provided, you should be able to make adjustments to their records accordingly.`,
    id: 6,
    question:
      "Why can't I edit my employees' training and study hours for the month?",
  },
  {
    answer: `You can record various types of spend in SocialPro, including expenditures related to Social Enterprise, Australian Disability Enterprise (ADE), and Aboriginal Owned Business.`,
    id: 7,
    question: "What type of spend can I record?",
  },
  {
    answer: `Reporting on the Local Content involves manually adding the reported LIDP data through a designated form within the platform. This allows you to input the relevant information related to local industry development initiatives and commitments. Additionally, you can compare the actual reported data with the expected targets listed in the targets list, providing valuable insights into performance and progress towards meeting LIDP objectives.`,
    id: 8,
    question: "How do I report on Local Content?",
  },
  {
    answer: `Upon submission, the package report can be downloaded in both PDF and CSV formats. This dual format availability ensures flexibility and convenience in accessing the report data.`,
    id: 9,
    question: "What happens once I submit the report?",
  },
  {
    answer: `Editing a submitted report is typically controlled by the contractor through the 'Subcontractor - reports' portal. Once a report has been submitted, any necessary edits or modifications would need to be authorized and implemented by the contractor overseeing the project. Access to editing functionalities may vary depending on the contractor's policies and procedures regarding report management and revision.`,
    id: 10,
    question: "Can I edit a report once it's been submitted?",
  },
];

export const faqSet7 = [
  {
    answer: `A project report is a comprehensive document that consolidates all submitted package reports across the project. This report can be generated at any stage of the month and serves as a tool for contractors to track the submission status of individual package reports. By providing an overview of who has and hasn't submitted their reports, contractors can efficiently manage and monitor reporting compliance. Additionally, the project report enables contractors to send reminders to those who have not yet submitted their reports, facilitating timely completion and ensuring project transparency and accountability.`,
    id: 1,
    question: "What is a project report?",
  },
  {
    answer: `Submitting a project report is a straightforward process facilitated through the dedicated side tab labeled 'Project Reports.' Simply navigate to this tab within the platform, where you'll find the option to initiate the submission process. From there, follow the prompts to compile and submit the project report, ensuring all necessary information and data from submitted package reports are accurately consolidated. This streamlined approach enables contractors to efficiently manage project reporting tasks and maintain oversight of reporting compliance across the project.`,
    id: 2,
    question: "How do I submit a project report?",
  },
  {
    answer: `While it's generally considered good practice to wait until every subcontractor has submitted their report before finalising the project report, contractors have the discretion to submit it at any stage. Waiting ensures that the project report accurately reflects the status of all subcontractor submissions, providing a comprehensive overview of reporting compliance.`,
    id: 3,
    question:
      "Do I need to wait until every subcontractor has submitted their report?",
  },
  {
    answer: `Once the report is submitted, the system generates both CSV and PDF outputs. These outputs contain consolidated data from the submitted package reports and can be utilised for stakeholder reporting purposes, including ICN VMC reporting.`,
    id: 4,
    question: "What happens once I submit the report?",
  },
  {
    answer: `Yes, the contractor retains full capabilities to edit a project report even after it has been submitted. This flexibility allows for adjustments or revisions to be made as needed, ensuring the accuracy and completeness of the report. By maintaining the ability to edit submitted reports, contractors can address any discrepancies or updates promptly, enhancing the reliability and relevance of project reporting for stakeholders.`,
    id: 5,
    question: "Can I edit a report once it's been submitted?",
  },
];

export const faqSet8 = [
  {
    answer: `The Smart Directory provides a comprehensive directory encompassing all Commitment Categories within the industry. This feature enables users to access information on companies based on their specific targets or requirements for the project.<br><br>
The Commitment Categories included in the Smart Directory cover a wide range of criteria, such as <b>Female Apprentices and Trainees</b>, <b>MPSG Hours Requirement</b>, <b>Victorian Priority Workforce</b>, <b>Indigenous Participation</b>, <b>Local Jobs First</b>, <b>Female Participation</b>, <b>Social Disability Enterprise Spend</b>, and <b>Indigenous Business Spend</b>. By leveraging the Smart Directory, users can efficiently identify and select companies that align with their targets and help achieve shortfalls.`,
    id: 1,
    question: "What is the Smart Directory?",
  },
  {
    answer: `
  <b>The Smart Directory</b> offers a wealth of information to users, including basic introductions, contact details, service locations, and product/service offerings of listed companies. Additionally, users can access details regarding commitment category types, providing insights into each company's specific commitments and focus areas. Furthermore, <b>the Smart Directory</b> provides information on Enterprise Bargaining Agreements (EBAs) and website links for further exploration. This comprehensive array of information empowers users to make informed decisions when selecting companies for their projects, ensuring alignment with project requirements and objectives.
`,
    id: 2,
    question: "What information can I get from the Smart Directory?",
  },
  {
    answer: `The Smart Directory is maintained with a high level of accuracy and is updated regularly through an automated scraping tool. This tool systematically retrieves and updates information from various sources to ensure the directory remains current and relevant. By leveraging automated processes, the Smart Directory can capture changes and updates swiftly, enhancing its accuracy and reliability.`,
    id: 3,
    question:
      "How accurate is the Smart Directory and how regularly is it updated?",
  },
  {
    answer: `

  <b>Target Analytics</b> are tabs conveniently located in the side menu of the platform, offering users comprehensive insights into project performance across various metrics. These tabs provide detailed analytics related to Labour Hours, Spend, Local Content, and policies. By accessing these tabs, users can delve into specific areas of interest, analyse data, and monitor project progress effectively. This feature enables users to make informed decisions, track performance against targets, and optimise project outcomes efficiently.

`,
    id: 4,
    question: "What are target analytics?",
  },
  {
    answer: `
  <b>To gain a comprehensive understanding of your performance against commitments</b>, you can utilise both the dashboard and target analytics features within the platform. The dashboard offers a high-level overview of performance metrics, providing a quick snapshot of progress against commitments. For more detailed insights and analysis, you can delve into the target analytics section. <b>By leveraging both the dashboard and target analytics</b>, you can effectively monitor performance, identify areas for improvement, and track progress against commitments with precision.`,
    id: 5,
    question:
      "Where can I go to get an understanding for how I'm performing against my commitments?",
  },
];
