import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";
import { Fragment } from "react";

export const ForecastTableRowSkeleton = () => {
  const skeletonCell = (
    <TableCell align="center" sx={{ paddingBottom: "7px", paddingTop: "7px" }}>
      <Skeleton animation="wave" />
    </TableCell>
  );

  return (
    <TableRow>
      <TableCell
        align="center"
        sx={{ paddingBottom: "7px", paddingTop: "7px" }}
      >
        <Stack direction="row" justifyContent="center">
          <Skeleton
            animation="wave"
            height={40}
            width={50}
            sx={{ borderRadius: "16px" }}
          />
        </Stack>
      </TableCell>
      {Array.from({ length: 6 }, (_, index) => (
        <Fragment key={index}>{skeletonCell}</Fragment>
      ))}
    </TableRow>
  );
};
