import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack, Tooltip, Zoom } from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";

interface ActionCellProps extends CustomCellRendererProps {
  loading: boolean;
  handleEdit: (name: string) => void;
  confirmDelete: (lc: PackageLocalContentLineItem) => void;
}

export const ActionCell = ({
  confirmDelete,
  data,
  handleEdit,
  loading,
}: ActionCellProps) => {
  return (
    <Stack direction="row" justifyContent={"flex-end"}>
      <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
        <IconButton
          color="primary"
          size="medium"
          disabled={loading}
          onClick={() => handleEdit(data)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
        <IconButton
          color="error"
          size="medium"
          disabled={loading || !!data.reportId}
          onClick={() => confirmDelete(data)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
