import { Skeleton, TableCell, TableRow } from "@mui/material";

export const EmployeeHoursSubTableSkeleton = () => {
  return (
    <TableRow>
      {Array.from({ length: 4 }).map((_, index) => (
        <TableCell
          key={index}
          align="center"
          width={"25%"}
          sx={{ paddingBottom: "10px", paddingTop: "10px" }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );
};
