"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTitleCase = exports.formatProfileName = exports.formattedUserName = exports.cleanString = exports.getZipTitle = exports.getFileName = exports.getDocumentName = exports.validateEmail = exports.convertObjectToPostgresSafeObject = exports.reversePostgresSafe = exports.makePostgresSafe = exports.cleanTabsFromString = void 0;
const cleanTabsFromString = (input, removeSpaces) => {
    if (removeSpaces) {
        return input.replace(/[\s\t]+/g, "");
    }
    else {
        return input.replace(/[\t]+/g, "");
    }
};
exports.cleanTabsFromString = cleanTabsFromString;
const makePostgresSafe = (x) => {
    return Object.entries(x).reduce((acc, [key, value]) => {
        if (typeof value === "string") {
            acc[key] = value.replace(/'/g, "''").trim();
        }
        return { ...acc };
    }, x);
};
exports.makePostgresSafe = makePostgresSafe;
const reversePostgresSafe = (x) => {
    return Object.entries(x).reduce((acc, [key, value]) => {
        if (typeof value === "string") {
            acc[key] = value.replace(/''/g, "'").trim();
        }
        return { ...acc };
    }, x);
};
exports.reversePostgresSafe = reversePostgresSafe;
const isObject = (d) => {
    return typeof d === "object" && d !== null;
};
const convertObjectToPostgresSafeObject = (requestData) => {
    if (requestData.data && Array.isArray(requestData.data)) {
        return requestData.data.map((d) => isObject(d) ? (0, exports.makePostgresSafe)(d) : d);
    }
    else {
        return (0, exports.makePostgresSafe)(requestData);
    }
};
exports.convertObjectToPostgresSafeObject = convertObjectToPostgresSafeObject;
const date_1 = require("./date");
const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};
exports.validateEmail = validateEmail;
const getDocumentName = (prefix, originalName) => `${prefix}.${originalName.split(".").pop()}`;
exports.getDocumentName = getDocumentName;
const getFileName = (originalName) => `${originalName.split("/").pop()}`;
exports.getFileName = getFileName;
const getZipTitle = (prm) => {
    return `SocialPro_Project_Report-${(0, date_1.formatChartDate)(prm).replace(" ", "_")}`;
};
exports.getZipTitle = getZipTitle;
const cleanString = (input) => {
    return input.replace(/ /g, "").trim().toUpperCase();
};
exports.cleanString = cleanString;
const formattedUserName = (userId) => {
    let formattedUserId = userId.replace("BMD-SAML-IDP_", "");
    formattedUserId = formattedUserId.replace(/@/g, "");
    formattedUserId = formattedUserId.replace(/\./g, "");
    formattedUserId = formattedUserId.substring(0, 36);
    return formattedUserId.toLowerCase();
};
exports.formattedUserName = formattedUserName;
const formatProfileName = (userAttributes) => {
    const givenName = userAttributes.given_name || null;
    const surname = userAttributes.family_name || null;
    if (!givenName && !surname)
        return "";
    return [givenName, surname].join(" ").trim();
};
exports.formatProfileName = formatProfileName;
const toTitleCase = (str) => {
    // Check if the string is already in uppercase
    if (str === str.toUpperCase()) {
        // Convert the entire string to lowercase and apply title case transformation
        return str.toLowerCase().replace(/\b\w/g, (char) => {
            return char.toUpperCase();
        });
    }
    else {
        // Apply title case transformation directly
        return str.replace(/\b\w/g, (char) => {
            return char.toUpperCase();
        });
    }
};
exports.toTitleCase = toTitleCase;
