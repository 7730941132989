import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  centerCellStyles,
  defaultCellConfig,
  ProgressCell,
  rightCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import { useState } from "react";
import {
  LabourHoursAnalyticsModelLineItem,
  LabourHourTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AnalyticsHoursLevel1TableRowSkeleton } from "./AnalyticsHoursLevel1TableRowSkeleton";

interface AnalyticsHoursLevel1TableProps {
  loading: boolean;
  labourHourAnalytics?: LabourHoursAnalyticsModelLineItem;
  searchTerm: string;
  handleAction: (target: LabourHourTargetAnalyticsLineItem) => void;
}

export const AnalyticsHoursLevel1Table = ({
  handleAction,
  labourHourAnalytics,
  loading,
  searchTerm,
}: AnalyticsHoursLevel1TableProps) => {
  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      icon: <ViewProjectIcon />,
      singleAction: true,
      title: "View",
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      field: "targetType",
      flex: 2.5,
      headerName: "Target Type",
      onCellClicked: (params) => handleAction(params.data),
      valueGetter: (params: any) => {
        const description = params.data.targetDescription; // Default to empty if null/undefined
        const type = params.data.targetType
          ? labourHourCommitmentTypeToString(params.data.targetType)
          : "";
        return description || type; // Prefer description, fallback to type
      },
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "targetValue",
      flex: 1.5,
      headerClass: "right-table-header",
      headerName: "Target (Hours)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "totalReportedValue",
      flex: 1.5,
      headerClass: "right-table-header",
      headerName: "Actual (Hours)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "monthReportedValue",
      flex: 1.5,
      headerClass: "right-table-header",
      headerName: "This Month",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...defaultCellConfig,
      cellStyle: rightCellStyles,
      field: "actual",
      flex: 1.5,
      headerClass: "right-table-header",
      headerName: "% of Total Hours",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}%`,
    },
    {
      ...defaultCellConfig,
      cellRenderer: ProgressCell,
      cellStyle: rightCellStyles,
      field: "progress",
      flex: 2,
      headerClass: "right-table-header",
      headerName: "Progress to Target",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      cellStyle: centerCellStyles,
      field: "",
      headerClass: "centered-table-header",
      headerName: "",
    },
  ]);

  const targetInfo = labourHourAnalytics?.targets || [];
  targetInfo.sort((a, b) => {
    const aType =
      a.targetDescription || labourHourCommitmentTypeToString(a.targetType);
    const bType =
      b.targetDescription || labourHourCommitmentTypeToString(b.targetType);
    return aType.localeCompare(bType);
  });

  return loading || !targetInfo ? (
    <TableSkeleton
      tableHead={generateTableHead(colDefs)}
      noFullTableBorder
      rows={() => <AnalyticsHoursLevel1TableRowSkeleton singleAction />}
    />
  ) : (
    <Table<LabourHourTargetAnalyticsLineItem>
      columnDefs={colDefs}
      loading={loading}
      data={targetInfo}
      context={{ cellActions }}
      searchValue={searchTerm}
      searchKeys={["targetType", "targetDescription"]}
      noFullTableBorder
    />
  );
};
