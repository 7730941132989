import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { DropdownIcon } from "assets/constants";
import { useCallback, useRef, useState, FocusEvent } from "react";
import {
  createDefaultLabourHour,
  LabourHourLineItem,
} from "social-pro-common/interfaces/labourHour";
import { PolicySubmissionLineItem } from "social-pro-common/interfaces/policySubmission";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import {
  policyTypeToString,
  ProjectPolicyLineItem,
} from "social-pro-common/interfaces/projectPolicy";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { getDocumentName } from "social-pro-common/utils/string";

interface ActionCellProps extends CustomCellRendererProps {
  userProfile: ProfileLineItem;
  validateEnabled?: (data: any) => boolean;
}

export const centerCellStyles = {
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
};

export const defaultCellStyles = {
  alignItems: "left",
  cursor: "pointer",
  display: "flex",
  justifyContent: "left",
};

export const rightCellStyles = {
  alignItems: "right",
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "right",
};

export const defaultCellConfig: ColDef = {
  cellStyle: defaultCellStyles,
  flex: 1,
  resizable: false,
  wrapHeaderText: true,
};

export interface CellAction {
  title: string;
  icon: React.ReactNode;
  action: (data: any) => Promise<void>;
  disabled?: boolean;
  adminOnly?: boolean;
  singleAction?: boolean;
  validateEnabled?: (data: any) => boolean;
}

export const ActionCell = ({
  context: { cellActions },
  data,
}: ActionCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (data.isTotalRow) {
    return <> </>;
  }

  if (cellActions?.length === 1 && cellActions[0]?.singleAction) {
    const action = cellActions[0];
    return (
      <Button
        sx={{ ":hover": { background: "none" }, color: "#155EEF" }}
        disabled={action.disabled}
        disableRipple
        variant="text"
        onClick={() => action.action(data)}
      >
        {action.title}
      </Button>
    );
  }

  return cellActions && cellActions.length ? (
    <>
      <Box
        sx={{
          alignItem: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <IconButton
          disableRipple
          onClick={handleClick}
          aria-label="more"
          size="medium"
          sx={{
            minWidth: "auto",
          }}
        >
          <DropdownIcon />
        </IconButton>
      </Box>
      <Menu
        className="actionlist"
        disableScrollLock
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": { p: 0 },
          "& .MuiMenu-paper ul li": {
            m: 0,
            p: 0,
          },
          "& .MuiMenu-paper ul li:focus": {
            backgroundColor: "transparent !important",
          },
          "& .MuiMenu-paper ul li:hover": {
            backgroundColor: "transparent !important",
            borderRadius: 0,
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              border: "1px solid #E9EAEB",
              borderRadius: "10px",
              boxShadow: "0px 1px 2px 0px #0A0D120D",
              mt: 0.5,
              overflow: "visible",
              padding: "12px",
            },
          },
        }}
      >
        {cellActions
          .filter((action: CellAction) => {
            const activeValue = action.validateEnabled
              ? action.validateEnabled(data)
              : true;
            if (!activeValue) {
              return false;
            }
            return true;
          })
          .map((action: CellAction, index: number, arr: CellAction[]) => {
            return [
              <MenuItem
                key={`${index}-menu-item`}
                disableRipple
                onClick={() => {
                  action.action(data);
                  setAnchorEl(null);
                }}
                sx={{ p: 0 }}
                disabled={action.disabled}
              >
                <Box sx={{ alignItems: "center", display: "flex", gap: "8px" }}>
                  {action.icon}
                  <Typography
                    color="#181D27"
                    sx={{
                      fontFamily: "'Inter'",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    {action.title}
                  </Typography>
                </Box>
              </MenuItem>,
              arr.length > 1 && index < arr.length - 1 ? (
                <Divider
                  key={`${index}-divider`}
                  orientation="horizontal"
                  sx={{ backgroundColor: "#D5D7DA" }}
                />
              ) : null,
            ].filter(Boolean);
          })}
      </Menu>
    </>
  ) : null;
};

export const ActionCellUriToggle = (props: ActionCellProps) => {
  const { context, data } = props;

  function handleClick() {
    context.handleAction(data);
  }

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      size="small"
      disabled={context.loading || !data.uri}
    />
  );
};

export const ProgressCell = (props: CustomCellRendererProps) => {
  const progress = props.data.progress ?? props.data.estimatedProgress ?? 0;
  const clampedProgress = Math.min(100, Math.max(0, progress));
  return (
    <>
      <Stack
        direction={"row"}
        gap={"12px"}
        alignItems={"center"}
        width={"100%"}
      >
        <Box
          sx={{
            bgcolor: "#E9EAEB",
            borderRadius: "9999px",
            flex: 1,
            height: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              bgcolor: "#004eeb",
              borderRadius: "9999px",
              height: "8px",
              width: `${clampedProgress}%`,
            }}
          />
        </Box>
        <Typography
          sx={{
            color: "#181D27",
            fontFamily: '"Inter", sans-serif',
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
          }}
        >
          {formatDecimalPlaces(clampedProgress, 0)}%
        </Typography>
      </Stack>
    </>
  );
};

export const SubmissionCell = (props: CustomCellRendererProps) => {
  const { context, data } = props;

  const projectPolicy = data as ProjectPolicyLineItem;
  const policySubmission = context.policySubmissions.find(
    (pp: PolicySubmissionLineItem) => pp.projectPolicyId === projectPolicy.id,
  );

  if (!policySubmission) {
    return (
      <Box alignContent={"center"} display={"flex"} justifyContent={"center"}>
        <DangerousOutlinedIcon
          color="error"
          sx={{ margin: "auto", padding: "auto" }}
        />
      </Box>
    );
  }
  return data.created.toLocaleDateString("en-GB");
};

interface DownloadCellProps extends CustomCellRendererProps {
  text: string;
}

export const UploadDownloadCell = (props: DownloadCellProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const { context, data } = props;

  const projectPolicy = data as ProjectPolicyLineItem;
  const policySubmission = context.policySubmissions.find(
    (pp: PolicySubmissionLineItem) => pp.projectPolicyId === projectPolicy.id,
  );
  const selectedTargetId = context.selectedTargetId;
  const isDownloading = context.isDownloading;
  const isUploading = context.isUploading;
  const uploadProgress = context.uploadProgress;

  function handleDownload() {
    context.onDownload(
      policySubmission.uri,
      getDocumentName(
        projectPolicy.policyDescription ||
          policyTypeToString(projectPolicy.policyType),
        policySubmission.uri,
      ),
    );
  }

  function onUpload(e: React.ChangeEvent<HTMLInputElement>) {
    context.onUpload(e, data);
  }

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <>
      <label htmlFor="containedUploadButtonFile">
        {policySubmission ? (
          isDownloading &&
          projectPolicy.id === selectedTargetId &&
          policySubmission?.uri ? (
            <CircularProgress />
          ) : policySubmission?.uri ? (
            <Tooltip
              placement="top"
              title="Download"
              TransitionComponent={Zoom}
            >
              <IconButton
                size="medium"
                disabled={isUploading || isDownloading}
                onClick={handleDownload}
              >
                <CloudDownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : null
        ) : null}
      </label>
      {isUploading && projectPolicy.id === selectedTargetId ? (
        <CircularProgressWithLabel value={uploadProgress} />
      ) : (
        <label htmlFor="containedUploadButtonFile">
          <input
            ref={fileInput}
            hidden
            type="file"
            style={{
              cursor: "pointer",
              opacity: 0,
              padding: 0.5,
              position: "fixed",
              zIndex: 1,
            }}
            onChange={onUpload}
          />
          <Tooltip
            placement="top"
            title={policySubmission ? "Reupload" : "Upload"}
            TransitionComponent={Zoom}
          >
            <IconButton
              color="primary"
              size="medium"
              disabled={isUploading || isDownloading}
              onClick={() => {
                onFileInput();
              }}
            >
              <CloudUploadIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </label>
      )}
    </>
  );
};

export const AvatarNameCellRenderer = (props: CustomCellRendererProps) => {
  const { contractorLogo, contractorName } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Avatar
        src={contractorLogo}
        alt={contractorName}
        style={{
          backgroundColor: "#ffffff",
          height: 24,
          marginRight: "8px",
          width: 24,
        }}
        sizes="small"
      />
      <span>{contractorName}</span>
    </Box>
  );
};

export const HeaderSubHeaderCell = (props: CustomCellRendererProps) => {
  const { header, subheader } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Stack direction="column">
        <Typography variant="h6">{header}</Typography>
        <Typography variant="body2">{subheader}</Typography>
      </Stack>
    </Box>
  );
};

interface LabourHourInputCustomCellRendererProps {
  context: any;
  data: any;
  valueKey: "amountOfHoursWorked" | "amountOfHoursStudied";
}

export const LabourHourInputCell = (
  props: LabourHourInputCustomCellRendererProps,
) => {
  const { context, data, valueKey } = props;

  const labourHours = context.labourHours as LabourHourLineItem[];
  const hourForEmployee = labourHours.find((lh) => lh.employeeId === data.id);

  const [value, setValue] = useState(
    hourForEmployee ? formatDecimalPlaces(hourForEmployee[valueKey], 2) : "0",
  );

  const handleSave = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      const newValue = parseFloat(e.target.value || "0");

      const dataToSave = hourForEmployee
        ? { ...hourForEmployee, [valueKey]: newValue }
        : createDefaultLabourHour(
            context.project.id,
            context.reportId,
            context.contractorPackage.id,
            context.organisation.id,
            data,
          );

      dataToSave[valueKey] = newValue;
      await context.handleUpdateValue(dataToSave);
    },
    [hourForEmployee, context, data, valueKey],
  );
  // TODO: fix, it doesn't disable the field correctly
  const isSubmitting = context.submittingRowId === data.id;

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        variant="standard"
        fullWidth
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleSave}
        disabled={isSubmitting || context.disabled}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: "white",
            borderColor: "#D5D7DA",
            fontSize: "14px",
            lineHeight: "20px",
            opacity: "100%",
            outline: "none",
          },
        }}
        sx={{
          "& .MuiInputBase-input": {
            alignItems: "center",
            borderRadius: "0px !important",
            color: "#717680",
            fontFamily: '"Inter", sans-serif',
            fontSize: "14px",
            lineHeight: "20px",
            maxHeight: "52px",
            ml: 1,
            padding: "0px",
          },
          bgcolor: "white",
          border: "1px solid #D5D7DA",
          borderRadius: "5px",
          boxShadow: "0px 1px 2px 0px #0A0D120D",
          padding: "10px 14px 10px 12px",
        }}
      />
    </Box>
  );
};
