import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackageDetails } from "@hooks/crud/contractorPackages/useContractorPackageDetails";
import { PackageDetailsTabOption } from "@stories/molecules/PackageDetailsTab/PackageDetailsTab";
import { Header } from "@stories/organisms/Header/Header";
import { PackageDetailsComments } from "@stories/organisms/PackageDetails/PackageDetailsComments";
import { PackageDetailsEnviroSustainability } from "@stories/organisms/PackageDetails/PackageDetailsEnviroSustainability";
import { PackageDetailsForecast } from "@stories/organisms/PackageDetails/PackageDetailsForecast";
import { PackageDetailsHours } from "@stories/organisms/PackageDetails/PackageDetailsHours";
import { PackageDetailsHoursTarget } from "@stories/organisms/PackageDetails/PackageDetailsHoursTarget";
import { PackageDetailsLocalContent } from "@stories/organisms/PackageDetails/PackageDetailsLocalContent";
import { PackageDetailsPolicy } from "@stories/organisms/PackageDetails/PackageDetailsPolicy";
import { PackageReports } from "@stories/organisms/PackageDetails/PackageDetailsReports";
import { PackageDetailsSpend } from "@stories/organisms/PackageDetails/PackageDetailsSpend";
import { PackageDetailsSpendTarget } from "@stories/organisms/PackageDetails/PackageDetailsSpendTarget";
import { SummaryDetails } from "@stories/organisms/PackageDetails/PackageDetailsSummary";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  LabourHourTargetAnalyticsLineItem,
  SocialSpendTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";

export const PackageDetails = () => {
  const { packageId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const {
    contractorOrganisation,
    contractorPackageDetails,
    isContractorPackageLoading,
  } = useContractorPackageDetails(packageId);

  const [labourHourTargetId, setLabourHourTargetId] = useState<string>();

  const onLabourHourTargetChange = (
    target?: LabourHourTargetAnalyticsLineItem,
  ) => {
    setLabourHourTargetId(target?.id);
  };

  const [socialSpendTargetId, setSocialSpendTargetId] = useState<string>();

  const onSocialSpendTargetChange = (
    targetId?: SocialSpendTargetAnalyticsLineItem,
  ) => {
    setSocialSpendTargetId(targetId?.id);
  };

  const handleOnBack = () => {
    if (selectedProject && contractorPackage) {
      navigate(RouteHelper.packages(selectedProject.id, contractorPackage.id));
    }
  };

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = async (tabIndex: number) => {
    setLabourHourTargetId(undefined);
    setSocialSpendTargetId(undefined);
    setTabIndex(tabIndex);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      const tabIndex = (() => {
        switch (tab) {
          default:
          case PackageDetailsTabOption.Hours:
            return 0;
          case PackageDetailsTabOption.Spend:
            return 1;

          case PackageDetailsTabOption.LocalContent:
            return 2;

          case PackageDetailsTabOption.Policy:
            return 3;

          case PackageDetailsTabOption.Enviro:
            return 4;

          case PackageDetailsTabOption.Forecast:
            return 5;

          case PackageDetailsTabOption.Reports:
            return 6;

          case PackageDetailsTabOption.Comments:
            return 7;
          case PackageDetailsTabOption.Details:
            return 8;
        }
      })();
      setTabIndex(tabIndex);
    }
    setSearchParams();
  }, []);

  const loading =
    isAuthProfileLoading || isProjectLoading || isContractorPackageLoading;

  const selectedPackageStep = (() => {
    switch (tabIndex) {
      case 0:
        return !labourHourTargetId ? (
          <PackageDetailsHours
            isLoading={loading}
            subPackage={contractorPackageDetails}
            onLabourHourTargetChange={onLabourHourTargetChange}
          />
        ) : (
          <PackageDetailsHoursTarget
            isLoading={loading}
            subPackage={contractorPackageDetails}
            targetId={labourHourTargetId}
            handleOnBack={() => {
              setLabourHourTargetId(undefined);
            }}
          />
        );

      case 1:
        return !socialSpendTargetId ? (
          <PackageDetailsSpend
            isLoading={loading}
            subPackage={contractorPackageDetails}
            onSocialSpendTargetChange={onSocialSpendTargetChange}
          />
        ) : (
          <PackageDetailsSpendTarget
            isLoading={loading}
            subPackage={contractorPackageDetails}
            targetId={socialSpendTargetId}
            handleOnBack={() => {
              setSocialSpendTargetId(undefined);
            }}
          />
        );
      case 2:
        return (
          <PackageDetailsLocalContent
            loading={loading}
            subPackage={contractorPackageDetails}
            localContents={getSortedLocalContent(
              contractorPackageDetails?.localContent,
            )}
          />
        );
      case 3:
        return (
          <PackageDetailsPolicy
            isLoading={loading}
            subPackage={contractorPackageDetails}
          />
        );
      case 4:
        return (
          <PackageDetailsEnviroSustainability
            loading={loading}
            project={selectedProject}
            subPackage={contractorPackageDetails}
          />
        );
      case 5:
        return (
          <PackageDetailsForecast
            isLoading={loading}
            subPackage={contractorPackageDetails}
          />
        );
      case 6:
        return (
          <PackageReports
            isLoading={loading}
            contractorPackage={contractorPackage}
            subPackage={contractorPackageDetails}
          />
        );
      case 7:
        return (
          <PackageDetailsComments
            isLoading={loading}
            project={selectedProject}
            organisation={contractorOrganisation}
            subPackage={contractorPackageDetails}
          />
        );
      case 8:
        return (
          <SummaryDetails
            isLoading={loading}
            project={selectedProject}
            contractorOrganisation={contractorOrganisation}
            subPackage={contractorPackageDetails}
          />
        );
    }
  })();

  const tabs = [
    {
      label: "Labour Hours",
    },
    {
      label: "Social Spend",
    },
    {
      label: "Local Content",
    },
    {
      label: "Policy",
    },
    {
      label: "Enviro & Sustainability",
    },
    {
      label: "Forecast",
    },
    {
      label: "Reports",
    },
    {
      label: "Comments",
    },
    {
      label: "Details",
    },
  ];
  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={userOrganisation?.organisationName || "Organisation"}
        mainTitle="Package Details"
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        handleBack={handleOnBack}
      />

      {selectedPackageStep}
    </PageContainer>
  );
};
