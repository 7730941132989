"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProjectComment = exports.decodeProjectComment = void 0;
const projectComment_1 = require("../../interfaces/projectComment");
const string_1 = require("../../utils/string");
const decodeProjectComment = (comment) => {
    return {
        comment: comment.comment,
        commentType: decodeProjectCommentType(comment.comment_type),
        created: new Date(comment.created),
        id: comment.project_comment_id,
        projectId: comment.project_id,
        reportId: comment.report_id,
    };
};
exports.decodeProjectComment = decodeProjectComment;
const encodeProjectComment = (comment) => {
    const safeComment = (0, string_1.makePostgresSafe)(comment);
    return {
        comment: safeComment.comment,
        comment_type: encodeProjectCommentType(safeComment.commentType),
        created: new Date(safeComment.created),
        project_comment_id: safeComment.id,
        project_id: safeComment.projectId,
        report_id: safeComment.reportId,
    };
};
exports.encodeProjectComment = encodeProjectComment;
const decodeProjectCommentType = (commentType) => {
    switch (commentType) {
        case "project-comment-type_project-summary":
            return projectComment_1.ProjectCommentType.ProjectSummary;
        default:
            throw Error("Project type not found");
    }
};
const encodeProjectCommentType = (commentType) => {
    switch (commentType) {
        case projectComment_1.ProjectCommentType.ProjectSummary:
            return "project-comment-type_project-summary";
        default:
            throw Error("Project type not found");
    }
};
