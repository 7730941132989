import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useSocialProAdminOrganisation } from "@hooks/crud/socialProAdmin/useSocialProAdminOrganisation";
import { useSocialProAdminProfile } from "@hooks/crud/socialProAdmin/useSocialProAdminProfile";
import { Grid } from "@mui/material";
import { ProfileTable } from "@stories/molecules/ProfileTable/ProfileTable";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { OrganisationTable } from "@stories/organisms/Tables/OrganisationTable";
import { useState } from "react";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";

export const SocialProAdmin = (): JSX.Element => {
  const { userOrganisation } = useProfileContext();
  const { isAuthLoading, userProfile } = useAuthContext();

  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedOrganisation, setSelectedOrganisation] =
    useState<OrganisationLineItem>();

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const {
    isSocialProAdminLoading,
    joinOrganisation,
    organisationCount,
    organisations,
    updateMaxProjectCount,
    updateOrganisationContractor,
  } = useSocialProAdminOrganisation(searchTerm, page);

  const {
    changePassword,
    createProfile,
    deleteProfile,
    isSocialProAdminLoadingProfile,
    profiles,
    updateProfile,
  } = useSocialProAdminProfile(selectedOrganisation?.id);

  const handleClickOrganisation = (organisation: OrganisationLineItem) => {
    setSelectedOrganisation(organisation);
  };

  const handleBack = () => {
    setSelectedOrganisation(undefined);
  };

  const loading =
    isSocialProAdminLoading || isSocialProAdminLoadingProfile || isAuthLoading;
  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={userOrganisation?.organisationName || "org"}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        mainTitle={"Social Pro Admin"}
        handleBack={handleBack}
      />
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0 40px",
          overflow: "hidden",
          padding: "0px 0 !important",
        }}
      >
        {selectedOrganisation && userProfile ? (
          <ProfileTable
            loading={false}
            profiles={profiles}
            handleChangePassword={async (
              profile: ProfileLineItem,
              newPassword: string,
            ): Promise<void> => {
              await changePassword(profile, newPassword);
            }}
            handleDeleteUser={async (
              profile: ProfileLineItem,
            ): Promise<void> => {
              await deleteProfile(profile);
            }}
            handleCreateUser={async (
              profile: ProfileLineItem,
            ): Promise<void> => {
              await createProfile(profile);
            }}
            handleUpdateUser={async (
              profile: ProfileLineItem,
            ): Promise<void> => {
              await updateProfile(profile);
            }}
            organisation={selectedOrganisation}
            userProfile={userProfile}
          />
        ) : (
          <OrganisationTable
            loading={loading}
            userOrganisation={userOrganisation}
            organisations={organisations}
            organisationCount={organisationCount}
            setPage={setPage}
            handleJoinOrganisation={joinOrganisation}
            handleUpdateOrganisationContractor={updateOrganisationContractor}
            handleUpdateMaxProjectCount={updateMaxProjectCount}
            handleClickOrganisation={handleClickOrganisation}
          />
        )}
      </Grid>
    </PageContainer>
  );
};
