import { useProfileContext } from "@hooks/context/useProfileContext";
import { useIntegration } from "@hooks/crud/integration/useIntegration";
import { Grid } from "@mui/material";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import {
  IntegrationInfoLineItem,
  IntegrationType,
} from "social-pro-common/interfaces/integration";

import { DamstraImport } from "./DamstraImport/DamstraImport";
import { Hammertech } from "./Hammertech/Hammertech";

export const Integrations = () => {
  const { isAuthProfileLoading, setUserOrganisation, userOrganisation } =
    useProfileContext();

  const { createIntegration, isIntegrationLoading, updateIntegration } =
    useIntegration();

  const handleUpdateIntegration = async (
    integration: IntegrationInfoLineItem,
  ) => {
    if (userOrganisation) {
      const matchingIntegration = userOrganisation?.integrations?.find(
        (i) => i.id == integration.id,
      );
      if (matchingIntegration) {
        await updateIntegration(integration);
        setUserOrganisation({
          ...userOrganisation,
          integrations: userOrganisation.integrations?.map((i) =>
            i.id == integration.id ? integration : i,
          ),
        });
      } else {
        await createIntegration(integration);
        setUserOrganisation({
          ...userOrganisation,
          integrations: [...(userOrganisation.integrations || []), integration],
        });
      }
    }
  };
  const loading = isAuthProfileLoading || isIntegrationLoading;
  return (
    <PageContainer>
      <Header
        loading={loading}
        mainTitle={"Integrations"}
        subTitle={"Manage 3rd party integrations"}
      />
      <Grid
        container
        sx={{
          alignItems: "space-between",
          flex: 1,
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <Grid
          item
          md={4}
          sx={{
            textAlign: "left",
          }}
        >
          <Hammertech
            loading={loading}
            integration={userOrganisation?.integrations?.find(
              (i) => i.integrationType === IntegrationType.HammerTech,
            )}
            handleIntegrationChange={handleUpdateIntegration}
            userOrganisation={userOrganisation}
          />
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            padding: "0px 10px 0px",
            textAlign: "left",
          }}
        >
          <DamstraImport
            loading={loading}
            integration={userOrganisation?.integrations?.find(
              (i) => i.integrationType === IntegrationType.DamstraImport,
            )}
            handleIntegrationChange={handleUpdateIntegration}
            userOrganisation={userOrganisation}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};
