import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  policyTypeToDescription,
  policyTypeToString,
  ProjectPolicyLineItem,
} from "social-pro-common/interfaces/projectPolicy";

const TABLE_HEAD = [
  { id: "policyDescription", label: "Policy Name", width: "40%" },
  { id: "action", label: "Action", width: "20%" },
];

interface ActionCellProps extends CustomCellRendererProps {
  loading: boolean;
  handleDelete: (policy: ProjectPolicyLineItem) => void;
}

const InfoCell = ({ data }: CustomCellRendererProps) => {
  return (
    <Stack direction="row" spacing={2}>
      <Tooltip
        placement="top"
        title={policyTypeToDescription(data.policyType)}
        TransitionComponent={Zoom}
      >
        <InfoIcon color={"info"} />
      </Tooltip>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography>
          {data.policyDescription || policyTypeToString(data.policyType)}
        </Typography>
      </Box>
    </Stack>
  );
};

const ActionCell = ({ data, handleDelete, loading }: ActionCellProps) => {
  return (
    <Stack direction="row" justifyContent={"center"}>
      <Tooltip placement="top" title="Delete" TransitionComponent={Zoom}>
        <IconButton
          color="error"
          size="medium"
          disabled={loading}
          onClick={() => handleDelete(data)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export { ActionCell, InfoCell, TABLE_HEAD };
