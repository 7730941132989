import { Box, Grid, Table, TableBody, Typography } from "@mui/material";
import React from "react";
import { SocialSpendTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import {
  StyledTableContainer,
  TableRowItem,
  SummaryTableSkeleton,
} from "./components";

type SocialSpendAnalyticsSummaryProps = {
  summary?: SocialSpendTargetAnalyticsLineItem;
  loading: boolean;
};

const SocialSpendAnalyticsSummary: React.FC<
  SocialSpendAnalyticsSummaryProps
> = ({ loading, summary }) => {
  if (loading) {
    return (
      <Grid md={12} item>
        <Box pt={2} mb={2}>
          <SummaryTableSkeleton />
        </Box>
      </Grid>
    );
  }

  if (!summary) {
    return (
      <Grid md={12} item>
        <Box pt={2} mb={2}>
          <Typography variant="body1" color="textSecondary">
            No data available.
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid md={12} item>
      <StyledTableContainer>
        <Table>
          <TableBody>
            <TableRowItem
              header1="Target Type"
              value1={
                summary.targetDescription ||
                socialSpendCommitmentTypeToString(summary.targetType)
              }
              header2="Target Value"
              value2={`$${formatDecimalPlaces(summary.targetValue, 0)}`}
            />
            <TableRowItem
              header1="Month Reported"
              value1={`$${formatDecimalPlaces(summary.monthReportedValue, 0)}`}
              header2="Total Reported"
              value2={`$${formatDecimalPlaces(summary.totalReportedValue, 0)}`}
            />
            <TableRowItem
              header1="This month (Hours)"
              value1={`$${formatDecimalPlaces(summary.monthReportedValue, 0)}`}
              header2="Progress To Target"
              value2={`${formatDecimalPlaces(summary.progress, 0)}%`}
            />
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Grid>
  );
};

export default SocialSpendAnalyticsSummary;
