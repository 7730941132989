import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { ReportTableSocial } from "@stories/molecules/ReportTable/ReportTableSocial";
import { ReportTableSustainability } from "@stories/molecules/ReportTable/ReportTableSustainability";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

import { Header } from "../Header/Header";

interface PackageReportProps {
  isLoading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  subPackage?: ContractorPackageLineItem;
}
export const PackageReports = ({
  contractorPackage,
  isLoading,
  subPackage,
}: PackageReportProps) => {
  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = async (index: number) => {
    setTabIndex(index);
    handleSearchChange("");
  };
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loading = isLoading || isProjectLoading;

  const tabs = [
    {
      label: "Social",
    },
    {
      label: "Sustainability",
    },
  ];
  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={4}>
          <Header
            mainTitle="Reports"
            subTitle="Summary of Report Submissions"
            loading={loading}
            tabs={tabs}
            handleTabChange={handleTabChange}
            tabIndex={tabIndex}
            isSubheading
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            headerFontSizes={[18, 14]}
            headerGap={0}
          />
          {tabIndex === 0 ? (
            <ReportTableSocial
              isLoading={loading}
              project={selectedProject}
              contractorPackage={contractorPackage}
              subPackage={subPackage}
              isPrimaryPackage={isPrimaryPackage}
            />
          ) : (
            <ReportTableSustainability
              isLoading={loading}
              project={selectedProject}
              subPackage={subPackage}
              contractorPackage={contractorPackage}
              isPrimaryPackage={isPrimaryPackage}
            />
          )}
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
