"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateABN = void 0;
const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
const validateABN = (item) => {
    const abn = item.replace(",", "").replace(/ /g, "");
    if (abn.length !== 11) {
        return false;
    }
    let total = 0;
    for (let i = 0; i < 11; i++) {
        total += weights[i] * (parseInt(abn[i]) - (i === 0 ? 1 : 0));
    }
    if (total === 0 || total % 89 !== 0) {
        return false;
    }
    else {
        return true;
    }
};
exports.validateABN = validateABN;
