"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultSubcontractorNote = void 0;
const uuid_1 = require("uuid");
const createDefaultSubcontractorNote = (projectId, contractorPackageId, comment) => {
    return {
        comment,
        contractorPackageId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        projectId,
    };
};
exports.createDefaultSubcontractorNote = createDefaultSubcontractorNote;
