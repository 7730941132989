"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeContractorEmployeeProject = exports.decodeContractorEmployeeProject = void 0;
const string_1 = require("../../utils/string");
const decodeContractorEmployeeProject = (contractorEmployeeProject) => {
    return {
        assigned: contractorEmployeeProject.assigned,
        contractorEmployeeId: contractorEmployeeProject.contractor_employee_id,
        contractorPackageId: contractorEmployeeProject.contractor_package_id,
        id: contractorEmployeeProject.contractor_employee_project_id,
        projectId: contractorEmployeeProject.project_id,
    };
};
exports.decodeContractorEmployeeProject = decodeContractorEmployeeProject;
const encodeContractorEmployeeProject = (contractorEmployeeProject) => {
    const safeContractorEmployeeProject = (0, string_1.makePostgresSafe)(contractorEmployeeProject);
    return {
        assigned: safeContractorEmployeeProject.assigned,
        contractor_employee_id: safeContractorEmployeeProject.contractorEmployeeId,
        contractor_employee_project_id: safeContractorEmployeeProject.id,
        contractor_package_id: safeContractorEmployeeProject.contractorPackageId,
        project_id: safeContractorEmployeeProject.projectId,
    };
};
exports.encodeContractorEmployeeProject = encodeContractorEmployeeProject;
