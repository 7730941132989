"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeFileGeneration = void 0;
const decodeFileGeneration = (data) => {
    return {
        contractorPackageId: data.contractor_package_id,
        created: new Date(data.created),
        fileGenerationId: data.file_generation_id,
        progress: data.progress,
        projectId: data.project_id,
        reportId: data.report_id,
        status: data.status,
        url: data.url,
    };
};
exports.decodeFileGeneration = decodeFileGeneration;
