"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeGreenMetricCategory = exports.decodeGreenMetricCategory = void 0;
const string_1 = require("../../utils/string");
const decodeGreenMetricCategory = (greenMetricCategory) => {
    return {
        created: new Date(greenMetricCategory.created),
        greenMetricId: greenMetricCategory.green_metric_id,
        id: greenMetricCategory.green_metric_category_id,
        metricType: greenMetricCategory.metric_type,
        targetCategory: greenMetricCategory.target_category,
        targetType: greenMetricCategory.target_type,
    };
};
exports.decodeGreenMetricCategory = decodeGreenMetricCategory;
const encodeGreenMetricCategory = (greenMetricCategory) => {
    const safeGreenMetric = (0, string_1.makePostgresSafe)(greenMetricCategory);
    return {
        created: safeGreenMetric.created,
        green_metric_category_id: safeGreenMetric.id,
        green_metric_id: safeGreenMetric.greenMetricId,
        metric_type: safeGreenMetric.metricType,
        target_category: safeGreenMetric.targetCategory,
        target_type: safeGreenMetric.targetType,
    };
};
exports.encodeGreenMetricCategory = encodeGreenMetricCategory;
