import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProfile } from "@hooks/crud/profile/useProfile";
import { useProfileProjectAccess } from "@hooks/crud/profileProjectAccess/profileProjectAccess";
import { useProject } from "@hooks/crud/project/useProject";
import { UserTable } from "@stories/molecules/UserTable/UserTable";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import UserModal from "@stories/organisms/UserModal/UserModal";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProfileProjectAccessLineItem } from "social-pro-common/interfaces/profileProjectAccess";
import { createDefaultUser } from "social-pro-common/interfaces/user";

import { ProfileGroups } from "../ProfileGroups/ProfileGroups";

export const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isContractor } = useAuthContext();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const { isProjectLoading, projects } = useProject(userProfile);

  const [selectedProfile, setSelectedProfile] = useState<ProfileLineItem>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const {
    deleteProfile,
    inviteProfile,
    isProfileLoading,
    profiles,
    updateProfile,
  } = useProfile(userOrganisation?.id);

  const {
    createProfileProjectAccess,
    isProfileProjectAccessLoading,
    profileProjectAccessLevels,
    updateProfileProjectAccess,
  } = useProfileProjectAccess(userOrganisation?.id);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedProfile(undefined);
  };
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleUpdateProfileProjectAccess = useCallback(
    async (accessItem: ProfileProjectAccessLineItem, create = true) => {
      if (userProfile?.isAdmin) {
        if (create) {
          await createProfileProjectAccess({
            ...accessItem,
          });
        } else {
          await updateProfileProjectAccess({
            ...accessItem,
          });
        }
      }
    },
    [],
  );

  const handleCreateUser = useCallback(async (profile: ProfileLineItem) => {
    if (userProfile?.isAdmin) {
      const newUser = createDefaultUser(profile.email, isContractor);
      await inviteProfile({
        ...profile,
        id: newUser.id,
      });
    }
  }, []);

  const handleEditUser = useCallback(async (profile: ProfileLineItem) => {
    setSelectedProfile(profile);
    handleOpen();
  }, []);

  const handleUpdateUser = useCallback(
    async (profile: ProfileLineItem) => {
      if (userProfile?.isAdmin) {
        await updateProfile(profile);
      }
    },
    [profiles],
  );

  const handleDeleteUser = useCallback(
    async (profile: ProfileLineItem) => {
      if (userProfile?.isAdmin) {
        await deleteProfile(profile);
      }
    },
    [profiles],
  );

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
    setSearchTerm("");
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tabIndex === 0 && tab) {
      setTabIndex(tab === "permissions" ? 1 : 0);
    }
    setSearchParams();
  }, []);

  const loading =
    isAuthProfileLoading ||
    isProfileLoading ||
    isProjectLoading ||
    isProfileProjectAccessLoading;

  const content = (() => {
    switch (tabIndex) {
      case 0:
        return (
          <UserTable
            loading={loading}
            profiles={profiles}
            searchTerm={searchTerm}
            handleOpen={handleOpen}
            handleEditUser={handleEditUser}
            handleDeleteUser={handleDeleteUser}
          />
        );
      case 1:
        return (
          <ProfileGroups
            loading={loading}
            searchTerm={searchTerm}
            projects={projects}
            profiles={profiles}
            profileProjectAccessLevels={profileProjectAccessLevels}
            isProfileProjectAccessLoading={isProfileProjectAccessLoading}
            updateProfileProjectAccess={handleUpdateProfileProjectAccess}
          />
        );
    }
  })();

  const tabs = [{ label: "Users" }, { label: "Permissions" }];
  return (
    <PageContainer>
      <Header
        loading={loading}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        mainTitle={"Users and Permissions"}
        subTitle={`SocialPro user access and permissions`}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />
      {content}
      {userProfile && userOrganisation ? (
        <UserModal
          open={open}
          loading={loading}
          organisation={userOrganisation}
          profiles={profiles}
          handleClose={handleClose}
          createUser={handleCreateUser}
          updateUser={handleUpdateUser}
          existingUser={selectedProfile}
        />
      ) : null}
    </PageContainer>
  );
};
