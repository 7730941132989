"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeCommitmentAnalysis = exports.decodePackageReport = exports.decodePackageCommitmentReport = exports.decodeMainChartData = exports.decodeChartData = exports.decodeSubcontractorActivity = exports.decodeProgressChartModel = exports.decodePolicyProgress = exports.decodeDashboardTargetModel = exports.decodeDashboardSubcontractorProgress = exports.decodeDashboardSupplierModel = exports.decodeDashboardModel = void 0;
const number_1 = require("../../utils/number");
const forecastData_1 = require("../forecastData");
const socialBusiness_1 = require("../socialBusiness");
const decodeDashboardModel = async (data, getImageUrl) => {
    const top3Subcontractors = await Promise.all(data.top3_subcontractors.map(async (d) => (0, exports.decodeDashboardSubcontractorProgress)(d, getImageUrl)));
    const bottom3Subcontractors = await Promise.all(data.bottom3_subcontractors.map(async (d) => (0, exports.decodeDashboardSubcontractorProgress)(d, getImageUrl)));
    const recommendedSuppliers = await Promise.all(data.recommended_suppliers.map((rs) => (0, exports.decodeDashboardSupplierModel)(rs, getImageUrl)));
    const subcontractorActivity = await Promise.all(data.subcontractor_activity.map((sa) => (0, exports.decodeSubcontractorActivity)(sa, getImageUrl)));
    return {
        bottom3Subcontractors,
        bottom3Targets: data.bottom3_targets.map((d) => (0, exports.decodeDashboardTargetModel)(d)),
        chartData: (0, exports.decodeMainChartData)(data.chart_data),
        hourProgress: (0, exports.decodeProgressChartModel)(data.hour_progress),
        policyProgress: (0, exports.decodePolicyProgress)(data.policy_progress),
        projectPCChange: (0, number_1.formatDecimalPlaces)(data.project_pc_change, 2),
        projectTargetProgress: (0, number_1.formatDecimalPlaces)(data.project_target_progress, 2),
        projectTimeProgress: (0, number_1.formatDecimalPlaces)(data.project_time_progress, 2),
        recommendedSuppliers,
        spendProgress: (0, exports.decodeProgressChartModel)(data.spend_progress),
        subcontractorActivity,
        top3Subcontractors,
        top3Targets: data.top3_targets.map((d) => (0, exports.decodeDashboardTargetModel)(d)),
    };
};
exports.decodeDashboardModel = decodeDashboardModel;
const decodeDashboardSupplierModel = async (data, getImageUrl) => {
    const presignedUrl = data.supplier_logo && data.supplier_logo.length > 0
        ? await getImageUrl(`${socialBusiness_1.SOCIAL_BUSINESS_LOGO_PREFIX}/${data.supplier_logo}`)
        : "";
    return {
        supplierLogo: presignedUrl,
        supplierName: data.supplier_name,
        supplierTargetType: data.supplier_target_type,
    };
};
exports.decodeDashboardSupplierModel = decodeDashboardSupplierModel;
const decodeDashboardSubcontractorProgress = async (data, getImageUrl) => {
    const presignedUrl = data.supplier_logo && data.supplier_logo.length > 0
        ? await getImageUrl(data.supplier_logo)
        : "";
    return {
        pcChange: (0, number_1.formatDecimalPlaces)(data.pc_change, 2),
        supplierLogo: presignedUrl,
        supplierName: data.supplier_name,
    };
};
exports.decodeDashboardSubcontractorProgress = decodeDashboardSubcontractorProgress;
const decodeDashboardTargetModel = (data) => {
    return {
        achievedValue: data.achieved_value,
        isSpend: data.is_spend,
        progress: data.progress,
        targetDescription: data.target_description,
        targetDisplayValue: data.target_display_value,
        targetType: data.target_type,
    };
};
exports.decodeDashboardTargetModel = decodeDashboardTargetModel;
const decodePolicyProgress = (data) => {
    return {
        expected: data.expected,
        submitted: data.submitted,
    };
};
exports.decodePolicyProgress = decodePolicyProgress;
const decodeProgressChartModel = (data) => {
    return {
        achieved: data.achieved,
        pcChange: data.pc_change,
        target: data.target,
    };
};
exports.decodeProgressChartModel = decodeProgressChartModel;
const decodeSubcontractorActivity = async (data, getImageUrl) => {
    const presignedUrl = data.organisation_logo.length > 0
        ? await getImageUrl(data.organisation_logo)
        : "";
    return {
        created: new Date(data.created),
        organisationLogo: presignedUrl,
        organisationName: data.organisation_name,
        reportId: data.report_id,
    };
};
exports.decodeSubcontractorActivity = decodeSubcontractorActivity;
const decodeChartData = (data) => {
    return {
        achievedData: data.achieved_data,
        id: data.id,
    };
};
exports.decodeChartData = decodeChartData;
const decodeMainChartData = (data) => {
    return {
        forecast: data.forecasts.map((d) => (0, forecastData_1.decodeForecastModel)(d)),
        greenMetrics: (0, exports.decodeChartData)(data.green_metrics),
        labourHours: (0, exports.decodeChartData)(data.labour_hours),
        socialSpend: (0, exports.decodeChartData)(data.social_spend),
    };
};
exports.decodeMainChartData = decodeMainChartData;
const decodePackageCommitmentReport = (data) => {
    return {
        contractorPackageId: data.contractor_package_id,
        packageCommitmentId: data.package_commitment_id,
        projectCommitmentId: data.project_commitment_id,
        projectId: data.project_id,
        reportId: data.report_id,
        targetToDate: data.target_to_date,
        valueAchieved: data.value_achieved,
        valueToDate: data.value_to_date,
    };
};
exports.decodePackageCommitmentReport = decodePackageCommitmentReport;
const decodePackageReport = (data) => {
    return {
        contractorPackageId: data.contractor_package_id,
        currentMonthTarget: data.current_month_target,
        currentMonthTotal: data.current_month_total,
        currentTarget: data.current_target,
        currentTotal: data.current_total,
        packageCommitmentId: data.package_commitment_id,
        packageCommitmentTotal: data.package_commitment_total,
        packageCommitmentValue: data.package_commitment_value,
        projectCommitmentId: data.project_commitment_id,
        projectId: data.project_id,
    };
};
exports.decodePackageReport = decodePackageReport;
const decodeCommitmentAnalysis = (commitmentAnalysis) => {
    return {
        id: commitmentAnalysis.project_commitment_id,
        reported: commitmentAnalysis.reported,
        target: commitmentAnalysis.target,
    };
};
exports.decodeCommitmentAnalysis = decodeCommitmentAnalysis;
