"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultReportDocument = void 0;
const uuid_1 = require("uuid");
const createDefaultReportDocument = (contractorPackageId, reportId, uri, reportDocumentType) => {
    return {
        contractorPackageId,
        created: new Date(),
        id: (0, uuid_1.v4)(),
        reportDocumentType,
        reportId,
        uri,
    };
};
exports.createDefaultReportDocument = createDefaultReportDocument;
