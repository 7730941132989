"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProjectLabourHourCommitment = exports.decodeProjectLabourHourCommitment = void 0;
const string_1 = require("../../utils/string");
const decodeProjectLabourHourCommitment = (socialCommitment) => {
    return {
        abns: socialCommitment.abns,
        achievedValue: socialCommitment.achieved_value,
        colour: socialCommitment.colour,
        created: new Date(socialCommitment.created),
        employmentLevel: socialCommitment.employment_level,
        gender: socialCommitment.gender,
        id: socialCommitment.project_labour_hour_commitment_id,
        maxDistance: socialCommitment.max_distance
            ? socialCommitment.max_distance
            : undefined,
        occupationType: socialCommitment.occupation_type,
        postCodes: socialCommitment.post_codes,
        projectId: socialCommitment.project_id,
        socialFactors: socialCommitment.social_factors,
        targetDescription: socialCommitment.target_description
            ? socialCommitment.target_description
            : undefined,
        targetName: socialCommitment.target_type,
        targetValue: socialCommitment.target_value,
        targetValueRealised: socialCommitment.target_value_realised,
    };
};
exports.decodeProjectLabourHourCommitment = decodeProjectLabourHourCommitment;
const encodeProjectLabourHourCommitment = (socialCommitment) => {
    const safeSocialCommitment = (0, string_1.makePostgresSafe)(socialCommitment);
    return {
        abns: socialCommitment.abns,
        achieved_value: safeSocialCommitment.achievedValue,
        colour: safeSocialCommitment.colour,
        created: safeSocialCommitment.created,
        employment_level: socialCommitment.employmentLevel,
        gender: socialCommitment.gender,
        max_distance: safeSocialCommitment.maxDistance,
        occupation_type: socialCommitment.occupationType,
        post_codes: socialCommitment.postCodes,
        project_id: safeSocialCommitment.projectId,
        project_labour_hour_commitment_id: safeSocialCommitment.id,
        social_factors: socialCommitment.socialFactors,
        target_description: safeSocialCommitment.targetDescription,
        target_type: safeSocialCommitment.targetName,
        target_value: safeSocialCommitment.targetValue,
        target_value_realised: safeSocialCommitment.targetValueRealised,
    };
};
exports.encodeProjectLabourHourCommitment = encodeProjectLabourHourCommitment;
