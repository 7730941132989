"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectToPackageSocialSpendCommitment = exports.cloneProjectSpendCommitmentsForPackage = exports.socialSpendCommitmentTypeToDescription = exports.socialSpendCommitmentTypeToAbbreviatedString = exports.socialSpendCommitmentTypeToString = exports.SocialCommitmentTypeSpend = exports.projectLevelSpendCommitmentType = void 0;
const uuid_1 = require("uuid");
exports.projectLevelSpendCommitmentType = [];
var SocialCommitmentTypeSpend;
(function (SocialCommitmentTypeSpend) {
    SocialCommitmentTypeSpend["DefaultSpend"] = "social-commitment-type_default_spend";
    SocialCommitmentTypeSpend["SocialDisabilityEnterpriseSpend"] = "social-commitment-type_social-disability-enterprise-spend";
    SocialCommitmentTypeSpend["AboriginalTorresStraitIslanderBusinessSpend"] = "social-commitment-type_aboriginal-torres-strait-island-business-spend";
})(SocialCommitmentTypeSpend || (exports.SocialCommitmentTypeSpend = SocialCommitmentTypeSpend = {}));
const socialSpendCommitmentTypeToString = (socialCommitmentType) => {
    switch (socialCommitmentType) {
        case SocialCommitmentTypeSpend.DefaultSpend:
            return "Custom Spend";
        case SocialCommitmentTypeSpend.SocialDisabilityEnterpriseSpend:
            return "Social Disability Enterprise Spend";
        case SocialCommitmentTypeSpend.AboriginalTorresStraitIslanderBusinessSpend:
            return "Indigenous Business Spend";
    }
};
exports.socialSpendCommitmentTypeToString = socialSpendCommitmentTypeToString;
const socialSpendCommitmentTypeToAbbreviatedString = (targetName) => {
    switch (targetName) {
        default:
        case SocialCommitmentTypeSpend.DefaultSpend:
            return "Custom";
        case SocialCommitmentTypeSpend.SocialDisabilityEnterpriseSpend:
            return "SDES";
        case SocialCommitmentTypeSpend.AboriginalTorresStraitIslanderBusinessSpend:
            return "Indigenous Spend";
    }
};
exports.socialSpendCommitmentTypeToAbbreviatedString = socialSpendCommitmentTypeToAbbreviatedString;
const socialSpendCommitmentTypeToDescription = (socialCommitmentType) => {
    switch (socialCommitmentType) {
        default:
        case SocialCommitmentTypeSpend.DefaultSpend:
            return "Custom Spend: Description missing - please contact support";
        case SocialCommitmentTypeSpend.SocialDisabilityEnterpriseSpend:
            return "Value of goods, services and construction carried out by certified Social Enterprises or Australian Disability Enterprises under the Social Procurement Framework (SPF).";
        case SocialCommitmentTypeSpend.AboriginalTorresStraitIslanderBusinessSpend:
            return "An organisation, operating a business, that is 50% or more Indigenous-owned, as certified by Kinaway or Supply Nation. A social enterprise or registered charity may also be an Indigenous enterprise if it is operating a business.";
    }
};
exports.socialSpendCommitmentTypeToDescription = socialSpendCommitmentTypeToDescription;
const cloneProjectSpendCommitmentsForPackage = (packageId, commitments) => {
    return commitments
        .map((c) => {
        return {
            ...c,
            achievedValue: 0,
            id: (0, uuid_1.v4)(),
            packageId,
            projectSocialSpendCommitmentId: c.id,
        };
    })
        .filter((c) => !exports.projectLevelSpendCommitmentType.includes(c.targetName));
};
exports.cloneProjectSpendCommitmentsForPackage = cloneProjectSpendCommitmentsForPackage;
const projectToPackageSocialSpendCommitment = (packageId, commitment) => {
    return {
        achieved_value: commitment.achieved_value,
        contractor_package_id: packageId,
        created: commitment.created,
        package_social_spend_commitment_id: (0, uuid_1.v4)(),
        project_id: commitment.project_id,
        project_social_spend_commitment_id: commitment.project_social_spend_commitment_id,
        target_description: commitment.target_description,
        target_type: commitment.target_type,
        target_value: commitment.target_value,
        target_value_realised: commitment.target_value_realised,
    };
};
exports.projectToPackageSocialSpendCommitment = projectToPackageSocialSpendCommitment;
