import { Box, Grid, Typography } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { userHasAdminAccess, userHasManagerAccess } from "@utils/projectAccess";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  ArchiveProjectIcon,
  EditProjectIcon,
  ReloadIcon,
  ViewProjectIcon,
} from "assets/constants";
import { useState } from "react";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";

import { ProjectRowSkeleton } from "./ProjectRowSkeleton";
import {
  CollaboratorsActionCell,
  ProjectNameActionCell,
  StatusCell,
} from "./ProjectTableCells";
import box from "../../../assets/images/dashboard/box.png";
import { generateTableHead, Table } from "../Table/Table";
import {
  ActionCell,
  CellAction,
  centerCellStyles,
  defaultCellConfig,
  ProgressCell,
} from "../Table/TableCells";
import TableSkeleton from "../Table/TableSkeleton";

interface ProjectTableProps {
  loading: boolean;
  isContractor: boolean;
  userProfile: ProfileLineItem;
  projects: ProjectDetailLineItem[];
  archived: boolean;
  handleViewProject: (project: ProjectDetailLineItem) => void;
  handleEditProject: (project: ProjectDetailLineItem) => void;
  handleArchiveProject: (project: ProjectDetailLineItem) => void;
  handleAcceptPackage: (projectId: string, contractorPackageId: string) => void;
  handleOpeEditModal: () => void;
}

export const ProjectTable = ({
  archived,
  handleAcceptPackage,
  handleArchiveProject,
  handleEditProject,
  handleOpeEditModal,
  handleViewProject,
  isContractor,
  loading,
  projects,
  userProfile,
}: ProjectTableProps) => {
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedProject, setSelectedProject] =
    useState<ProjectDetailLineItem | null>(null);

  const handleOpenConfirmDialog = (project: ProjectDetailLineItem) => {
    setSelectedProject(project);
    setOpenConfirmDialog(true);
  };

  const sortedProjects = projects.sort(
    (a: ProjectDetailLineItem, b: ProjectDetailLineItem) =>
      a.created.getTime() - b.created.getTime(),
  );

  const handleRowClick = (data: any) => {
    const project = data as ProjectDetailLineItem;
    if (project.isAccepted) {
      handleViewProject(project);
    } else {
      setOpenAcceptDialog(true);
      setSelectedProject(project);
    }
  };

  const cellActions = [
    {
      action: handleRowClick,
      icon: <ViewProjectIcon />,
      title: "View Project",
    },
    {
      action: handleEditProject,
      adminOnly: true,
      icon: <EditProjectIcon />,
      title: "Edit Project",
      validateEnabled: (project: ProjectDetailLineItem) =>
        userHasManagerAccess(project),
    },
    {
      action: handleOpenConfirmDialog,
      adminOnly: true,
      icon: archived ? <ReloadIcon /> : <ArchiveProjectIcon />,
      title: archived ? "Restore Project" : "Archive Project",
      validateEnabled: (project: ProjectDetailLineItem) =>
        userHasAdminAccess(project),
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...defaultCellConfig,
      cellRenderer: StatusCell,
      cellStyle: centerCellStyles,
      field: "status",
      flex: 0.5,
      headerClass: "centered-table-header",
      headerName: "Status",
      minWidth: 100,
      onCellClicked: (params) => handleRowClick(params.data),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ProjectNameActionCell,
      field: "projectName",
      headerName: "Project Name",
      minWidth: 220,
      onCellClicked: (params) => handleRowClick(params.data),
    },
    {
      ...defaultCellConfig,
      autoHeight: true,
      field: "location",
      headerName: "Location",
      minWidth: 200,
      onCellClicked: (params) => handleRowClick(params.data),
      valueFormatter: (params) =>
        `${params.data.projectAddress.addressLine1} ${params.data.projectAddress.postCode}`,
      wrapText: true,
    },
    {
      ...defaultCellConfig,
      cellRenderer: CollaboratorsActionCell,
      field: "collaborators",
      headerName: "Collaborators",
      minWidth: 200,
      onCellClicked: (params) => handleRowClick(params.data),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ProgressCell,
      field: "progress",
      headerClass: "left-table-header",
      headerName: "Progress",
      minWidth: 200,
      onCellClicked: (params) => handleRowClick(params.data),
    },
    {
      ...defaultCellConfig,
      cellRenderer: ActionCell,
      cellRendererParams: { userProfile },
      cellStyle: centerCellStyles,
      field: "",
      flex: 0.3,
      headerClass: "centered-table-header",
      headerName: "",
      minWidth: 50,
    },
  ]);

  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{ backgroundColor: "white", borderRadius: "16px" }}
    >
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ProjectRowSkeleton}
        />
      ) : null}
      {!loading && projects.length > 0 ? (
        <Table
          columnDefs={colDefs}
          loading={loading}
          data={sortedProjects || []}
          context={{ cellActions }}
          rowHeight={72}
        />
      ) : null}
      {!loading && projects.length === 0 ? (
        <Grid
          data-test-id="empty-project-table"
          item
          xs={12}
          md={12}
          sx={{
            alignItems: "center",
            background: "#E9F0F5",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            minHeight: "400px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
            }}
          >
            <img src={box} alt="box" />
            {!archived && isContractor && userProfile.isAdmin ? (
              <>
                <Typography
                  variant="h4"
                  style={{
                    color: "#87959F",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  Create a project
                </Typography>

                <StyledButton
                  id={"add-first-project-button"}
                  className="blackBtn"
                  loading={loading}
                  variant="contained"
                  onClick={handleOpeEditModal}
                >
                  Add Project
                </StyledButton>
              </>
            ) : (
              <Typography
                variant="h4"
                style={{
                  color: "#87959F",
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                No projects
              </Typography>
            )}
          </Box>
        </Grid>
      ) : null}
      <ConfirmationDialog
        message={
          selectedProject?.archived
            ? "Are you sure you want to restore this project?"
            : "Are you sure you want to archive this project?"
        }
        open={openConfirmDialog}
        title={
          selectedProject?.archived ? "Restore Project" : "Archive Project"
        }
        buttonText={selectedProject?.archived ? "Restore" : "Archive"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          if (selectedProject) {
            handleArchiveProject(selectedProject);
          }
          setOpenConfirmDialog(false);
        }}
      />
      <ConfirmationDialog
        message={"Do you accept this package of works?"}
        open={openAcceptDialog}
        title={"Accept Package"}
        buttonText={"Accept"}
        intent={"error"}
        onCancel={() => setOpenAcceptDialog(false)}
        onConfirm={async () => {
          if (selectedProject) {
            await handleAcceptPackage(
              selectedProject.id,
              selectedProject.contractorPackageId,
            );
            await handleViewProject(selectedProject);
          }
          setOpenAcceptDialog(false);
        }}
      />
    </Grid>
  );
};
